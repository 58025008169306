import "./Footer.css";
// import "../photo/ankit.jpg";
// import ankit_img from "../Public/rafting2.jpg";
import rafting from "../Public/rafting10.jpg";
import camping from "../Public/camping.jpg";
import bungee from "../Public/bungee.jpg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer_back_mobile">
      <div className="footer_mobile">
        <div className="location_div_mobile">
          <Link class="location_img_name_mobile" to={"/rafting-in-rishikesh"}>
            <div class="location_imh_mobile">
              <img class="location_image" src={rafting} alt="" loading="lazy" />
            </div>
            <div class="location_name_mobile">Rafting in Rishikesh</div>
          </Link>
          <Link to={"/camping-in-rishikesh"} class="location_img_name_mobile">
            <div class="location_imh_mobile">
              <img class="location_image" src={camping} alt="" loading="lazy" />
            </div>
            <div class="location_name_mobile">Camping in Rishikesh</div>
          </Link>
          <Link
            to={"/bungee-jumping-in-rishikesh"}
            class="location_img_name_mobile"
          >
            <div class="location_imh_mobile">
              <img class="location_image" src={bungee} alt="" loading="lazy" />
            </div>
            <div class="location_name_mobile">Bungee Jumping in Rishikesh</div>
          </Link>
        </div>
        <div className="line_mobile"></div>
        <div className="content_div">
          <h2 className="content_heading color-orange">
            {" "}
            <span className="color-orange">Thrill</span>{" "}
            <span className="color-ornage">Thirsty</span> - feel the thrill
          </h2>
          <p className="content_discription color-blue">
            Feel free to navigate anywhere
          </p>
        </div>
        <div className="option_div">
          <Link to={"/about"} class="about_name-mobile">
            About
          </Link>
          <Link to={"/privacy-policy"} class="about_name-mobile">
            Privacy Policy
          </Link>
          {/* <div class="about_name-mobile">Support</div> */}
          <Link to={"/contact"} class="about_name-mobile">
            Contact
          </Link>
          <Link to={"/terms-and-conditions"} class="about_name-mobile">
            Terms & Conditions
          </Link>
          <Link to={"/payment-and-booking"} class="about_name-mobile">
            Payment and Booking
          </Link>
          {/* <div class="about_name-mobile">Jobs</div> */}
          {/* <div class="about_name-mobile">Log in</div> */}
          {/* <div class="about_name-mobile">Sign up</div> */}
          {/* <div class="about_name-mobile">Agents</div> */}
        </div>
        <div className="footer-space"></div>
        <div className="line"></div>
        <div className="logo_div_mobile">
          <Link
            to={
              "https://www.facebook.com/profile.php?id=61554704606376&mibextid=ZbWKwL"
            }
          >
            <svg
              className="fill-orange footer-svg"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path d="M12 2.03998C6.5 2.03998 2 6.52998 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.84998C10.44 7.33998 11.93 5.95998 14.22 5.95998C15.31 5.95998 16.45 6.14998 16.45 6.14998V8.61998H15.19C13.95 8.61998 13.56 9.38998 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96C15.9164 21.5878 18.0622 20.3855 19.6099 18.57C21.1576 16.7546 22.0054 14.4456 22 12.06C22 6.52998 17.5 2.03998 12 2.03998Z"></path>{" "}
              </g>
            </svg>
          </Link>
          <Link
            to={
              "https://www.instagram.com/thrillthirsty?igsh=MXFnM2xoYW5zN2Uwcw=="
            }
          >
            <svg
              className="fill-orange footer-svg"
              version="1.1"
              id="Layer_1"
              viewBox="-143 145 512 512"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <path d="M113,446c24.8,0,45.1-20.2,45.1-45.1c0-9.8-3.2-18.9-8.5-26.3c-8.2-11.3-21.5-18.8-36.5-18.8s-28.3,7.4-36.5,18.8 c-5.3,7.4-8.5,16.5-8.5,26.3C68,425.8,88.2,446,113,446z"></path>{" "}
                  <polygon points="211.4,345.9 211.4,308.1 211.4,302.5 205.8,302.5 168,302.6 168.2,346 "></polygon>{" "}
                  <path d="M183,401c0,38.6-31.4,70-70,70c-38.6,0-70-31.4-70-70c0-9.3,1.9-18.2,5.2-26.3H10v104.8C10,493,21,504,34.5,504h157 c13.5,0,24.5-11,24.5-24.5V374.7h-38.2C181.2,382.8,183,391.7,183,401z"></path>{" "}
                  <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M241,374.7v104.8 c0,27.3-22.2,49.5-49.5,49.5h-157C7.2,529-15,506.8-15,479.5V374.7v-52.3c0-27.3,22.2-49.5,49.5-49.5h157 c27.3,0,49.5,22.2,49.5,49.5V374.7z"></path>{" "}
                </g>{" "}
              </g>
            </svg>
          </Link>
          <Link to={"https://youtube.com/@thrillthirsty?si=3i7r5DMtRcfEz6Jw"}>
            <svg
              className="fill-orange footer-svg"
              version="1.1"
              id="Layer_1"
              viewBox="-143 145 512 512"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <polygon points="78.9,450.3 162.7,401.1 78.9,351.9 "></polygon>
                  <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M241,446.8L241,446.8 c0,44.1-44.1,44.1-44.1,44.1H29.1c-44.1,0-44.1-44.1-44.1-44.1v-91.5c0-44.1,44.1-44.1,44.1-44.1h167.8c44.1,0,44.1,44.1,44.1,44.1 V446.8z"></path>
                </g>
              </g>
            </svg>
          </Link>
          <Link to={"https://www.linkedin.com/company/thrill-thirsty/"}>
            <svg
              className="fill-orange footer-svg"
              version="1.1"
              id="Layer_1"
              viewBox="-143 145 512 512"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M41.4,508.1H-8.5V348.4h49.9 V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7c0-15.8,12.1-27.7,30.5-27.7c18.4,0,29.7,11.9,30.1,27.7 C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6c0-21.6-8.8-36.4-28.3-36.4c-14.9,0-23.2,10-27,19.6 c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1c3.3-11,21.2-26.6,49.8-26.6c35.5,0,63.3,23,63.3,72.4V508.1z "></path>{" "}
              </g>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Footer;
