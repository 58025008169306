import "./PhotoSection.css";
import { useState, useEffect } from "react";
import music from "../Public/music.mp3";

function Smallphotosection({ img, active, time, onClick }) {
  return (
    <div className="wps-b-indi-img" onClick={() => onClick(img)}>
      {active ? (
        <div className="wps-bim-timeline-outer">
          <div className="wps-bim-timeline">
            <div className="wps-bim-timeline-inner"></div>
          </div>
        </div>
      ) : null}
      <img src={img} alt="" className="wps-b-indi-img-img" loading="lazy" />
    </div>
  );
}
function PhotoSection({ setPhotoSection, photos }) {
  const [ObjectFit, setObjectFit] = useState("contain");
  const [current_img, setCurrent_img] = useState(0);
  const [time, setTime] = useState(10);
  const [music_onoff, setMusic_onoff] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          // Reset time when it reaches 0
          return 10;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (time === 0) {
      setCurrent_img((prevIndex) => (prevIndex + 1) % photos.length);
      setTime(10); // Reset time
    }
  }, [time, photos.length]);

  const handleImageClick = (clickedImgIndex) => {
    setCurrent_img(clickedImgIndex);
    setTime(10); // Restart the animation
  };
  return (
    <div className="whole-photo-section">
      <audio src={music} autoPlay loop muted={music_onoff}></audio>
      <div className="whole-photo-section-top">
        <img
          src={photos[current_img]}
          style={{ "object-fit": ObjectFit }}
          alt=""
          className="wps-top-img"
          loading="lazy"
        />
        <div
          className="wps-cut-div-photo"
          onClick={() => {
            setPhotoSection(false);
          }}
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="wps-cut-svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                className="fill-black"
              ></path>{" "}
            </g>
          </svg>
        </div>
        <div className="wps-top-cc-div">
          <div
            className="wps-cc-svg-div"
            onClick={() => {
              setObjectFit("cover");
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="wps-cc-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g id="System / Mobile_Button">
                  {" "}
                  <path
                    id="Vector"
                    d="M7 6.2002V17.8002C7 18.9203 7 19.4801 7.21799 19.9079C7.40973 20.2842 7.71547 20.5905 8.0918 20.7822C8.5192 21 9.07899 21 10.1969 21H13.8031C14.921 21 15.48 21 15.9074 20.7822C16.2837 20.5905 16.5905 20.2842 16.7822 19.9079C17 19.4805 17 18.9215 17 17.8036V6.19691C17 5.07899 17 4.5192 16.7822 4.0918C16.5905 3.71547 16.2837 3.40973 15.9074 3.21799C15.4796 3 14.9203 3 13.8002 3H10.2002C9.08009 3 8.51962 3 8.0918 3.21799C7.71547 3.40973 7.40973 3.71547 7.21799 4.0918C7 4.51962 7 5.08009 7 6.2002Z"
                    className="stroke-black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>{" "}
              </g>
            </svg>
          </div>
          <div className="wps-line"></div>
          <div
            className="wps-cc-svg-div"
            onClick={() => {
              setObjectFit("contain");
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="wps-cc-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <rect
                  x="4"
                  y="4"
                  width="16"
                  height="16"
                  rx="2"
                  className="stroke-black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></rect>{" "}
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className="whole-photo-section-bottom">
        {photos.map((item, index) => {
          return (
            <Smallphotosection
              img={item}
              active={index == current_img}
              time={time}
              onClick={() => handleImageClick(index)}
            />
          );
        })}

        <div></div>
      </div>
    </div>
  );
}

export default PhotoSection;
