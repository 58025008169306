import "./InputText.css";
function TextInput({ title, placeholder, type, set, get }) {
  return (
    <div className="d-whole-text-input">
      <div className="d-wti-title">{title}</div>
      <input
        value={get}
        onInput={(t) => {
          set(t.target.value);
        }}
        type={type}
        className="d-wti-input"
        placeholder={placeholder}
      />
    </div>
  );
}

export default TextInput;
