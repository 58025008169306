import "./Contact_page.css";
import wallpaper from "../Public/Destination.jpg";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "../GlobalComponents/Footer";
import DesktopFooter from "./DesktopFooter";
import BottomTab from "../ComponentsHome/BottomTab";
import { useEffect } from "react";

function Contact_page() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about_header">
        <Header />
      </div>

      <div className="About_page_cp">
        <Helmet>
          <title>Thrill Thirsty: contact us</title>
          <meta
            name="description"
            content="you can contact us via email or our contact number or you can directly reach to our office."
          />
        </Helmet>
        <div className="About_text_cp">Contact</div>
        <div className="about_image-div_cp">
          <img
            src={require("../Public/contact.jpg")}
            alt=""
            className="about-image_cp"
            loading="lazy"
            style={{ objectFit: "cover" }}
          />
          {/* <div className="About_img-content">Who We Are? </div> */}
        </div>

        <div className="content_about-text-div_cp">
          <div className="about-two-div-content_cp">
            <div className="about-heading-text_cp">
              We're Ready, Let's Talk.
            </div>
            <input
              type="text"
              className="name-input_cp"
              placeholder="Your name"
            />
            <input
              type="text"
              className="name-input_cp"
              placeholder="Email Address"
            />
            <input
              type="text"
              className="name-input_cp"
              placeholder="Message"
            />
            <button className="send-contact-msg_btn_cp">Send Message</button>
          </div>
          <div className="about-two-div-content_cp">
            <div className="about-heading-text_cp">
              We're Ready, Let's Talk.
            </div>
            <div className="about-heading-us-text_cp">Address​</div>
            <div className="about-heading-fill-text_cp">
              Top floor, Kothiyal Bhawan, Narendra Nagar, Tehri Garhwal,
              Uttarakhand, India 249175
            </div>
            <div className="about-heading-us-text_cp">Email Us</div>
            <div className="about-heading-fill-text_cp">
              contact@thrillthirsty.com
            </div>
            <div className="about-heading-us-text_cp">Call us</div>
            <div className="about-heading-fill-text_cp">
              +91-9084040280 , +91-7302040324
            </div>
            {/* <div className="about-heading-us-text_cp">Follow us</div>
            <div className="about-heading-follow-svg_cp">
              <svg
                height={40}
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <circle cx="24" cy="24" r="20" fill="#3B5998"></circle>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.315 16.9578C28.6917 16.8331 27.8498 16.74 27.3204 16.74C25.8867 16.74 25.7936 17.3633 25.7936 18.3607V20.1361H29.3774L29.065 23.8137H25.7936V35H21.3063V23.8137H19V20.1361H21.3063V17.8613C21.3063 14.7453 22.7708 13 26.4477 13C27.7252 13 28.6602 13.187 29.8753 13.4363L29.315 16.9578Z"
                    fill="white"
                  ></path>
                </g>
              </svg>
              <svg
                height={40}
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <circle cx="24" cy="24" r="20" fill="#1DA1F2"></circle>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M36 16.3086C35.1177 16.7006 34.1681 16.9646 33.1722 17.0838C34.1889 16.4742 34.9697 15.5095 35.3368 14.36C34.3865 14.9247 33.3314 15.3335 32.2107 15.5551C31.3123 14.5984 30.0316 14 28.6165 14C25.8975 14 23.6928 16.2047 23.6928 18.9237C23.6928 19.3092 23.7368 19.6852 23.8208 20.046C19.7283 19.8412 16.1005 17.8805 13.6719 14.9015C13.2479 15.6287 13.0055 16.4742 13.0055 17.3766C13.0055 19.0845 13.8735 20.5916 15.1958 21.4747C14.3878 21.4491 13.6295 21.2275 12.9647 20.8587V20.9203C12.9647 23.3066 14.663 25.296 16.9141 25.7496C16.5013 25.8616 16.0661 25.9224 15.6174 25.9224C15.2998 25.9224 14.991 25.8912 14.6902 25.8336C15.3166 27.7895 17.1357 29.2134 19.2899 29.2534C17.6052 30.5733 15.4822 31.3612 13.1751 31.3612C12.7767 31.3612 12.3848 31.338 12 31.2916C14.1791 32.6884 16.7669 33.5043 19.5475 33.5043C28.6037 33.5043 33.5562 26.0016 33.5562 19.4956C33.5562 19.282 33.5522 19.0693 33.5418 18.8589C34.5049 18.1629 35.34 17.2958 36 16.3086Z"
                    fill="white"
                  ></path>
                </g>
              </svg>
              <svg
                height={35}
                viewBox="0 0 72 72"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g fill="none" fill-rule="evenodd">
                    {" "}
                    <path
                      d="M36,72 L36,72 C55.882251,72 72,55.882251 72,36 L72,36 C72,16.117749 55.882251,-3.65231026e-15 36,0 L36,0 C16.117749,3.65231026e-15 -2.4348735e-15,16.117749 0,36 L0,36 C2.4348735e-15,55.882251 16.117749,72 36,72 Z"
                      fill="#FF0002"
                    ></path>{" "}
                    <path
                      d="M31.044,42.269916 L31.0425,28.6877416 L44.0115,35.5022437 L31.044,42.269916 Z M59.52,26.3341627 C59.52,26.3341627 59.0505,23.003199 57.612,21.5363665 C55.7865,19.610299 53.7405,19.6012352 52.803,19.4894477 C46.086,19 36.0105,19 36.0105,19 L35.9895,19 C35.9895,19 25.914,19 19.197,19.4894477 C18.258,19.6012352 16.2135,19.610299 14.3865,21.5363665 C12.948,23.003199 12.48,26.3341627 12.48,26.3341627 C12.48,26.3341627 12,30.2467232 12,34.1577731 L12,37.8256098 C12,41.7381703 12.48,45.6492202 12.48,45.6492202 C12.48,45.6492202 12.948,48.9801839 14.3865,50.4470165 C16.2135,52.3730839 18.612,52.3126583 19.68,52.5135736 C23.52,52.8851913 36,53 36,53 C36,53 46.086,52.9848936 52.803,52.4954459 C53.7405,52.3821478 55.7865,52.3730839 57.612,50.4470165 C59.0505,48.9801839 59.52,45.6492202 59.52,45.6492202 C59.52,45.6492202 60,41.7381703 60,37.8256098 L60,34.1577731 C60,30.2467232 59.52,26.3341627 59.52,26.3341627 L59.52,26.3341627 Z"
                      fill="#FFF"
                    ></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div> */}
          </div>
        </div>
      </div>

      <div className="about_footer_desktop">
        <DesktopFooter />
      </div>
      <div className="about_footer_mobile">
        <Footer />
        <BottomTab />
      </div>
    </>
  );
}

export default Contact_page;
