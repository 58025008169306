import "./GlobalPhotoStatus.css";
// import rafting from "../Public/rafting-p2.jpg";
// import logo from "../Public/logo.jpeg";
// import pp from "../Public/rafting7.jpg";
import music from "../Public/music.mp3";
import { useState, useEffect } from "react";
function GlobalPhotoStatus({ setStatus, desktop_status, desktop_status_indi }) {
  const [music_on_off, setMusic_on_off] = useState(false);
  const [current_index, setCurrent_index] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrent_index((prevIndex) =>
        prevIndex === desktop_status_indi.length - 1 ? 0 : prevIndex + 1
      ); // Increment image index or reset to 0 when reaching the end
    }, 5000); // Change the interval to trigger every 15 seconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="whole-photo-status">
      <audio src={music} autoPlay muted={music_on_off} loop></audio>
      <div className="wps-top-dark">
        <div className="wps-time-indicator-whole-div">
          <div className="wps-time-indicator-outer">
            <div className="wps-time-indicator-inner"></div>
          </div>
        </div>
        <div className="wps-title-section">
          <div className="wps-profile-photo">
            <img
              src={desktop_status_indi[current_index].smallimg}
              alt=""
              className="wps-profile-photo-img"
              loading="lazy"
            />
          </div>
          <div className="wps-profile-content">
            <div className="wps-pp-content-title">
              {desktop_status_indi[current_index].title}
            </div>
            <div className="wps-pp-content-description">
              {desktop_status_indi[current_index].des}
            </div>
          </div>
          <div
            className="wps-cut-div"
            onClick={() => {
              setStatus(false);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="wps-cut-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                  className="fill-white"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div>
      </div>
      {/* <div className="wps-book-now-inner">
        <div className="wps-bn-1">
          <div className="wps-bn-pp-div">
            <img
              src={desktop_status_indi[current_index].img}
              alt=""
              className="wps-bn-pp-img"
            />
          </div>
          <div>
            <div className="wps-bn-title">12 km River Rafting</div>
            <div className="wps-bn-description">₹899/person · 5 rapids</div>
          </div>
        </div>
        <div className="wps-bn-2">
          <button className="wps-bn-2-btn">
            <div className="wps-bn-2-btn-text">Book Now</div>
            <div className="wps-bn-2-svg-div">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="wps-bn-2-svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                    className="fill-white"
                  ></path>{" "}
                </g>
              </svg>
            </div>
          </button>
        </div>
      </div> */}
      <img
        src={desktop_status_indi[current_index].img}
        alt=""
        className="whole-photo-status-img"
        loading="lazy"
      />
    </div>
  );
}

export default GlobalPhotoStatus;
