import "./EnterPerson.css";
export function EnterPerson({ placeholder, set, get }) {
  return (
    <input
      type="number"
      placeholder={placeholder}
      className="input-no-persons"
      value={get}
      onInput={(t) => {
        set(t.target.value);
      }}
    />
  );
}

export function Persons({ persons, set, get }) {
  return (
    <div
      className={
        parseInt(get) < parseInt(persons) || get == "" || get == undefined
          ? "gf-persons"
          : "gf-persons-active"
      }
      onClick={() => {
        set(persons);
      }}
    >
      {persons}
    </div>
  );
}
