import "./About_us.css";
import wallpaper from "../Public/Destination.jpg";
import bungee from "../Public/bungee.jpg";
import Header from "./Header";
import Footer from "../GlobalComponents/Footer";
import DesktopFooter from "./DesktopFooter";
import BottomTab from "../ComponentsHome/BottomTab";
import { useEffect } from "react";

function About_us() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about_header">
        <Header />
      </div>

      <div className="About_page">
        <div className="About_text">About</div>
        <div className="about_image-div">
          <img
            src={require("../Public/about.jpg")}
            alt=""
            className="about-image"
            loading="lazy"
            style={{
              objectFit: "cover",
            }}
          />
        </div>
        <div className="about-imgae-content">
          <div className="about-us-content">
            <div className="about-us-text">About us</div>
            <div className="about-us-discription">
              <p>
                We at Thrill Thirsty want to give you the best adventure
                activities and tour and travel service in Uttrakhand right now
                we are starting this from Rishikesh.
              </p>
              <p>
                Right now we are providing River rafting, camping, and Bungee
                Jumping in Rishikesh.
              </p>
              <p>
                In the near future, we plan to expand our services to include
                guided treks and wildlife safaris throughout the stunning
                landscapes of Uttarakhand.
              </p>
              <p>
                Our team is committed to creating unforgettable adventure
                experiences with a high regard for safety and environmental
                sustainability. We believe in delivering personalized service
                with the utmost professionalism, ensuring your journey with us
                is thrilling, enjoyable, and memorable.
              </p>
              <p>
                One of the most unique features we have is the Trip calculator
                with this feature you can add to your card all the activities
                like river rafting, camping, and bungee jumping in place and you
                can calculate the final price this will help you to make
                customized package for your trip to Rishikesh.
              </p>
              <p>
                In the payment gateway, the documents are authorized under the
                name of Ashish Pundir.
              </p>
            </div>
          </div>
          <div className="about_con_img">
            <img
              src={bungee}
              alt=""
              className="about-image"
              loading="lazy"
              style={{
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <div className="content_about-text-div">
          <div className="about-two-div-content">
            <div className="about-heading-text">Mission:</div>
            <div className="about-discription-text">
              At Thrill Thirsty, we're on a mission to spark adventure in every
              traveler, satisfying the thirst for thrills through unforgettable
              journeys in Rishikesh and Uttarakhand. Our commitment is to
              deliver safe, sustainable, and immersive experiences that forge a
              deep connection with nature, fostering personal growth and
              cultural appreciation.
            </div>
          </div>
          <div className="about-two-div-content">
            <div className="about-heading-text">Vision:</div>
            <div className="about-discription-text">
              Thrill Thirsty envisions a world where adventure is embraced, and
              environmental stewardship is paramount. We strive to be a leading
              force in adventure travel, known for excellence, innovation, and
              responsible tourism. Our goal is to create a global community of
              adventurers who share a passion for exploration and sustainable
              travel, leaving an enduring mark on hearts and minds.
            </div>
          </div>
        </div>
      </div>

      <div className="about_footer_desktop">
        <DesktopFooter />
      </div>
      <div className="about_footer_mobile">
        <Footer />
        <BottomTab />
      </div>
    </>
  );
}

export default About_us;
