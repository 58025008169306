import axios from "axios";
import { useEffect } from "react";
import Header from "../DesktopComponents/Header";
import HeaderMobile from "../ComponentsActivity/HeaderMobile";
import Footer from "../GlobalComponents/Footer";
import DesktopFooter from "../DesktopComponents/DesktopFooter";
import "./ThankYou.css";
import { Link, useNavigate } from "react-router-dom";
import camping from "../Public/camping.jpg";
import { Helmet } from "react-helmet";

function ThankYouCamping() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const bookingvalue = urlParams.get("bookingvalue");
  //   const download_invoice = () => {
  //     if (id != "" || id != null || id != undefined) {
  //       axios
  //         .post(
  //           "http://192.168.43.33:9000/activities/rafting/thank-you",
  //           {
  //             id: id,
  //           },
  //           {
  //             responseType: "blob", // Set responseType to 'blob' to receive binary data
  //           }
  //         )
  //         .then((res) => {
  //           // Create a URL for the blob data
  //           const url = window.URL.createObjectURL(new Blob([res.data]));

  //           // Create an <a> element to trigger the file download
  //           const link = document.createElement("a");
  //           link.href = url;
  //           link.setAttribute("download", "invoice.pdf");
  //           document.body.appendChild(link);
  //           link.click();

  //           // Clean up after the download is complete
  //           window.URL.revokeObjectURL(url);
  //         })
  //         .catch((error) => {
  //           console.error("Error downloading PDF:", error);
  //         });
  //     }
  //   };
  //   useEffect(() => {
  //     download_invoice();
  //   }, []);
  useEffect(() => {
    if (
      bookingvalue == "" ||
      bookingvalue == undefined ||
      bookingvalue == null
    ) {
    } else {
      window.dataLayer.push({
        event: "Booking_Confirm_Camping",
        adventure: "camping",
        ecommerce_value: bookingvalue,
      });
      urlParams.delete("bookingvalue");
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
  }, []);
  return (
    <>
      <div className="desktop-thankyou-section">
        <Helmet>
          <title>Thank you</title>
          <meta
            name="description"
            content="Thank you for booking with Thrillthirsty"
          />
        </Helmet>
        <Header />
        <div className="Thanku_page-div">
          <div className="Thanku_page-center-div">
            <div className="Thanku_page-first-thanku-div">
              <div className="Thanku_page-tick-circle-div">
                <svg
                  height={80}
                  width={80}
                  fill="#fff"
                  viewBox="0 0 1024 1024"
                  stroke="#fff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z"></path>
                  </g>
                </svg>
              </div>
              <div className="Thanku_page-thanku-test-h-div">
                Thank you for <br /> Booking!
              </div>
              <div className="Thanku_page-thanku-discription-h-div">
                Booking confirmed! Expect a call from Thrill Thirsty. Need help?
                Call at <span className="orange">+91-9084040280</span>
              </div>
              <div className="t-y-page-call-download-btn-div">
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className="Thanku_page-download-now-button"
                >
                  Back to home
                </button>
              </div>
            </div>
            <div className="Thanku_page-second-invoice-div">
              <div className="in-page-div-map">
                <img
                  src={camping}
                  alt=""
                  className="thankyou-img"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <DesktopFooter />
      </div>
      <div className="mobile-thank-you-section">
        <div className="Thanku_page-div">
          <div className="Thanku_page-center-div">
            <div className="Thanku_page-first-thanku-div">
              <div className="Thanku_page-tick-circle-div">
                <svg
                  height={80}
                  width={80}
                  fill="#fff"
                  viewBox="0 0 1024 1024"
                  stroke="#fff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z"></path>
                  </g>
                </svg>
              </div>
              <div className="Thanku_page-thanku-test-h-div">
                Thank you for <br /> Booking!
              </div>
              <div className="Thanku_page-thanku-discription-h-div">
                Booking confirmed! Expect a call from Thrill Thirsty. Need help?
                Call at
                <Link to={"tel:+919084040280"} className="orange">
                  +91-9084040280
                </Link>
              </div>
              <div className="t-y-page-call-download-btn-div">
                <button
                  //   onClick={download_invoice}
                  className="Thanku_page-download-now-button"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Back to home
                </button>
              </div>
            </div>
            <div className="Thanku_page-second-invoice-div">
              <div className="in-page-div-map">
                <img
                  src={camping}
                  alt=""
                  className="thankyou-img"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ThankYouCamping;
