import "./GlobalForm.css";
import TextInput from "../GlobalFormComponents/TextInput";
import OptionInput from "../GlobalFormComponents/OptionInput";
import { EnterPerson, Persons } from "../GlobalFormComponents/EnterPerason";
import Title from "../GlobalFormComponents/Title";
import Button from "../GlobalFormComponents/Button";
import InputDate from "../GlobalFormComponents/InputDate";
import PriceSlip from "../GlobalFormComponents/PriceSlip";
import { Link } from "react-router-dom";
function GlobalFormBungeeMobile({
  setForm,
  setMobile_date,
  setMobile_no_of_people,
  setMobile_name,
  setMobile_email,
  setMobile_dor,
  setMobile_contact,
  mobile_no_of_people,
  mobile_name,
  mobile_email,
  mobile_date,
  mobile_dor,
  mobile_contact,
  mobile_slot,
  setMobile_slot,
  setCurrent_type,
  current_type,
  price,
  km,
  submitMobileAdvance,
  submitMobileFull,
  setChoosen_slot,
  choosen_slot,
}) {
  return (
    <div className="whole-form">
      <div
        className="wf-1"
        onClick={() => {
          setForm(false);
        }}
      ></div>
      <div className="wf-2">
        <div className="wf2-header">
          <div className="wf-header-content">
            <div className="wf-h-title">Fill your details</div>
            <p className="wf-h-description">
              Need instant Jump? Dial or click{" "}
              <Link to={"tel:" + "+919084040280"} className="color-orange">
                +91-9084040280
              </Link>
            </p>
          </div>
          <div
            className="wf-header-close"
            onClick={() => {
              setForm(false);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="wf-header-close-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M16 8L8 16M8.00001 8L16 16"
                  className="stroke-gray"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div>
        <TextInput
          title={"Your name"}
          placeholder={"Enter your good name"}
          type={"text"}
          set={setMobile_name}
          get={mobile_name}
        />
        <TextInput
          title={"Your contact"}
          placeholder={"Enter your contact number"}
          type={"number"}
          set={setMobile_contact}
          get={mobile_contact}
        />
        <TextInput
          title={"Your email"}
          placeholder={"Enter your email address"}
          type={"email"}
          set={setMobile_email}
          get={mobile_email}
        />
        <OptionInput
          title={"Height of Bungee"}
          options={["55", "83", "109", "117", "Free Style (111 m)"]}
          set={setCurrent_type}
          get={current_type}
        />
        {/* <OptionInput
          title={"Today’s available slots"}
          options={["06:00 AM", "06:10 AM", "06:20 AM", "06:30 AM", "06:40 AM"]}
        /> */}
        {/* <OptionInput
          title={"Tomorrow’s available slots"}
          options={["06:00 AM", "06:10 AM", "06:20 AM", "06:30 AM", "06:40 AM"]}
        /> */}
        <InputDate
          title={"Select date"}
          placeholder={"Choose a day"}
          forhtml={"bookanotherday"}
          set={setMobile_date}
          get={mobile_date}
          //   type={"date"}
        />

        {/* open it when their is something on the another day box */}
        {mobile_date != "" ? (
          <OptionInput
            title={"Available slots on " + mobile_date}
            options={mobile_slot}
            set={setChoosen_slot}
            get={choosen_slot}
          />
        ) : null}
        {/* <OptionInput title={"Number of person"} options={[]} /> */}
        <Title title={"Number of person"} />
        <div className="form-persons-div">
          <EnterPerson
            placeholder={"Enter the number of person"}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={1}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={2}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={3}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={4}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={5}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={6}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={7}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={8}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <div></div>
        </div>
        <PriceSlip
          content={
            current_type < 5
              ? km[current_type] +
                " Meter Jump ( ₹" +
                parseInt(price[current_type]).toLocaleString() +
                " ) x " +
                mobile_no_of_people +
                " adults = ₹" +
                (
                  parseInt(price[current_type]) * parseInt(mobile_no_of_people)
                ).toLocaleString()
              : " Free style Jump ( ₹" +
                parseInt(price[current_type]).toLocaleString() +
                " ) x " +
                mobile_no_of_people +
                " adults = ₹" +
                (
                  parseInt(price[current_type]) * parseInt(mobile_no_of_people)
                ).toLocaleString()
          }
        />
        <div className="gf-text-on-btn">
          Missed the slot? Dial or click{" "}
          <Link to={"tel:" + 9084040280} className="color-orange">
            +91-9084040280
          </Link>{" "}
          for instant booking!
        </div>
        {current_type == 2 ? null : (
          <>
            <Button content={"Call Now"} submit={submitMobileAdvance} />
            <div className="mf-or-whole">
              <div className="mfow-1"></div>
              <div>or</div>
              <div className="mfow-1"></div>
            </div>
          </>
        )}
        <Button content={"Chat on Whatsaap"} submit={submitMobileFull} />
        <div className="gf-space"></div>
      </div>
    </div>
  );
}

export default GlobalFormBungeeMobile;
