import "./DesktopStatus.css";
function DesktopStatus({ setDesktop_status, desktop_status, img }) {
  return (
    <div
      className="whole-desktop-status-section"
      onClick={() => {
        setDesktop_status(!desktop_status);
      }}
    >
      <div className="wdss-white-section">
        <img src={img} alt="" className="wdss-img" loading="lazy" />
      </div>
    </div>
  );
}

export default DesktopStatus;
