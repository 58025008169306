import { useState, useEffect } from "react";
import Check from "@mui/icons-material/Check";
import styled from "styled-components";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

const CheckoutButton = styled(Button)`
  && {
    background-color: green;
    color: white;
  }
`;

const Payment = () => {
  const [self, setSelf] = useState();
  useEffect(() => {
    console.log("self");
    console.log(self);
  }, [self]);
  const [oncheckOpen, setOnCheckOpen] = useState(false);
  let amount = 250; //update amount
  let contact = 1234567890; //update contact no.
  let url = "http://127.0.0.1:9000";

  const data = {
    txnid: "123456", //String
    amount: 250.0, //Float
    productinfo: "testpayment", //String
    firstname: "ankit", //String
    email: "ankitpundir361@gmail.com", //String
  };

  useEffect(() => {
    makePayment();
  }, []);
  let reshash; //hashvalue generated will be stored in this variable

  const makePayment = async () => {
    await paymentReq();
    await responseReq();
  };

  //This method will generate the hashvalue
  const paymentReq = async () => {
    try {
      reshash = await axios.post(`${url}/pay`, JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch {
      console.log("Payment Error");
    }
  };

  //We are using a modal, to open the payment transaction gateway after a click
  const handleCheckClose = () => setOnCheckOpen(false); //close the modal
  const handleCheckOpen = () => setOnCheckOpen(true); //open the modal

  //This method will use that hash value to make the transaction complete
  const responseReq = async () => {
    const pd = {
      key: "heRjXi", //once testing is done, change it to live key
      txnid: "123456", //String,
      amount: amount.toFixed(2), //Float
      firstname: "ankit",
      email: "ankitpundir361@gmail.com",
      phone: contact,
      productinfo: "test product",
      surl: `${url}/response/test`, //url called if payment is successful, we have written the code in server.js below in the medium article
      furl: "/fails", //url called when payment fails
      hash: reshash?.data?.hash, //hashvalue
      service_provider: "payu_paisa",
    };
    let res;
    try {
      res = await axios
        .post(`${url}/response`, JSON.stringify(pd), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("res.data");
          console.log(res.data);
          setSelf(res.data);
          handleCheckOpen();
          return res;
        })
        .catch((Er) => {
          console.log(Er);
        });
    } catch (err) {
      console.log("response error");
    }
  };
  return (
    <Modal
      open={oncheckOpen}
      onClose={handleCheckClose}
      closeAfterTransition
      style={{
        background: "white",
        width: "50%",
        margin: "auto",
        height: "20vh",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          height: "20vh",
          padding: "8%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <a href={self} target="_blank" rel="noreferrer noopener">
          <CheckoutButton style={{ margin: "auto", alignItems: "center" }}>
            You'll be redirected to PayU payment Gateway | <Check />
          </CheckoutButton>
        </a>
      </div>
    </Modal>
  );
};

export default Payment;
