import "./IndiAccountBooking.css";
import img from "../Public/Destination.jpg";
function IndiAccountBooking() {
  return (
    <div className="whole-indi-account-booking">
      <div className="iab-img-div">
        <img src={img} alt="" className="iab-img-img" loading="lazy" />
      </div>
      <div className="iab-content-div">
        <div className="iab-content-title">River Rafting</div>
        <div className="iab-content">12-02-2024</div>
        <div className="iab-content">26 km</div>
        <div className="iab-content">8 Adults</div>
      </div>
      <div className="iab-price-section">₹12,000</div>
    </div>
  );
}

export default IndiAccountBooking;
