import "./Options.css";
function Options({ content, active, setCurrent_type, current_no }) {
  return active ? (
    <div className="content-active">{content}</div>
  ) : (
    <div
      className="content-inactive"
      onClick={() => {
        setCurrent_type(current_no);
      }}
    >
      {content}
    </div>
  );
}

export default Options;
