import "./IndiAccountCoupon.css";
import img from "../Public/Destination.jpg";
function IndiAccountCoupon() {
  return (
    <div className="offer_div">
      <div className="offer_img_div">
        <img src={img} alt="" className="image_bloger" loading="lazy" />
      </div>
      <div className="off_div">₹100 off </div>
      <div className="num_div">
        Use <span className="color-blue"> 0987654337 </span> on your next
        adventure
      </div>
    </div>
  );
}

export default IndiAccountCoupon;
