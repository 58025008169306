import "./AccountAbout.css";
import InputEdit from "../GlobalFormComponents/InputEdit";
import Button from "../GlobalFormComponents/Button";
function AccountAbout() {
  return (
    <>
      <InputEdit title={"Name"} content={"Ankit Pundir"} />
      <InputEdit title={"Email"} content={"ankitpundir361@gmail.com"} />
      <InputEdit title={"Contact"} content={"7302040324"} />
      <InputEdit title={"Gender"} content={"Male"} />
      <InputEdit title={"Date of birth"} content={"04-12-2000"} />
      <Button content={"Save"} />
      <div className="account-space"></div>
    </>
  );
}

export default AccountAbout;
