import "./MainTitle.css";
function MainTitleBungee({
  setForm,
  price,
  type,
  rapids,
  se,
  current_type,
  rapids_blink_mobile,
  km_blink_mobile,
  start_blink_mobile,
  end_blink_mobile,
  age,
  weight,
}) {
  return (
    <div className="main-title">
      <div className="mt-price-info-section">
        <h2 className="mt-price">
          INR {price}
          <span className="color-black mt-price-small">/person</span>
        </h2>
        {/* <p className="mt-info">Video charges : 1000/person</p> */}
        <p className="mt-info-bungee">
          Weight:
          <span ref={rapids_blink_mobile}>{weight[current_type].min}kg</span>to
          <span ref={km_blink_mobile}>{weight[current_type].max}kg</span>
        </p>
        <p className="mt-info-bungee">
          Age:<span ref={start_blink_mobile}>{age[current_type].min}</span>to
          <span ref={end_blink_mobile}>{age[current_type].max}</span>
        </p>
      </div>
      <div className="mt-book-now-section">
        <button
          className="mt-book-now-btn"
          onClick={() => {
            setForm(true);
          }}
        >
          Book Now
        </button>
      </div>
    </div>
  );
}

export default MainTitleBungee;
