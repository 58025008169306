import "./Indi_section.css";
export default function Indi_section({ image, title, keys, values, msg }) {
  return (
    <div className="lp-indi-section">
      <div className="lp-is-img-div">
        <img src={image} alt="" className="lp-is-img" />
      </div>
      <div className="lp-is-title">{title}</div>
      <div className="lp-is-content-section">
        <div className="LP-IS-CS-1">{keys[0]}</div>
        <div className="lp-is-cs-2">{values[0]}</div>
      </div>
      <div className="lp-is-content-section">
        <div className="LP-IS-CS-1">{keys[1]}</div>
        <div className="lp-is-cs-2">{values[1]}</div>
      </div>
      <div className="lp-is-content-section">
        <div className="LP-IS-CS-1">{keys[2]}</div>
        <div className="lp-is-cs-2">{values[2]}</div>
      </div>
      <div className="lp-is-content-section">
        <div className="LP-IS-CS-1">{keys[3]}</div>
        <div className="lp-is-cs-2">{values[3]}</div>
      </div>
      <div className="lp-is-btn-section">
        <a className="lp-is-indi-btn" href="tel://9084040280">
          CALL NOW
        </a>
        <a className="lp-is-indi-btn" href={msg}>
          ENQUIRE NOW
        </a>
      </div>
    </div>
  );
}
