import BottomTab from "../ComponentsHome/BottomTab";
import HeaderMobile from "../ComponentsActivity/HeaderMobile";
import { useSearchParams } from "react-router-dom";
// import MainTitle from "../ComponentsActivity/MainTitle";
import Options from "../ComponentsActivity/Options";
import "./Rafting.css";
import ActivityTimeline from "../ComponentsActivity/ActivityTimeline";
// import rafting1 from "../Public/rafting7.jpg";
// import rafting8 from "../Public/rafting8.jpg";
// import rafting3 from "../Public/rafting9.jpg";
// import rafting4 from "../Public/rafting5.jpg";
// import rafting2 from "../Public/rafting2.jpg";
import Title from "../ComponentsHome/Title";
import Status from "../GlobalComponents/Status";
import PageMenu from "../GlobalComponents/PageMenu";
import TitleWithoutDescription from "../GlobalComponents/TitleWithoutDescription";
// import ContentSection from "../GlobalComponents/ContentSection";
// import GlobalForm from "../GlobalComponents/Globalform";
import GlobalFormBungee from "../GlobalComponents/GlobalFormBungee";
import { useEffect, useState } from "react";
import GlobalPhotoStatus from "../GlobalStatus/GlobalPhotoStatus";
import PhotoSection from "../GlobalPhoto/PhotoSection";
import "./RaftingResponsive.css";
import Header from "../DesktopComponents/Header";
import "./RaftingDesktop.css";
import IndiType from "../DesktopComponents/IndiType";
import IndiWhere from "../DesktopComponents/IndiWhere";
import TitleDesktop from "../DesktopComponents/TitleDesktop";
import DesktopStatus from "../DesktopComponents/DesktopStatus";
import Photo_section_desktop from "../DesktopComponents/Photo_section";
import Status_section from "../DesktopComponents/Status_section";
import "../DesktopComponents/DesktopForm.css";
import InputDate from "../DesktopComponents/InputDate";
import TextInput from "../DesktopComponents/InputText";
import { useRef } from "react";
import DesktopContentTitle from "../DesktopComponents/DesktopContentTitle";

// import hd_rafting1 from "../Public/rafting5.jpg";
// import hd_rafting2 from "../Public/rafting4.jpg";
// import hd_rafting3 from "../Public/rafting3.jpg";
import AfterForm from "../DesktopComponents/AfterForm";
import BigGroup from "../DesktopComponents/BigGroup";
// import newrafting1 from "../Public/newrafting1.jpg";
// import newrafting2 from "../Public/newrafting2.jpg";
// import newrafting3 from "../Public/newrafting3.jpg";
// import newrafting4 from "../Public/newrafting4.jpg";
// import newrafting5 from "../Public/newrafting4.jpg";
// import newrafting6 from "../Public/newrafting6.jpg";
// import newrafting7 from "../Public/newrafting7.jpg";
// import newrafting8 from "../Public/newrafting8.jpg";
import Footer from "../GlobalComponents/Footer";

// importing bungee images here
// import bungee from "../Public/bungee.jpg";
import bungee1 from "../Public/bungee1.jpg";
import bungee2 from "../Public/bungee2.jpg";
import bungee3 from "../Public/bungee3.jpg";
import bungee4 from "../Public/bungee4.jpg";
import bungee5 from "../Public/bungee5.jpg";
import bungee6 from "../Public/bungee6.jpg";
import bungee7 from "../Public/bungee7.jpg";
import bungee8 from "../Public/bungee8.jpg";
import bungee9 from "../Public/bungee9.jpg";
import MainTitleBungee from "../ComponentsActivity/MainTitleBungee";
import axios from "axios";
import { Helmet } from "react-helmet";
import CallWhattsaap from "../Msgs/CallWhattsaap";

// function IndiSection({ section, active, ref }) {
//   return (
//     <div
//       onClick={() => {
//         ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
//       }}
//       className={active ? "damw-indi-section-active" : "damw-indi-section"}
//     >
//       {section}
//     </div>
//   );
// }

function Bungee() {
  const [params] = useSearchParams();
  const paramCurrentType = params.get("currentType");
  const [form, setForm] = useState(false);
  const [status, setStatus] = useState(false);
  const [photoSection, setPhotoSection] = useState(false);
  const [desktop_photo, setDesktop_photo] = useState(false);
  const [desktop_status, setDesktop_status] = useState(false);
  const [time_out_desktop, setTime_out_desktop] = useState(undefined);

  const [current_type, setCurrent_type] = useState(1);
  const desktop_type = useRef(null);
  useState(() => {
    if (paramCurrentType == undefined) {
    } else {
      setCurrent_type(paramCurrentType);
      // if (paramCurrentType == 6) {
      //   desktop_type.current.scrollLeft += 100;
      // }
    }
  }, []);

  useEffect(() => {
    if (current_type == 5 && desktop_type.current) {
      const scrollDiv = () => {
        // desktop_type.current.scrollLeft += 200; // Adjust the scroll offset as needed
        containerRef2.current.scrollLeft += 300; // Adjust the scroll offset as needed
        clearInterval(intervalId);
      };

      const intervalId = setInterval(scrollDiv, 1000);
      return () => clearInterval(intervalId);
    }
  }, [current_type]);

  useEffect(() => {
    if (current_type == 6 && desktop_type.current) {
      const scrollDiv = () => {
        desktop_type.current.scrollLeft += 200; // Adjust the scroll offset as needed
        containerRef2.current.scrollLeft += 350; // Adjust the scroll offset as needed
        clearInterval(intervalId);
      };

      const intervalId = setInterval(scrollDiv, 1000);
      return () => clearInterval(intervalId);
    }
  }, [current_type]);

  useEffect(() => {
    if (current_type == 7 && desktop_type.current) {
      const scrollDiv = () => {
        desktop_type.current.scrollLeft += 500; // Adjust the scroll offset as needed
        containerRef2.current.scrollLeft += 550; // Adjust the scroll offset as needed
        clearInterval(intervalId);
      };

      const intervalId = setInterval(scrollDiv, 1000);
      return () => clearInterval(intervalId);
    }
  }, [current_type]);

  useEffect(() => {
    if (current_type == 8 && desktop_type.current) {
      const scrollDiv = () => {
        desktop_type.current.scrollLeft += 800; // Adjust the scroll offset as needed
        containerRef2.current.scrollLeft += 800; // Adjust the scroll offset as needed
        clearInterval(intervalId);
      };

      const intervalId = setInterval(scrollDiv, 1000);
      return () => clearInterval(intervalId);
    }
  }, [current_type]);

  useEffect(() => {
    if (current_type == 9 && desktop_type.current) {
      const scrollDiv = () => {
        desktop_type.current.scrollLeft += 1000; // Adjust the scroll offset as needed
        containerRef2.current.scrollLeft += 1000; // Adjust the scroll offset as needed
        clearInterval(intervalId);
      };

      const intervalId = setInterval(scrollDiv, 1000);
      return () => clearInterval(intervalId);
    }
  }, [current_type]);
  const [choosen_date, setChoosen_date] = useState(undefined);
  const [price, setPrice] = useState({
    1: 3000,
    2: 3700,
    3: 4000,
    4: 4600,
    5: 4000,
    6: 6000,
    7: 3000,
    8: 7600,
    9: 3500,
  });
  const [rapids, setRapids] = useState({
    1: 6,
    2: 6,
    3: 4,
    4: 8,
    5: 14,
    6: "20+",
  });
  const [km, setKm] = useState({
    1: "55",
    2: "83",
    3: "109",
    4: "117",
    5: "Freestyle (111 m)",
    6: "Couple Bungee (55 m)",
    7: "Reverse Bungee (55 m)",
    8: "Freestyle Couple Bungee (111 m)",
    9: "Rocket Bungee (109 m)",
  });
  const [no_people, setNo_people] = useState(2);
  const kms_blink = useRef(null);
  const rapids_blink = useRef(null);
  const blink_begin = useRef(null);
  const blink_last = useRef(null);

  const aboutRef = useRef(null);
  const content_scRef = useRef(null);
  const content_vroalRef = useRef(null);
  const content_rwaacRef = useRef(null);
  const content_carirRef = useRef(null);
  const content_advantagesRef = useRef(null);
  const content_rrseRef = useRef(null);
  const content_itineraryRef = useRef(null);
  const content_ecfrrrRef = useRef(null);
  const content_inclusionsRef = useRef(null);
  const content_exclusionsRef = useRef(null);
  const content_htrrRef = useRef(null);
  const content_corRef = useRef(null);
  const content_rirrRef = useRef(null);
  const content_ttcfrirRef = useRef(null);
  const content_ttdwRef = useRef(null);
  const content_tntdRef = useRef(null);
  const content_bcirwrRef = useRef(null);
  const content_bafRef = useRef(null);
  const content_cirwrRef = useRef(null);
  const content_ueRef = useRef(null);
  const content_paaarRef = useRef(null);

  const aboutRefm = useRef(null);
  const content_scRefm = useRef(null);
  const content_vroalRefm = useRef(null);
  const content_rwaacRefm = useRef(null);
  const content_carirRefm = useRef(null);
  const content_advantagesRefm = useRef(null);
  const content_rrseRefm = useRef(null);
  const content_itineraryRefm = useRef(null);
  const content_ecfrrrRefm = useRef(null);
  const content_inclusionsRefm = useRef(null);
  const content_exclusionsRefm = useRef(null);
  const content_htrrRefm = useRef(null);
  const content_corRefm = useRef(null);
  const content_rirrRefm = useRef(null);
  const content_ttcfrirRefm = useRef(null);
  const content_ttdwRefm = useRef(null);
  const content_tntdRefm = useRef(null);
  const content_bcirwrRefm = useRef(null);
  const content_bafRefm = useRef(null);
  const content_cirwrRefm = useRef(null);
  const content_ueRefm = useRef(null);
  const content_paaarRefm = useRef(null);

  const [slots, setSlots] = useState([]);

  const [where, setWhere] = useState({
    1: [
      {
        img: require("../Public/bj55/bj55_1.png"),
        tag: "Step 1",
        title: "Briefing",
      },
      {
        img: require("../Public/bj55/bj55_2.jpg"),
        tag: "Step 2",
        title: "Waiver Form",
      },
      {
        img: require("../Public/bj55/bj55_3.jpg"),
        tag: "Step 3",
        title: "Store your belongings",
      },
      {
        img: require("../Public/bj55/bj55_5.jpg"),
        tag: "Step 4",
        title: "Onto the bridge",
      },
      {
        img: require("../Public/bj55/bj55_4.jpg"),
        tag: "Step 5",
        title: "Get harnessed",
      },
      {
        img: require("../Public/bj55/bj55_1.png"),
        tag: "Step 6",
        title: "The Jump",
      },
      {
        img: require("../Public/bj55/bj55_2.jpg"),
        tag: "Step 7",
        title: "Recovery",
      },
    ],
    2: [
      {
        img: require("../Public/bj83/bj83_4.jpeg"),
        tag: "Step 1",
        title: "Briefing",
      },
      {
        img: require("../Public/bj83/bj83_5.jpeg"),
        tag: "Step 2",
        title: "Waiver Form",
      },
      {
        img: require("../Public/bj83/bj83_6.jpeg"),
        tag: "Step 3",
        title: "Store your belongings",
      },
      {
        img: require("../Public/bj83/bj83_7.jpeg"),
        tag: "Step 4",
        title: "Onto the bridge",
      },
      {
        img: require("../Public/bj83/bj83_4.jpeg"),
        tag: "Step 5",
        title: "Get harnessed",
      },
      {
        img: require("../Public/bj83/bj83_5.jpeg"),
        tag: "Step 6",
        title: "The Jump",
      },
      {
        img: require("../Public/bj83/bj83_6.jpeg"),
        tag: "Step 7",
        title: "Recovery",
      },
    ],
    3: [
      {
        img: require("../Public/bj109/bj109_1.jpeg"),
        tag: "Step 1",
        title: "Briefing",
      },
      {
        img: require("../Public/bj109/bj109_2.jpeg"),
        tag: "Step 2",
        title: "Waiver Form",
      },
      {
        img: require("../Public/bj109/bj109_3.jpeg"),
        tag: "Step 3",
        title: "Store your belongings",
      },
      {
        img: require("../Public/bj109/bj109_4.jpeg"),
        tag: "Step 4",
        title: "Onto the bridge",
      },
      {
        img: require("../Public/bj109/bj109_5.jpeg"),
        tag: "Step 5",
        title: "Get harnessed",
      },
      {
        img: require("../Public/bj109/bj109_6.jpeg"),
        tag: "Step 6",
        title: "The Jump",
      },
      {
        img: require("../Public/bj109/bj109_1.jpeg"),
        tag: "Step 7",
        title: "Recovery",
      },
    ],
    4: [
      {
        img: require("../Public/bj117/bj117_1.jpeg"),
        tag: "Step 1",
        title: "Briefing",
      },
      {
        img: require("../Public/bj117/bj117_2.jpeg"),
        tag: "Step 2",
        title: "Waiver Form",
      },
      {
        img: require("../Public/bj117/bj117_3.jpeg"),
        tag: "Step 3",
        title: "Store your belongings",
      },
      {
        img: require("../Public/bj117/bj117_4.jpeg"),
        tag: "Step 4",
        title: "Onto the bridge",
      },
      {
        img: require("../Public/bj117/bj117_1.jpeg"),
        tag: "Step 5",
        title: "Get harnessed",
      },
      {
        img: require("../Public/bj117/bj117_2.jpeg"),
        tag: "Step 6",
        title: "The Jump",
      },
      {
        img: require("../Public/bj117/bj117_3.jpeg"),
        tag: "Step 7",
        title: "Recovery",
      },
    ],
  });

  const [se, setSe] = useState({
    1: {
      start: "Bhrampuri",
      end: "Nim beach",
    },
    2: {
      start: "Marine Drive",
      end: "Shivpuri",
    },
    3: {
      start: "Club House",
      end: "Nim beach",
    },
    4: {
      start: "Shivpuri",
      end: "Nim beach",
    },
    5: {
      start: "Marine Drive",
      end: "Nim beach",
    },
    6: {
      start: "Kaudiyala",
      end: "Nim beach",
    },
  });

  const [weight, setWeight] = useState({
    1: {
      min: 40,
      max: 180,
    },
    2: {
      min: 40,
      max: 110,
    },
    3: {
      min: 50,
      max: 110,
    },
    4: {
      min: 50,
      max: 110,
    },
    5: {
      min: 50,
      max: 110,
    },
    6: {
      min: 80,
      max: 110,
    },
    7: {
      min: 50,
      max: 110,
    },
    8: {
      min: 50,
      max: 110,
    },
    9: {
      min: 50,
      max: 110,
    },
  });

  const [age, setAge] = useState({
    1: {
      min: 12,
      max: 45,
    },
    2: {
      min: 12,
      max: 45,
    },
    3: {
      min: 15,
      max: 45,
    },
    4: {
      min: 15,
      max: 45,
    },
    5: {
      min: 15,
      max: 45,
    },
    6: {
      min: 15,
      max: 45,
    },
    7: {
      min: 15,
      max: 45,
    },
    8: {
      min: 15,
      max: 45,
    },
    9: {
      min: 15,
      max: 45,
    },
  });

  const [choosen_slot, setChoosen_slot] = useState(undefined);

  // scroll section here
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollStartX, setScrollStartX] = useState(0);
  const [curent_menu_option, setCurrent_menu_option] = useState("");

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStartX(e.clientX);
    setScrollStartX(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - dragStartX;
    containerRef.current.scrollLeft = scrollStartX - dx;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (time_out_desktop != undefined) {
      clearTimeout(time_out_desktop[0]);
    }
    kms_blink.current.classList.add("blink_animation");
    rapids_blink.current.classList.add("blink_animation");
    blink_begin.current.classList.add("blink_animation");
    blink_last.current.classList.add("blink_animation");

    km_blink_mobile.current.classList.add("blink_animation");
    rapids_blink_mobile.current.classList.add("blink_animation");
    start_blink_mobile.current.classList.add("blink_animation");
    end_blink_mobile.current.classList.add("blink_animation");

    setTime_out_desktop([
      setTimeout(() => {
        kms_blink.current.classList.remove("blink_animation");
        rapids_blink.current.classList.remove("blink_animation");
        blink_begin.current.classList.remove("blink_animation");
        blink_last.current.classList.remove("blink_animation");

        km_blink_mobile.current.classList.remove("blink_animation");
        rapids_blink_mobile.current.classList.remove("blink_animation");
        start_blink_mobile.current.classList.remove("blink_animation");
        end_blink_mobile.current.classList.remove("blink_animation");
      }, 500),
    ]);
  }, [current_type]);

  const [desktop_status_indi, setDesktop_status_indi] = useState([
    {
      smallimg: require("../Public/bj55/bj55_1.png"),
      img: require("../Public/bj55/bj55_1.png"),
      title: "55 Meter Bungee",
      des: "short and simple bungee",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/bj83/bj83_4.jpeg"),
      img: require("../Public/bj83/bj83_4.jpeg"),
      title: "83 Meter bungee",
      des: "feel the thrill",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/bj109/bj109_1.jpeg"),
      img: require("../Public/bj109/bj109_1.jpeg"),
      title: "109 Meter bungee",
      des: "Feel the splash",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/bj117/bj117_1.jpeg"),
      img: require("../Public/bj117/bj117_1.jpeg"),
      title: "117 Meter bungee",
      des: "India's heighest bungee",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
  ]);

  const [menu_desktop_content, setMenu_desktop_content] = useState([
    { content: "About", tag: "about", ref: aboutRef },
    {
      content: "Types of Bungee Jumping",
      tag: "content-sc",
      ref: content_scRef,
    },
    {
      content: "55 Meters Bungee Jump",
      tag: "content-vroal",
      ref: content_vroalRef,
    },
    {
      content: "83 Meters Bungee Jump",
      tag: "content-rwaac",
      ref: content_rwaacRef,
    },
    {
      content: "109 Meters of Bungee Jump",
      tag: "content-carir",
      ref: content_carirRef,
    },
    {
      content: "117 Meters of Bungee Jump",
      tag: "content-advantages",
      ref: content_advantagesRef,
    },
    {
      content: "About Bungee Jumping in Rishikesh",
      tag: "content-rrse",
      ref: content_rrseRef,
    },
    {
      content: "Photography & Videography",
      tag: "content-itinerary",
      ref: content_itineraryRef,
    },
    {
      content: "Age and Weight Criteria",
      tag: "content-ecfrrr",
      ref: content_ecfrrrRef,
    },
    {
      content: "Safety Standards",
      tag: "content-inclusions",
      ref: content_inclusionsRef,
    },
    {
      content: "Itinerary",
      tag: "content-exclusions",
      ref: content_exclusionsRef,
    },
    {
      content: "Payment & Cancellation Policy",
      tag: "content-htrr",
      ref: content_htrrRef,
    },
    {
      content: "People Also Ask About Bungee",
      tag: "content-cor",
      ref: content_corRef,
    },
  ]);

  const [menu_mobile_content, setMenu_mobile_content] = useState([
    { content: "About", tag: "about", ref: aboutRefm },
    {
      content: "Types of Bungee Jumping",
      tag: "content-sc",
      ref: content_scRefm,
    },
    {
      content: "55 Meters Bungee Jump",
      tag: "content-vroal",
      ref: content_vroalRefm,
    },
    {
      content: "83 Meters Bungee Jump",
      tag: "content-rwaac",
      ref: content_rwaacRefm,
    },
    {
      content: "109 Meters of Bungee Jump",
      tag: "content-carir",
      ref: content_carirRefm,
    },
    {
      content: "117 Meters of Bungee Jump",
      tag: "content-advantages",
      ref: content_advantagesRefm,
    },
    {
      content: "About Bungee Jumping in Rishikesh",
      tag: "content-rrse",
      ref: content_rrseRefm,
    },
    {
      content: "Photography & Videography",
      tag: "content-itinerary",
      ref: content_itineraryRefm,
    },
    {
      content: "Age and Weight Criteria",
      tag: "content-ecfrrr",
      ref: content_ecfrrrRefm,
    },
    {
      content: "Safety Standards",
      tag: "content-inclusions",
      ref: content_inclusionsRefm,
    },
    {
      content: "Itinerary",
      tag: "content-exclusions",
      ref: content_exclusionsRefm,
    },
    {
      content: "Payment & Cancellation Policy",
      tag: "content-htrr",
      ref: content_htrrRefm,
    },
    {
      content: "People Also Ask About Bungee",
      tag: "content-cor",
      ref: content_corRefm,
    },
  ]);

  const [current_mobile_menu, setCurrent_mobile_menu] = useState(0);

  // mobile form details
  const [mobile_name, setMobile_name] = useState("");
  const [mobile_contact, setMobile_contact] = useState("");
  const [mobile_email, setMobile_email] = useState("");
  const [mobile_dor, setMobile_dor] = useState("");
  const [mobile_date, setMobile_date] = useState("");
  const [mobile_no_of_people, setMobile_no_of_people] = useState(2);
  const [mobile_slot, setMobile_slot] = useState([]);

  // creating blinks for mobile form
  const start_blink_mobile = useRef(null);
  const end_blink_mobile = useRef(null);
  const km_blink_mobile = useRef(null);
  const rapids_blink_mobile = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSlotsDesktop = () => {
    if (choosen_date == "") {
    } else {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/get-slots", {
          date: choosen_date,
          km:
            current_type == 1
              ? "55"
              : current_type == 2
              ? "83"
              : current_type == 3
              ? "109"
              : current_type == 4
              ? "117 m"
              : current_type == 5
              ? "freestyle 111"
              : current_type == 6
              ? "couple bungee 55"
              : current_type == 7
              ? "reverse bungee 55"
              : current_type == 8
              ? "freestyle couple bungee 111"
              : "rocket bungee 109",
        })
        .then((res) => {
          setSlots(res.data);
        });
    }
  };

  const getSlotsMobiles = () => {
    if (mobile_date == "") {
    } else {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/get-slots", {
          date: mobile_date,
          km:
            current_type == 1
              ? "55"
              : current_type == 2
              ? "83"
              : current_type == 3
              ? "109"
              : current_type == 4
              ? "117 m"
              : current_type == 5
              ? "freestyle 111"
              : current_type == 6
              ? "couple bungee 55"
              : current_type == 7
              ? "reverse bungee 55"
              : current_type == 8
              ? "freestyle couple bungee 111"
              : "rocket bungee 109",
        })
        .then((res) => {
          // alert(res.data);
          setMobile_slot(res.data);
        })
        .catch((err) => {
          // alert(err);
        });
    }
  };

  useEffect(() => {
    if (mobile_date != "") {
      getSlotsMobiles();
    }
  }, [current_type, mobile_date]);

  useEffect(() => {
    if (choosen_date != "") {
      getSlotsDesktop();
    }
  }, [choosen_date, current_type]);

  const desktop_form_section = useRef(null);
  const [formName, setFormName] = useState("");
  const [formContact, setFormContact] = useState("");
  const [formEmail, setFormEmail] = useState("");

  const f_r_key = useRef(null);
  const f_r_contact = useRef(null);
  const f_r_email = useRef(null);
  const f_r_txnid = useRef(null);
  const f_r_amount = useRef(null);
  const f_r_p_info = useRef(null);
  const f_r_first_name = useRef(null);
  const f_r_hash = useRef(null);
  const f_r_form = useRef(null);

  const rulesDesktop = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      no_people == 0 ||
      no_people == "" ||
      no_people == undefined ||
      no_people == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (
      slots[choosen_slot] == "" ||
      slots[choosen_slot] == undefined ||
      slots[choosen_slot] == null
    ) {
      alert("Please choose a slot");
      allgood = false;
    } else if (
      choosen_date == "" ||
      choosen_date == undefined ||
      choosen_date == null
    ) {
      alert("please choose a date");
      allgood = false;
    } else if (choosen_date == "2024-04-19" && current_type == 2) {
      alert("83 Meter bungee is closed on 19th because of elections");
      allgood = false;
    } else if (
      current_type == "" ||
      current_type == undefined ||
      current_type == null ||
      current_type > 9
    ) {
      alert("Invalid type of bungee");
      allgood = false;
    } else if (
      formContact == "" ||
      formContact == undefined ||
      formContact == null ||
      formContact.length != 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (formEmail == "" || formEmail == undefined || formEmail == null) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(formEmail)) {
      alert("Invalid email");
      allgood = false;
    } else if (formName == "" || formName == undefined || formName == null) {
      alert("Invalid name");
      allgood = false;
    }

    return allgood;
  };

  const rulesMobile = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      mobile_no_of_people == 0 ||
      mobile_no_of_people == "" ||
      mobile_no_of_people == undefined ||
      mobile_no_of_people == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (
      choosen_slot == "" ||
      choosen_slot == undefined ||
      choosen_slot == null
    ) {
      alert("Please choose a slot");
      allgood = false;
    } else if (
      mobile_date == "" ||
      mobile_date == undefined ||
      mobile_date == null
    ) {
      alert("please choose a date");
      allgood = false;
    } else if (
      current_type == "" ||
      current_type == undefined ||
      current_type == null ||
      current_type > 9
    ) {
      alert("Invalid type of bungee");
      allgood = false;
    } else if (
      mobile_contact == "" ||
      mobile_contact == undefined ||
      mobile_contact == null ||
      mobile_contact.length < 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (
      mobile_email == "" ||
      mobile_email == undefined ||
      mobile_email == null
    ) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(mobile_email)) {
      alert("Invalid email");
      allgood = false;
    } else if (
      mobile_name == "" ||
      mobile_name == undefined ||
      mobile_name == null
    ) {
      alert("Invalid name");
      allgood = false;
    } else if (mobile_date == "2024-04-19" && current_type == 2) {
      alert("83 Meter bungee is closed on 19th because of elections");
      allgood = false;
    }

    return allgood;
  };

  const submitAdvance = () => {
    if (rulesDesktop()) {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/enquery", {
          name: formName,
          email: formEmail,
          contact: formContact,
          type: (() => {
            if (current_type == 1) {
              return "55 m";
            } else if (current_type == 2) {
              return "83 m";
            } else if (current_type == 3) {
              return "109 m";
            } else if (current_type == 4) {
              return "117 m";
            } else if (current_type == 5) {
              return "freestyle 111";
            } else if (current_type == 6) {
              return "couple bungee 55";
            } else if (current_type == 7) {
              return "reverse bungee 55";
            } else if (current_type == 8) {
              return "freestyle couple bungee 111";
            } else if (current_type == 9) {
              return "rocket bungee 109";
            }
          })(),
          date: choosen_date,
          slot: slots[choosen_slot],
          headcount: no_people,
          payment_type: "advance",
          totalAmount: parseInt(price[current_type]) * parseInt(no_people),
          amount:
            (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100,
          // amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "bungee",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          // f_r_amount.current.value = 1;
          f_r_amount.current.value =
            (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100;
          f_r_contact.current.value = formContact;
          f_r_email.current.value = formEmail;
          f_r_first_name.current.value = formName;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "bungee";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitFull = () => {
    if (rulesDesktop()) {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/enquery", {
          name: formName,
          email: formEmail,
          contact: formContact,
          type: (() => {
            if (current_type == 1) {
              return "55 m";
            } else if (current_type == 2) {
              return "83 m";
            } else if (current_type == 3) {
              return "109 m";
            } else if (current_type == 4) {
              return "117 m";
            } else if (current_type == 5) {
              return "freestyle 111";
            } else if (current_type == 6) {
              return "couple bungee 55";
            } else if (current_type == 7) {
              return "reverse bungee 55";
            } else if (current_type == 8) {
              return "freestyle couple bungee 111";
            } else if (current_type == 9) {
              return "rocket bungee 109";
            }
          })(),
          date: choosen_date,
          slot: slots[choosen_slot],
          headcount: no_people,
          amount: parseInt(price[current_type]) * parseInt(no_people),
          // amount: 1,
          totalAmount: parseInt(price[current_type]) * parseInt(no_people),
          payment_type: "full",
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "bungee",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value =
            parseInt(price[current_type]) * parseInt(no_people);
          // f_r_amount.current.value = 1;
          f_r_contact.current.value = formContact;
          f_r_email.current.value = formEmail;
          f_r_first_name.current.value = formName;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "bungee";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitMobileAdvance = () => {
    if (rulesMobile()) {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/enquery", {
          name: mobile_name,
          email: mobile_email,
          contact: mobile_contact,
          type: (() => {
            if (current_type == 1) {
              return "55 m";
            } else if (current_type == 2) {
              return "83 m";
            } else if (current_type == 3) {
              return "109 m";
            } else if (current_type == 4) {
              return "117 m";
            } else if (current_type == 5) {
              return "freestyle 111";
            } else if (current_type == 6) {
              return "couple bungee 55";
            } else if (current_type == 7) {
              return "reverse bungee 55";
            } else if (current_type == 8) {
              return "freestyle couple bungee 111";
            } else if (current_type == 9) {
              return "rocket bungee 109";
            }
          })(),
          date: mobile_date,
          slot: mobile_slot[choosen_slot - 1],
          headcount: mobile_no_of_people,
          payment_type: "advance",
          totalAmount:
            parseInt(price[current_type]) * parseInt(mobile_no_of_people),
          amount:
            (parseInt(price[current_type]) *
              parseInt(mobile_no_of_people) *
              20) /
            100,
          // amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "bungee",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          // f_r_amount.current.value = 1;
          f_r_amount.current.value =
            (parseInt(price[current_type]) *
              parseInt(mobile_no_of_people) *
              20) /
            100;
          f_r_contact.current.value = mobile_contact;
          f_r_email.current.value = mobile_email;
          f_r_first_name.current.value = mobile_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "bungee";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitMobileFull = () => {
    if (rulesMobile()) {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/enquery", {
          name: mobile_name,
          email: mobile_email,
          contact: mobile_contact,
          type: (() => {
            if (current_type == 1) {
              return "55 m";
            } else if (current_type == 2) {
              return "83 m";
            } else if (current_type == 3) {
              return "109 m";
            } else if (current_type == 4) {
              return "117 m";
            } else if (current_type == 5) {
              return "freestyle 111";
            } else if (current_type == 6) {
              return "couple bungee 55";
            } else if (current_type == 7) {
              return "reverse bungee 55";
            } else if (current_type == 8) {
              return "freestyle couple bungee 111";
            } else if (current_type == 9) {
              return "rocket bungee 109";
            }
          })(),
          date: mobile_date,
          slot: mobile_slot[choosen_slot - 1],
          headcount: mobile_no_of_people,
          payment_type: "full",
          totalAmount:
            parseInt(price[current_type]) * parseInt(mobile_no_of_people),
          amount: parseInt(price[current_type]) * parseInt(mobile_no_of_people),
          // amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "bungee",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          // f_r_amount.current.value = 1;
          f_r_amount.current.value =
            parseInt(price[current_type]) * parseInt(mobile_no_of_people);
          f_r_contact.current.value = mobile_contact;
          f_r_email.current.value = mobile_email;
          f_r_first_name.current.value = mobile_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "bungee";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setForm(true);
    }, 15000); // 15 seconds in milliseconds

    // Cleanup function to clear the timeout in case component unmounts before 15 seconds
    return () => clearTimeout(timer);
  }, []);

  // scroll function here
  const containerRef1 = useRef(null);
  const [isDragging1, setIsDragging1] = useState(false);
  const [dragStartX1, setDragStartX1] = useState(0);
  const [scrollStartX1, setScrollStartX1] = useState(0);

  const handleMouseDown1 = (e) => {
    setIsDragging1(true);
    setDragStartX1(e.clientX);
    setScrollStartX1(containerRef1.current.scrollLeft);
  };

  const handleMouseMove1 = (e) => {
    if (!isDragging1) return;
    const dx = e.clientX - dragStartX1;
    containerRef1.current.scrollLeft = scrollStartX1 - dx;
  };

  const handleMouseUp1 = () => {
    setIsDragging1(false);
  };

  //  2nd
  const containerRef2 = useRef(null);
  const [isDragging2, setIsDragging2] = useState(false);
  const [dragStartX2, setDragStartX2] = useState(0);
  const [scrollStartX2, setScrollStartX2] = useState(0);

  const handleMouseDown2 = (e) => {
    setIsDragging2(true);
    setDragStartX2(e.clientX);
    setScrollStartX2(containerRef2.current.scrollLeft);
  };

  const handleMouseMove2 = (e) => {
    if (!isDragging2) return;
    const dx = e.clientX - dragStartX2;
    containerRef2.current.scrollLeft = scrollStartX2 - dx;
  };

  const handleMouseUp2 = () => {
    setIsDragging2(false);
  };

  // 3rd
  const containerRef3 = useRef(null);
  const [isDragging3, setIsDragging3] = useState(false);
  const [dragStartX3, setDragStartX3] = useState(0);
  const [scrollStartX3, setScrollStartX3] = useState(0);

  const handleMouseDown3 = (e) => {
    setIsDragging3(true);
    setDragStartX3(e.clientX);
    setScrollStartX3(containerRef3.current.scrollLeft);
  };

  const handleMouseMove3 = (e) => {
    if (!isDragging3) return;
    const dx = e.clientX - dragStartX3;
    containerRef3.current.scrollLeft = scrollStartX3 - dx;
  };

  const handleMouseUp3 = () => {
    setIsDragging3(false);
  };

  // 4th
  const containerRef4 = useRef(null);
  const [isDragging4, setIsDragging4] = useState(false);
  const [dragStartX4, setDragStartX4] = useState(0);
  const [scrollStartX4, setScrollStartX4] = useState(0);

  const handleMouseDown4 = (e) => {
    setIsDragging4(true);
    setDragStartX4(e.clientX);
    setScrollStartX4(containerRef4.current.scrollLeft);
  };

  const handleMouseMove4 = (e) => {
    if (!isDragging4) return;
    const dx = e.clientX - dragStartX4;
    containerRef4.current.scrollLeft = scrollStartX4 - dx;
  };

  const handleMouseUp4 = () => {
    setIsDragging4(false);
  };

  // dt
  // 4th
  // const containerRef4 = useRef(null);
  const [isDraggingdt, setIsDraggingdt] = useState(false);
  const [dragStartXdt, setDragStartXdt] = useState(0);
  const [scrollStartXdt, setScrollStartXdt] = useState(0);

  const handleMouseDowndt = (e) => {
    setIsDraggingdt(true);
    setDragStartXdt(e.clientX);
    setScrollStartXdt(desktop_type.current.scrollLeft);
  };

  const handleMouseMovedt = (e) => {
    if (!isDraggingdt) return;
    const dx = e.clientX - dragStartXdt;
    desktop_type.current.scrollLeft = scrollStartXdt - dx;
  };

  const handleMouseUpdt = () => {
    setIsDraggingdt(false);
  };

  // type ref

  return (
    <>
      <CallWhattsaap />
      <div className="rafting-desktop-section">
        <Helmet>
          <title>
            Thrill Thirsty: Bungee Jumping in Rishikesh | Feel the Height
          </title>
          <meta
            name="description"
            content="Experience bungee jumping in Rishikesh with an experienced bungee master and top-quality equipment. From splash bungee to India's heaviest, we have it all."
          />
          {/* Other meta tags */}
        </Helmet>
        <Header active={"bungee"} />
        <div className="rafting-ds-section1">
          <div className="rafting-ds-section1-bottom-dark">
            <h1 className="rds-sbd-title">Bungee Jumping In Rishikesh</h1>
            <div className="rds-sbd-description">
              Experience bungee jumping in Rishikesh with an experienced bungee
              master and top-quality equipment
            </div>
          </div>
          <div className="rafting-ds-section1-right-dark">
            <div className="rdss1-r-div">
              <div className="rdss1-r-d-inner">
                <div
                  className="rdss1-r-d-div1"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={bungee4}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div2"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={bungee6}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div3"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={bungee3}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div4"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={bungee1}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                  <div className="rdss1-r-d-div-4-dark">+18</div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={require("../Public/bj117/bj117_4.jpeg")}
            alt=""
            className="rafting-ds-section1-img"
            loading="lazy"
            style={{ objectPosition: "0px 60%", transform: "ScaleX(1)" }}
          />
        </div>

        <div className="rafting-desktop-body">
          <div className="rafting-desktop-body-1">
            <div
              className="rafting-type-section"
              ref={desktop_type}
              onMouseDown={handleMouseDowndt}
              onMouseMove={handleMouseMovedt}
              onMouseUp={handleMouseUpdt}
              onMouseLeave={handleMouseUpdt}
            >
              <IndiType
                type={"55 m"}
                active={current_type == 1 ? true : false}
                select={() => {
                  setCurrent_type(1);
                }}
              />
              <IndiType
                type={"83 m"}
                active={current_type == 2 ? true : false}
                select={() => {
                  setCurrent_type(2);
                }}
              />
              <IndiType
                type={"109 m"}
                active={current_type == 3 ? true : false}
                select={() => {
                  setCurrent_type(3);
                }}
              />
              <IndiType
                type={"117 m"}
                active={current_type == 4 ? true : false}
                select={() => {
                  setCurrent_type(4);
                }}
              />
              <IndiType
                type={"Freestyle (111 m)"}
                active={current_type == 5 ? true : false}
                select={() => {
                  setCurrent_type(5);
                }}
              />
              <IndiType
                type={"Couple Bungee (55 m)"}
                active={current_type == 6 ? true : false}
                select={() => {
                  setCurrent_type(6);
                }}
              />
              <IndiType
                type={"Reverse Bungee (55 m)"}
                active={current_type == 7 ? true : false}
                select={() => {
                  setCurrent_type(7);
                }}
              />
              <IndiType
                type={"Freestyle Couple Bungee (111 m)"}
                active={current_type == 8 ? true : false}
                select={() => {
                  setCurrent_type(8);
                }}
              />
              <IndiType
                type={"Rocket Bungee (109 m)"}
                active={current_type == 9 ? true : false}
                select={() => {
                  setCurrent_type(9);
                }}
              />
            </div>
            {/* <div className="rafting-where-where-section"> */}
            {/* <div
                className="rafting-where-where-section-inner"
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                {where[current_type].map((item, index) => {
                  return (
                    <IndiWhere
                      img={where[current_type][index].img}
                      tag={where[current_type][index].tag}
                      title={where[current_type][index].title}
                    />
                  );
                })}

                <div className="rww-empty-space"></div>
              </div> */}
            {/* <div className="rafting-where-where-dark">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="rww-svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      className="fill-orange"
                    ></path>{" "}
                  </g>
                </svg>
              </div> */}
            {/* </div> */}
            {/* <TitleDesktop
              title={"Some of our memories"}
              description={"These are some of our memories"}
              more={false}
            /> */}

            {/* <div className="desktop-status-whole">
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/bj55/bj55_1.png")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/bj83/bj83_4.jpeg")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/bj109/bj109_1.jpeg")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/bj117/bj117_1.jpeg")}
              />
            </div> */}

            <TitleDesktop
              title={"Watch our shorts"}
              description={"These are some of our shorts "}
              more={false}
            />

            <div className="desktop-status-whole">
              <div className="desktop-shorts">
                {/* <a
                  href="https://youtube.com/shorts/s3mLYJUrl0E?si=rcCMWPVGTFf20K7b"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="20px"
                    height="20px"
                    baseProfile="basic"
                  >
                    <path
                      fill="#ff3d00"
                      d="M29.103,2.631c4.217-2.198,9.438-0.597,11.658,3.577c2.22,4.173,0.6,9.337-3.617,11.534l-3.468,1.823	c2.987,0.109,5.836,1.75,7.328,4.555c2.22,4.173,0.604,9.337-3.617,11.534L18.897,45.37c-4.217,2.198-9.438,0.597-11.658-3.577	s-0.6-9.337,3.617-11.534l3.468-1.823c-2.987-0.109-5.836-1.75-7.328-4.555c-2.22-4.173-0.6-9.337,3.617-11.534	C10.612,12.346,29.103,2.631,29.103,2.631z M19.122,17.12l11.192,6.91l-11.192,6.877C19.122,30.907,19.122,17.12,19.122,17.12z"
                    />
                    <path
                      fill="#fff"
                      d="M19.122,17.12v13.787l11.192-6.877L19.122,17.12z"
                    />
                  </svg>
                  <img src={require("../Public/bjshort1.jpeg")} alt="" />
                </a> */}
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/ni82w2B3fr8"
                  title="India&#39;s highest Bungy Jump #rishikesh #bungeejumping #bungee #travelshorts #travelvlog #courage #yt"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="desktop-shorts">
                {/* <a
                  href="https://youtube.com/shorts/i5KctFOwOTo?si=QY-UHPhkIRA1guTW"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="20px"
                    height="20px"
                    baseProfile="basic"
                  >
                    <path
                      fill="#ff3d00"
                      d="M29.103,2.631c4.217-2.198,9.438-0.597,11.658,3.577c2.22,4.173,0.6,9.337-3.617,11.534l-3.468,1.823	c2.987,0.109,5.836,1.75,7.328,4.555c2.22,4.173,0.604,9.337-3.617,11.534L18.897,45.37c-4.217,2.198-9.438,0.597-11.658-3.577	s-0.6-9.337,3.617-11.534l3.468-1.823c-2.987-0.109-5.836-1.75-7.328-4.555c-2.22-4.173-0.6-9.337,3.617-11.534	C10.612,12.346,29.103,2.631,29.103,2.631z M19.122,17.12l11.192,6.91l-11.192,6.877C19.122,30.907,19.122,17.12,19.122,17.12z"
                    />
                    <path
                      fill="#fff"
                      d="M19.122,17.12v13.787l11.192-6.877L19.122,17.12z"
                    />
                  </svg>
                  <img src={require("../Public/bjshort2.jpeg")} alt="" />
                </a> */}
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/i5KctFOwOTo"
                  title="FreeStyle Bungy Jump in Rishikesh #rishikesh #bungeejumping #harrypotter #harrypotterfan #potterhead"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="desktop-shorts">
                {/* <a
                  href="https://youtube.com/shorts/ni82w2B3fr8?si=5Az6GXmIM1IOMfmh"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="20px"
                    height="20px"
                    baseProfile="basic"
                  >
                    <path
                      fill="#ff3d00"
                      d="M29.103,2.631c4.217-2.198,9.438-0.597,11.658,3.577c2.22,4.173,0.6,9.337-3.617,11.534l-3.468,1.823	c2.987,0.109,5.836,1.75,7.328,4.555c2.22,4.173,0.604,9.337-3.617,11.534L18.897,45.37c-4.217,2.198-9.438,0.597-11.658-3.577	s-0.6-9.337,3.617-11.534l3.468-1.823c-2.987-0.109-5.836-1.75-7.328-4.555c-2.22-4.173-0.6-9.337,3.617-11.534	C10.612,12.346,29.103,2.631,29.103,2.631z M19.122,17.12l11.192,6.91l-11.192,6.877C19.122,30.907,19.122,17.12,19.122,17.12z"
                    />
                    <path
                      fill="#fff"
                      d="M19.122,17.12v13.787l11.192-6.877L19.122,17.12z"
                    />
                  </svg>
                  <img src={require("../Public/bjshort3.jpeg")} alt="" />
                </a> */}
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/ONpV8yWjI5c"
                  title="#garylu #75hardchallenge #75hard #bungeejumping #bungee #rishikesh #ytindia #travelshorts #viral"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="desktop-shorts">
                {/* <a
                  href="https://youtube.com/shorts/ONpV8yWjI5c?si=bqBeMx_Iif9xGVI0"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="20px"
                    height="20px"
                    baseProfile="basic"
                  >
                    <path
                      fill="#ff3d00"
                      d="M29.103,2.631c4.217-2.198,9.438-0.597,11.658,3.577c2.22,4.173,0.6,9.337-3.617,11.534l-3.468,1.823	c2.987,0.109,5.836,1.75,7.328,4.555c2.22,4.173,0.604,9.337-3.617,11.534L18.897,45.37c-4.217,2.198-9.438,0.597-11.658-3.577	s-0.6-9.337,3.617-11.534l3.468-1.823c-2.987-0.109-5.836-1.75-7.328-4.555c-2.22-4.173-0.6-9.337,3.617-11.534	C10.612,12.346,29.103,2.631,29.103,2.631z M19.122,17.12l11.192,6.91l-11.192,6.877C19.122,30.907,19.122,17.12,19.122,17.12z"
                    />
                    <path
                      fill="#fff"
                      d="M19.122,17.12v13.787l11.192-6.877L19.122,17.12z"
                    />
                  </svg>
                  <img src={require("../Public/bjshort4.jpeg")} alt="" />
                </a> */}
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/s3mLYJUrl0E"
                  title="#tandem #bungee #bungeejumping #adventuretime #rafting #rishikesh #rafting #viralshorts #viralvideos"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div className="damw-outer">
              <div className="desktop-activity-menu-whole">
                <div className="damw-1">
                  <div className="damw-title">Bungee Jumping</div>
                  <div className="damw-rating-div">
                    <div className="damw-svg-div">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="damw-svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M9.15316 5.40838C10.4198 3.13613 11.0531 2 12 2C12.9469 2 13.5802 3.13612 14.8468 5.40837L15.1745 5.99623C15.5345 6.64193 15.7144 6.96479 15.9951 7.17781C16.2757 7.39083 16.6251 7.4699 17.3241 7.62805L17.9605 7.77203C20.4201 8.32856 21.65 8.60682 21.9426 9.54773C22.2352 10.4886 21.3968 11.4691 19.7199 13.4299L19.2861 13.9372C18.8096 14.4944 18.5713 14.773 18.4641 15.1177C18.357 15.4624 18.393 15.8341 18.465 16.5776L18.5306 17.2544C18.7841 19.8706 18.9109 21.1787 18.1449 21.7602C17.3788 22.3417 16.2273 21.8115 13.9243 20.7512L13.3285 20.4768C12.6741 20.1755 12.3469 20.0248 12 20.0248C11.6531 20.0248 11.3259 20.1755 10.6715 20.4768L10.0757 20.7512C7.77268 21.8115 6.62118 22.3417 5.85515 21.7602C5.08912 21.1787 5.21588 19.8706 5.4694 17.2544L5.53498 16.5776C5.60703 15.8341 5.64305 15.4624 5.53586 15.1177C5.42868 14.773 5.19043 14.4944 4.71392 13.9372L4.2801 13.4299C2.60325 11.4691 1.76482 10.4886 2.05742 9.54773C2.35002 8.60682 3.57986 8.32856 6.03954 7.77203L6.67589 7.62805C7.37485 7.4699 7.72433 7.39083 8.00494 7.17781C8.28555 6.96479 8.46553 6.64194 8.82547 5.99623L9.15316 5.40838Z"
                            className="fill-orange"
                          ></path>{" "}
                        </g>
                      </svg>
                    </div>
                    <div className="damw-rating-text">4.8</div>
                  </div>
                </div>
                <div
                  className="damw-2"
                  ref={containerRef1}
                  onMouseDown={handleMouseDown1}
                  onMouseMove={handleMouseMove1}
                  onMouseUp={handleMouseUp1}
                  onMouseLeave={handleMouseUp1}
                >
                  {menu_desktop_content.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setCurrent_menu_option(item.tag);
                          let t =
                            item.ref.current.getBoundingClientRect().top +
                            window.pageYOffset;
                          let scrollToPosition = t - 200;
                          window.scrollTo({
                            top: scrollToPosition,
                            behavior: "smooth",
                          });
                          console.log(item.ref);
                          // console.log(content_ttdw);
                        }}
                        className={
                          item.tag == curent_menu_option
                            ? "damw-indi-section-active"
                            : "damw-indi-section"
                        }
                      >
                        {item.content}
                      </div>
                    );
                  })}
                  <div></div>
                </div>
              </div>
            </div>

            <DesktopContentTitle
              title={"About Bungee Jumping"}
              // description={"ye nhi kra to kya kra"}
              more={false}
              id="about"
            />

            <div className="rafting-desktop-about-content" ref={aboutRef}>
              Ever wanted to try bungee jumping in Rishikesh? It is an amazing
              and thrilling experience. In the last 3 years (before 2021), there
              was only 1 bungee spot available (jumping height: 83 meters), but
              now 3 more options are available. One is Thrill Factory at 55
              meters; the second is Himalayan Bungee, which is India’s Heights
              Bungee at 107 meters; and the most unique splash bungee is the
              109-height bungee tower. Jumping heights are located at
              Mohanchatti, and the other three are located at Shivpuri, which
              are really close to each other. We have all these options
              available; you can book any one of them that suits you best.
            </div>
            <DesktopContentTitle
              title={"Types of Bungee Jumping"}
              more={false}
              id={"content-sc"}
            />
            <div className="rafting-desktop-about-content" ref={content_scRef}>
              <ul>
                <li>55 Meters</li>
                <li>83 Meters</li>
                <li>109 Meters</li>
                <li>117 Meters</li>
                <li>Free style (111 Meter)</li>
                <li>Couple bungee (55 Meter)</li>
                <li>Reverse bungee (55 Meter)</li>
                <li>Free style couple bungee (111 Meter)</li>
                <li>Rocket Bungee (109 Meter)</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"55 Meters Bungee Jump"}
              more={false}
              id={"content-vrfal"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_vroalRef}
            >
              This is Thrill Fectory's Bungee Jumpin in Rishikesh, with 55
              meters of height. located in Shivpuri (18.5 km from Rishikesh).
              This bungee is made on a tower; the tower height is 60 meters from
              the ground. This bungee was designed by experts from the United
              Kingdom, which makes it safe and trustworthy. Over 10,000 jumps
              have been made in this bungee since 2021. You will also get 20+
              adventure activities like reverse bungee,rope course, bowling,sky
              cycling,scad jump,zipline,and much more there.They also provide
              Tandem Bungee, so you and your companion (2 people) can experience
              it together.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Height: 55 Meters</li>
                <li>Time: 10AM-8PM</li>
                <li>Age: 12-45 Years</li>
                <li>Weight: 40 Kgs (Minimum) to 180 Kgs (Maximum)</li>
                <li>Cost: Rs 3000 Per Person</li>
                <li>Video Charges: Rs 800</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"83 Meters Bungee Jump"}
              more={false}
              id={"content-rwaac"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_rwaacRef}
            >
              This is the oldest bungee jump in Rishikesh, with 83 meters of
              height. located in Mohanchatti (25 km from Rishikesh). This bungee
              gives you the velly view; it is made on a mountain with a
              platfarm. This bungee was designed by experts from New Zealand,
              which makes it safe and trustworthy. Over 1.50 lakh jumps have
              been made in this bungee since 2010. You will also get gaint swing
              and flying fox (speed is 140 KMH and the longest flating fox in
              Asia) at this bungee site.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Height: 83 Meters</li>
                <li>Age: 12-45 Years</li>
                <li>Weight: 40 Kgs (Minimum) to 110 Kgs (Maximum)</li>
                <li>Cost: Rs 3700 Per Person</li>
                <li>Video Charges: Rs 900</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"109 Meters of Bungee Jump"}
              more={false}
              id={"content-carir"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_carirRef}
            >
              This is the safest water-based bungee jump in Rishikesh, with 109
              meters of tower height. located in Shivpuri (18.5 km from
              Rishikesh). This bungee is made on a tower; the tower height is
              109 meters from the ground. When you jump from a jumping platform,
              in the group, you get a water splash from a reverse shower, which
              gives you a totally different level of experience. You will also
              see a rocket bungee here, which is kind of a rocket bungee.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Height: 109 Meters (Tower height)</li>
                <li>
                  Age: 15 to 45 Years (Above 45: Self-declaration form and Bungy
                  Master permission required)
                </li>
                <li>Weight: 50 Kg to 110 Kg </li>
                <li>Cost: Rs 4000 Per Person</li>
                <li>Video Charges: Free</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"117 Meters of Bungee Jump"}
              more={false}
              id={"content-advantages"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_advantagesRef}
            >
              This is India’s highest bungee jump in Rishikesh, with 107 meters
              of jump height. located in Shivpuri (18.5 km from Rishikesh). This
              bungee is made on a tower; the tower height is 117 meters from the
              ground. This bungee is verified by best-in-field engineers (IIT,
              Skeleton, Studio emergence), has international bungy masters (from
              the United Kingdom, Switzerland, and Japan), and uses high-quality
              international equipment, which makes it trustworthy and safe. You
              will also get another activity in the same tower, which is gaint
              swing, and 40+ more adventure activities like paintball, go
              karting, rope course, sky cycling, supperman shot, etc. (by Hikers
              Adventure Park). Tandem Bungee is not open yet but is coming soon.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Height: 106 Meters</li>
                <li>
                  Age: 15 to 45 Years (Above 45: Self-declaration form and Bungy
                  Master permission required)
                </li>
                <li>Weight: 50 Kg to 110 Kg </li>
                <li>Tandem Bungee weight: Upto 140 Kg (Coming soon)</li>
                <li>Cost: Rs 4600 Per Person</li>
                <li>Video Charges: Rs 1500</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Reverse Bungee"}
              more={false}
              id={"content-advantages"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_advantagesRef}
            >
              This bungee requires a minimum of 2 people. It will take you to
              180 feet within 2 seconds, giving you a unique bungee experience
              that will throw you into the sky from the ground. This is located
              at Shivpuri (Thrill Factory).
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Height: 55 Meters</li>
                <li>Time: 10AM-8PM</li>
                <li>Age: 12-45 Years</li>
                <li>Weight: 40 Kgs (Minimum) to 180 Kgs (Maximum) </li>
                <li>Cost: Rs 3000 Per Person</li>
                <li>Video Charges: Rs 800</li>
              </ul>
            </div>

            <DesktopContentTitle
              title={"111 Meters of Freestyle Bungee Jumping"}
              more={false}
              id={"content-advantages"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_advantagesRef}
            >
              The Freestyle Bungee allows you to jump in your own style. There
              are multiple styles like rope cut, front and back flip, tandem,
              Harry Potter bungee jump, bike bungee jump, and much more. The
              height for this bungee is 111 meters, and it is located at
              Shivpuri. It is stabilized in the Himalayan Bungee Tower (117m).
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Height: 106 Meters</li>
                <li>
                  Age: 15 to 45 Years (Above 45: Self-declaration form and Bungy
                  Master permission required)
                </li>
                <li>Weight: 50 Kg to 110 Kg </li>
                <li>Tandem Bungee weight: Upto 140 Kg</li>
                <li>Cost: Rs 4000 Per Person</li>
                <li>Video Charges: Rs Free</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Rocket Bungee 109m"}
              more={false}
              id={"content-advantages"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_advantagesRef}
            >
              This is a reverse kind of bungee which pulls you against gravity
              via a rope and gives you a thrilling experience. It is set up in
              the Splash Bungee Tower in Rishikesh
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Height: 109 Meters (Tower height)</li>
                <li>
                  Age: 15 to 45 Years (Above 45: Self-declaration form and Bungy
                  Master permission required)
                </li>
                <li>Weight: 50 Kg to 110 Kg</li>
                <li>Cost: Rs 4000 Per Person</li>
                <li>Video Charges: Free</li>
              </ul>
            </div>

            <DesktopContentTitle
              title={"About Bungee Jumping in Rishikesh"}
              more={false}
              id={"content-rrsm"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_rrseRef}
            >
              Experience bungee jumping in Rishikesh with experienced bungee
              masters and New Zealand and United States safety standards.
              Shivpuri is becoming an adventure hub, having 3 out of 4 bungees
              and 50+ adventure activities. So if you want to book bungee
              jumping, you can also do some more adventurous activities like
              rocket bungee, reverse bungee, splash bungee, zipline, camping,
              rope course, go-karting, paintball, sky cycling, giant swing, wall
              climbing, and much more at the same location. Apart from bungee
              jumping, these bungee towers have a restaurant on top, so you can
              have delicious food while watching a beautiful sunset.
            </div>

            <DesktopContentTitle
              title={"Photography & Videography"}
              more={false}
              id={"content-itinerary"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_itineraryRef}
            >
              <ul>
                <li>55 Meters charges 800/person</li>
                <li>83 Meters charges 900/person</li>
                <li>109 Meters do not charges</li>
                <li>117 Meters charges 1500/person</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Age and Weight Criteria:"}
              more={false}
              id={"content-ecfrrr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_ecfrrrRef}
            >
              All bungees that are located in Rishikesh have almost the same age
              and weight criteria:
              <ul>
                <li>Minimum age: 12+</li>
                <li>
                  Max Age: 45 (Requires to sign the self-declaration form along
                  with permission from Bungy Master.)
                </li>
                <li>Min. weight: 45+</li>
                <li>Max Weight: 110</li>
                <li>
                  Tandon Bungee weight: 140 kg (both participants should have
                  the same weight of 5–10 kg).
                </li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Many people try to go rafting with their babies aged 5–10 years,
              but you need to understand it’s not safe. That’s why Uttrakhand
              tourism has a proper minimum and maximum age for safety.
            </div>

            <DesktopContentTitle
              title={"Safety Standards"}
              more={false}
              id={"content-inclusions"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_inclusionsRef}
            >
              All bungees follow different safety standards, but all of them are
              approved by Uttarakhand Tourism and follow all safety protocols.
              all having experienced bungee masters, 90% of them are forners who
              have 5 years+ experience as bungee masters.
            </div>
            <DesktopContentTitle
              title={"Itinerary "}
              more={false}
              id={"content-exclusions"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_exclusionsRef}
            >
              <ul>
                <li>Briefing: Understand the bungy and safety procedures.</li>
                <li>
                  Waiver Form: Sign the disclaimers stating that you have read
                  our terms and conditions informed the crew of any medical
                  conditions, and meet our set requirements for the tandem
                  bungee jumping.
                </li>

                <li>
                  Store your belongings: You will need to empty your pockets and
                  store your valuables at the reception so as to have a smooth
                  and clutter-free swing.
                </li>
                <li>
                  Onto the bridge!: Please note that entry to the bridge is
                  allowed ONLY for jumpers.
                </li>
                <li>
                  Get harnessed: A minimum of 3 safety checks are done before
                  you jump.
                </li>
                <li>
                  The Jump: Jump with rubber chords tied to your ankles and feel
                  the adrenalin rush like never before. Enjoy the fascinating
                  view, the wilderness of the valleys, and the holiness of the
                  Ganges as you overcome India's meanest feat.
                </li>
                <li>
                  Recovery: After the Jump, the Jumpers are lowered down to a
                  drop zone in the ground, and they Walk up to the cafeteria,
                  cool off with drinks and snacks while watching your Bungy
                  video. You can buy your pen drive and purchase some souvenirs
                  to remember your Jumpin Heights experience.
                </li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Payment & Cancellation Policy"}
              more={false}
              id={"content-htrr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_htrrRef}
            >
              The booking cost will be 800 for the bungee. Once the amount is
              paid the customer will receive a booking confirmation over email.
            </div>
            <div className="rafting-desktop-about-content">
              Cancellation of the booking
              <ul>
                <li>
                  The Customer will bear no cancellation charge on canceling 30
                  days prior to the booking date
                </li>
                <li>
                  The Customer will bear 50% of the booking amount as the
                  cancellation fee if canceled 15 days prior to the booking
                  date.
                </li>
                <li>
                  No refund will be made in case of canceling 7 days prior to
                  the booking date.
                </li>
                <li>
                  If the customer fails to reach the destination on the day of
                  booking, no refund will be processed.
                </li>
                <li>
                  The refunds will be processed through original payment methods
                  within 10 working days after cancellation.
                </li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"People Also Ask About Bungee"}
              more={false}
              id={"content-paaar"}
            />
            <div className="rafting-desktop-about-content" ref={content_corRef}>
              <ul>
                <li>Is prior experience required for bungee jumping?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              No prior experience is required for bungee jumping in Rishikesh.
              The bungee master provides instructions and ensures that
              participants are properly equipped and briefed before the jump.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  Is bungee jumping in Rishikesh available throughout the year?
                </li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Bungee jumping is generally available year-round in Rishikesh, but
              it's advisable to check for any weather-related considerations.
              Make sure you pre-book in peak season (March–June) to avoid
              slot-full problems.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>What about video recording for Bungee Jumping?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              You will get photos and video recordings for additional charges.
              Charges are different for different heights; the minimum is 800 Rs
              and the maximum is 1500 Rs.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Is Bungee Jumping Safe in Rishikesh?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              In Rishikesh, all the bungee jumping follows safety standards from
              the US and New Zealand. The Bungee Master also checks three times
              to ensure a safe jump.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Which bungee is truly India’s tallest bungee?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              The Himalayan Bungee is India's highest bungee, with 107 meters of
              height from the ground to the jumping spot and 117 miters of
              bungee towers. Splash bungee is the second, with 109 meters of
              tower height and approximately 90 meters of height from the ground
              to the jumping spot.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>What is Tandon Bungee in Rishikesh?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Tandon Bungee refers to a specific bungee jump in Rishikesh where
              two people do the bungee together. It's specially made for couples
              and friends who want to experience this thrilling experience
              together.
            </div>

            <div className="rafting-content-space"></div>
          </div>
          <div className="rafting-desktop-body-2">
            {/* here will be our form */}
            <div className="backdiv" ref={desktop_form_section}>
              <div className="head_background_color">
                <div className="form_heading">
                  <sapn className={"color-orange"}>
                    INR {price[current_type]}
                  </sapn>
                  {current_type == 6 || current_type == 7 || current_type == 8
                    ? "/couple"
                    : "/person"}
                </div>
                <div className="form_km_rapid1">
                  Weight:<span className="fkm_form"></span>
                  <span ref={blink_begin}>{weight[current_type].min}kg</span>
                  {/* <span className="form-km-space">·</span> */}
                  <span className="fkm_form"></span>
                  <span className="fkm_form"></span>
                  to
                  <span className="fkm_form"></span>
                  <span className="fkm_form"></span>
                  <span ref={blink_last}>{weight[current_type].max}kg</span>
                  <span className="fkm_form"></span>
                </div>
                <div className="form_km_rapid2">
                  Age: <span className="fkm_form"></span>{" "}
                  <span ref={kms_blink}>{age[current_type].min} </span>{" "}
                  <span className="fkm_form"></span>
                  <span className="form-km-space">to</span>
                  <span className="fkm_form"></span>
                  <span ref={rapids_blink}> {age[current_type].max} </span>
                  <span className="fkm_form"></span>
                </div>
              </div>
              <div className="form_fill_heading">Fill your details</div>
              <div className="form_discription">
                Need instant Jump? Dial
                <span className="form_no_color">+91-9084040280</span>
              </div>
              <TextInput
                title={"Name"}
                placeholder={"Enter your name"}
                type={"text"}
                set={setFormName}
                get={formName}
              />
              <TextInput
                title={"Contact"}
                placeholder={"Enter your contact"}
                type={"text"}
                get={formContact}
                set={setFormContact}
              />
              <TextInput
                title={"Email"}
                placeholder={"Enter your Email"}
                type={"text"}
                set={setFormEmail}
                get={formEmail}
              />
              <div className="form_name_text">Height</div>
              <div
                className="distances_div"
                ref={containerRef2}
                onMouseDown={handleMouseDown2}
                onMouseMove={handleMouseMove2}
                onMouseUp={handleMouseUp2}
                onMouseLeave={handleMouseUp2}
              >
                <button
                  className={
                    current_type == 1 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(1);
                  }}
                >
                  55 m
                </button>
                <button
                  className={
                    current_type == 2 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(2);
                  }}
                >
                  83 m
                </button>
                <button
                  className={
                    current_type == 3 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(3);
                  }}
                >
                  109 m
                </button>
                <button
                  className={
                    current_type == 4 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(4);
                  }}
                >
                  117 m
                </button>
                <button
                  className={
                    current_type == 5 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(5);
                  }}
                >
                  Free style (111 m)
                </button>
                <button
                  className={
                    current_type == 6 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(6);
                  }}
                >
                  Couple Bungee (55 m)
                </button>
                <button
                  className={
                    current_type == 7 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(7);
                  }}
                >
                  Reverse Bungee (55 m)
                </button>
                <button
                  className={
                    current_type == 8 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(8);
                  }}
                >
                  Free style couple bungee (111 m)
                </button>
                <button
                  className={
                    current_type == 9 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(9);
                  }}
                >
                  Rocket Bungee (109 m)
                </button>
              </div>
              <InputDate
                title={"Select Day"}
                placeholder={"Choose a date"}
                forhtml={"desktop_date"}
                setChoosen_date={setChoosen_date}
                choosen_date={choosen_date}
              />
              {choosen_date != undefined && choosen_date != "" ? (
                <>
                  <div className="form_name_text">
                    Slots Left: {choosen_date}
                  </div>
                  <div
                    className="distances_div"
                    ref={containerRef3}
                    onMouseDown={handleMouseDown3}
                    onMouseMove={handleMouseMove3}
                    onMouseUp={handleMouseUp3}
                    onMouseLeave={handleMouseUp3}
                  >
                    {slots.map((item, index) => {
                      return (
                        <button
                          className={
                            choosen_slot == index
                              ? "options_time_color_div"
                              : "options_time_div"
                          }
                          onClick={() => {
                            setChoosen_slot(index);
                          }}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                </>
              ) : null}
              <div className="number_viv">
                <div className="form_name_text">Total People</div>
                <div
                  className="form_chose_candidate"
                  ref={containerRef4}
                  onMouseDown={handleMouseDown4}
                  onMouseMove={handleMouseMove4}
                  onMouseUp={handleMouseUp4}
                  onMouseLeave={handleMouseUp4}
                >
                  <input
                    type="number"
                    className="formt_number_input"
                    placeholder="Choose another day"
                    onInput={(e) => {
                      setNo_people(e.target.value);
                    }}
                    value={no_people}
                  />
                  <button
                    className={
                      no_people >= 1 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(1);
                    }}
                  >
                    1
                  </button>
                  <button
                    className={
                      no_people >= 2 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(2);
                    }}
                  >
                    2
                  </button>
                  <button
                    className={
                      no_people >= 3 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(3);
                    }}
                  >
                    3
                  </button>
                  <button
                    className={
                      no_people >= 4 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(4);
                    }}
                  >
                    4
                  </button>
                  <button
                    className={
                      no_people >= 5 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(5);
                    }}
                  >
                    5
                  </button>
                  <button
                    className={
                      no_people >= 6 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(6);
                    }}
                  >
                    6
                  </button>
                  <button
                    className={
                      no_people >= 7 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(7);
                    }}
                  >
                    7
                  </button>
                  <button
                    className={
                      no_people >= 8 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(8);
                    }}
                  >
                    8
                  </button>
                </div>
              </div>
              <div className="form_blue_div">
                {current_type <= 4 ? (
                  <>
                    {km[current_type]} meter jump ( ₹{" "}
                    {parseInt(price[current_type]).toLocaleString()} ) x{" "}
                    {no_people} adults = ₹{" "}
                    {parseInt(price[current_type] * no_people).toLocaleString()}
                  </>
                ) : (
                  <>
                    {km[current_type]} ( ₹{" "}
                    {parseInt(price[current_type]).toLocaleString()} ) x{" "}
                    {no_people} adults = ₹{" "}
                    {parseInt(price[current_type] * no_people).toLocaleString()}
                  </>
                )}
              </div>
              <div className="form_last_discription">
                Need instant rafting? Dial <pre> </pre>
                <span className="form_no_color"> +91-9084040280</span>
              </div>
              {current_type == 2 ? null : (
                <>
                  <div
                    className="form_last_proceed_div"
                    onClick={submitAdvance}
                  >
                    Pay INR{" "}
                    {(
                      (parseInt(price[current_type] * no_people) * 20) /
                      100
                    ).toLocaleString()}{" "}
                    (20% Advance)
                  </div>
                  <div className="flpd-or-text">
                    <div className="flpd-or-1"></div>
                    <div className="flpd-or-or">or</div>
                    <div className="flpd-or-2"></div>
                  </div>
                </>
              )}
              <div className="form_last_proceed_div" onClick={submitFull}>
                Pay INR{" "}
                {parseInt(price[current_type] * no_people).toLocaleString()}{" "}
                (Full)
              </div>
            </div>

            <div className="after-form">
              <AfterForm
                title={"Bungee jumping at just"}
                price={"3000"}
                goto={desktop_form_section}
              />
              <BigGroup activity={"Bungee"} />
            </div>
            {/* form ends here */}
          </div>
          <div></div>
        </div>

        {desktop_photo ? (
          <div className="rafting-desktop-image-section">
            <Photo_section_desktop
              setDesktop_photo={setDesktop_photo}
              gallery={false}
              photos={[
                require("../Public/bj55/bj55_1.png"),
                require("../Public/bj55/bj55_2.jpg"),
                require("../Public/bj55/bj55_3.jpg"),
                require("../Public/bj83/bj83_6.jpeg"),
                require("../Public/bj83/bj83_7.jpeg"),
                require("../Public/bj83/bj83_4.jpeg"),
                require("../Public/bj83/bj83_5.jpeg"),
                require("../Public/bj109/bj109_1.jpeg"),
                require("../Public/bj117/bj117_1.jpeg"),
                require("../Public/bj117/bj117_2.jpeg"),
                require("../Public/bj117/bj117_3.jpeg"),
                require("../Public/bj117/bj117_4.jpeg"),
                bungee1,
                bungee2,
                bungee3,
                bungee4,
                bungee5,
                bungee6,
                bungee7,
                bungee8,
                bungee9,
              ]}
            />
          </div>
        ) : null}
        {desktop_status ? (
          <div className="rafting-desktop-image-section">
            <Status_section
              desktop_status={desktop_status}
              setDesktop_status={setDesktop_status}
              desktop_status_indi={desktop_status_indi}
            />
          </div>
        ) : null}
      </div>

      <form
        action="https://secure.payu.in/_payment"
        // action="https://test.payu.in/_payment"
        ref={f_r_form}
        method="post"
        className="hidden-form-rafting"
      >
        <input type="hidden" ref={f_r_key} name="key" value="heRjXi" />
        <input
          type="hidden"
          ref={f_r_txnid}
          name="txnid"
          value="t6svtqtjRdl4ws1"
        />
        <input
          type="hidden"
          ref={f_r_p_info}
          name="productinfo"
          value="iPhone"
        />
        <input type="hidden" ref={f_r_amount} name="amount" value="10" />
        <input
          type="hidden"
          ref={f_r_email}
          name="email"
          value="test@gmail.com"
        />
        <input
          type="hidden"
          ref={f_r_first_name}
          name="firstname"
          value="Ashish"
        />
        <input
          type="hidden"
          name="surl"
          value="https://api.thrillthirsty.com/pay/sucess-bungee"
        />
        <input
          type="hidden"
          name="furl"
          value="https://api.thrillthirsty.com/fail"
        />
        <input
          type="hidden"
          name="phone"
          ref={f_r_contact}
          value="9988776655"
        />
        <input type="hidden" name="hash" ref={f_r_hash} value="" />
      </form>

      <div className="rafting-mobile-section">
        <HeaderMobile
          setPhotoSection={setPhotoSection}
          img={require("../Public/bj117/bj117_4.jpeg")}
          title={"Bungee jumping in Rishikesh"}
          imgs={[bungee4, bungee1, bungee2, bungee7, bungee3]}
        />
        <MainTitleBungee
          setForm={setForm}
          price={price[current_type]}
          type={km[current_type]}
          rapids={rapids[current_type]}
          se={se}
          current_type={current_type}
          rapids_blink_mobile={rapids_blink_mobile}
          km_blink_mobile={km_blink_mobile}
          start_blink_mobile={start_blink_mobile}
          end_blink_mobile={end_blink_mobile}
          age={age}
          weight={weight}
        />
        <div className="rafting-options">
          <Options
            content={"55 m"}
            active={current_type == 1 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={1}
          />
          <Options
            content={"83 m"}
            active={current_type == 2 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={2}
          />
          <Options
            content={"109 m"}
            active={current_type == 3 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={3}
          />
          <Options
            content={"117 m"}
            active={current_type == 4 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={4}
          />
          <Options
            content={"Freestyle (111 m)"}
            active={current_type == 5 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={5}
          />
          <Options
            content={"Couple Bungee (55 m)"}
            active={current_type == 6 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={6}
          />
          <Options
            content={"Reverse Bungee (55 m)"}
            active={current_type == 7 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={7}
          />
          <Options
            content={"Freestyle Couple Bungee (111 m)"}
            active={current_type == 8 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={8}
          />
          <Options
            content={"Rocket Bungee (109 m)"}
            active={current_type == 9 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={9}
          />
          <div></div>
        </div>
        {/* <div className="activity-timeline">
          {where[current_type].map((item, index) => {
            return (
              <ActivityTimeline
                indicator={where[current_type][index].tag}
                title={where[current_type][index].title}
                img={where[current_type][index].img}
              />
            );
          })}

          <div></div>
        </div> */}
        {/* <Title
          title={"Some of our memories"}
          description={"These are some of our best memories"}
          more={false}
        /> */}

        {/* <div className="activity-status-div">
          <Status
            img={require("../Public/bj55/bj55_1.png")}
            content={""}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/bj83/bj83_4.jpeg")}
            content={""}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/bj109/bj109_1.jpeg")}
            content={""}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/bj117/bj117_1.jpeg")}
            content={""}
            setStatus={setStatus}
          />
        </div> */}

        <Title
          title={"Watch our shorts"}
          description={"These are some of our shorts"}
          more={false}
        />

        <div className="desktop-status-whole-mobile">
          <div className="desktop-shorts">
            {/* <a
              href="https://youtube.com/shorts/s3mLYJUrl0E?si=rcCMWPVGTFf20K7b"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="20px"
                height="20px"
                baseProfile="basic"
              >
                <path
                  fill="#ff3d00"
                  d="M29.103,2.631c4.217-2.198,9.438-0.597,11.658,3.577c2.22,4.173,0.6,9.337-3.617,11.534l-3.468,1.823	c2.987,0.109,5.836,1.75,7.328,4.555c2.22,4.173,0.604,9.337-3.617,11.534L18.897,45.37c-4.217,2.198-9.438,0.597-11.658-3.577	s-0.6-9.337,3.617-11.534l3.468-1.823c-2.987-0.109-5.836-1.75-7.328-4.555c-2.22-4.173-0.6-9.337,3.617-11.534	C10.612,12.346,29.103,2.631,29.103,2.631z M19.122,17.12l11.192,6.91l-11.192,6.877C19.122,30.907,19.122,17.12,19.122,17.12z"
                />
                <path
                  fill="#fff"
                  d="M19.122,17.12v13.787l11.192-6.877L19.122,17.12z"
                />
              </svg>
              <img src={require("../Public/bjshort1.jpeg")} alt="" />
            </a> */}

            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/ni82w2B3fr8"
              title="India&#39;s highest Bungy Jump #rishikesh #bungeejumping #bungee #travelshorts #travelvlog #courage #yt"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="desktop-shorts">
            {/* <a
              href="https://youtube.com/shorts/i5KctFOwOTo?si=QY-UHPhkIRA1guTW"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="20px"
                height="20px"
                baseProfile="basic"
              >
                <path
                  fill="#ff3d00"
                  d="M29.103,2.631c4.217-2.198,9.438-0.597,11.658,3.577c2.22,4.173,0.6,9.337-3.617,11.534l-3.468,1.823	c2.987,0.109,5.836,1.75,7.328,4.555c2.22,4.173,0.604,9.337-3.617,11.534L18.897,45.37c-4.217,2.198-9.438,0.597-11.658-3.577	s-0.6-9.337,3.617-11.534l3.468-1.823c-2.987-0.109-5.836-1.75-7.328-4.555c-2.22-4.173-0.6-9.337,3.617-11.534	C10.612,12.346,29.103,2.631,29.103,2.631z M19.122,17.12l11.192,6.91l-11.192,6.877C19.122,30.907,19.122,17.12,19.122,17.12z"
                />
                <path
                  fill="#fff"
                  d="M19.122,17.12v13.787l11.192-6.877L19.122,17.12z"
                />
              </svg>
              <img src={require("../Public/bjshort2.jpeg")} alt="" />
            </a> */}

            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/i5KctFOwOTo"
              title="FreeStyle Bungy Jump in Rishikesh #rishikesh #bungeejumping #harrypotter #harrypotterfan #potterhead"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>

          <div className="desktop-shorts">
            {/* <a
              href="https://youtube.com/shorts/ni82w2B3fr8?si=5Az6GXmIM1IOMfmh"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="20px"
                height="20px"
                baseProfile="basic"
              >
                <path
                  fill="#ff3d00"
                  d="M29.103,2.631c4.217-2.198,9.438-0.597,11.658,3.577c2.22,4.173,0.6,9.337-3.617,11.534l-3.468,1.823	c2.987,0.109,5.836,1.75,7.328,4.555c2.22,4.173,0.604,9.337-3.617,11.534L18.897,45.37c-4.217,2.198-9.438,0.597-11.658-3.577	s-0.6-9.337,3.617-11.534l3.468-1.823c-2.987-0.109-5.836-1.75-7.328-4.555c-2.22-4.173-0.6-9.337,3.617-11.534	C10.612,12.346,29.103,2.631,29.103,2.631z M19.122,17.12l11.192,6.91l-11.192,6.877C19.122,30.907,19.122,17.12,19.122,17.12z"
                />
                <path
                  fill="#fff"
                  d="M19.122,17.12v13.787l11.192-6.877L19.122,17.12z"
                />
              </svg>
              <img src={require("../Public/bjshort3.jpeg")} alt="" />
            </a> */}

            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/ONpV8yWjI5c"
              title="#garylu #75hardchallenge #75hard #bungeejumping #bungee #rishikesh #ytindia #travelshorts #viral"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>

          <div className="desktop-shorts">
            {/* <a
              href="https://youtube.com/shorts/ONpV8yWjI5c?si=bqBeMx_Iif9xGVI0"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="20px"
                height="20px"
                baseProfile="basic"
              >
                <path
                  fill="#ff3d00"
                  d="M29.103,2.631c4.217-2.198,9.438-0.597,11.658,3.577c2.22,4.173,0.6,9.337-3.617,11.534l-3.468,1.823	c2.987,0.109,5.836,1.75,7.328,4.555c2.22,4.173,0.604,9.337-3.617,11.534L18.897,45.37c-4.217,2.198-9.438,0.597-11.658-3.577	s-0.6-9.337,3.617-11.534l3.468-1.823c-2.987-0.109-5.836-1.75-7.328-4.555c-2.22-4.173-0.6-9.337,3.617-11.534	C10.612,12.346,29.103,2.631,29.103,2.631z M19.122,17.12l11.192,6.91l-11.192,6.877C19.122,30.907,19.122,17.12,19.122,17.12z"
                />
                <path
                  fill="#fff"
                  d="M19.122,17.12v13.787l11.192-6.877L19.122,17.12z"
                />
              </svg>
              <img src={require("../Public/bjshort4.jpeg")} alt="" />
            </a> */}

            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/s3mLYJUrl0E"
              title="#tandem #bungee #bungeejumping #adventuretime #rafting #rishikesh #rafting #viralshorts #viralvideos"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <PageMenu
          menu={menu_mobile_content}
          current_mobile_menu={current_mobile_menu}
          setCurrent_mobile_menu={setCurrent_mobile_menu}
          title={"Bungee Jumping"}
        />
        <TitleWithoutDescription content={"About Bungee jumping"} />
        <div className="activity-content-section" ref={aboutRefm}>
          Ever wanted to try bungee jumping in Rishikesh? It is an amazing and
          thrilling experience. In the last 3 years (before 2021), there was
          only 1 bungee spot available (jumping height: 83 meters), but now 3
          more options are available. One is Thrill Factory at 55 meters; the
          second is Himalayan Bungee, which is India’s Heights Bungee at 107
          meters; and the most unique splash bungee is the 109-height bungee
          tower. Jumping heights are located at Mohanchatti, and the other three
          are located at Shivpuri, which are really close to each other. We have
          all these options available; you can book any one of them that suits
          you best.
        </div>
        <TitleWithoutDescription content={"Types of bungee jumps"} />
        <div className="activity-content-section ulli" ref={content_scRefm}>
          <ul>
            <li>55 meters</li>
            <li>83 meters</li>
            <li>109 meters</li>
            <li>117 meters</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"55 Meters Bungee Jump"} />
        <div className="activity-content-section" ref={content_vroalRefm}>
          This is Thrill Fectory's Bungee Jumpin in Rishikesh, with 55 meters of
          height. located in Shivpuri (18.5 km from Rishikesh). This bungee is
          made on a tower; the tower height is 60 meters from the ground. This
          bungee was designed by experts from the United Kingdom, which makes it
          safe and trustworthy. Over 10,000 jumps have been made in this bungee
          since 2021. You will also get 20+ adventure activities like reverse
          bungee,rope course, bowling,sky cycling,scad jump,zipline,and much
          more there.They also provide Tandem Bungee, so you and your companion
          (2 people) can experience it together.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Height: 55 Meters</li>
            <li>Time: 10AM-8PM</li>
            <li>Age: 12-45 Years</li>
            <li>Weight: 40 Kgs (Minimum) to 180 Kgs (Maximum)</li>
            <li>Cost: Rs 3000 Per Person</li>
            <li>Video Charges: Rs 800</li>
          </ul>
        </div>

        <TitleWithoutDescription content={"83 Meters Bungee Jump"} />
        <div className="activity-content-section" ref={content_rwaacRefm}>
          This is the oldest bungee jump in Rishikesh, with 83 meters of height.
          located in Mohanchatti (25 km from Rishikesh). This bungee gives you
          the velly view; it is made on a mountain with a platfarm. This bungee
          was designed by experts from New Zealand, which makes it safe and
          trustworthy. Over 1.50 lakh jumps have been made in this bungee since
          2010. You will also get gaint swing and flying fox (speed is 140 KMH
          and the longest flating fox in Asia) at this bungee site.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Height: 83 Meters</li>
            <li>Age: 12-45 Years</li>
            <li>Weight: 40 Kgs (Minimum) to 110 Kgs (Maximum)</li>
            <li>Cost: Rs 3700 Per Person</li>
            <li>Video Charges: Rs 900</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"109 Meters Bungee Jump"} />
        <div className="activity-content-section" ref={content_carirRefm}>
          This is the safest water-based bungee jump in Rishikesh, with 109
          meters of tower height. located in Shivpuri (18.5 km from Rishikesh).
          This bungee is made on a tower; the tower height is 109 meters from
          the ground. When you jump from a jumping platform, in the group, you
          get a water splash from a reverse shower, which gives you a totally
          different level of experience. You will also see a rocket bungee here,
          which is kind of a rocket bungee.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Height: 109 Meters (Tower height)</li>
            <li>
              Age: 15 to 45 Years (Above 45: Self-declaration form and Bungy
              Master permission required)
            </li>
            <li>Weight: 50 Kg to 110 Kg </li>
            <li>Cost: Rs 4000 Per Person</li>
            <li>Video Charges: Free </li>
          </ul>
        </div>

        <TitleWithoutDescription content={"117 Meters Bungee Jump"} />
        <div className="activity-content-section" ref={content_advantagesRefm}>
          This is India’s highest bungee jump in Rishikesh, with 107 meters of
          jump height. located in Shivpuri (18.5 km from Rishikesh). This bungee
          is made on a tower; the tower height is 117 meters from the ground.
          This bungee is verified by best-in-field engineers (IIT, Skeleton,
          Studio emergence), has international bungy masters (from the United
          Kingdom, Switzerland, and Japan), and uses high-quality international
          equipment, which makes it trustworthy and safe. You will also get
          another activity in the same tower, which is gaint swing, and 40+ more
          adventure activities like paintball, go karting, rope course, sky
          cycling, supperman shot, etc. (by Hikers Adventure Park). Tandem
          Bungee is not open yet but is coming soon.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Height: 106 Meters</li>
            <li>
              Age: 15 to 45 Years (Above 45: Self-declaration form and Bungy
              Master permission required)
            </li>
            <li>Weight: 50 Kg to 110 Kg </li>
            <li>Tandem Bungee weight: Upto 140 Kg (Coming soon)</li>
            <li>Cost: Rs 4600 Per Person</li>
            <li>Video Charges: Rs 1500</li>
          </ul>
        </div>
        <TitleWithoutDescription
          content={"111 Meters of Freestyle Bungee Jumping"}
        />
        <div className="activity-content-section" ref={content_advantagesRefm}>
          The Freestyle Bungee allows you to jump in your own style. There are
          multiple styles like rope cut, front and back flip, tandem, Harry
          Potter bungee jump, bike bungee jump, and much more. The height for
          this bungee is 111 meters, and it is located at Shivpuri. It is
          stabilized in the Himalayan Bungee Tower (117m).
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Height: 106 Meters</li>
            <li>
              Age: 15 to 45 Years (Above 45: Self-declaration form and Bungy
              Master permission required)
            </li>
            <li>Weight: 50 Kg to 110 Kg</li>
            <li>Tandem Bungee weight: Upto 140 Kg</li>
            <li>Cost: Rs 4000 Per Person</li>
            <li>Video Charges: Rs Free</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Reverse Bungee"} />
        <div className="activity-content-section" ref={content_advantagesRefm}>
          This bungee requires a minimum of 2 people. It will take you to 180
          feet within 2 seconds, giving you a unique bungee experience that will
          throw you into the sky from the ground. This is located at Shivpuri
          (Thrill Factory).
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Height: 55 Meters</li>
            <li>Time: 10AM-8PM</li>
            <li>Age: 12-45 Years</li>
            <li>Weight: 40 Kgs (Minimum) to 180 Kgs (Maximum)</li>
            <li>Cost: Rs 3000 Per Person</li>
            <li>Video Charges: Rs 800</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Rocket Bungee 109m"} />
        <div className="activity-content-section" ref={content_advantagesRefm}>
          This is a reverse kind of bungee which pulls you against gravity via a
          rope and gives you a thrilling experience. It is set up in the Splash
          Bungee Tower in Rishikesh
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Height: 109 Meters (Tower height)</li>
            <li>
              Age: 15 to 45 Years (Above 45: Self-declaration form and Bungy
              Master permission required)
            </li>
            <li>Weight: 50 Kg to 110 Kg</li>
            <li>Cost: Rs 4000 Per Person</li>
            <li>Video Charges: Free</li>
          </ul>
        </div>
        <TitleWithoutDescription
          content={"About Bungee Jumping in Rishikesh"}
        />
        <div className="activity-content-section" ref={content_rrseRefm}>
          Experience bungee jumping in Rishikesh with experienced bungee masters
          and New Zealand and United States safety standards. Shivpuri is
          becoming an adventure hub, having 3 out of 4 bungees and 50+ adventure
          activities. So if you want to book bungee jumping, you can also do
          some more adventurous activities like rocket bungee, reverse bungee,
          splash bungee, zipline, camping, rope course, go-karting, paintball,
          sky cycling, giant swing, wall climbing, and much more at the same
          location. Apart from bungee jumping, these bungee towers have a
          restaurant on top, so you can have delicious food while watching a
          beautiful sunset.
        </div>

        <TitleWithoutDescription content={"Photography & Videography"} />
        <div
          className="activity-content-section ulli"
          ref={content_itineraryRefm}
        >
          <ul>
            {/* <li>Only Splash Bungee gives you free video recording, and the other 3 have their own price.</li> */}
            <li>55 Meters charges 800/person</li>
            <li>83 Meters charges 900/person</li>
            <li>109 Meters do not charges</li>
            <li>117 Meters charges 1500/person</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Age and Weight Criteria:"} />
        <div className="activity-content-section ulli" ref={content_ecfrrrRefm}>
          All bungees that are located in Rishikesh have almost the same age and
          weight criteria
          <ul>
            <li>Minimum age: 12+</li>
            <li>
              Max Age: 45 (Requires to sign the self-declaration form along with
              permission from Bungy Master.)
            </li>
            <li>Min. weight: 45kg</li>
            <li>Max Weight: 110kg</li>
            <li>
              Tandon Bungee weight: 140 kg (both participants should have the
              same weight of 5–10 kg).
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Safety Standards"} />
        <div
          className="activity-content-section ulli"
          ref={content_inclusionsRefm}
        >
          All bungees follow different safety standards, but all of them are
          approved by Uttarakhand Tourism and follow all safety protocols. all
          having experienced bungee masters, 90% of them are forners who have 5
          years+ experience as bungee masters.
        </div>
        <TitleWithoutDescription content={"Itinerary "} />
        <div
          className="activity-content-section ulli"
          ref={content_exclusionsRefm}
        >
          <ul>
            <li>Briefing: Understand the bungy and safety procedures.</li>
            <li>
              Waiver Form: Sign the disclaimers stating that you have read our
              terms and conditions informed the crew of any medical conditions,
              and meet our set requirements for the tandem bungee jumping.
            </li>
            <li>
              Store your belongings: You will need to empty your pockets and
              store your valuables at the reception so as to have a smooth and
              clutter-free swing.
            </li>
            <li>
              Onto the bridge!: Please note that entry to the bridge is allowed
              ONLY for jumpers.
            </li>
            <li>
              Get harnessed: A minimum of 3 safety checks are done before you
              jump.
            </li>
            <li>
              The Jump: Jump with rubber chords tied to your ankles and feel the
              adrenalin rush like never before. Enjoy the fascinating view, the
              wilderness of the valleys, and the holiness of the Ganges as you
              overcome India's meanest feat.
            </li>
            <li>
              Recovery: After the Jump, the Jumpers are lowered down to a drop
              zone in the ground, and they Walk up to the cafeteria, cool off
              with drinks and snacks while watching your Bungy video. You can
              buy your pen drive and purchase some souvenirs to remember your
              Jumpin Heights experience.
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Payment & Cancellation Policy"} />
        <div className="activity-content-section" ref={content_htrrRefm}>
          The booking cost will be 800 for the bungee. Once the amount is paid
          the customer will receive a booking confirmation over email.
        </div>
        <div className="activity-content-section">
          Cancellation of the booking
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              The Customer will bear no cancellation charge on canceling 30 days
              prior to the booking date.
            </li>
            <li>
              The Customer will bear 50% of the booking amount as the
              cancellation fee if canceled 15 days prior to the booking date.
            </li>
            <li>
              No refund will be made in case of canceling 7 days prior to the
              booking date.
            </li>
            <li>
              If the customer fails to reach the destination on the day of
              booking, no refund will be processed.
            </li>
            <li>
              The refunds will be processed through original payment methods
              within 10 working days after cancellation.
            </li>
          </ul>
        </div>

        <TitleWithoutDescription content={"People Also Ask About Bungee"} />
        <div className="activity-content-section ulli" ref={content_corRefm}>
          <ul>
            <li>Is prior experience required for bungee jumping?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          No prior experience is required for bungee jumping in Rishikesh. The
          bungee master provides instructions and ensures that participants are
          properly equipped and briefed before the jump.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              Is bungee jumping in Rishikesh available throughout the year?
            </li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Bungee jumping is generally available year-round in Rishikesh, but
          it's advisable to check for any weather-related considerations. Make
          sure you pre-book in peak season (March–June) to avoid slot-full
          problems.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>What about video recording for Bungee Jumping?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          You will get photos and video recordings for additional charges.
          Charges are different for different heights; the minimum is 800 Rs and
          the maximum is 1500 Rs.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Is Bungee Jumping Safe in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          In Rishikesh, all the bungee jumping follows safety standards from the
          US and New Zealand. The Bungee Master also checks three times to
          ensure a safe jump.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Which bungee is truly India’s tallest bungee?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          The Himalayan Bungee is India's highest bungee, with 107 meters of
          height from the ground to the jumping spot and 117 miters of bungee
          towers. Splash bungee is the second, with 109 meters of tower height
          and approximately 90 meters of height from the ground to the jumping
          spot.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>What is Tandon Bungee in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Tandon Bungee refers to a specific bungee jump in Rishikesh where two
          people do the bungee together. It's specially made for couples and
          friends who want to experience this thrilling experience together.
        </div>

        {/* form starts here */}
        {form ? (
          <GlobalFormBungee
            setForm={setForm}
            mobile_contact={mobile_contact}
            mobile_date={mobile_date}
            mobile_dor={mobile_dor}
            mobile_email={mobile_email}
            mobile_name={mobile_name}
            mobile_no_of_people={mobile_no_of_people}
            setMobile_contact={setMobile_contact}
            setMobile_dor={setMobile_dor}
            setMobile_email={setMobile_email}
            setMobile_name={setMobile_name}
            setMobile_no_of_people={setMobile_no_of_people}
            setMobile_date={setMobile_date}
            setMobile_slot={setMobile_slot}
            mobile_slot={mobile_slot}
            current_type={current_type}
            setCurrent_type={setCurrent_type}
            price={price}
            km={km}
            setChoosen_slot={setChoosen_slot}
            choosen_slot={choosen_slot}
            submitMobileAdvance={submitMobileAdvance}
            submitMobileFull={submitMobileFull}
          />
        ) : null}

        {status ? (
          <GlobalPhotoStatus
            setStatus={setStatus}
            desktop_status={desktop_status}
            desktop_status_indi={desktop_status_indi}
          />
        ) : null}
        {photoSection ? (
          <PhotoSection
            setPhotoSection={setPhotoSection}
            photos={[
              require("../Public/bj55/bj55_1.png"),
              require("../Public/bj55/bj55_2.jpg"),
              require("../Public/bj55/bj55_3.jpg"),
              require("../Public/bj83/bj83_6.jpeg"),
              require("../Public/bj83/bj83_7.jpeg"),
              require("../Public/bj83/bj83_4.jpeg"),
              require("../Public/bj83/bj83_5.jpeg"),
              require("../Public/bj109/bj109_1.jpeg"),
              require("../Public/bj117/bj117_1.jpeg"),
              require("../Public/bj117/bj117_2.jpeg"),
              require("../Public/bj117/bj117_3.jpeg"),
              require("../Public/bj117/bj117_4.jpeg"),
              bungee1,
              bungee2,
              bungee3,
              bungee4,
              bungee5,
              bungee6,
              bungee7,
              bungee8,
              bungee9,
            ]}
          />
        ) : null}
        <Footer />
        {/* <div className="space"></div> */}
        <BottomTab active={"bungee-jumping"} />
      </div>
    </>
  );
}

export default Bungee;
