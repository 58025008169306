import "./Status.css";

function Status({ img, content, setStatus }) {
  return (
    <div
      className="Status_div"
      onClick={() => {
        setStatus(true);
      }}
    >
      <div className="Status_upper_div">
        <div className="status_big_div">
          <div className="status_medium_div">
            <div className="status_small_div">
              <img src={img} alt="" className="status_img" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
      <div className="statys_content">{content}</div>
    </div>
  );
}
export default Status;
