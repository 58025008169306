import BottomTab from "../ComponentsHome/BottomTab";
import HeaderMobile from "../ComponentsHome/HeaderMobile";
import Title from "../ComponentsHome/Title";
// import Explore from "../ComponentsHome/Explore";
import Package from "../ComponentsHome/Package";
// import Budget from "../ComponentsHome/Budget";
// import CircularData from "../ComponentsHome/Circular-data";
// import Options from "../ComponentsHome/Options";
import Footer from "../GlobalComponents/Footer";
import {
  FeatureSectionLarge,
  FeatureSectionSmall,
} from "../ComponentsHome/FeatureSection";
import "./HomeDesktop.css";
import "./Home.css";
import "./HomeResponsive.css";
// import { Link } from "react-router-dom";
import destination from "../Public/Destination.jpg";
// import Manali from "../Public/Manali.jpg";
// import Harsil from "../Public/Harsil.jpg";
// import Dehradun from "../Public/Dehradun.jpg";
// import Nainital from "../Public/Nainital.jpg";
// import Rishikesh from "../Public/Rishikesh.jpg";
// import Haridwar from "../Public/Haridwar.jpg";
import Header from "../DesktopComponents/Header";
import { useEffect, useRef, useState } from "react";
import TitleDesktop from "../DesktopComponents/TitleDesktop";
import PackageDesktop from "../DesktopComponents/PackageDesktop";

import DataContext from "../Context/DataContext";
import { useContext } from "react";
import axios from "axios";
import DesktopFooter from "../DesktopComponents/DesktopFooter";
// import raftingimg from "../Public/rafting5.jpg";
// import campingimg from "../Public/camping.jpg";
// import bungeeimg from "../Public/bungee.jpg";

// import newrafting1 from "../Public/newrafting1.jpg";
import newrafting2 from "../Public/newrafting2.jpg";
// import newrafting3 from "../Public/newrafting3.jpg";
import newrafting4 from "../Public/newrafting4.jpg";
// import newrafting5 from "../Public/newrafting5.jpg";
// import newrafting6 from "../Public/newrafting6.jpg";

// import raftingnew from "../Public/rafting8.jpg";
import campingnew from "../Public/newcamping2.jpg";
import bungeenew from "../Public/bungee4.jpg";
// import campinghd from "../Public/campinghd.png";

// import bungee1 from "../Public/bungee1.jpg";
// import bungee2 from "../Public/bungee2.jpg";
// import bungee3 from "../Public/bungee3.jpg";
// import bungee4 from "../Public/bungee4.jpg";
// import bungee5 from "../Public/bungee5.jpg";
import bungee6 from "../Public/bungee6.jpg";
// import bungee7 from "../Public/bungee7.jpg";
// import bungee8 from "../Public/bungee8.jpg";
// import bungee9 from "../Public/bungee9.jpg";

// import newcamping1 from "../Public/newcamping1.jpg";
import newcamping2 from "../Public/newcamping2.jpg";
import newcamping3 from "../Public/newcamping3.jpg";
// import newcamping4 from "../Public/newcamping4.jpg";
import { useNavigate } from "react-router-dom";
import GlobalForm from "../GlobalComponents/Globalform";
import GlobalFormBungee from "../GlobalComponents/GlobalFormBungee";
import GlobalFormCamping from "../GlobalComponents/GlobalFormCamping";

// import r1 from "../Public/rafting/r1.jpg";
import r2 from "../Public/rafting/r2.jpg";
// import r3 from "../Public/rafting/r3.jpg";
// import r4 from "../Public/rafting/r4.jpg";
// import r5 from "../Public/rafting/r5.jpg";
// import r6 from "../Public/rafting/r6.jpg";
// import r7 from "../Public/rafting/r7.jpg";
// import r8 from "../Public/rafting/r8.jpg";
// import r9 from "../Public/rafting/r9.jpg";
// import r10 from "../Public/rafting/r10.jpg";
// import r11 from "../Public/rafting/r11.jpg";
// import r12 from "../Public/rafting/r12.jpg";
// import r13 from "../Public/rafting/r13.jpg";
// import r14 from "../Public/rafting/r14.jpg";
// import r15 from "../Public/rafting/r15.jpg";
// import r16 from "../Public/rafting/r16.jpg";

// import Photo_section_desktop from "../DesktopComponents/Photo_section";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CallWhattsaap from "../Msgs/CallWhattsaap";
function IndiPerfectThrill({ img, title, link }) {
  return (
    <Link to={link} className="indi-perfect-thrill">
      <div className="indi-pt-dark">{title}</div>
      <img
        src={img}
        alt=""
        className="indi-perfect-thrill-img"
        loading="lazy"
      />
    </Link>
  );
}

function Home() {
  const navigateToRafting = () => {
    window.open("/rafting-in-rishikesh", "_blank");
  };

  const navigateToCamping = () => {
    window.open("/camping-in-rishikesh", "_blank");
  };

  const navigateToBungee = () => {
    window.open("/bungee-jumping-in-rishikesh", "_blank");
  };
  const navigate = useNavigate();
  const [home_packages, setHome_packages] = useState([
    {
      img: require("../Public/rafting/r2.jpg"),
      tag: "09 km River Rafting",
      rating: "4.8 (120+)",
      title: "From Bhrampuri to Nim beach | River Rafting (09km)",
      price: "INR 600",
      strikePrice: "INR 800",
      type: 1,
    },
    {
      img: require("../Public/rafting/r6.jpg"),
      tag: "12 km River Rafting",
      rating: "5.0 (130+)",
      title: "From Marine Drive to Shivpuri | River Rafting (12km)",
      price: "INR 600",
      strikePrice: "INR 800",
      type: 2,
    },
    {
      img: require("../Public/rafting/r8.jpg"),
      tag: "12 km River Rafting",
      rating: "4.7 (100+)",
      title: "From Club House to Nim beach | River Rafting (12km)",
      price: "INR 600",
      strikePrice: "INR 800",
      type: 3,
    },
    {
      img: require("../Public/rafting/r11.jpg"),
      tag: "16 km River Rafting",
      rating: "5.0 (230+)",
      title: "From Shvpuri to Neem beach | River Rafting (16km)",
      price: "INR 1,000",
      strikePrice: "INR 1,200",
      type: 4,
    },
    {
      img: require("../Public/rafting/r14.jpg"),
      tag: "26 km River Rafting",
      rating: "4.9 (150+)",
      title: "From Marine Drive to Nim beach | River Rafting (26km)",
      price: "INR 1,500",
      strikePrice: "INR 1,700",
      type: 5,
    },
    {
      img: require("../Public/rafting/r16.jpg"),
      tag: "36 km River Rafting",
      rating: "5.0 (80+)",
      title: "From Kaudiyala to Nim beach | River Rafting (36km)",
      price: "INR 2,500",
      strikePrice: "INR 2,700",
      type: 6,
    },
  ]);

  const [camping_packages, setCamping_packages] = useState([
    {
      img: require("../Public/beachCamp/beachcamp4.jpeg"),
      tag: "Beach Camp",
      rating: "4.9 (120+)",
      title: "Ganga beach camp: Your riverside escape | Beach Camp",
      price: "INR 1,000",
      strikePrice: "INR 1,200",
      type: 1,
    },
    {
      img: require("../Public/luxuryCamp/lc6.jpeg"),
      tag: "Luxury Camp",
      rating: "5.0 (240+)",
      title: "Luxury, comfort, adventure awaits | Luxury Camp",
      price: "INR 1,200",
      strikePrice: "INR 1,400",
      type: 2,
    },
    {
      img: require("../Public/ACCottages/ac2.jpeg"),
      tag: "AC Cottages",
      rating: "4.9 (170+)",
      title: "AC Comfort Cottages: Your Cool Retreat | AC Cottages",
      price: "INR 2,000",
      strikePrice: "INR 2,200",
      type: 3,
    },
  ]);

  const [Bungee_packages, setBungee_packages] = useState([
    {
      img: require("../Public/bj55/bj55_1.png"),
      tag: "55 Meters",
      rating: "4.8(120+)",
      title: "Rishikesh Tower Bungee Thrills | 55 Meters Bungee jump",
      price: "INR 3,000",
      strikePrice: "INR 3,200",
      type: 1,
    },
    {
      img: require("../Public/bj83/bj83_4.jpeg"),
      tag: "83 Meters",
      rating: "5.0(100+)",
      title: "Thrill at 83-Meter Bungee Heights | 83 Meters Bungee jump",
      price: "INR 3,700",
      strikePrice: "INR 3,900",

      type: 2,
    },
    {
      img: require("../Public/bj109/bj109_1.jpeg"),
      tag: "109 Meters",
      rating: "4.9(90+)",
      title: "Ultimate 109-Meter Bungee Rush | 109 Meters Bungee jump",
      price: "INR 4,000",
      strikePrice: "INR 4,200",

      type: 3,
    },
    {
      img: require("../Public/bj117/bj117_3.jpeg"),
      tag: "117 Meters",
      rating: "4.8(120+)",
      title: "India's highest tower bungee | 117 Meters Bungee jump",
      price: "INR 4,600",
      strikePrice: "INR 4,800",

      type: 4,
    },
  ]);

  const [form_open, setForm_open] = useState(false);

  const containerRef1 = useRef(null);
  const [isDragging1, setIsDragging1] = useState(false);
  const [dragStartX1, setDragStartX1] = useState(0);
  const [scrollStartX1, setScrollStartX1] = useState(0);

  const containerRef2 = useRef(null);
  const [isDragging2, setIsDragging2] = useState(false);
  const [dragStartX2, setDragStartX2] = useState(0);
  const [scrollStartX2, setScrollStartX2] = useState(0);

  const containerRef3 = useRef(null);
  const [isDragging3, setIsDragging3] = useState(false);
  const [dragStartX3, setDragStartX3] = useState(0);
  const [scrollStartX3, setScrollStartX3] = useState(0);

  const handleMouseDown1 = (e) => {
    setIsDragging1(true);
    setDragStartX1(e.clientX);
    setScrollStartX1(containerRef1.current.scrollLeft);
  };

  const handleMouseMove1 = (e) => {
    if (!isDragging1) return;
    const dx = e.clientX - dragStartX1;
    containerRef1.current.scrollLeft = scrollStartX1 - dx;
  };

  const handleMouseUp1 = () => {
    setIsDragging1(false);
  };

  const handleMouseDown2 = (e) => {
    setIsDragging2(true);
    setDragStartX2(e.clientX);
    setScrollStartX2(containerRef2.current.scrollLeft);
  };

  const handleMouseMove2 = (e) => {
    if (!isDragging2) return;
    const dx = e.clientX - dragStartX2;
    containerRef2.current.scrollLeft = scrollStartX2 - dx;
  };

  const handleMouseUp2 = () => {
    setIsDragging2(false);
  };
  const handleMouseDown3 = (e) => {
    setIsDragging3(true);
    setDragStartX3(e.clientX);
    setScrollStartX3(containerRef3.current.scrollLeft);
  };

  const handleMouseMove3 = (e) => {
    if (!isDragging3) return;
    const dx = e.clientX - dragStartX3;
    containerRef3.current.scrollLeft = scrollStartX3 - dx;
  };

  const handleMouseUp3 = () => {
    setIsDragging3(false);
  };

  const [destinations, setDestinations] = useState([]);
  const { data } = useContext(DataContext);
  // useEffect(() => {
  //   axios.get(data.api + "/destinations/get-destinations").then((data) => {
  //     setDestinations(data.data);
  //     console.log(data.data);
  //   });
  // }, []);

  // here i am setting the rafting things
  const [current_type_rafting, setCurrect_type_rafting] = useState(1);
  const [current_type_bungee, setCurrect_type_bungee] = useState(1);
  const [current_type_camping, setCurrect_type_camping] = useState(1);
  const [adventure, setAdventure] = useState(2);
  const [form_name, setForm_name] = useState("");
  const [fomr_contact, setForm_contact] = useState("");
  const [form_email, setForm_email] = useState("");
  const [select_date, setSelected_date] = useState("");
  const [rafting_slots, setRafting_slots] = useState([]);
  const [bungeeSlots, setBungeeSlots] = useState([]);
  const [selected_rafting_slot, setSelectedRafting_slots] = useState(undefined);
  const [selected_bungee_slot, setselectedBungeeSlot] = useState(undefined);
  const [head_count, setHead_count] = useState(2);
  const [checkin, setChecin] = useState("");
  const [checkout, setcheckout] = useState("");
  const [rafting_prices, setRaftingprice] = useState({
    1: 600,
    2: 600,
    3: 600,
    4: 1000,
    5: 1500,
    6: 2500,
  });
  const [camping_prices, setCamping_prices] = useState({
    1: 1000,
    2: 1200,
    3: 1800,
  });
  const [bungee_prices, setBungee_prices] = useState({
    1: 3000,
    2: 3700,
    3: 4000,
    4: 4600,
  });
  const [rapids, setRapids] = useState({
    1: 6,
    2: 6,
    3: 4,
    4: 8,
    5: 14,
    6: "20+",
  });
  const [km, setKm] = useState({
    1: "09",
    2: "12",
    3: "12",
    4: "16",
    5: "26",
    6: "36",
  });

  const [height, setHeight] = useState({
    1: "55",
    2: "83",
    3: "107",
    4: "109",
  });

  const [weight, setWeight] = useState({
    1: {
      min: 40,
      max: 180,
    },
    2: {
      min: 40,
      max: 110,
    },
    3: {
      min: 50,
      max: 110,
    },
    4: {
      min: 50,
      max: 110,
    },
  });

  const [age, setAge] = useState({
    1: {
      min: 12,
      max: 45,
    },
    2: {
      min: 12,
      max: 45,
    },
    3: {
      min: 15,
      max: 45,
    },
    4: {
      min: 15,
      max: 45,
    },
  });

  const [se, setSe] = useState({
    1: {
      start: "Bhrampuri",
      end: "Nim beach",
    },
    2: {
      start: "Marine Drive",
      end: "Shivpuri",
    },
    3: {
      start: "Club House",
      end: "Nim beach",
    },
    4: {
      start: "Shivpuri",
      end: "Nim beach",
    },
    5: {
      start: "Marine Drive",
      end: "Nim beach",
    },
    6: {
      start: "Kaudiyala",
      end: "Nim beach",
    },
  });

  const getSlotsDesktop = () => {
    if (adventure == 1) {
      if (select_date == "") {
      } else {
        axios
          .post("https://api.thrillthirsty.com/activities/rafting/get-slots", {
            date: select_date,
            km:
              current_type_rafting == 1
                ? "09"
                : current_type_rafting == 2 || current_type_rafting == 3
                ? "12"
                : current_type_rafting == 4
                ? "16"
                : current_type_rafting == 5
                ? "26"
                : "36",
          })
          .then((res) => {
            setRafting_slots(res.data);
          });
      }
    } else if (adventure == 3) {
      if (select_date == "") {
      } else {
        axios
          .post("https://api.thrillthirsty.com/activities/bungee/get-slots", {
            date: select_date,
            km:
              current_type_bungee == 1
                ? "55"
                : current_type_bungee == 2
                ? "83"
                : current_type_bungee == 3
                ? "109"
                : "117",
          })
          .then((res) => {
            setBungeeSlots(res.data);
          });
      }
    }
  };

  useEffect(() => {
    if (select_date != "") {
      getSlotsDesktop();
    }
  }, [select_date, current_type_bungee, current_type_rafting]);

  const f_r_key = useRef(null);
  const f_r_contact = useRef(null);
  const f_r_email = useRef(null);
  const f_r_txnid = useRef(null);
  const f_r_amount = useRef(null);
  const f_r_p_info = useRef(null);
  const f_r_first_name = useRef(null);
  const f_r_hash = useRef(null);
  const f_r_form = useRef(null);
  const [open_ps, setOpen_ps] = useState(false);

  const rulesRafting = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      head_count == 0 ||
      head_count == "" ||
      head_count == undefined ||
      head_count == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (
      selected_rafting_slot == "" ||
      selected_rafting_slot == undefined ||
      selected_rafting_slot == null
    ) {
      alert("Please choose a slot");
      allgood = false;
    } else if (
      select_date == "" ||
      select_date == undefined ||
      select_date == null
    ) {
      alert("please choose a date");
      allgood = false;
    } else if (
      current_type_rafting == "" ||
      current_type_rafting == undefined ||
      current_type_rafting == null ||
      current_type_rafting > 6
    ) {
      alert("Invalid type of rafting");
      allgood = false;
    } else if (
      fomr_contact == "" ||
      fomr_contact == undefined ||
      fomr_contact == null ||
      fomr_contact.length < 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (
      form_email == "" ||
      form_email == undefined ||
      form_email == null
    ) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(form_email)) {
      alert("Invalid email");
      allgood = false;
    } else if (form_name == "" || form_name == undefined || form_name == null) {
      alert("Invalid name");
      allgood = false;
    } else if (head_count < 6 && current_type_rafting == 6) {
      alert(
        "36 km rafting will only be booked if their are more than 5 people"
      );
      allgood = false;
    }

    return allgood;
  };

  const rulesCamping = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      head_count == 0 ||
      head_count == "" ||
      head_count == undefined ||
      head_count == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (checkin == "" || checkin == undefined || checkin == null) {
      alert("please choose a checin date");
      allgood = false;
    } else if (checkout == "" || checkout == undefined || checkout == null) {
      alert("please choose a checin date");
      allgood = false;
    } else if (
      current_type_camping == "" ||
      current_type_camping == undefined ||
      current_type_camping == null ||
      current_type_camping > 3
    ) {
      alert("Invalid type of rafting");
      allgood = false;
    } else if (
      fomr_contact == "" ||
      fomr_contact == undefined ||
      fomr_contact == null ||
      fomr_contact.length < 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (
      form_email == "" ||
      form_email == undefined ||
      form_email == null
    ) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(form_email)) {
      alert("Invalid email");
      allgood = false;
    } else if (form_name == "" || form_name == undefined || form_name == null) {
      alert("Invalid name");
      allgood = false;
    }

    return allgood;
  };

  const rulesBungee = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      head_count == 0 ||
      head_count == "" ||
      head_count == undefined ||
      head_count == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (
      selected_bungee_slot == "" ||
      selected_bungee_slot == undefined ||
      selected_bungee_slot == null
    ) {
      alert("Please choose a slot");
      allgood = false;
    } else if (
      select_date == "" ||
      select_date == undefined ||
      select_date == null
    ) {
      alert("please choose a date");
      allgood = false;
    } else if (
      current_type_bungee == "" ||
      current_type_bungee == undefined ||
      current_type_bungee == null ||
      current_type_bungee > 4
    ) {
      alert("Invalid type of rafting");
      allgood = false;
    } else if (
      fomr_contact == "" ||
      fomr_contact == undefined ||
      fomr_contact == null ||
      fomr_contact.length < 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (
      form_email == "" ||
      form_email == undefined ||
      form_email == null
    ) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(form_email)) {
      alert("Invalid email");
      allgood = false;
    } else if (form_name == "" || form_name == undefined || form_name == null) {
      alert("Invalid name");
      allgood = false;
    }

    return allgood;
  };

  // submit section starts here
  const submitAdvanceRafting = () => {
    if (rulesRafting()) {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_rafting == 1) {
              return "09 km";
            } else if (current_type_rafting == 2) {
              return "12 km-md";
            } else if (current_type_rafting == 3) {
              return "12 km-club";
            } else if (current_type_rafting == 4) {
              return "16 km";
            } else if (current_type_rafting == 5) {
              return "26 km";
            } else if (current_type_rafting == 6) {
              return "36 km";
            }
          })(),
          date: select_date,
          slot: selected_rafting_slot,
          headcount: head_count,
          payment_type: "advance",
          totalAmount:
            parseInt(rafting_prices[current_type_rafting]) *
            parseInt(head_count),
          // amount:
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100,
          amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "rafting",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = 1;
          // f_r_amount.current.value =
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100;
          f_r_contact.current.value = fomr_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = form_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "rafting";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitFullRafting = () => {
    if (rulesRafting()) {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_rafting == 1) {
              return "09 km";
            } else if (current_type_rafting == 2) {
              return "12 km-md";
            } else if (current_type_rafting == 3) {
              return "12 km-club";
            } else if (current_type_rafting == 4) {
              return "16 km";
            } else if (current_type_rafting == 5) {
              return "26 km";
            } else if (current_type_rafting == 6) {
              return "36 km";
            }
          })(),
          date: select_date,
          slot: selected_rafting_slot,
          headcount: head_count,
          payment_type: "full",
          totalAmount:
            parseInt(rafting_prices[current_type_rafting]) *
            parseInt(head_count),
          // amount:
          //   parseInt(price[current_type]) * parseInt(no_people),
          amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "rafting",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = 1;
          // f_r_amount.current.value =
          //   parseInt(price[current_type]) * parseInt(no_people);
          f_r_contact.current.value = fomr_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = form_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "rafting";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitAdvanceRaftingMobile = () => {
    if (rulesRafting()) {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_rafting == 1) {
              return "09 km";
            } else if (current_type_rafting == 2) {
              return "12 km-md";
            } else if (current_type_rafting == 3) {
              return "12 km-club";
            } else if (current_type_rafting == 4) {
              return "16 km";
            } else if (current_type_rafting == 5) {
              return "26 km";
            } else if (current_type_rafting == 6) {
              return "36 km";
            }
          })(),
          date: select_date,
          slot: rafting_slots[selected_rafting_slot],
          headcount: head_count,
          payment_type: "advance",
          totalAmount:
            parseInt(rafting_prices[current_type_rafting]) *
            parseInt(head_count),
          // amount:
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100,
          amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "rafting",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = 1;
          // f_r_amount.current.value =
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100;
          f_r_contact.current.value = fomr_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = form_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "rafting";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitFullRaftingMobile = () => {
    if (rulesRafting()) {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_rafting == 1) {
              return "09 km";
            } else if (current_type_rafting == 2) {
              return "12 km-md";
            } else if (current_type_rafting == 3) {
              return "12 km-club";
            } else if (current_type_rafting == 4) {
              return "16 km";
            } else if (current_type_rafting == 5) {
              return "26 km";
            } else if (current_type_rafting == 6) {
              return "36 km";
            }
          })(),
          date: select_date,
          slot: rafting_slots[selected_rafting_slot],
          headcount: head_count,
          payment_type: "full",
          totalAmount:
            parseInt(rafting_prices[current_type_rafting]) *
            parseInt(head_count),
          // amount:
          //   parseInt(price[current_type]) * parseInt(no_people),
          amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "rafting",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = 1;
          // f_r_amount.current.value =
          //   parseInt(price[current_type]) * parseInt(no_people);
          f_r_contact.current.value = fomr_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = form_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "rafting";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitAdvanceBungee = () => {
    if (rulesBungee()) {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_bungee == 1) {
              return "55 m";
            } else if (current_type_bungee == 2) {
              return "83 m";
            } else if (current_type_bungee == 3) {
              return "109 m";
            } else if (current_type_bungee == 4) {
              return "117 m";
            }
          })(),
          date: select_date,
          slot: selected_bungee_slot,
          headcount: head_count,
          payment_type: "advance",
          totalAmount:
            parseInt(bungee_prices[current_type_bungee]) * parseInt(head_count),
          // amount:
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100,
          amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "bungee",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = 1;
          // f_r_amount.current.value =
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100;
          f_r_contact.current.value = fomr_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = form_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "bungee";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitFullBungee = () => {
    if (rulesBungee()) {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_bungee == 1) {
              return "55 m";
            } else if (current_type_bungee == 2) {
              return "83 m";
            } else if (current_type_bungee == 3) {
              return "109 m";
            } else if (current_type_bungee == 4) {
              return "117 m";
            }
          })(),
          date: select_date,
          slot: selected_bungee_slot,
          headcount: head_count,
          payment_type: "full",
          totalAmount:
            parseInt(bungee_prices[current_type_bungee]) * parseInt(head_count),
          // amount:
          //   parseInt(price[current_type]) * parseInt(no_people),
          amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "bungee",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = 1;
          // f_r_amount.current.value =
          //   parseInt(price[current_type]) * parseInt(no_people);
          f_r_contact.current.value = fomr_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = form_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "bungee";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitAdvanceBungeeMobile = () => {
    if (rulesBungee()) {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_bungee == 1) {
              return "55 m";
            } else if (current_type_bungee == 2) {
              return "83 m";
            } else if (current_type_bungee == 3) {
              return "109 m";
            } else if (current_type_bungee == 4) {
              return "117 m";
            }
          })(),
          date: select_date,
          slot: bungeeSlots[selected_bungee_slot],
          headcount: head_count,
          payment_type: "advance",
          totalAmount:
            parseInt(bungee_prices[current_type_bungee]) * parseInt(head_count),
          // amount:
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100,
          amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "bungee",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = 1;
          // f_r_amount.current.value =
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100;
          f_r_contact.current.value = fomr_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = form_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "bungee";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitFullBungeeMobile = () => {
    if (rulesBungee()) {
      axios
        .post("https://api.thrillthirsty.com/activities/bungee/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_bungee == 1) {
              return "55 m";
            } else if (current_type_bungee == 2) {
              return "83 m";
            } else if (current_type_bungee == 3) {
              return "109 m";
            } else if (current_type_bungee == 4) {
              return "117 m";
            }
          })(),
          date: select_date,
          slot: bungeeSlots[selected_bungee_slot],
          headcount: head_count,
          payment_type: "full",
          totalAmount:
            parseInt(bungee_prices[current_type_bungee]) * parseInt(head_count),
          // amount:
          //   parseInt(price[current_type]) * parseInt(no_people),
          amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "bungee",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = 1;
          // f_r_amount.current.value =
          //   parseInt(price[current_type]) * parseInt(no_people);
          f_r_contact.current.value = fomr_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = form_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "bungee";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitCamping = () => {
    if (rulesCamping()) {
      axios
        .post("https://api.thrillthirsty.com/activities/camping/enquery", {
          name: form_name,
          email: form_email,
          contact: fomr_contact,
          type: (() => {
            if (current_type_camping == 1) {
              return "Beach Camp";
            } else if (current_type_camping == 2) {
              return "Luxury Camp";
            } else if (current_type_camping == 3) {
              return "AC Cottages";
            }
          })(),
          checkIn: checkin,
          checkOut: checkout,
          slot: selected_bungee_slot,
          headcount: head_count,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "camping",
            Bookingvalue: 100,
          });
          navigate("/activities/camping/thank-you");
        });
    }
  };

  const rafting_ref = useRef(null);
  const camping_ref = useRef(null);
  const bungee_ref = useRef(null);

  return (
    <>
      <CallWhattsaap />
      <div className="home-desktop">
        <Helmet>
          <title>
            Thrill Thirsty: Book adventure activities in Rishikesh online.
          </title>
          <meta
            name="description"
            content="Thrill thirsty provides you all the adventure activities in rishikesh with safety and top notch thrilling experience"
          />
          {/* Other meta tags */}
        </Helmet>
        {/* {open_ps ? (
          <Photo_section_desktop
            setDesktop_photo={setOpen_ps}
            photos={[
              newrafting1,
              newrafting2,
              newcamping1,
              newcamping2,
              bungee1,
              bungee2,
              newrafting3,
              newcamping3,
              bungee3,
              newrafting4,
              newcamping4,
              bungee4,
              newrafting5,
              bungee5,
              newrafting6,
              bungee6,
              bungee7,
              bungee8,
            ]}
          />
        ) : null} */}
        <Header active={"explore"} />
        <div className="home-desktop-section-1">
          <div className="hds1-content-section">
            <div className="hds1-content-inner">
              <div className="hds1-content-section-title">
                <span className="mogra color-orange">Thrill </span>
                <span className="mogra color-blue"> Thirsty</span>
              </div>
              <div className="hds1-content-section-tagline">
                Get an Awesome Experience with us...
              </div>
              <div className="hds1-content-content">
                one-stop solution for your Rishikesh adventure trip(river
                rafting, camping, bungee). plan any adventure with us at the
                best price for an unforgettable experience.
              </div>
              <div className="hds1-btn-section">
                <button
                  className="hds1-btn-btn"
                  onClick={() => {
                    // setAdventure(1);
                    // setCurrect_type_rafting(1);
                    // setForm_open(true);
                    navigateToRafting();
                  }}
                >
                  Experience the thrill
                </button>
                <Link to={"/gallery"} className="hds1-gallery-div">
                  <div className="hds1-svg-div">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="hds1-play-svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          opacity="0.5"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          className="fill-blue"
                        ></path>{" "}
                        <path
                          d="M15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868V9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059Z"
                          className="fill-orange"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                  <div className="visit-gallery-text">Visit our gallery</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="hds1-destination-section">
            <div className="hds1-ds-grid">
              <div
                onClick={() => {
                  let t =
                    containerRef1.current.getBoundingClientRect().top +
                    window.pageYOffset;
                  let scrollToPosition = t - 170;
                  window.scrollTo({
                    top: scrollToPosition,
                    behavior: "smooth",
                  });
                }}
                className="hds1-ds-grid-1 hds1-ds-grid-indi"
              >
                <div className="hds1-ds-g1-dark">Rafting</div>
                <img
                  src={r2}
                  alt=""
                  className="hds1-ds-grid-indi-img"
                  loading="lazy"
                />
              </div>
              <div
                className="hds1-ds-grid-2 hds1-ds-grid-indi"
                onClick={() => {
                  let t =
                    containerRef2.current.getBoundingClientRect().top +
                    window.pageYOffset;
                  let scrollToPosition = t - 170;
                  window.scrollTo({
                    top: scrollToPosition,
                    behavior: "smooth",
                  });
                }}
              >
                <div className="hds1-ds-g1-dark">Luxury camps</div>
                <img
                  src={require("../Public/luxuryCamp/lc6.jpeg")}
                  alt=""
                  className="hds1-ds-grid-indi-img"
                  loading="lazy"
                />
              </div>
              <div
                className="hds1-ds-grid-3 hds1-ds-grid-indi"
                onClick={() => {
                  let t =
                    containerRef2.current.getBoundingClientRect().top +
                    window.pageYOffset;
                  let scrollToPosition = t - 170;
                  window.scrollTo({
                    top: scrollToPosition,
                    behavior: "smooth",
                  });
                }}
              >
                <div className="hds1-ds-g1-dark">Beach camps</div>
                <img
                  src={newcamping2}
                  alt=""
                  className="hds1-ds-grid-indi-img"
                  loading="lazy"
                />
              </div>
              <div
                className="hds1-ds-grid-4 hds1-ds-grid-indi"
                onClick={() => {
                  let t =
                    containerRef3.current.getBoundingClientRect().top +
                    window.pageYOffset;
                  let scrollToPosition = t - 170;
                  window.scrollTo({
                    top: scrollToPosition,
                    behavior: "smooth",
                  });
                }}
              >
                <div className="hds1-ds-g1-dark">India's heighest Bungee</div>
                <img
                  src={require("../Public/bj117/bj117_4.jpeg")}
                  alt=""
                  className="hds1-ds-grid-indi-img"
                  loading="lazy"
                />
              </div>
              <div
                className="hds1-ds-grid-5 hds1-ds-grid-indi"
                onClick={() => {
                  let t =
                    containerRef3.current.getBoundingClientRect().top +
                    window.pageYOffset;
                  let scrollToPosition = t - 170;
                  window.scrollTo({
                    top: scrollToPosition,
                    behavior: "smooth",
                  });
                }}
              >
                <div className="hds1-ds-g1-dark">Splash Bungee</div>
                <img
                  src={require("../Public/bj109/bj109_4.jpeg")}
                  alt=""
                  className="hds1-ds-grid-indi-img"
                  loading="lazy"
                />
              </div>
              <div
                className="hds1-ds-grid-6 hds1-ds-grid-indi"
                onClick={() => {
                  let t =
                    containerRef2.current.getBoundingClientRect().top +
                    window.pageYOffset;
                  let scrollToPosition = t - 170;
                  window.scrollTo({
                    top: scrollToPosition,
                    behavior: "smooth",
                  });
                }}
              >
                <div className="hds1-ds-g1-dark">AC Cottages</div>
                <img
                  src={require("../Public/ACCottages/ac2.jpeg")}
                  alt=""
                  className="hds1-ds-grid-indi-img"
                  loading="lazy"
                />
              </div>
            </div>
            <div>
              {/* <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="hds1-ds-arrow"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                    className="fill-orange"
                  ></path>{" "}
                </g>
              </svg> */}
            </div>
          </div>
        </div>

        <div className="home-desktop-section-2-outer">
          <div className="home-desktop-section-2">
            <div className="hds2-content-section">
              <div className="hds2-perfect-text">
                Find the <br />
                <span className="color-orange"> Perfect Thrill </span>
              </div>
              <div className="hds2-cs-line">
                <div className="hds-cs-line-inner"></div>
              </div>
              {/* <button>Explore all</button> */}
            </div>
            <IndiPerfectThrill
              img={require("../Public/rafting/r8.jpg")}
              title={"Rafting"}
              link={"/rafting-in-rishikesh"}
            />
            <IndiPerfectThrill
              img={require("../Public/beachCamp/beachcamp7.jpeg")}
              title={"Camping"}
              link={"/camping-in-rishikesh"}
            />
            <IndiPerfectThrill
              img={require("../Public/bj55/bj55_1.png")}
              title={"Bungee"}
              link={"/bungee-jumping-in-rishikesh"}
            />
            <div></div>
          </div>
          {/* <div className="hds2-pt-scroll-pointer">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hds2-pt-sp-svg"
              onClick={scrollActivities}
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                  className="fill-orange"
                ></path>{" "}
              </g>
            </svg>
          </div> */}
        </div>

        <TitleDesktop
          title={"River Rafting in Rishikesh"}
          description={"Starting at just ₹599/person"}
          more={false}
        />
        <div
          className="whole-desktop-package-outer"
          ref={containerRef1}
          onMouseDown={handleMouseDown1}
          onMouseMove={handleMouseMove1}
          onMouseUp={handleMouseUp1}
          onMouseLeave={handleMouseUp1}
        >
          {home_packages.map((item, index) => {
            return (
              <PackageDesktop
                img={item.img}
                price={item.price}
                tag={item.tag}
                rating={item.rating}
                title={item.title}
                strikePrice={item.strikePrice}
                set={() => {
                  // setAdventure(1);
                  // setCurrect_type_rafting(item.type);
                  // setForm_open(true);
                  navigateToRafting();
                }}
              />
            );
          })}
          <div></div>
        </div>
        <TitleDesktop
          title={"Camping in Rishikesh"}
          description={"Starting at just ₹999/person"}
          more={false}
        />
        <div
          className="whole-desktop-package-outer"
          ref={containerRef2}
          onMouseDown={handleMouseDown2}
          onMouseMove={handleMouseMove2}
          onMouseUp={handleMouseUp2}
          onMouseLeave={handleMouseUp2}
        >
          {camping_packages.map((item, index) => {
            return (
              <PackageDesktop
                img={item.img}
                price={item.price}
                tag={item.tag}
                rating={item.rating}
                title={item.title}
                strikePrice={item.strikePrice}
                set={() => {
                  // setAdventure(2);
                  // setCurrect_type_camping(item.type);
                  // setForm_open(true);
                  navigateToCamping();
                }}
              />
            );
          })}
          <div></div>
        </div>
        <TitleDesktop
          title={"Bungee jumping in Rishikesh"}
          description={"Starting at just ₹2,999/person"}
          more={false}
        />
        <div
          className="whole-desktop-package-outer"
          ref={containerRef3}
          onMouseDown={handleMouseDown3}
          onMouseMove={handleMouseMove3}
          onMouseUp={handleMouseUp3}
          onMouseLeave={handleMouseUp3}
        >
          {Bungee_packages.map((item, index) => {
            return (
              <PackageDesktop
                img={item.img}
                price={item.price}
                tag={item.tag}
                rating={item.rating}
                title={item.title}
                strikePrice={item.strikePrice}
                set={() => {
                  // setAdventure(3);
                  // setCurrect_type_bungee(item.type);
                  // setForm_open(true);
                  navigateToBungee();
                }}
              />
            );
          })}
          <div></div>
        </div>

        {/* <TitleDesktop
          title={"Best Picks for you"}
          description={"These are some of our bestest offers only for you"}
          more={true}
        /> */}

        {/* <div className="home-desktop-section-2-outer">
          <div className="home-desktop-section-2" ref={scrollactivity}>
            <div className="hds2-content-section">
              <div className="hds2-perfect-text">
                Find the <br />
                <span className="color-orange"> Best Services </span>
              </div>
              <div className="hds2-cs-line">
                <div className="hds-cs-line-inner"></div>
              </div>
            </div>
            <IndiPerfectThrill img={Manali} title={"Cab"} />
            <IndiPerfectThrill img={Haridwar} title={"Restaurant"} />
            <IndiPerfectThrill img={Harsil} title={"Rental"} />
            <IndiPerfectThrill img={destination} title={"Hotel"} />
            <IndiPerfectThrill img={Dehradun} title={"Skiing"} />
            <IndiPerfectThrill img={Nainital} title={"Paragliding"} />
            <div></div>
          </div>
          <div className="hds2-pt-scroll-pointer">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hds2-pt-sp-svg"
              onClick={scrollActivities}
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                  className="fill-orange"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div> */}

        {form_open ? (
          <div className="desktop-home-form">
            <div className="dhf-1"></div>
            <div className="dhf-2">
              <div className="dhf-2-form">
                <div
                  className="dhf-2-cut"
                  onClick={() => {
                    setForm_open(false);
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="dhf-2-vut-svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M16 8L8 16M8.00001 8L16 16"
                        className="stroke-blue"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </div>
                <div className="dhf-2-form-header">
                  <div className="dhf2fh-title">
                    ₹
                    {adventure == 1
                      ? rafting_prices[current_type_rafting]
                      : adventure == 2
                      ? camping_prices[current_type_camping]
                      : bungee_prices[current_type_bungee]}
                    <span className="color-black">/person </span>
                  </div>
                  {adventure == 1 ? (
                    <>
                      <div className="dhf2fh-tag">
                        {se[current_type_rafting].start} to{" "}
                        {se[current_type_rafting].end}
                      </div>
                      <div className="dhf2fh-tag">
                        {km[current_type_rafting]} km ·{" "}
                        {rapids[current_type_rafting]} rapids
                      </div>
                    </>
                  ) : adventure == 2 ? (
                    <>
                      <div className="dhf2fh-tag">2 Days & 1 Night</div>
                      <div className="dhf2fh-tag">
                        Accomodation, activikties, meals
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="dhf2fh-tag">
                        Weight: {weight[current_type_bungee].min}kg to{" "}
                        {weight[current_type_bungee].max}kg
                      </div>
                      <div className="dhf2fh-tag">
                        Age: {age[current_type_bungee].min} ·{" "}
                        {age[current_type_bungee].max}
                      </div>
                    </>
                  )}
                </div>
                <div className="dhf2f-bottom-form">
                  <div className="dhf-bf-header">
                    <div className="dhf-bh-h-title">Fill your details</div>
                    <div className="dhf-bh-h-des">
                      Need instant rafting? Dial or click{" "}
                      <span className="orange"> +917302040324</span>!
                    </div>
                  </div>
                  <div className="dhf-bh-h-input-section dhf-bh-h-first">
                    <div className="dhf-bh-i-s-title">Your Name</div>
                    <div className="dhf-bh-i-s-input">
                      <input
                        type="text"
                        className="dhf-bh-isi-input"
                        placeholder="Enter your name"
                        value={form_name}
                        onInput={(e) => {
                          setForm_name(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="dhf-bh-h-input-section">
                    <div className="dhf-bh-i-s-title">Your Contact</div>
                    <div className="dhf-bh-i-s-input">
                      <input
                        type="number"
                        className="dhf-bh-isi-input"
                        placeholder="Enter your contact number"
                        value={fomr_contact}
                        onInput={(e) => {
                          setForm_contact(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="dhf-bh-h-input-section">
                    <div className="dhf-bh-i-s-title">Your Email</div>
                    <div className="dhf-bh-i-s-input">
                      <input
                        type="email"
                        className="dhf-bh-isi-input"
                        placeholder="Enter your email address"
                        value={form_email}
                        onInput={(e) => {
                          setForm_email(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="dhf-bh-h-input-section">
                    {adventure == 1 ? (
                      <>
                        <div className="dhf-bh-i-s-title">
                          Distance of rafting
                        </div>
                        <div className="dhf-bh-i-s-input-scroll">
                          <div
                            className={
                              current_type_rafting == 1
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_rafting(1);
                            }}
                          >
                            9 km
                          </div>
                          <div
                            className={
                              current_type_rafting == 2
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_rafting(2);
                            }}
                          >
                            12 km
                          </div>
                          <div
                            className={
                              current_type_rafting == 3
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_rafting(3);
                            }}
                          >
                            12 km
                          </div>
                          <div
                            className={
                              current_type_rafting == 4
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_rafting(4);
                            }}
                          >
                            16 km
                          </div>
                          <div
                            className={
                              current_type_rafting == 5
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_rafting(5);
                            }}
                          >
                            26 km
                          </div>
                          <div
                            className={
                              current_type_rafting == 6
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_rafting(6);
                            }}
                          >
                            36 km
                          </div>
                          <div></div>
                        </div>
                      </>
                    ) : adventure == 2 ? (
                      <>
                        <div className="dhf-bh-i-s-title">Type of camp</div>
                        <div className="dhf-bh-i-s-input-scroll">
                          <div
                            className={
                              current_type_camping == 1
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_camping(1);
                            }}
                          >
                            Beach Camp
                          </div>
                          <div
                            onClick={() => {
                              setCurrect_type_camping(2);
                            }}
                            className={
                              current_type_camping == 2
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                          >
                            Luxury Camp
                          </div>
                          <div
                            onClick={() => {
                              setCurrect_type_camping(3);
                            }}
                            className={
                              current_type_camping == 3
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                          >
                            AC cottages
                          </div>
                          <div></div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="dhf-bh-i-s-title">Height of bungee</div>
                        <div className="dhf-bh-i-s-input-scroll">
                          <div
                            className={
                              current_type_bungee == 1
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_bungee(1);
                            }}
                          >
                            55 m
                          </div>
                          <div
                            className={
                              current_type_bungee == 2
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_bungee(2);
                            }}
                          >
                            83 m
                          </div>
                          <div
                            className={
                              current_type_bungee == 3
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_bungee(3);
                            }}
                          >
                            109 m
                          </div>
                          <div
                            className={
                              current_type_bungee == 4
                                ? "dhf-bh-is-indi-active"
                                : "dhf-bh-is-indi"
                            }
                            onClick={() => {
                              setCurrect_type_bungee(4);
                            }}
                          >
                            117 m
                          </div>
                          <div></div>
                        </div>
                      </>
                    )}
                  </div>

                  {adventure == 1 || adventure == 3 ? (
                    <div className="dhf-bh-h-input-section">
                      <div className="dhf-bh-i-s-title">Select the date</div>
                      <div className="dhf-bh-i-s-input">
                        <input
                          type="date"
                          className="dhf-bh-isi-input dbii-date"
                          value={select_date}
                          onInput={(e) => {
                            setSelected_date(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {adventure == 2 ? (
                    <>
                      <div className="dhf-bh-h-input-section">
                        <div className="dhf-bh-i-s-title">Check in date</div>
                        <div className="dhf-bh-i-s-input">
                          <input
                            type="date"
                            className="dhf-bh-isi-input dbii-date"
                            value={checkin}
                            onInput={(e) => {
                              setChecin(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="dhf-bh-h-input-section">
                        <div className="dhf-bh-i-s-title">Check out date</div>
                        <div className="dhf-bh-i-s-input">
                          <input
                            type="date"
                            className="dhf-bh-isi-input dbii-date"
                            value={checkout}
                            onInput={(e) => {
                              setcheckout(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {(adventure == 1 || adventure == 3) && select_date != "" ? (
                    <div className="dhf-bh-h-input-section">
                      <div className="dhf-bh-i-s-title">Select slot</div>
                      <div className="dhf-bh-i-s-input-scroll">
                        {adventure == 1
                          ? rafting_slots.map((item, index) => {
                              return (
                                <div
                                  onClick={() => {
                                    setSelectedRafting_slots(item);
                                  }}
                                  className={
                                    selected_rafting_slot == item
                                      ? "dhf-bh-is-indi-active"
                                      : "dhf-bh-is-indi"
                                  }
                                >
                                  {item}
                                </div>
                              );
                            })
                          : adventure == 3
                          ? bungeeSlots.map((item, index) => {
                              return (
                                <div
                                  onClick={() => {
                                    setselectedBungeeSlot(item);
                                  }}
                                  className={
                                    selected_bungee_slot == item
                                      ? "dhf-bh-is-indi-active"
                                      : "dhf-bh-is-indi"
                                  }
                                >
                                  {item}
                                </div>
                              );
                            })
                          : null}
                        <div></div>
                      </div>
                    </div>
                  ) : null}

                  <div className="dhf-bh-h-input-section">
                    <div className="dhf-bh-i-s-title">Number of people</div>
                    <div className="dhf-bh-i-s-input-scroll">
                      <input
                        className="dhf-bh-is-indi-input"
                        type="number"
                        placeholder="Enter the number of persons"
                        value={head_count}
                        onInput={(e) => {
                          setHead_count(e.target.value);
                        }}
                      ></input>
                      <div
                        onClick={() => {
                          setHead_count(1);
                        }}
                        className={
                          head_count >= 1
                            ? "dhf-bh-is-indi-i-active"
                            : "dhf-bh-is-indi-i"
                        }
                      >
                        1
                      </div>
                      <div
                        onClick={() => {
                          setHead_count(2);
                        }}
                        className={
                          head_count >= 2
                            ? "dhf-bh-is-indi-i-active"
                            : "dhf-bh-is-indi-i"
                        }
                      >
                        2
                      </div>
                      <div
                        onClick={() => {
                          setHead_count(3);
                        }}
                        className={
                          head_count >= 3
                            ? "dhf-bh-is-indi-i-active"
                            : "dhf-bh-is-indi-i"
                        }
                      >
                        3
                      </div>
                      <div
                        onClick={() => {
                          setHead_count(4);
                        }}
                        className={
                          head_count >= 4
                            ? "dhf-bh-is-indi-i-active"
                            : "dhf-bh-is-indi-i"
                        }
                      >
                        4
                      </div>
                      <div
                        onClick={() => {
                          setHead_count(5);
                        }}
                        className={
                          head_count >= 5
                            ? "dhf-bh-is-indi-i-active"
                            : "dhf-bh-is-indi-i"
                        }
                      >
                        5
                      </div>
                      <div
                        onClick={() => {
                          setHead_count(6);
                        }}
                        className={
                          head_count >= 6
                            ? "dhf-bh-is-indi-i-active"
                            : "dhf-bh-is-indi-i"
                        }
                      >
                        6
                      </div>
                      <div
                        onClick={() => {
                          setHead_count(7);
                        }}
                        className={
                          head_count >= 7
                            ? "dhf-bh-is-indi-i-active"
                            : "dhf-bh-is-indi-i"
                        }
                      >
                        7
                      </div>
                      <div
                        onClick={() => {
                          setHead_count(8);
                        }}
                        className={
                          head_count >= 8
                            ? "dhf-bh-is-indi-i-active"
                            : "dhf-bh-is-indi-i"
                        }
                      >
                        8
                      </div>
                      <div></div>
                    </div>
                  </div>

                  {adventure == 1 ? (
                    <div className="dhf-bh-h-is-blue-line">
                      {km[current_type_rafting]} km rafting ( ₹{" "}
                      {parseInt(
                        rafting_prices[current_type_rafting]
                      ).toLocaleString()}{" "}
                      ) x {head_count} adults = ₹{" "}
                      {parseInt(
                        rafting_prices[current_type_rafting] * head_count
                      ).toLocaleString()}
                    </div>
                  ) : adventure == 2 ? (
                    <div className="dhf-bh-h-is-blue-line">
                      Once you book we will call you back
                    </div>
                  ) : (
                    <div className="dhf-bh-h-is-blue-line">
                      {height[current_type_rafting]} meter Bungee ( ₹{" "}
                      {parseInt(
                        bungee_prices[current_type_bungee]
                      ).toLocaleString()}{" "}
                      ) x {head_count} adults = ₹{" "}
                      {parseInt(
                        bungee_prices[current_type_bungee] * head_count
                      ).toLocaleString()}
                    </div>
                  )}
                  {/* <div className="dhf-bh-h-is-blue-line">
                    {km[current_type_rafting]} km rafting ( ₹{" "}
                    {parseInt(
                      rafting_prices[current_type_rafting]
                    ).toLocaleString()}{" "}
                    ) x {head_count} adults = ₹{" "}
                    {parseInt(
                      rafting_prices[current_type_rafting] * head_count
                    ).toLocaleString()}
                  </div> */}

                  {adventure == 1 ? (
                    <div className="dhf-bh-is-button-section">
                      <button
                        onClick={submitAdvanceRafting}
                        className="dhf-bh-is-btn"
                      >
                        Pay INR{" "}
                        {(
                          (parseInt(
                            rafting_prices[current_type_rafting] * head_count
                          ) *
                            20) /
                          100
                        ).toLocaleString()}{" "}
                        (As Advance)
                      </button>
                      <div className="flpd-or-text">
                        <div className="flpd-or-1"></div>
                        <div className="flpd-or-or">or</div>
                        <div className="flpd-or-2"></div>
                      </div>
                      <button
                        className="dhf-bh-is-btn"
                        onClick={submitFullRafting}
                      >
                        Pay INR{" "}
                        {parseInt(
                          rafting_prices[current_type_rafting] * head_count
                        ).toLocaleString()}{" "}
                        (Full)
                      </button>
                    </div>
                  ) : adventure == 2 ? (
                    <div className="dhf-bh-is-button-section">
                      <button onClick={submitCamping} className="dhf-bh-is-btn">
                        Book Now
                      </button>
                    </div>
                  ) : (
                    <div className="dhf-bh-is-button-section">
                      <button
                        onClick={submitAdvanceBungee}
                        className="dhf-bh-is-btn"
                      >
                        Pay INR{" "}
                        {(
                          (parseInt(
                            bungee_prices[current_type_bungee] * head_count
                          ) *
                            20) /
                          100
                        ).toLocaleString()}{" "}
                        (As Advance)
                      </button>
                      <div className="flpd-or-text">
                        <div className="flpd-or-1"></div>
                        <div className="flpd-or-or">or</div>
                        <div className="flpd-or-2"></div>
                      </div>
                      <button
                        className="dhf-bh-is-btn"
                        onClick={submitFullBungee}
                      >
                        Pay INR{" "}
                        {parseInt(
                          bungee_prices[current_type_bungee] * head_count
                        ).toLocaleString()}{" "}
                        (Full)
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div></div>
          </div>
        ) : null}
        <DesktopFooter />
        {/* <div className="home-desktop-space"></div> */}
      </div>

      <form
        action="https://secure.payu.in/_payment"
        // action="https://test.payu.in/_payment"
        ref={f_r_form}
        method="post"
        className="hidden-form-rafting"
      >
        <input type="hidden" ref={f_r_key} name="key" value="heRjXi" />
        <input
          type="hidden"
          ref={f_r_txnid}
          name="txnid"
          value="t6svtqtjRdl4ws1"
        />
        <input
          type="hidden"
          ref={f_r_p_info}
          name="productinfo"
          value="iPhone"
        />
        <input type="hidden" ref={f_r_amount} name="amount" value="10" />
        <input
          type="hidden"
          ref={f_r_email}
          name="email"
          value="test@gmail.com"
        />
        <input
          type="hidden"
          ref={f_r_first_name}
          name="firstname"
          value="Ashish"
        />
        <input
          type="hidden"
          name="surl"
          value={
            adventure == 1
              ? "https://api.thrillthirsty.com/pay/sucess"
              : adventure == 3
              ? "https://api.thrillthirsty.com/pay/sucess-bungee"
              : ""
          }
        />
        <input
          type="hidden"
          name="furl"
          value="https://api.thrillthirsty.com/fail"
        />
        <input
          type="hidden"
          name="phone"
          ref={f_r_contact}
          value="9988776655"
        />
        <input type="hidden" name="hash" ref={f_r_hash} value="" />
      </form>

      <div className="home-mobile">
        {form_open == true && adventure == 1 ? (
          <GlobalForm
            setForm={setForm_open}
            setMobile_date={setSelected_date}
            setMobile_no_of_people={setHead_count}
            setMobile_name={setForm_name}
            setMobile_email={setForm_email}
            setMobile_contact={setForm_contact}
            mobile_no_of_people={head_count}
            mobile_name={form_name}
            mobile_email={form_email}
            mobile_date={select_date}
            mobile_contact={fomr_contact}
            mobile_slot={rafting_slots}
            setCurrent_type={setCurrect_type_rafting}
            current_type={current_type_rafting}
            price={rafting_prices}
            km={km}
            choosen_slot={selected_rafting_slot}
            setChoosen_slot={setSelectedRafting_slots}
            submitMobileAdvance={submitAdvanceRaftingMobile}
            submitMobileFull={submitFullRaftingMobile}
          />
        ) : form_open == true && adventure == 2 ? (
          <GlobalFormCamping
            setForm={setForm_open}
            setMobile_date={setSelected_date}
            setMobile_no_of_people={setHead_count}
            setMobile_name={setForm_name}
            setMobile_email={setForm_email}
            setMobile_contact={setForm_contact}
            mobile_no_of_people={head_count}
            mobile_name={form_name}
            mobile_email={form_email}
            mobile_date={select_date}
            mobile_contact={fomr_contact}
            mobile_slot={bungeeSlots}
            setCurrent_type={setCurrect_type_camping}
            current_type={current_type_camping}
            price={camping_prices}
            // km={height}
            checkIn={checkin}
            checkOut={checkout}
            SetCheckOut={setcheckout}
            setCheckIn={setChecin}
            submitMobile={submitCamping}
          />
        ) : form_open == true && adventure == 3 ? (
          <GlobalFormBungee
            setForm={setForm_open}
            setMobile_date={setSelected_date}
            setMobile_no_of_people={setHead_count}
            setMobile_name={setForm_name}
            setMobile_email={setForm_email}
            setMobile_contact={setForm_contact}
            mobile_no_of_people={head_count}
            mobile_name={form_name}
            mobile_email={form_email}
            mobile_date={select_date}
            mobile_contact={fomr_contact}
            mobile_slot={bungeeSlots}
            setCurrent_type={setCurrect_type_bungee}
            current_type={current_type_bungee}
            price={bungee_prices}
            km={height}
            choosen_slot={selected_bungee_slot}
            setChoosen_slot={setselectedBungeeSlot}
            submitMobileAdvance={submitAdvanceBungeeMobile}
            submitMobileFull={submitFullBungeeMobile}
          />
        ) : null}
        <HeaderMobile />
        <Title
          title={"Explore our best activites"}
          description={"Here are some of the best activities for you"}
          more={false}
        />
        <div className="explore-activites">
          <FeatureSectionLarge
            img={require("../Public/rafting/r2.jpg")}
            title={"River Rafting"}
            description={"INR 599/person"}
            link={"rafting-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={campingnew}
            title={"Camping"}
            description={"INR 999/person"}
            link={"camping-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={bungeenew}
            title={"Bungee Jump"}
            description={"INR 2999/person"}
            link={"bungee-jumping-in-rishikesh"}
          />
          <FeatureSectionLarge
            img={destination}
            title={"Many More"}
            description={"comming soon"}
            link={undefined}
          />
          {/* <FeatureSectionSmall /> */}
          {/* <FeatureSectionLarge /> */}
        </div>
        <Title
          title={"Our best picks for you"}
          description={"Our curated selection tailored just for you"}
          more={false}
        />
        <div className="home-packages-div">
          <Package
            img={newrafting4}
            price={"INR 1000"}
            title={"River rafting from Shivpuri to Nim Beach"}
            tag={"16 km rafting"}
            rating={"5.0(120+)"}
            strikePrice={"INR 1,200"}
            set={() => {
              // setAdventure(1);
              // setCurrect_type_rafting(1);
              // setForm_open(true);
              navigateToRafting();
            }}
          />
          <Package
            img={newcamping2}
            price={"INR 1000"}
            title={"Ganga Beach Camp: Your Riverside Escape"}
            tag={"Beach camp"}
            strikePrice={"INR 1,200"}
            rating={"4.9(80+)"}
            set={() => {
              // setAdventure(2);
              // setCurrect_type_camping(1);
              // setForm_open(true);
              navigateToCamping();
            }}
          />
          <Package
            img={bungee6}
            price={"INR 3000"}
            title={"Rishikesh Tower Bungee Thrills"}
            tag={"55 meter bungee"}
            rating={"5.0(180+)"}
            strikePrice={"INR 3,200"}
            set={() => {
              // setAdventure(3);
              // setCurrect_type_bungee(1);
              // setForm_open(true);
              navigateToBungee();
            }}
          />
          <Package
            img={newcamping3}
            price={"INR 1500"}
            title={"Luxury, comfort, adventure awaits"}
            tag={"Luxury camps"}
            strikePrice={"INR 1,700"}
            rating={"4.8(200+)"}
            set={() => {
              // setAdventure(2);
              // setCurrect_type_camping(2);
              // setForm_open(true);
              navigateToCamping();
            }}
          />
          <Package
            img={bungeenew}
            price={"INR 4600"}
            title={"India's Highest Tower Bungee"}
            tag={"117 meters bungee"}
            rating={"5.0(200+)"}
            strikePrice={"INR 4,800"}
            set={() => {
              // setAdventure(3);
              // setCurrect_type_bungee(4);
              // setForm_open(true);
              navigateToBungee();
            }}
          />
          <Package
            img={newrafting2}
            price={"INR 1,500"}
            title={"Rafting from Marine drive to Nim Beach"}
            tag={"26 km rafting"}
            strikePrice={"INR 1,700"}
            rating={"5.0(120+)"}
            set={() => {
              // setAdventure(1);
              // setCurrect_type_rafting(5);
              // setForm_open(true);
              navigateToRafting();
            }}
          />
        </div>
        {/* <Title
          title={"Explore our best activites"}
          description={"Here are some of the best activities for you"}
          more={true}
        /> */}
        {/* <Budget /> */}
        <Title
          title={"River Rafting In Rishikesh"}
          description={"Starting at just ₹599/person"}
          more={false}
        />
        {/* <div className="explore-activites">
          <FeatureSectionLarge
            img={newrafting3}
            title={"River Rafting"}
            description={"9km to 36km"}
          /> */}
        {/* <FeatureSectionSmall /> */}
        {/* </div> */}
        <div className="scroll-section">
          <FeatureSectionSmall
            img={require("../Public/rafting/r2.jpg")}
            title={"9km rafting"}
            description={"Bhrampuri to Nim beach"}
            link={"/rafting-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/rafting/r6.jpg")}
            title={"12km rafting"}
            description={"Marine Drive to Shivpuri"}
            link={"/rafting-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/rafting/r8.jpg")}
            title={"12km rafting"}
            description={"Club House to Nim beach"}
            link={"/rafting-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/rafting/r11.jpg")}
            title={"16km rafting"}
            description={"Shivpuri to Nim beach"}
            link={"/rafting-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/rafting/r14.jpg")}
            title={"26km rafting"}
            description={"Marine Drive to Nim beach"}
            link={"/rafting-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/rafting/r16.jpg")}
            title={"36km rafting"}
            description={"Kaudiyala to Nim beach"}
            link={"/rafting-in-rishikesh"}
          />
          <div></div>
        </div>
        <Title
          title={"Camping in rishikesh"}
          description={"Startinig at just ₹999/person"}
          more={false}
        />

        <div className="scroll-section">
          <FeatureSectionSmall
            img={newcamping2}
            title={"Beach camp"}
            description={"Starts at ₹999/person"}
            link={"/camping-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/luxuryCamp/lc6.jpeg")}
            title={"Luxury camp"}
            description={"Starts at ₹1,199/person"}
            link={"/camping-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/ACCottages/ac5.jpeg")}
            title={"AC Cottages"}
            description={"Starts at ₹1,999/person"}
            link={"/camping-in-rishikesh"}
          />
          <div></div>
        </div>
        <Title
          title={"Bungee jumping in rishikesh"}
          description={"Startinig at just ₹2999/person"}
          more={false}
        />

        <div className="scroll-section">
          <FeatureSectionSmall
            img={require("../Public/bj55/bj55_1.png")}
            title={"55 Meter"}
            description={"Starts at ₹2,999/person"}
            link={"/bungee-jumping-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/bj83/bj83_4.jpeg")}
            title={"83 Meters"}
            description={"Starts at ₹3,699/person"}
            link={"/bungee-jumping-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/bj109/bj109_1.jpeg")}
            title={"109 Meters"}
            description={"Starts at ₹3,999/person"}
            link={"/bungee-jumping-in-rishikesh"}
          />
          <FeatureSectionSmall
            img={require("../Public/bj117/bj117_3.jpeg")}
            title={"117 Meters"}
            description={"Starts at ₹4,599/person"}
            link={"/bungee-jumping-in-rishikesh"}
          />
          <div></div>
        </div>
        {/* <div className="scroll-section">
          <Options content={"All"} active={true} />
          <Options content={"Hotels"} active={false} />
          <Options content={"Restaurant"} active={false} />
          <Options content={"Cab"} active={false} />
          <Options content={"Rental"} active={false} />
          <div></div>
        </div>
        <div className="home-packages-div">
          <Package />
          <Package />
          <Package />
          <Package />
          <Package />
          <Package />
          <Package />
          <Package />
        </div> */}
        <Footer />
        {/* <div className="main-space"></div> */}
        <BottomTab active={"explore"} />
      </div>
    </>
  );
}

export default Home;
