import "./AccountCoupons.css";
import IndiAccountCoupon from "./IndiAccountCoupon";
function AccountCoupons() {
  return (
    <>
      <div className="main-account-coupon">
        <IndiAccountCoupon />
        <IndiAccountCoupon />
        <IndiAccountCoupon />
      </div>
      <div className="account-coupon-space"></div>
    </>
  );
}

export default AccountCoupons;
