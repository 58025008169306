import "./AccountCart.css";
import IndiAccountCart from "./IndiAccountCart";
function AccountCart() {
  return (
    <>
      <IndiAccountCart />
      <IndiAccountCart />
      <IndiAccountCart />
      <IndiAccountCart />
      <IndiAccountCart />
      <div className="account-cart-space"></div>
    </>
  );
}

export default AccountCart;
