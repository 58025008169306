import BottomTab from "../ComponentsHome/BottomTab";
import HeaderMobile from "../ComponentsActivity/HeaderMobile";
import MainTitle from "../ComponentsActivity/MainTitle";
import Options from "../ComponentsActivity/Options";
import "./Rafting.css";
import Title from "../ComponentsHome/Title";
import Status from "../GlobalComponents/Status";
import PageMenu from "../GlobalComponents/PageMenu";
import TitleWithoutDescription from "../GlobalComponents/TitleWithoutDescription";
import GlobalForm from "../GlobalComponents/Globalform";
import { useEffect, useState } from "react";
import GlobalPhotoStatus from "../GlobalStatus/GlobalPhotoStatus";
import PhotoSection from "../GlobalPhoto/PhotoSection";
import "./RaftingResponsive.css";
import Header from "../DesktopComponents/Header";
import "./RaftingDesktop.css";
import IndiType from "../DesktopComponents/IndiType";
import IndiWhere from "../DesktopComponents/IndiWhere";
import TitleDesktop from "../DesktopComponents/TitleDesktop";
import DesktopStatus from "../DesktopComponents/DesktopStatus";
import Photo_section_desktop from "../DesktopComponents/Photo_section";
import Status_section from "../DesktopComponents/Status_section";
import "../DesktopComponents/DesktopForm.css";
import InputDate from "../DesktopComponents/InputDate";
import TextInput from "../DesktopComponents/InputText";
import { useRef } from "react";
import DesktopContentTitle from "../DesktopComponents/DesktopContentTitle";
import AfterForm from "../DesktopComponents/AfterForm";
import BigGroup from "../DesktopComponents/BigGroup";
import Footer from "../GlobalComponents/Footer";
import axios from "axios";
import { Helmet } from "react-helmet";
import CallWhattsaap from "../Msgs/CallWhattsaap";

// function IndiSection({ section, active, ref }) {
//   return (
//     <div
//       onClick={() => {
//         ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
//       }}
//       className={active ? "damw-indi-section-active" : "damw-indi-section"}
//     >
//       {section}
//     </div>
//   );
// }

function Rafting() {
  const isWeekend = (dateString) => {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Get the day of the week (0-6)
    const day = date.getDay();

    // Return true if it's Saturday (6) or Sunday (0), otherwise false
    return day === 0 || day === 6;
  };
  const [form, setForm] = useState(false);
  const [status, setStatus] = useState(false);
  const [photoSection, setPhotoSection] = useState(false);
  const [desktop_photo, setDesktop_photo] = useState(false);
  const [desktop_status, setDesktop_status] = useState(false);
  const [time_out_desktop, setTime_out_desktop] = useState(undefined);

  const [current_type, setCurrent_type] = useState(1);
  const [choosen_date, setChoosen_date] = useState("");
  const [price, setPrice] = useState({
    1: 600,
    2: 800,
    3: 600,
    4: 1000,
    5: 1500,
    6: 2500,
  });
  const [rapids, setRapids] = useState({
    1: 6,
    2: 6,
    3: 4,
    4: 8,
    5: 14,
    6: "20+",
  });
  const [km, setKm] = useState({
    1: "09",
    2: "12",
    // 3: "12",
    4: "16",
    5: "26",
    6: "36",
  });

  const [weekEnd, setWeekEnd] = useState(false);

  const [no_people, setNo_people] = useState(2);
  const kms_blink = useRef(null);
  const rapids_blink = useRef(null);
  const blink_begin = useRef(null);
  const blink_last = useRef(null);

  const aboutRef = useRef(null);
  const content_scRef = useRef(null);
  const content_vroalRef = useRef(null);
  const content_rwaacRef = useRef(null);
  const content_carirRef = useRef(null);
  const content_advantagesRef = useRef(null);
  const content_rrseRef = useRef(null);
  const content_itineraryRef = useRef(null);
  const content_ecfrrrRef = useRef(null);
  const content_inclusionsRef = useRef(null);
  const content_exclusionsRef = useRef(null);
  const content_htrrRef = useRef(null);
  const content_corRef = useRef(null);
  const content_rirrRef = useRef(null);
  const content_ttcfrirRef = useRef(null);
  const content_ttdwRef = useRef(null);
  const content_tntdRef = useRef(null);
  const content_bcirwrRef = useRef(null);
  const content_bafRef = useRef(null);
  const content_cirwrRef = useRef(null);
  const content_ueRef = useRef(null);
  const content_paaarRef = useRef(null);

  const aboutRefm = useRef(null);
  const content_scRefm = useRef(null);
  const content_vroalRefm = useRef(null);
  const content_rwaacRefm = useRef(null);
  const content_carirRefm = useRef(null);
  const content_advantagesRefm = useRef(null);
  const content_rrseRefm = useRef(null);
  const content_itineraryRefm = useRef(null);
  const content_ecfrrrRefm = useRef(null);
  const content_inclusionsRefm = useRef(null);
  const content_exclusionsRefm = useRef(null);
  const content_htrrRefm = useRef(null);
  const content_corRefm = useRef(null);
  const content_rirrRefm = useRef(null);
  const content_ttcfrirRefm = useRef(null);
  const content_ttdwRefm = useRef(null);
  const content_tntdRefm = useRef(null);
  const content_bcirwrRefm = useRef(null);
  const content_bafRefm = useRef(null);
  const content_cirwrRefm = useRef(null);
  const content_ueRefm = useRef(null);
  const content_paaarRefm = useRef(null);

  const [slots, setSlots] = useState([]);

  // useEffect(() => {
  //   alert(slots[0]);
  // }, [slots]);

  const [where, setWhere] = useState({
    1: [
      {
        img: require("../Public/rafting/r1.jpg"),
        tag: "Begin",
        title: "Reporting to Office",
      },
      {
        img: require("../Public/rafting/r2.jpg"),
        tag: "~ 5 min",
        title: "Consult form filling",
      },
      {
        img: require("../Public/rafting/r3.jpg"),
        tag: "~ 10 min",
        title: "heading to the starting point",
      },
      {
        img: require("../Public/rafting/r4.jpg"),
        tag: "~ 20 min",
        title: "wearing jacket and helmet",
      },
      {
        img: require("../Public/rafting/r5.jpg"),
        tag: "~ 30 min",
        title: "Safety breifing",
      },
      {
        img: require("../Public/rafting/r6.jpg"),
        tag: "~ 40 min",
        title: "Training",
      },
      {
        img: require("../Public/rafting/r7.jpg"),
        tag: "~ 60 min",
        title: "Start rafting",
      },
      {
        img: require("../Public/rafting/r8.jpg"),
        tag: "~ 70 min",
        title: "Rafting - river activities",
      },
      {
        img: require("../Public/rafting/r9.jpg"),
        tag: "~ 90 min",
        title: "Maggie point",
      },
      {
        img: require("../Public/rafting/r10.jpg"),
        tag: "~ 120 min",
        title: "Ending point",
      },
      {
        img: require("../Public/rafting/r11.jpg"),
        tag: "~ 130 min",
        title: "Back to office",
      },
      {
        img: require("../Public/rafting/r12.jpg"),
        tag: "~ 140 min",
        title: "Changing and freshing up",
      },
      {
        img: require("../Public/rafting/r13.jpg"),
        tag: "~ 140 min",
        title: "Taking feed back ",
      },
      {
        img: require("../Public/rafting/r14.jpg"),
        tag: "~ 150 min",
        title: "Everyone say good bye",
      },
    ],
    2: [
      {
        img: require("../Public/rafting/r1.jpg"),
        tag: "Begin",
        title: "Reporting to Office",
      },
      {
        img: require("../Public/rafting/r2.jpg"),
        tag: "~ 5 min",
        title: "Consult form filling",
      },
      {
        img: require("../Public/rafting/r3.jpg"),
        tag: "~ 10 min",
        title: "heading to the starting point",
      },
      {
        img: require("../Public/rafting/r4.jpg"),
        tag: "~ 20 min",
        title: "wearing jacket and helmet",
      },
      {
        img: require("../Public/rafting/r5.jpg"),
        tag: "~ 30 min",
        title: "Safety breifing",
      },
      {
        img: require("../Public/rafting/r6.jpg"),
        tag: "~ 40 min",
        title: "Training",
      },
      {
        img: require("../Public/rafting/r7.jpg"),
        tag: "~ 60 min",
        title: "Start rafting",
      },
      {
        img: require("../Public/rafting/r8.jpg"),
        tag: "~ 70 min",
        title: "Rafting - river activities",
      },
      {
        img: require("../Public/rafting/r9.jpg"),
        tag: "~ 90 min",
        title: "Maggie point",
      },
      {
        img: require("../Public/rafting/r10.jpg"),
        tag: "~ 120 min",
        title: "Ending point",
      },
      {
        img: require("../Public/rafting/r11.jpg"),
        tag: "~ 130 min",
        title: "Back to office",
      },
      {
        img: require("../Public/rafting/r12.jpg"),
        tag: "~ 140 min",
        title: "Changing and freshing up",
      },
      {
        img: require("../Public/rafting/r13.jpg"),
        tag: "~ 140 min",
        title: "Taking feed back ",
      },
      {
        img: require("../Public/rafting/r14.jpg"),
        tag: "~ 150 min",
        title: "Everyone say good bye",
      },
    ],
    3: [
      {
        img: require("../Public/rafting/r1.jpg"),
        tag: "Begin",
        title: "Reporting to Office",
      },
      {
        img: require("../Public/rafting/r2.jpg"),
        tag: "~ 5 min",
        title: "Consult form filling",
      },
      {
        img: require("../Public/rafting/r3.jpg"),
        tag: "~ 10 min",
        title: "heading to the starting point",
      },
      {
        img: require("../Public/rafting/r4.jpg"),
        tag: "~ 20 min",
        title: "wearing jacket and helmet",
      },
      {
        img: require("../Public/rafting/r5.jpg"),
        tag: "~ 30 min",
        title: "Safety breifing",
      },
      {
        img: require("../Public/rafting/r6.jpg"),
        tag: "~ 40 min",
        title: "Training",
      },
      {
        img: require("../Public/rafting/r7.jpg"),
        tag: "~ 60 min",
        title: "Start rafting",
      },
      {
        img: require("../Public/rafting/r8.jpg"),
        tag: "~ 70 min",
        title: "Rafting - river activities",
      },
      {
        img: require("../Public/rafting/r9.jpg"),
        tag: "~ 90 min",
        title: "Maggie point",
      },
      {
        img: require("../Public/rafting/r10.jpg"),
        tag: "~ 120 min",
        title: "Ending point",
      },
      {
        img: require("../Public/rafting/r11.jpg"),
        tag: "~ 130 min",
        title: "Back to office",
      },
      {
        img: require("../Public/rafting/r12.jpg"),
        tag: "~ 140 min",
        title: "Changing and freshing up",
      },
      {
        img: require("../Public/rafting/r13.jpg"),
        tag: "~ 140 min",
        title: "Taking feed back ",
      },
      {
        img: require("../Public/rafting/r14.jpg"),
        tag: "~ 150 min",
        title: "Everyone say good bye",
      },
    ],
    4: [
      {
        img: require("../Public/rafting/r1.jpg"),
        tag: "Begin",
        title: "Reporting to Office",
      },
      {
        img: require("../Public/rafting/r2.jpg"),
        tag: "~ 5 min",
        title: "Consult form filling",
      },
      {
        img: require("../Public/rafting/r3.jpg"),
        tag: "~ 10 min",
        title: "heading to the starting point",
      },
      {
        img: require("../Public/rafting/r4.jpg"),
        tag: "~ 20 min",
        title: "wearing jacket and helmet",
      },
      {
        img: require("../Public/rafting/r5.jpg"),
        tag: "~ 30 min",
        title: "Safety breifing",
      },
      {
        img: require("../Public/rafting/r6.jpg"),
        tag: "~ 40 min",
        title: "Training",
      },
      {
        img: require("../Public/rafting/r7.jpg"),
        tag: "~ 60 min",
        title: "Start rafting",
      },
      {
        img: require("../Public/rafting/r8.jpg"),
        tag: "~ 70 min",
        title: "Rafting - river activities",
      },
      {
        img: require("../Public/rafting/r9.jpg"),
        tag: "~ 90 min",
        title: "Maggie point",
      },
      {
        img: require("../Public/rafting/r10.jpg"),
        tag: "~ 120 min",
        title: "Ending point",
      },
      {
        img: require("../Public/rafting/r11.jpg"),
        tag: "~ 130 min",
        title: "Back to office",
      },
      {
        img: require("../Public/rafting/r12.jpg"),
        tag: "~ 140 min",
        title: "Changing and freshing up",
      },
      {
        img: require("../Public/rafting/r13.jpg"),
        tag: "~ 140 min",
        title: "Taking feed back ",
      },
      {
        img: require("../Public/rafting/r14.jpg"),
        tag: "~ 150 min",
        title: "Everyone say good bye",
      },
    ],
    5: [
      {
        img: require("../Public/rafting/r1.jpg"),
        tag: "Begin",
        title: "Reporting to Office",
      },
      {
        img: require("../Public/rafting/r2.jpg"),
        tag: "~ 5 min",
        title: "Consult form filling",
      },
      {
        img: require("../Public/rafting/r3.jpg"),
        tag: "~ 10 min",
        title: "heading to the starting point",
      },
      {
        img: require("../Public/rafting/r4.jpg"),
        tag: "~ 20 min",
        title: "wearing jacket and helmet",
      },
      {
        img: require("../Public/rafting/r5.jpg"),
        tag: "~ 30 min",
        title: "Safety breifing",
      },
      {
        img: require("../Public/rafting/r6.jpg"),
        tag: "~ 40 min",
        title: "Training",
      },
      {
        img: require("../Public/rafting/r7.jpg"),
        tag: "~ 60 min",
        title: "Start rafting",
      },
      {
        img: require("../Public/rafting/r8.jpg"),
        tag: "~ 70 min",
        title: "Rafting - river activities",
      },
      {
        img: require("../Public/rafting/r9.jpg"),
        tag: "~ 90 min",
        title: "Maggie point",
      },
      {
        img: require("../Public/rafting/r10.jpg"),
        tag: "~ 120 min",
        title: "Ending point",
      },
      {
        img: require("../Public/rafting/r11.jpg"),
        tag: "~ 130 min",
        title: "Back to office",
      },
      {
        img: require("../Public/rafting/r12.jpg"),
        tag: "~ 140 min",
        title: "Changing and freshing up",
      },
      {
        img: require("../Public/rafting/r13.jpg"),
        tag: "~ 140 min",
        title: "Taking feed back ",
      },
      {
        img: require("../Public/rafting/r14.jpg"),
        tag: "~ 150 min",
        title: "Everyone say good bye",
      },
    ],
    6: [
      {
        img: require("../Public/rafting/r1.jpg"),
        tag: "Begin",
        title: "Reporting to Office",
      },
      {
        img: require("../Public/rafting/r2.jpg"),
        tag: "~ 5 min",
        title: "Consult form filling",
      },
      {
        img: require("../Public/rafting/r3.jpg"),
        tag: "~ 10 min",
        title: "heading to the starting point",
      },
      {
        img: require("../Public/rafting/r4.jpg"),
        tag: "~ 20 min",
        title: "wearing jacket and helmet",
      },
      {
        img: require("../Public/rafting/r5.jpg"),
        tag: "~ 30 min",
        title: "Safety breifing",
      },
      {
        img: require("../Public/rafting/r6.jpg"),
        tag: "~ 40 min",
        title: "Training",
      },
      {
        img: require("../Public/rafting/r7.jpg"),
        tag: "~ 60 min",
        title: "Start rafting",
      },
      {
        img: require("../Public/rafting/r8.jpg"),
        tag: "~ 70 min",
        title: "Rafting - river activities",
      },
      {
        img: require("../Public/rafting/r9.jpg"),
        tag: "~ 90 min",
        title: "Maggie point",
      },
      {
        img: require("../Public/rafting/r10.jpg"),
        tag: "~ 120 min",
        title: "Ending point",
      },
      {
        img: require("../Public/rafting/r11.jpg"),
        tag: "~ 130 min",
        title: "Back to office",
      },
      {
        img: require("../Public/rafting/r12.jpg"),
        tag: "~ 140 min",
        title: "Changing and freshing up",
      },
      {
        img: require("../Public/rafting/r13.jpg"),
        tag: "~ 140 min",
        title: "Taking feed back ",
      },
      {
        img: require("../Public/rafting/r14.jpg"),
        tag: "~ 150 min",
        title: "Everyone say good bye",
      },
    ],
  });

  const [se, setSe] = useState({
    1: {
      start: "Bhrampuri",
      end: "Nim beach",
    },
    2: {
      start: "Marine Drive",
      end: "Shivpuri",
    },
    3: {
      start: "Club House",
      end: "Nim beach",
    },
    4: {
      start: "Shivpuri",
      end: "Nim beach",
    },
    5: {
      start: "Marine Drive",
      end: "Nim beach",
    },
    6: {
      start: "Kaudiyala",
      end: "Nim beach",
    },
  });

  const [choosen_slot, setChoosen_slot] = useState(undefined);

  // useEffect(() => {
  //   alert(choosen_slot);
  // }, [choosen_slot]);

  // scroll section here
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollStartX, setScrollStartX] = useState(0);
  const [curent_menu_option, setCurrent_menu_option] = useState("");

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStartX(e.clientX);
    setScrollStartX(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - dragStartX;
    containerRef.current.scrollLeft = scrollStartX - dx;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (time_out_desktop != undefined) {
      clearTimeout(time_out_desktop[0]);
    }
    kms_blink.current.classList.add("blink_animation");
    rapids_blink.current.classList.add("blink_animation");
    blink_begin.current.classList.add("blink_animation");
    blink_last.current.classList.add("blink_animation");

    km_blink_mobile.current.classList.add("blink_animation");
    rapids_blink_mobile.current.classList.add("blink_animation");
    start_blink_mobile.current.classList.add("blink_animation");
    end_blink_mobile.current.classList.add("blink_animation");

    setTime_out_desktop([
      setTimeout(() => {
        kms_blink.current.classList.remove("blink_animation");
        rapids_blink.current.classList.remove("blink_animation");
        blink_begin.current.classList.remove("blink_animation");
        blink_last.current.classList.remove("blink_animation");

        km_blink_mobile.current.classList.remove("blink_animation");
        rapids_blink_mobile.current.classList.remove("blink_animation");
        start_blink_mobile.current.classList.remove("blink_animation");
        end_blink_mobile.current.classList.remove("blink_animation");
      }, 500),
    ]);
  }, [current_type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [desktop_status_indi, setDesktop_status_indi] = useState([
    {
      smallimg: require("../Public/rafting/r1.jpg"),
      img: require("../Public/rafting/r1.jpg"),
      title: "9km river rafting",
      des: "Experience the thrill with our 9 km rafting",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/rafting/r2.jpg"),
      img: require("../Public/rafting/r2.jpg"),
      title: "12 km River Rafting",
      des: "Experience the thrill with our 12 km rafting",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/rafting/r3.jpg"),
      img: require("../Public/rafting/r3.jpg"),
      title: "16 km River Rafting",
      des: "Experience the thrill with our 16 km rafting",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/rafting/r4.jpg"),
      img: require("../Public/rafting/r4.jpg"),
      title: "26 River Rafting",
      des: "Experience the thrill with our 26 km rafting",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/rafting/r5.jpg"),
      img: require("../Public/rafting/r5.jpg"),
      title: "36 km River Rafting",
      des: "Experience the thrill with our 36 km rafting",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
  ]);

  const [menu_desktop_content, setMenu_desktop_content] = useState([
    { content: "About", tag: "about", ref: aboutRef },
    {
      content: "Itinerary",
      tag: "content-itinerary",
      ref: content_itineraryRef,
    },
    {
      content: "Eligibility Criteria for River Rafting Rishikesh",
      tag: "content-ecfrrr",
      ref: content_ecfrrrRef,
    },
    {
      content: "Rishikesh Rafting Safety Measures",
      tag: "content-rrse",
      ref: content_rrseRef,
    },
    {
      content: "Advantages",
      tag: "content-advantages",
      ref: content_advantagesRef,
    },
    {
      content: "Inclusions",
      tag: "content-inclusions",
      ref: content_inclusionsRef,
    },
    {
      content: "Exclusions",
      tag: "content-exclusions",
      ref: content_exclusionsRef,
    },
    {
      content: "Various Rapids for All Levels",
      tag: "content-vroal",
      ref: content_vroalRef,
    },
    {
      content: "Classification of Rapids",
      tag: "content-cor",
      ref: content_corRef,
    },
    {
      content: "Rafting in Rishikesh Rapids",
      tag: "content-rirr",
      ref: content_rirrRef,
    },
    {
      content: "Things to Carry for Rafting in Rishikesh",
      tag: "content-ttcfrir",
      ref: content_ttcfrirRef,
    },
    { content: "Things to Do/Ware", tag: "content-ttdw", ref: content_ttdwRef },
    { content: "Things not to do", tag: "content-tntd", ref: content_tntdRef },
    {
      content: "Benefits and Features:",
      tag: "content-baf",
      ref: content_bafRef,
    },
    { content: "Spiritual Connection", tag: "content-sc", ref: content_scRef },
    {
      content: "Rafting with Adventure Activities Combo",
      tag: "content-rwaac",
      ref: content_rwaacRef,
    },
    {
      content: "Camping and Rafting in Rishikesh",
      tag: "content-carir",
      ref: content_carirRef,
    },
    {
      content: "How to Reach Rishikesh?",
      tag: "content-htrr",
      ref: content_htrrRef,
    },
    {
      content: "Beach Camping in Rishikesh with Rafting",
      tag: "content-bcirwr",
      ref: content_bcirwrRef,
    },
    {
      content: "Camping in Rishikesh with Rafting",
      tag: "content-cirwr",
      ref: content_cirwrRef,
    },
    { content: "Unique Experiences", tag: "content-ue", ref: content_ueRef },
    {
      content: "People Also Ask About Rishikesh",
      tag: "content-paaar",
      ref: content_paaarRef,
    },
  ]);

  const [menu_mobile_content, setMenu_mobile_content] = useState([
    { content: "About", tag: "about", ref: aboutRefm },
    {
      content: "Itinerary",
      tag: "content-itinerary",
      ref: content_itineraryRefm,
    },
    {
      content: "Eligibility Criteria for River Rafting Rishikesh",
      tag: "content-ecfrrr",
      ref: content_ecfrrrRefm,
    },
    {
      content: "Rishikesh Rafting Safety Measures",
      tag: "content-rrse",
      ref: content_rrseRefm,
    },
    {
      content: "Advantages",
      tag: "content-advantages",
      ref: content_advantagesRefm,
    },
    {
      content: "Inclusions",
      tag: "content-inclusions",
      ref: content_inclusionsRefm,
    },
    {
      content: "Exclusions",
      tag: "content-exclusions",
      ref: content_exclusionsRefm,
    },
    {
      content: "Classification of Rapids",
      tag: "content-cor",
      ref: content_corRefm,
    },
    {
      content: "Rafting in Rishikesh Rapids",
      tag: "content-rirr",
      ref: content_rirrRefm,
    },
    {
      content: "Things to Carry for Rafting in Rishikesh",
      tag: "content-ttcfrir",
      ref: content_ttcfrirRefm,
    },
    {
      content: "Things to Do/Ware",
      tag: "content-ttdw",
      ref: content_ttdwRefm,
    },
    { content: "Things not to do", tag: "content-tntd", ref: content_tntdRefm },
    {
      content: "Benefits and Features:",
      tag: "content-baf",
      ref: content_bafRefm,
    },
    { content: "Spiritual Connection", tag: "content-sc", ref: content_scRefm },
    {
      content: "Various Rapids for All Levels",
      tag: "content-vroal",
      ref: content_vroalRefm,
    },
    {
      content: "Rafting with Adventure Activities Combo",
      tag: "content-rwaac",
      ref: content_rwaacRefm,
    },
    {
      content: "Camping and Rafting in Rishikesh",
      tag: "content-carir",
      ref: content_carirRefm,
    },
    {
      content: "How to Reach Rishikesh?",
      tag: "content-htrr",
      ref: content_htrrRefm,
    },
    {
      content: "Beach Camping in Rishikesh with Rafting",
      tag: "content-bcirwr",
      ref: content_bcirwrRefm,
    },
    {
      content: "Camping in Rishikesh with Rafting",
      tag: "content-cirwr",
      ref: content_cirwrRefm,
    },
    { content: "Unique Experiences", tag: "content-ue", ref: content_ueRefm },
    {
      content: "People Also Ask About Rishikesh",
      tag: "content-paaar",
      ref: content_paaarRefm,
    },
  ]);

  const [current_mobile_menu, setCurrent_mobile_menu] = useState(0);

  // mobile form details
  const [mobile_name, setMobile_name] = useState("");
  const [mobile_contact, setMobile_contact] = useState("");
  const [mobile_email, setMobile_email] = useState("");
  const [mobile_dor, setMobile_dor] = useState("");
  const [mobile_date, setMobile_date] = useState("");
  const [mobile_no_of_people, setMobile_no_of_people] = useState(2);
  const [mobile_slot, setMobile_slot] = useState([]);

  // creating blinks for mobile form
  const start_blink_mobile = useRef(null);
  const end_blink_mobile = useRef(null);
  const km_blink_mobile = useRef(null);
  const rapids_blink_mobile = useRef(null);

  // form data is here
  const [formname, setformName] = useState("");
  const [form_email, setForm_email] = useState("");
  const [form_contact, setForm_contact] = useState("");

  // form ref
  const f_r_key = useRef(null);
  const f_r_contact = useRef(null);
  const f_r_email = useRef(null);
  const f_r_txnid = useRef(null);
  const f_r_amount = useRef(null);
  const f_r_p_info = useRef(null);
  const f_r_first_name = useRef(null);
  const f_r_hash = useRef(null);
  const f_r_form = useRef(null);

  // useEffect(() => {
  //   alert(mobile_slot[choosen_slot - 1]);
  // }, [choosen_slot]);

  const rulesDesktop = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      no_people == 0 ||
      no_people == "" ||
      no_people == undefined ||
      no_people == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (
      slots[choosen_slot] == "" ||
      slots[choosen_slot] == undefined ||
      slots[choosen_slot] == null
    ) {
      alert("Please choose a slot");
      allgood = false;
    } else if (
      choosen_date == "" ||
      choosen_date == undefined ||
      choosen_date == null
    ) {
      alert("please choose a date");
      allgood = false;
    } else if (
      current_type == "" ||
      current_type == undefined ||
      current_type == null ||
      current_type > 6
    ) {
      alert("Invalid type of rafting");
      allgood = false;
    } else if (
      form_contact == "" ||
      form_contact == undefined ||
      form_contact == null ||
      form_contact.length < 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (
      form_email == "" ||
      form_email == undefined ||
      form_email == null
    ) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(form_email)) {
      alert("Invalid email");
      allgood = false;
    } else if (formname == "" || formname == undefined || formname == null) {
      alert("Invalid name");
      allgood = false;
    } else if (choosen_date == "2024-04-19") {
      alert("Rafting is closed on 19th because of elections");
      allgood = false;
    } else if (no_people < 6 && current_type == 6) {
      alert(
        "36 km rafting will only be booked if their are more than 5 people"
      );
      allgood = false;
    }
    // console.log(choosen_date);
    return allgood;
  };

  const rulesMobile = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      mobile_no_of_people == 0 ||
      mobile_no_of_people == "" ||
      mobile_no_of_people == undefined ||
      mobile_no_of_people == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (
      choosen_slot == "" ||
      choosen_slot == undefined ||
      choosen_slot == null
    ) {
      alert("Please choose a slot");
      allgood = false;
    } else if (
      mobile_date == "" ||
      mobile_date == undefined ||
      mobile_date == null
    ) {
      alert("please choose a date");
      allgood = false;
    } else if (mobile_date == "2024-04-19") {
      alert("Rafting is closed on 19th because of elections");
      allgood = false;
    } else if (
      current_type == "" ||
      current_type == undefined ||
      current_type == null ||
      current_type > 6
    ) {
      alert("Invalid type of rafting");
      allgood = false;
    } else if (
      mobile_contact == "" ||
      mobile_contact == undefined ||
      mobile_contact == null ||
      mobile_contact.length < 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (
      mobile_email == "" ||
      mobile_email == undefined ||
      mobile_email == null
    ) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(mobile_email)) {
      alert("Invalid email");
      allgood = false;
    } else if (
      mobile_name == "" ||
      mobile_name == undefined ||
      mobile_name == null
    ) {
      alert("Invalid name");
      allgood = false;
    } else if (mobile_no_of_people < 6 && current_type == 6) {
      alert(
        "36 km rafting will only be booked if their are more than 5 people"
      );
      allgood = false;
    }

    return allgood;
  };

  const submitAdvance = () => {
    if (rulesDesktop()) {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/enquery", {
          name: formname,
          email: form_email,
          contact: form_contact,
          type: (() => {
            if (current_type == 1) {
              return "09 km";
            } else if (current_type == 2) {
              return "12 km-md";
            } else if (current_type == 3) {
              return "12 km-club";
            } else if (current_type == 4) {
              return "16 km";
            } else if (current_type == 5) {
              return "26 km";
            } else if (current_type == 6) {
              return "36 km";
            }
          })(),
          date: choosen_date,
          slot: slots[choosen_slot],
          headcount: no_people,
          payment_type: "advance",
          totalAmount: (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                (parseInt(price[current_type]) - 200 / 100) *
                parseInt(no_people)
              );
            } else {
              return parseInt(price[current_type]) * parseInt(no_people);
            }
          })(),
          amount: (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                ((parseInt(price[current_type]) - 200) *
                  parseInt(no_people) *
                  20) /
                100
              );
            } else {
              return (
                (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100
              );
            }
          })(),
          // amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "rafting",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          // f_r_amount.current.value = 1;
          f_r_amount.current.value = (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                ((parseInt(price[current_type]) - 200) *
                  parseInt(no_people) *
                  20) /
                100
              );
            } else {
              return (
                (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100
              );
            }
          })();
          f_r_contact.current.value = form_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = formname;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "rafting";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitFull = () => {
    if (rulesDesktop()) {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/enquery", {
          name: formname,
          email: form_email,
          contact: form_contact,
          type: (() => {
            if (current_type == 1) {
              return "09 km";
            } else if (current_type == 2) {
              return "12 km-md";
            } else if (current_type == 3) {
              return "12 km-club";
            } else if (current_type == 4) {
              return "16 km";
            } else if (current_type == 5) {
              return "26 km";
            } else if (current_type == 6) {
              return "36 km";
            }
          })(),
          date: choosen_date,
          slot: slots[choosen_slot],
          headcount: no_people,
          amount: (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                (parseInt(price[current_type]) - 200) * parseInt(no_people)
              );
            } else {
              return parseInt(price[current_type]) * parseInt(no_people);
            }
          })(),
          // amount: 1,
          totalAmount: (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                (parseInt(price[current_type]) - 200) * parseInt(no_people)
              );
            } else {
              return parseInt(price[current_type]) * parseInt(no_people);
            }
          })(),
          payment_type: "full",
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "rafting",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          f_r_amount.current.value = (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                (parseInt(price[current_type]) - 200) * parseInt(no_people)
              );
            } else {
              return parseInt(price[current_type]) * parseInt(no_people);
            }
          })();
          // f_r_amount.current.value = 1;
          f_r_contact.current.value = form_contact;
          f_r_email.current.value = form_email;
          f_r_first_name.current.value = formname;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "rafting";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitMobileAdvance = () => {
    if (rulesMobile()) {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/enquery", {
          name: mobile_name,
          email: mobile_email,
          contact: mobile_contact,
          type: (() => {
            if (current_type == 1) {
              return "09 km";
            } else if (current_type == 2) {
              return "12 km-md";
            } else if (current_type == 3) {
              return "12 km-club";
            } else if (current_type == 4) {
              return "16 km";
            } else if (current_type == 5) {
              return "26 km";
            } else if (current_type == 6) {
              return "36 km";
            }
          })(),
          date: mobile_date,
          slot: mobile_slot[choosen_slot - 1],
          headcount: mobile_no_of_people,
          payment_type: "advance",
          totalAmount:
            // parseInt(price[current_type]) * parseInt(mobile_no_of_people),
            (() => {
              if (current_type == 2 && weekEnd == false) {
                return (
                  (parseInt(price[current_type]) - 200) *
                  parseInt(mobile_no_of_people)
                );
              } else {
                return (
                  parseInt(price[current_type]) * parseInt(mobile_no_of_people)
                );
              }
            })(),
          amount: (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                ((parseInt(price[current_type]) - 200) *
                  parseInt(mobile_no_of_people) *
                  20) /
                100
              );
            } else {
              return (
                (parseInt(price[current_type]) *
                  parseInt(mobile_no_of_people) *
                  20) /
                100
              );
            }
          })(),
          // (parseInt(price[current_type]) *
          //   parseInt(mobile_no_of_people) *
          //   20) /
          // 100,
          // amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "rafting",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          // f_r_amount.current.value = 1;
          f_r_amount.current.value = (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                ((parseInt(price[current_type]) - 200) *
                  parseInt(mobile_no_of_people) *
                  20) /
                100
              );
            } else {
              return (
                (parseInt(price[current_type]) *
                  parseInt(mobile_no_of_people) *
                  20) /
                100
              );
            }
          })();
          f_r_contact.current.value = mobile_contact;
          f_r_email.current.value = mobile_email;
          f_r_first_name.current.value = mobile_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "rafting";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const submitMobileFull = () => {
    if (rulesMobile()) {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/enquery", {
          name: mobile_name,
          email: mobile_email,
          contact: mobile_contact,
          type: (() => {
            if (current_type == 1) {
              return "09 km";
            } else if (current_type == 2) {
              return "12 km-md";
            } else if (current_type == 3) {
              return "12 km-club";
            } else if (current_type == 4) {
              return "16 km";
            } else if (current_type == 5) {
              return "26 km";
            } else if (current_type == 6) {
              return "36 km";
            }
          })(),
          date: mobile_date,
          slot: mobile_slot[choosen_slot - 1],
          headcount: mobile_no_of_people,
          payment_type: "full",
          totalAmount: (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                (parseInt(price[current_type]) - 200) *
                parseInt(mobile_no_of_people)
              );
            } else {
              return (
                parseInt(price[current_type]) * parseInt(mobile_no_of_people)
              );
            }
          })(),
          // parseInt(price[current_type]) * parseInt(mobile_no_of_people),
          amount: (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                (parseInt(price[current_type]) - 200) *
                parseInt(mobile_no_of_people)
              );
            } else {
              return (
                parseInt(price[current_type]) * parseInt(mobile_no_of_people)
              );
            }
          })(),
          // amount: parseInt(price[current_type]) * parseInt(mobile_no_of_people),
          // amount: 1,
        })
        .then((res) => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "rafting",
            txnid: res.data.txnid,
            Bookingvalue: 100,
          });
          // f_r_amount.current.value = 1;
          f_r_amount.current.value = (() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                (parseInt(price[current_type]) - 200) *
                parseInt(mobile_no_of_people)
              );
            } else {
              return (
                parseInt(price[current_type]) * parseInt(mobile_no_of_people)
              );
            }
          })();
          f_r_contact.current.value = mobile_contact;
          f_r_email.current.value = mobile_email;
          f_r_first_name.current.value = mobile_name;
          f_r_hash.current.value = res.data.hash;
          f_r_key.current.value = res.data.key;
          f_r_p_info.current.value = "rafting";
          f_r_txnid.current.value = res.data.txnid;
          f_r_form.current.submit();
        });
    }
  };

  const getSlotsDesktop = () => {
    if (choosen_date == "") {
    } else {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/get-slots", {
          date: choosen_date,
          km:
            current_type == 1
              ? "09"
              : current_type == 2 || current_type == 3
              ? "12"
              : current_type == 4
              ? "16"
              : current_type == 5
              ? "26"
              : "36",
        })
        .then((res) => {
          setSlots(res.data);
        });
    }
  };

  const getSlotsMobiles = () => {
    if (mobile_date == "") {
    } else {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/get-slots", {
          date: mobile_date,
          km:
            current_type == 1
              ? "09"
              : current_type == 2 || current_type == 3
              ? "12"
              : current_type == 4
              ? "16"
              : current_type == 5
              ? "26"
              : "36",
        })
        .then((res) => {
          // alert(res.data);
          setMobile_slot(res.data);
        })
        .catch((err) => {
          // alert(err);
        });
    }
  };

  useEffect(() => {
    if (mobile_date != "") {
      getSlotsMobiles();
    }
  }, [current_type, mobile_date]);

  useEffect(() => {
    if (choosen_date != "") {
      getSlotsDesktop();
    }
  }, [choosen_date, current_type]);

  const getSlotsMobile = () => {
    // alert("mobile slots");
    if (mobile_date == "") {
    } else {
      axios
        .post("https://api.thrillthirsty.com/activities/rafting/get-slots", {
          date: "2024-12-03",
          km:
            current_type == 1
              ? "09"
              : current_type == 2 || current_type == 3
              ? "12"
              : current_type == 4
              ? "16"
              : current_type == 5
              ? "26"
              : "36",
        })
        .then((res) => {
          setSlots(res.data);
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  useEffect(() => {
    if (mobile_date != "") {
      getSlotsMobile();
    }
  }, [mobile_date, current_type]);

  const desktop_form_rafting = useRef(null);

  // this use effect will open the form after 15 sec
  useEffect(() => {
    const timer = setTimeout(() => {
      setForm(true);
    }, 15000); // 15 seconds in milliseconds

    // Cleanup function to clear the timeout in case component unmounts before 15 seconds
    return () => clearTimeout(timer);
  }, []);

  // scroll function here
  const containerRef1 = useRef(null);
  const [isDragging1, setIsDragging1] = useState(false);
  const [dragStartX1, setDragStartX1] = useState(0);
  const [scrollStartX1, setScrollStartX1] = useState(0);

  const handleMouseDown1 = (e) => {
    setIsDragging1(true);
    setDragStartX1(e.clientX);
    setScrollStartX1(containerRef1.current.scrollLeft);
  };

  const handleMouseMove1 = (e) => {
    if (!isDragging1) return;
    const dx = e.clientX - dragStartX1;
    containerRef1.current.scrollLeft = scrollStartX1 - dx;
  };

  const handleMouseUp1 = () => {
    setIsDragging1(false);
  };

  //  2nd
  const containerRef2 = useRef(null);
  const [isDragging2, setIsDragging2] = useState(false);
  const [dragStartX2, setDragStartX2] = useState(0);
  const [scrollStartX2, setScrollStartX2] = useState(0);

  const handleMouseDown2 = (e) => {
    setIsDragging2(true);
    setDragStartX2(e.clientX);
    setScrollStartX2(containerRef2.current.scrollLeft);
  };

  const handleMouseMove2 = (e) => {
    if (!isDragging2) return;
    const dx = e.clientX - dragStartX2;
    containerRef2.current.scrollLeft = scrollStartX2 - dx;
  };

  const handleMouseUp2 = () => {
    setIsDragging2(false);
  };

  // 3rd
  const containerRef3 = useRef(null);
  const [isDragging3, setIsDragging3] = useState(false);
  const [dragStartX3, setDragStartX3] = useState(0);
  const [scrollStartX3, setScrollStartX3] = useState(0);

  const handleMouseDown3 = (e) => {
    setIsDragging3(true);
    setDragStartX3(e.clientX);
    setScrollStartX3(containerRef3.current.scrollLeft);
  };

  const handleMouseMove3 = (e) => {
    if (!isDragging3) return;
    const dx = e.clientX - dragStartX3;
    containerRef3.current.scrollLeft = scrollStartX3 - dx;
  };

  const handleMouseUp3 = () => {
    setIsDragging3(false);
  };

  // 4th
  const containerRef4 = useRef(null);
  const [isDragging4, setIsDragging4] = useState(false);
  const [dragStartX4, setDragStartX4] = useState(0);
  const [scrollStartX4, setScrollStartX4] = useState(0);

  const handleMouseDown4 = (e) => {
    setIsDragging4(true);
    setDragStartX4(e.clientX);
    setScrollStartX4(containerRef4.current.scrollLeft);
  };

  const handleMouseMove4 = (e) => {
    if (!isDragging4) return;
    const dx = e.clientX - dragStartX4;
    containerRef4.current.scrollLeft = scrollStartX4 - dx;
  };

  const handleMouseUp4 = () => {
    setIsDragging4(false);
  };

  useEffect(() => {
    if (isWeekend(choosen_date)) {
      setWeekEnd(true);
    } else {
      setWeekEnd(false);
    }
  }, [choosen_date]);

  useEffect(() => {
    if (isWeekend(mobile_date)) {
      setWeekEnd(true);
    } else {
      setWeekEnd(false);
    }
  }, [mobile_date]);
  return (
    <>
      <CallWhattsaap />

      <div className="discount-layer">
        16 km, 26 km, and 36 km rafting canceled due to high water levels.
      </div>

      <div className="rafting-desktop-section">
        <Helmet>
          <title>
            Thrill Thirsty: River Rafting in Rishikesh | Starting @499 only
          </title>
          <meta
            name="description"
            content="Thrilling river rafting adventures in Rishikesh with Thrill Thirsty. Expert guides, safe journeys, and unforgettable memories await. Book your adventure now!"
          />
        </Helmet>
        <Header active={"rafting"} />
        <div className="rafting-ds-section1">
          <div className="rafting-ds-section1-bottom-dark">
            <h1 className="rds-sbd-title">River Rafting In Rishikesh</h1>
            <div className="rds-sbd-description">
              River Rafting in Rishikesh is one of the best activities of
              summers
            </div>
          </div>
          <div className="rafting-ds-section1-right-dark">
            <div className="rdss1-r-div">
              <div className="rdss1-r-d-inner">
                <div
                  className="rdss1-r-d-div1"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={require("../Public/rafting/r2.jpg")}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div2"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={require("../Public/rafting/r3.jpg")}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div3"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={require("../Public/rafting/r6.jpg")}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div4"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={require("../Public/rafting/r7.jpg")}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                  <div className="rdss1-r-d-div-4-dark">+20</div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={require("../Public/rafting/r14.jpg")}
            alt=""
            className="rafting-ds-section1-img"
            loading="lazy"
          />
        </div>

        <div className="rafting-desktop-body">
          <div className="rafting-desktop-body-1">
            <div className="rafting-type-section">
              <IndiType
                type={"09 km"}
                active={current_type == 1 ? true : false}
                select={() => {
                  setCurrent_type(1);
                }}
              />
              <IndiType
                type={"12 km"}
                active={current_type == 2 ? true : false}
                select={() => {
                  setCurrent_type(2);
                }}
              />
              {/* <IndiType
                type={"12 km"}
                active={current_type == 3 ? true : false}
                select={() => {
                  setCurrent_type(3);
                }}
              /> */}
              {/* <IndiType
                type={"16 km"}
                active={current_type == 4 ? true : false}
                select={() => {
                  setCurrent_type(4);
                }}
              />
              <IndiType
                type={"26 km"}
                active={current_type == 5 ? true : false}
                select={() => {
                  setCurrent_type(5);
                }}
              />
              <IndiType
                type={"36 km"}
                active={current_type == 6 ? true : false}
                select={() => {
                  setCurrent_type(6);
                }}
              /> */}
            </div>
            {/* <div className="rafting-where-where-section"> */}
            {/* <div
                className="rafting-where-where-section-inner"
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                {where[current_type].map((item, index) => {
                  return (
                    <IndiWhere
                      img={where[current_type][index].img}
                      tag={where[current_type][index].tag}
                      title={where[current_type][index].title}
                    />
                  );
                })}

                <div className="rww-empty-space"></div>
              </div> */}
            {/* <div className="rafting-where-where-dark">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="rww-svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      className="fill-orange"
                    ></path>{" "}
                  </g>
                </svg>
              </div> */}
            {/* </div> */}
            <TitleDesktop
              title={"Some of our memories"}
              description={"These are some of our memories"}
              more={false}
            />

            <div className="desktop-status-whole">
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/rafting/r1.jpg")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/rafting/r2.jpg")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/rafting/r3.jpg")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/rafting/r4.jpg")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/rafting/r5.jpg")}
              />
            </div>

            <div className="damw-outer">
              <div className="desktop-activity-menu-whole">
                <div className="damw-1">
                  <div className="damw-title">River Rafting</div>
                  <div className="damw-rating-div">
                    <div className="damw-svg-div">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="damw-svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M9.15316 5.40838C10.4198 3.13613 11.0531 2 12 2C12.9469 2 13.5802 3.13612 14.8468 5.40837L15.1745 5.99623C15.5345 6.64193 15.7144 6.96479 15.9951 7.17781C16.2757 7.39083 16.6251 7.4699 17.3241 7.62805L17.9605 7.77203C20.4201 8.32856 21.65 8.60682 21.9426 9.54773C22.2352 10.4886 21.3968 11.4691 19.7199 13.4299L19.2861 13.9372C18.8096 14.4944 18.5713 14.773 18.4641 15.1177C18.357 15.4624 18.393 15.8341 18.465 16.5776L18.5306 17.2544C18.7841 19.8706 18.9109 21.1787 18.1449 21.7602C17.3788 22.3417 16.2273 21.8115 13.9243 20.7512L13.3285 20.4768C12.6741 20.1755 12.3469 20.0248 12 20.0248C11.6531 20.0248 11.3259 20.1755 10.6715 20.4768L10.0757 20.7512C7.77268 21.8115 6.62118 22.3417 5.85515 21.7602C5.08912 21.1787 5.21588 19.8706 5.4694 17.2544L5.53498 16.5776C5.60703 15.8341 5.64305 15.4624 5.53586 15.1177C5.42868 14.773 5.19043 14.4944 4.71392 13.9372L4.2801 13.4299C2.60325 11.4691 1.76482 10.4886 2.05742 9.54773C2.35002 8.60682 3.57986 8.32856 6.03954 7.77203L6.67589 7.62805C7.37485 7.4699 7.72433 7.39083 8.00494 7.17781C8.28555 6.96479 8.46553 6.64194 8.82547 5.99623L9.15316 5.40838Z"
                            className="fill-orange"
                          ></path>{" "}
                        </g>
                      </svg>
                    </div>
                    <div className="damw-rating-text">4.8</div>
                  </div>
                </div>
                <div
                  className="damw-2"
                  ref={containerRef1}
                  onMouseDown={handleMouseDown1}
                  onMouseMove={handleMouseMove1}
                  onMouseUp={handleMouseUp1}
                  onMouseLeave={handleMouseUp1}
                >
                  {menu_desktop_content.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setCurrent_menu_option(item.tag);
                          let t =
                            item.ref.current.getBoundingClientRect().top +
                            window.pageYOffset;
                          let scrollToPosition = t - 200;
                          window.scrollTo({
                            top: scrollToPosition,
                            behavior: "smooth",
                          });
                          console.log(item.ref);
                          // console.log(content_ttdw);
                        }}
                        className={
                          item.tag == curent_menu_option
                            ? "damw-indi-section-active"
                            : "damw-indi-section"
                        }
                      >
                        {item.content}
                      </div>
                    );
                  })}
                  <div></div>
                </div>
              </div>
            </div>

            {/* this is about */}

            <DesktopContentTitle
              title={"River Rafting in Rishikesh"}
              // description={"ye nhi kra to kya kra"}
              more={false}
              id="about"
            />

            <div className="rafting-desktop-about-content" ref={aboutRef}>
              Rishikesh is known as the “Adventure Capital of India” because of
              its variety of adventure activities like bungee jumping, camping,
              river rafting, and much more. People come to Rishikesh from
              different counties to experience the best river rafting. Apart
              from adventure, Rishikesh is rich in spiritual and natural beauty.
              It's one of the best travel destinations. River Rafting in
              Rishikesh is also known as the safest river rafting (with
              adventure) in the world because there is a flate section (rescue
              section) available after every rapid. So the river guide had the
              time to rescue the team. The serene beauty of the Ganges and
              Himalayas makes this river rafting journey more beautiful.
              Kudiyala to Muni ki Reti Zone is known as the adventure hub of
              Rishikesh because all the adventure activities are located in this
              location. Shipuri is known as the birthplace of River Rafting
              Rishikesh because the first commercial river rafting trip was
              started there. Shivpuri is the most popular starting point from
              where 16 km of rafting start (Shivpuri to Rishikesh), and the
              oldest and most trusted whitewater rafting school is located at
              Shivpuri.
            </div>

            {/* this is itenery */}
            <DesktopContentTitle
              title={"Itinerary"}
              more={false}
              id={"content-itinerary"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_itineraryRef}
            >
              <ul>
                <li>Reporting to our Office on your reporting time</li>
                <li>Filling consult form</li>
                <li>Going to the Starting point</li>
                <li>
                  Gearing up - wearing jacket, helmet, and holding paddle.
                </li>
                <li>
                  Safety brief: 10-20 minute brief on how to do peddling,
                  rescue, and other safety instructions.
                </li>
                <li>Activity: Start of Rafting</li>
                <li>
                  Back to Office: After completing your rafting ride we will
                  take you back to our office.
                </li>
                <li>
                  Changing: you can change your clothes in the changing room
                </li>
                <li>
                  Videos/Photos: you can take your photos/video on addition
                  charges by our river guide
                </li>
              </ul>
            </div>

            {/* elegebility */}

            <DesktopContentTitle
              title={"Eligibility Criteria for River Rafting Rishikesh"}
              more={false}
              id={"content-ecfrrr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_ecfrrrRef}
            >
              <ul>
                <li>The minimum age is 14 (12 for the 9KM sections).</li>
                <li>
                  Max Age: 60 (65 got 9 KM section)Since 2022 Disabled persons
                  also can go for river rafting in Rishiksh with proper support
                  and guidance. Before 2022 there was not any policy for
                  disabled person.
                </li>
                <li>Min Weight: 40 KM</li>
                <li>Max Weight: 100–110 kg</li>
                <li>Alchol drinking is not allowed.</li>
                <li>
                  Asthmatics, pregnant women, recent heart surgery, and people
                  with serious medical conditions are not permitted.
                </li>
                <li>
                  Since 2022, disabled people can also go river rafting in
                  Rishikesh with proper support and guidance. Before 2022, there
                  was no policy for disabled people.
                </li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Many people try to go rafting with their babies aged 5–10 years,
              but you need to understand it’s not safe. That’s why Uttrakhand
              tourism has a proper minimum and maximum age for safety.
            </div>

            <DesktopContentTitle
              title={"Rishikesh Rafting Safety Measures"}
              more={false}
              id={"content-rrsm"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_rrseRef}
            >
              Safety is most important when it comes to river rafting, and
              Raftinggo takes this aspect seriously. Here are the detailed
              guidelines and precautions in place to ensure a secure and
              thrilling River Rafting experience in Rishikesh:
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Quality Equipment</li>
                <li>Expert Guides</li>
                <li>Firstaid course</li>
                <li>Rescue course</li>
                <li>Swimming</li>
                <li>CPR Course</li>
                <li>River Reading, river lines, and rapids</li>
                <li>Pre-Departure Briefing</li>
                <li>Flip and rescue</li>
                <li>Life Jackets at all Times</li>
                <li>Weather Monitoring</li>
                <li>First Aid and Medical Support</li>
                <li>Age and Health Restrictions</li>
              </ul>
            </div>
            {/* advantages */}

            <DesktopContentTitle
              title={"Advantages"}
              more={false}
              id={"content-advantages"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_advantagesRef}
            >
              <ul>
                <li>
                  Provides a holistic adventure experience combining the
                  tranquility of camping with the thrill of rafting.
                </li>
                <li>
                  Offers a chance to connect with nature in a serene setting,
                  away from the hustle and bustle of city life.
                </li>
                <li>
                  Expertly curated packages cater to both beginners, providing a
                  gentle introduction, and experienced rafters, seeking the
                  ultimate challenge.
                </li>
              </ul>
            </div>

            {/* inclusion */}
            <DesktopContentTitle
              title={"Inclusions"}
              more={false}
              id={"content-inclusions"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_inclusionsRef}
            >
              <ul>
                <li>Equipment (Jacket, Helmet, Peddel)</li>
                <li>Professional Guide</li>
                <li>Office to Office Transport</li>
                <li>Safety Kayaker (only for 36KM)</li>
              </ul>
            </div>

            {/* exclusion */}
            <DesktopContentTitle
              title={"Exclusions"}
              more={false}
              id={"content-exclusions"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_exclusionsRef}
            >
              <ul>
                <li>Meals</li>
                <li>
                  Video Recording (You can take it by river guide on addition
                  charges)
                </li>
              </ul>
            </div>

            {/* varied rapids */}
            <DesktopContentTitle
              title={"Various Rapids for All Levels"}
              more={false}
              id={"content-vrfal"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_vroalRef}
            >
              Whether you're a beginner and looking for a baby rapid of Grade I
              or you and a true adventure enthusiast are looking for Grand IV
              Rapid Ganges, you have it all. You will get these rapids on
              different routes. Some of the grade I-II rapids are Black Money
              Rapid,Sweet Sixteen Rapid,Good Morning Rapid, and Body Surfing,
              and Grade III-IV rapids are Three Blind Mice Rapid, The Great
              Wall, and Golf Cours. You can choose any route that suits you.
            </div>

            {/* classification of rapids */}

            <DesktopContentTitle
              title={"Classification of Rapids"}
              more={false}
              id={"content-cor"}
            />
            <div className="rafting-desktop-about-content" ref={content_corRef}>
              To make the most of your rafting adventure, it's essential to
              understand the different classes of rapids that you'll encounter
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  Class I (Easy): Gentle, slow-moving water with small,
                  easy-to-navigate waves. Perfect for beginners
                </li>
                <li>
                  Class II (Novice): Slightly more challenging than Class I,
                  with moderate waves and maneuvering required. This level is
                  suitable for those with some rafting experience or beginners
                  looking for a bit more excitement.
                </li>
                <li>
                  Class III (Intermediate): Rapids with strong currents, larger
                  waves, and potential obstacles. This level requires good
                  teamwork and paddling skills. It's an exhilarating experience
                  for those seeking a thrilling adventure.
                </li>
                <li>
                  Class IV (Advanced): Intense rapids with powerful waves,
                  turbulent water, and complex maneuvering. This level demands
                  precise paddling and a strong teamwork ethic. It's ideal for
                  experienced rafters seeking a true adrenaline rush
                </li>
                <li>
                  Class V (Expert): Extremely challenging rapids with very
                  turbulent water, large waves, and numerous obstacles. This
                  level demands expert paddling skills, strength, and a high
                  level of physical fitness. It's reserved for seasoned rafters
                  seeking the ultimate white-water challenge.
                </li>
                <li>
                  Class VI (Extreme): These rapids are considered extremely
                  dangerous and are not recommended for commercial rafting due
                  to their unpredictable and potentially life-threatening
                  nature.
                </li>
              </ul>
            </div>

            <div className="rafting-desktop-about-content">
              Note: After Grade VI all rapids are considers as falls.
            </div>

            {/* rapids */}

            <DesktopContentTitle
              title={"Rafting in Rishikesh Rapids"}
              more={false}
              id={"content-rirr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_rirrRef}
            >
              Here are the all the rapids which you will see while doing rafting
              in rishikesh.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  Deniel's Dip Rapid (Class III+): This rapid is located at
                  Kaudiyala, 25–30 km from Rishikesh, and has thrills and
                  adventurous waves.
                </li>
                <li>
                  The Black money (Class I): This rapid offers a thrilling
                  combination of fast-paced water and stunning scenery. It's a
                  must-experience for any rafter in Rishikesh.
                </li>
                <li>
                  Good Morning/Balancing Rapid (Class I): In this rapid, rafters
                  can stand on the raft by taking paddles support and try to
                  balance their bodies to avoid falling in the raft or river due
                  to waves. That’s why it's called balancing rapid.
                </li>
                <li>
                  Three Blind Mice (Class III): This is this three-in-one rapd
                  in ganga, which has 3 stages in 1 single rapid and 3 raids in
                  under 50 miters of area. waves and suddenly hit that raft;
                  that’s why it's called "three blind mice". The second mice of
                  this rapid have hidrollic eddy, which is only 1 place in
                  Ganga.
                </li>
                <li>
                  Cross Fire (class III): This is a most beautiful rapids with
                  stunning scenery and also having adventurus.
                </li>
                <li>
                  Body surfing (Class II): In this rapid rafting, you can jump
                  on the river and experience the waves hitting your body;
                  that’s why it's called body surfing. As the class is II, it's
                  totally safe to jump on this rapid.
                </li>
                <li>
                  Shivpuri Rapid (Class III): Located a few kilometers upstream
                  from Rishikesh, this rapid offers an exciting start to your
                  journey. Its swirling currents and challenging waves make it a
                  favorite among adventure seekers.
                </li>
                <li>
                  Return to Sebder (Class II): The waves touch and come back
                  from the mountain, and that’s why the name of the rapid is
                  Return to Sender. This rapid is located in Shivpuri, 15 km
                  from Rishikesh, and Maa Jentapuri temple is located on top of
                  that mountain.
                </li>
                <li>
                  Butterfly (Class I): This small rapid is class I and has
                  little waves in it.
                </li>
                <li>
                  Roller Coaster (Class III+): True to its name, this rapid
                  provides an exhilarating ride with its steep drops and wild
                  waves. Hold on tight and enjoy the rush of excitement.
                </li>
                <li>
                  Tea off (Class I): This is a small wave enjoying rapids that
                  comes just before the golf course.
                </li>
                <li>
                  Golf Course (Class III+): the most famous and adventurous
                  rapid of river rafting in Rishieksh Known for its challenging
                  waves and technical maneuvers, this rapid provides an
                  opportunity for experienced rafters to test their skills and
                  teamwork. with the highest flip chances of 80–95% in heavy
                  volume and 30–50% in low volume.
                </li>
                <li>
                  {" "}
                  Club House (Class III): This rapid comes just after the golf
                  course rapid. A thrilling ride awaits at Club House; the heavy
                  waves make this rapid uniq. It's a favorite among both
                  beginners and experienced rafters.
                </li>
                <li>
                  Initiation (Class II): Perfect for beginners, this rapid
                  provides a gentle introduction to the world of rafting. It's
                  an opportunity to build confidence and get comfortable on the
                  water.
                </li>
                <li>
                  Double Trouble (Class II): As the name suggests, this rapid
                  offers two wallpools between the main line of the river, and
                  you need to go to the exact line; otherwise, you can get stuck
                  or flip, which is called double trouble.
                </li>
                <li>
                  {" "}
                  Ladies Delight (Class II): Named for its relatively calm
                  waters, this rapid provides a scenic and enjoyable stretch for
                  rafters of all levels.
                </li>
                <li>
                  Sweet Sixteen (Class I): A serene section of the river, Sweet
                  Sixteen offers a chance to relax and soak in the stunning
                  surroundings. It's a great interlude between more intense
                  rapids.
                </li>
                <li>Hilton (Class II)</li>
              </ul>
            </div>

            {/* carry */}

            <DesktopContentTitle
              title={"Things to Carry for Rafting in Rishikesh"}
              more={false}
              id={"content-ttcfrir"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_ttcfrirRef}
            >
              To ensure a successful and enjoyable rafting trip, it's essential
              to pack wisely. Here's a comprehensive list of items that should
              be on your checklist:
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  Quick-dry clothing to ensure comfort during and after the
                  rafting adventure.
                </li>
                <li>
                  Sturdy footwear, such as river sandals or water shoes,
                  Slippers.
                </li>
                <li>
                  Sunscreen with a high SPF to protect your skin from the sun's
                  intense rays.
                </li>
                <li>
                  A waterproof camera or a protective case to capture the
                  moments without worrying about water damage or our guide have
                  a Gopro he can give you video/Photo on Addition charges.
                </li>
                <li>One Pair of Extra cloth for changing.</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Note: You can keep your valuables—mobiles, wallets, and
              watches—during the rafting duration. We will provide a common dry
              bag that is water-proof for that (10-liter capacity dry bag). If
              you have more Havy bags, you can keep them at our office.
            </div>

            <DesktopContentTitle
              title={"Things to Do/Ware"}
              more={false}
              id={"content-ttdw"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_ttdwRef}
            >
              <ul>
                <li>
                  Ware light clothes: ware Light and quick-dry material cloths
                </li>
                <li>
                  Ware Jecket: Make sure you ware jecket properly; it should be
                  properly tight.
                </li>
                <li>
                  Ware helmet: a helmet will protect your head from any obstacle
                  or peddle.
                </li>
                <li>
                  Footwares: A flip-flop or slipper is the best option to wear
                  while going river rafting. Make sure your footwear has a good
                  grip.
                </li>
                <li>
                  Proper Peddling: Rafting is teamwork, so you need to do
                  paddling and rest according to the guide's instructions.
                </li>
                <li>
                  Help your fellows back: If somebody falls into the river, you
                  are sure to rescue him or her.
                </li>
                <li>
                  Group Activity: Injot group activities like body surfing,
                  maggie at maggi point, jumping from boats, etc.
                </li>
              </ul>
            </div>

            {/* things not to wear */}
            <DesktopContentTitle
              title={"Things not to do"}
              more={false}
              id={"content-tntd"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_tntdRef}
            >
              <ul>
                <li>
                  Don’t Stand: In the rapid section, don’t try to stand up or do
                  any other activity; just follow the instructions or your river
                  guide. Otherwise, you can fall into the water, which can be
                  uncomfortable for you.
                </li>
                <li>
                  Don’t Drink: Drinking alcohol is not allowed because it can be
                  risky. Also, it's a holy river, so please avoid it.
                </li>
                <li>
                  {" "}
                  Don’t Be Overconfident: Never try to be overconfident and show
                  off your skill.
                </li>
                <li>
                  Asthmatics, pregnant women, and heart patients are not aloow
                  in rafting. Avoid rafting if you have had any recent surgery
                  or operation or if you have any serious medical condition.
                </li>
                <li>
                  Havy Clothes: In winter, you can use wet or swim suits to
                  maintain body temperature instead of havy jackets and sweaters
                  or any kind of warm cloth.
                </li>
                <li>
                  Waring saree or Burkha: Any kind of cloth that is long or open
                  from the bottom is not allowed.
                </li>
              </ul>
            </div>

            <DesktopContentTitle
              title={"Benefits and Features"}
              more={false}
              id={"content-baf"}
            />
            <div className="rafting-desktop-about-content" ref={content_bafRef}>
              <ul>
                <li>
                  Offers a unique blend of adventure and relaxation, allowing
                  you to experience the best of Rishikesh's natural beauty.
                </li>
                <li>
                  The scenic beachside setting provides a serene backdrop for
                  your camping experience, creating memories that will last a
                  lifetime.
                </li>
              </ul>
            </div>

            <DesktopContentTitle
              title={"Spiritual Connection"}
              more={false}
              id={"content-sc"}
            />
            <div className="rafting-desktop-about-content" ref={content_scRef}>
              Rishikesh allows you to spiritually connect while rafting,
              surrounded by the serene ambiance of ashrams (Osho Ganga Dham
              Ashram, Ganga Niwas Swarg Ashram,Sivananda Ashram, Parmarth
              Niketan Ashram, Gita Bhawan), bridges (Ram Jhula Laxmn Jhula and
              Janki Setu), ghats, and temples dotting the riverbanks. While
              rafting, you can get a view of these from the river, which can be
              good to see and capture on camera as a beautiful memory.
            </div>

            <DesktopContentTitle
              title={"Rafting with Adventure Activities Combo"}
              more={false}
              id={"content-rwaac"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_rwaacRef}
            >
              Its not just the Rafting, RAftinggo provides you, but in a rafting
              journey we add some activities like boat jumping, body surfing,
              cliff jumping (Banned in Rishikesh since 2021 due to an acident),
              break at Maggie Point, photo/videography (on additional charges),
              slogans and shouting and balancing with paddles.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  Maximizes your adventure quotient with a blend of activities
                  that cater to the daring spirit within you.
                </li>
                <li>
                  Expert guides ensure safety throughout the experience,
                  allowing you to fully enjoy each activity without worry.
                </li>
                <li>
                  Perfect for thrill-seekers looking to make the most of their
                  trip, ensuring an action-packed adventure from start to
                  finish.
                </li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Camping and Rafting in Rishikesh"}
              more={false}
              id={"content-carir"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_carirRef}
            >
              Camping in Rishikesh is a unique experience. You meet a lot of
              people sitting on the chairs, and music is playing with a bonfire.
              Beach camping is the best and most raw camping experience in
              Rishikesh, which will connect you with nature. Camping is a better
              option than a hotel because of the environment and experience. In
              this camping and rafting combo, we offer 3 time meals, music, a
              bonfire (Nov-Feb only), a 23-hour stay, and rafting. You can
              choose any rafting that suits you better. After camping a whole
              day, the next day, after breakfast, we will take you for river
              rafting.
            </div>

            <DesktopContentTitle
              title={"How to Reach Rishikesh?"}
              more={false}
              id={"content-htrr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_htrrRef}
            >
              Planning your journey to Rishikesh? Here are valuable tips on
              transportation options, whether you're traveling by air, train, or
              road:
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  By Air: The nearest airport to Rishikesh is the Jolly Grant
                  Airport in Dehradun, approximately 20-25 kilometers away. From
                  the airport, you can hire a taxi or use public transport to
                  reach Rishikesh.
                </li>
                <li>
                  By Train: Rishikesh has its own railway station; there are two
                  railway stations in Rishikesh (Rishikesh and Yog Nagari
                  Rishikesh) that are well connected to major cities in India.
                  From the station, you can easily find taxis or autorickshaws
                  to take you to your destination.
                </li>
                <li>
                  By Road: Rishikesh is well-connected by road to major cities
                  in North India. You can opt for private or state-run buses, or
                  even hire a taxi for a more comfortable journey. Mostly privet
                  buses take you to the nepalifar which is 12KM from Rishieksh
                  or you can take bus which is going directly to ISBT
                  Rishikesh/Bus adda Rishikesh.
                </li>
                <li>
                  Local Transportation: Once in Rishikesh, getting around is
                  relatively easy. Auto-rickshaws, E-rickshawa,taxis, buses and
                  rented motorcycles/car are readily available for local travel.
                </li>
              </ul>
            </div>

            {/* <div className="desktop-content-space"></div> */}

            <DesktopContentTitle
              title={"Beach Camping in Rishikesh with Rafting"}
              more={false}
              id={"content-bcirwr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_bcirwrRef}
            >
              Experience the best of both worlds with a package that combines
              the thrill of rafting with the tranquility of beach camping.
              Imagine falling asleep to the soothing lullaby of the Ganges, the
              sound of water gently lapping against the shore. Wake up
              refreshed, ready for another day of adrenaline-fueled adventure on
              the river.
            </div>

            <DesktopContentTitle
              title={"Camping in Rishikesh with Rafting"}
              more={false}
              id={"content-cirwr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_cirwrRef}
            >
              Delve into the unique experiences offered by camping in Rishikesh
              combined with an adrenaline-pumping rafting adventure. This
              package ensures that you get the best of both worlds, with the
              serenity of nature and the thrill of the rapids. Imagine sitting
              by the campfire under a star-studded sky, recounting the day's
              adventures with fellow travelers.
            </div>
            <DesktopContentTitle
              title={"Unique Experiences:"}
              more={false}
              id={"content-ue"}
            />
            <div className="rafting-desktop-about-content" ref={content_ueRef}>
              <ul>
                <li>
                  Allows you to reconnect with nature in a tranquil camping
                  setting, far from the hustle and bustle of city life.
                </li>
                <li>
                  Pairs perfectly with the adrenaline rush of rafting for an
                  unforgettable adventure that balances excitement with moments
                  of peace and reflection.
                </li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"People Also Ask About Rishikesh"}
              more={false}
              id={"content-paaar"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_paaarRef}
            >
              <ul>
                <li>What are the best months for rafting in Rishikesh?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              The best months for River rafting in Rishikesh are mid-September -
              November and mid-February to the end of June. you will get a good
              water level in the months of September, October, May, and June
              which will give you the best adventurous experience(Due to the
              strong rapids). and Nov-April you will get the cleanest/most
              transparent water.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Are there age restrictions for rafting in Rishikesh?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              The minimum age is 14 for the 12+ section and for the 9KM section,
              the min age is 12 Years. The maximum age is 60 for the 12KM+
              section and for 9KM 65 is the maximum age.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  What safety measures are in place for rafting in Rishikesh?
                </li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Rishikesh takes safety seriously. All rafting equipment undergoes
              regular maintenance and quality checks. Expert guides accompany
              each expedition, well-versed in river dynamics and rescue
              techniques. A thorough pre-departure briefing is conducted, and a
              well-defined emergency response plan is in place. a person will
              only get the river guide license after he passes the guide test
              conducted by the UTDB team.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Can non-swimmers participate in rafting in Rishikesh?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Absolutely! Wearing a life jacket is mandatory for all
              participants, regardless of their swimming abilities. This ensures
              that even non-swimmers can enjoy the adventure with confidence,
              knowing that they have the necessary safety equipment. this PFD
              (life jacket) is specially designed for non-swimmers.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  What are the nearby attractions in Rishikesh for
                  non-adventurous travelers?
                </li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Rishikesh offers a plethora of attractions for those seeking a
              more relaxed experience. Explore the numerous ashrams (Parmarth
              Niketan, Nirmal Ashram, The Beatles Ashram, etc) and yoga centers,
              visit the iconic Triveni Ghat for a spiritual experience, or take
              a leisurely stroll across the Laxman Jhula, Janki Setu, and Ram
              Jhula suspension bridges for breathtaking views of the Ganges.
              Don’t miss the Ganga arti at Triveni ghat, Parmarth Niketan, and
              Strugan ghat in the morning and evening time. Kyarki is the best
              sunset point. you can also visit waterfalls like Neer Waterfall,
              Patna Waterfall, and some other hidden waterfalls.
            </div>

            <div className="rafting-content-space"></div>
          </div>
          <div className="rafting-desktop-body-2">
            {/* here will be our form */}
            <div className="backdiv" ref={desktop_form_rafting}>
              <div className="head_background_color">
                <div className="form_heading">
                  <sapn className={"color-orange"}>
                    INR {price[current_type]}
                  </sapn>
                  /person
                </div>
                <div className="form_km_rapid1">
                  <span ref={blink_begin}>{se[current_type].start}</span>
                  {/* <span className="form-km-space">·</span> */}
                  <span className="fkm_form"></span>
                  <span className="fkm_form"></span>
                  to
                  <span className="fkm_form"></span>
                  <span className="fkm_form"></span>
                  <span ref={blink_last}>{se[current_type].end}</span>
                  <span className="fkm_form"></span>
                </div>
                <div className="form_km_rapid2">
                  <span ref={kms_blink}> {km[current_type]} </span>{" "}
                  <span className="fkm_form"></span> km
                  <span className="form-km-space">·</span>
                  <span ref={rapids_blink}> {rapids[current_type]} </span>
                  <span className="fkm_form"></span>
                  rapids
                </div>
              </div>
              <div className="form_fill_heading">Fill your details</div>
              <div className="form_discription">
                Need instant rafting? Dial
                <span className="form_no_color">+91-9084040280</span>
              </div>
              <TextInput
                title={"Name"}
                placeholder={"Enter your name"}
                type={"text"}
                set={setformName}
                get={formname}
              />
              <TextInput
                title={"Contact"}
                placeholder={"Enter your contact"}
                type={"number"}
                set={setForm_contact}
                get={form_contact}
              />
              <TextInput
                title={"Email"}
                placeholder={"Enter your Email"}
                type={"email"}
                set={setForm_email}
                get={form_email}
              />
              <div className="form_name_text">Rafting Distance</div>
              <div
                className="distances_div"
                ref={containerRef2}
                onMouseDown={handleMouseDown2}
                onMouseMove={handleMouseMove2}
                onMouseUp={handleMouseUp2}
                onMouseLeave={handleMouseUp2}
              >
                <button
                  className={
                    current_type == 1 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(1);
                  }}
                >
                  09 km
                </button>
                <button
                  className={
                    current_type == 2 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(2);
                  }}
                >
                  12 km
                </button>
                {/* <button
                  className={
                    current_type == 3 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(3);
                  }}
                >
                  12 km
                </button> */}
                {/* <button
                  className={
                    current_type == 4 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(4);
                  }}
                >
                  16 km
                </button>
                <button
                  className={
                    current_type == 5 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(5);
                  }}
                >
                  26 km
                </button>
                <button
                  className={
                    current_type == 6 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(6);
                  }}
                >
                  36 km
                </button> */}
              </div>
              <InputDate
                title={"Select Day"}
                placeholder={"Choose a date"}
                forhtml={"desktop_date"}
                setChoosen_date={setChoosen_date}
                choosen_date={choosen_date}
              />
              {choosen_date != undefined && choosen_date != "" ? (
                <>
                  <div className="form_name_text">
                    Slots Left: {choosen_date}
                  </div>
                  <div
                    className="distances_div"
                    ref={containerRef3}
                    onMouseDown={handleMouseDown3}
                    onMouseMove={handleMouseMove3}
                    onMouseUp={handleMouseUp3}
                    onMouseLeave={handleMouseUp3}
                  >
                    {slots.map((item, index) => {
                      return (
                        <button
                          className={
                            choosen_slot == index
                              ? "options_time_color_div"
                              : "options_time_div"
                          }
                          onClick={() => {
                            setChoosen_slot(index);
                          }}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                </>
              ) : null}
              <div className="number_viv">
                <div className="form_name_text">Total People</div>
                <div
                  className="form_chose_candidate"
                  ref={containerRef4}
                  onMouseDown={handleMouseDown4}
                  onMouseMove={handleMouseMove4}
                  onMouseUp={handleMouseUp4}
                  onMouseLeave={handleMouseUp4}
                >
                  <input
                    type="number"
                    className="formt_number_input"
                    placeholder="Choose another day"
                    onInput={(e) => {
                      setNo_people(e.target.value);
                    }}
                    value={no_people}
                  />
                  <button
                    className={
                      no_people >= 1 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(1);
                    }}
                  >
                    1
                  </button>
                  <button
                    className={
                      no_people >= 2 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(2);
                    }}
                  >
                    2
                  </button>
                  <button
                    className={
                      no_people >= 3 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(3);
                    }}
                  >
                    3
                  </button>
                  <button
                    className={
                      no_people >= 4 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(4);
                    }}
                  >
                    4
                  </button>
                  <button
                    className={
                      no_people >= 5 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(5);
                    }}
                  >
                    5
                  </button>
                  <button
                    className={
                      no_people >= 6 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(6);
                    }}
                  >
                    6
                  </button>
                  <button
                    className={
                      no_people >= 7 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(7);
                    }}
                  >
                    7
                  </button>
                  <button
                    className={
                      no_people >= 8 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(8);
                    }}
                  >
                    8
                  </button>
                </div>
              </div>
              <div className="form_blue_div">
                {km[current_type]} km rafting ( ₹{" "}
                {(() => {
                  if (current_type == 2 && weekEnd == false) {
                    return <>{parseInt(price[current_type]) - 200}</>;
                  } else {
                    return <>{parseInt(price[current_type])}</>;
                  }
                })()}{" "}
                ) x {no_people} adults = ₹{" "}
                {(() => {
                  if (current_type == 2 && weekEnd == false) {
                    return (
                      <>{(parseInt(price[current_type]) - 200) * no_people}</>
                    );
                  } else {
                    return <>{parseInt(price[current_type]) * no_people}</>;
                  }
                })()}
              </div>
              <div className="form_last_discription">
                Need instant rafting? Dial <pre> </pre>
                <span className="form_no_color"> +91-9084040280</span>
              </div>
              <div className="form_last_proceed_div" onClick={submitAdvance}>
                {/* Pay INR{" "}
                {(
                  (parseInt(price[current_type] * no_people) * 20) /
                  100
                ).toLocaleString()}{" "}
                (As advance) */}

                {(() => {
                  if (current_type == 2 && weekEnd == false) {
                    return (
                      <>
                        {"Pay INR "}
                        {(parseInt(price[current_type]) - 200) *
                          ((parseInt(no_people) * 20) / 100)}
                        {" (As advance)"}
                      </>
                    );
                  } else {
                    return (
                      "Pay INR " +
                      (parseInt(price[current_type]) *
                        parseInt(no_people) *
                        20) /
                        100 +
                      " (As advance)"
                    );
                  }
                })()}
              </div>
              <div className="flpd-or-text">
                <div className="flpd-or-1"></div>
                <div className="flpd-or-or">or</div>
                <div className="flpd-or-2"></div>
              </div>
              <div className="form_last_proceed_div" onClick={submitFull}>
                {(() => {
                  if (current_type == 2 && weekEnd == false) {
                    return (
                      <>
                        {"Pay INR "}
                        {(parseInt(price[current_type]) - 200) * no_people}
                        {" (Full)"}
                      </>
                    );
                  } else {
                    return (
                      "Pay INR " +
                      parseInt(price[current_type]) * parseInt(no_people) +
                      " (Full)"
                    );
                  }
                })()}
              </div>

              {current_type == 2 && weekEnd == false ? (
                <div
                  className="color-blue "
                  style={{
                    width: "100%",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "16px",
                    // fontWeight: "600",
                    marginBottom: "20px",
                  }}
                >
                  Flat ₹200 off on weekdays
                </div>
              ) : null}
            </div>

            <div className="after-form">
              <AfterForm
                title={"River rafting at just"}
                price={"500"}
                goto={desktop_form_rafting}
              />
              <BigGroup activity={"rafting"} />
            </div>
            {/* form ends here */}
          </div>
          <div></div>
        </div>

        {desktop_photo ? (
          <div className="rafting-desktop-image-section">
            <Photo_section_desktop
              setDesktop_photo={setDesktop_photo}
              photos={[
                require("../Public/rafting/r1.jpg"),
                require("../Public/rafting/r2.jpg"),
                require("../Public/rafting/r3.jpg"),
                require("../Public/rafting/r4.jpg"),
                require("../Public/rafting/r5.jpg"),
                require("../Public/rafting/r6.jpg"),
                require("../Public/rafting/r7.jpg"),
                require("../Public/rafting/r8.jpg"),
                require("../Public/rafting/r9.jpg"),
                require("../Public/rafting/r10.jpg"),
                require("../Public/rafting/r11.jpg"),
                require("../Public/rafting/r12.jpg"),
              ]}
            />
          </div>
        ) : null}

        {desktop_status ? (
          <div className="rafting-desktop-image-section">
            <Status_section
              desktop_status={desktop_status}
              setDesktop_status={setDesktop_status}
              desktop_status_indi={desktop_status_indi}
            />
          </div>
        ) : null}
      </div>

      <form
        action="https://secure.payu.in/_payment"
        // action="https://test.payu.in/_payment"
        ref={f_r_form}
        method="post"
        className="hidden-form-rafting"
      >
        <input type="hidden" ref={f_r_key} name="key" value="heRjXi" />
        <input
          type="hidden"
          ref={f_r_txnid}
          name="txnid"
          value="t6svtqtjRdl4ws1"
        />
        <input
          type="hidden"
          ref={f_r_p_info}
          name="productinfo"
          value="iPhone"
        />
        <input type="hidden" ref={f_r_amount} name="amount" value="10" />
        <input
          type="hidden"
          ref={f_r_email}
          name="email"
          value="test@gmail.com"
        />
        <input
          type="hidden"
          ref={f_r_first_name}
          name="firstname"
          value="Ashish"
        />
        <input
          type="hidden"
          name="surl"
          value="https://api.thrillthirsty.com/pay/sucess"
        />
        <input
          type="hidden"
          name="furl"
          value="https://api.thrillthirsty.com/fail"
        />
        <input
          type="hidden"
          name="phone"
          ref={f_r_contact}
          value="9988776655"
        />
        <input type="hidden" name="hash" ref={f_r_hash} value="" />
      </form>

      <div className="rafting-mobile-section">
        <HeaderMobile
          setPhotoSection={setPhotoSection}
          img={require("../Public/rafting/r2.jpg")}
          title={"River Rafting in Rishikesh"}
          imgs={[
            require("../Public/rafting/r2.jpg"),
            require("../Public/rafting/r7.jpg"),
            require("../Public/rafting/r8.jpg"),
            require("../Public/rafting/r3.jpg"),
            require("../Public/rafting/r6.jpg"),
          ]}
        />
        <MainTitle
          setForm={setForm}
          price={price[current_type]}
          type={km[current_type]}
          rapids={rapids[current_type]}
          se={se}
          current_type={current_type}
          rapids_blink_mobile={rapids_blink_mobile}
          km_blink_mobile={km_blink_mobile}
          start_blink_mobile={start_blink_mobile}
          end_blink_mobile={end_blink_mobile}
        />
        <div className="rafting-options">
          <Options
            content={"09 km"}
            active={current_type == 1 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={1}
          />
          <Options
            content={"12 km"}
            active={current_type == 2 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={2}
          />
          {/* <Options
            content={"12 km"}
            active={current_type == 3 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={3}
          /> */}
          {/* <Options
            content={"16 km"}
            active={current_type == 4 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={4}
          />
          <Options
            content={"26 km"}
            active={current_type == 5 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={5}
          />
          <Options
            content={"36 km"}
            active={current_type == 6 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={6}
          /> */}
          <div></div>
        </div>
        {/* <div className="activity-timeline">
          {where[current_type].map((item, index) => {
            return (
              <ActivityTimeline
                indicator={where[current_type][index].tag}
                title={where[current_type][index].title}
                img={where[current_type][index].img}
              />
            );
          })}

          <div></div>
        </div> */}
        <Title
          title={"Some of our memories"}
          description={"These are some of our best memories"}
          more={false}
        />

        <div className="activity-status-div">
          <Status
            img={require("../Public/rafting/r1.jpg")}
            content={"9km rafting"}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/rafting/r2.jpg")}
            content={"12km rafting"}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/rafting/r3.jpg")}
            content={"16km rafting"}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/rafting/r4.jpg")}
            content={"26km rafting"}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/rafting/r5.jpg")}
            content={"36km rafting"}
            setStatus={setStatus}
          />
        </div>
        <PageMenu
          menu={menu_mobile_content}
          current_mobile_menu={current_mobile_menu}
          setCurrent_mobile_menu={setCurrent_mobile_menu}
          title={"River Rafting"}
        />
        <TitleWithoutDescription content={"About River Rafting"} />
        <div className="activity-content-section" ref={aboutRefm}>
          Rishikesh allows you to spiritually connect while rafting, surrounded
          by the serene ambiance of ashrams (Osho Ganga Dham Ashram, Ganga Niwas
          Swarg Ashram,Sivananda Ashram, Parmarth Niketan Ashram, Gita Bhawan),
          bridges (Ram Jhula Laxmn Jhula and Janki Setu), ghats, and temples
          dotting the riverbanks. While rafting, you can get a view of these
          from the river, which can be good to see and capture on camera as a
          beautiful memory.
        </div>
        <TitleWithoutDescription content={"Itinerary"} />
        <div
          className="activity-content-section ulli"
          ref={content_itineraryRefm}
        >
          <ul>
            <li>Reporting to our Office on your reporting time</li>
            <li>Filling consult form</li>
            <li>Going to the Starting point</li>
            <li>Gearing up - wearing jacket, helmet, and holding paddle.</li>
            <li>
              Safety brief: 10-20 minute brief on how to do peddling, rescue,
              and other safety instructions.
            </li>
            <li>Activity: Start of Rafting</li>
            <li>
              Back to Office: After completing your rafting ride we will take
              you back to our office.
            </li>
            <li>Changing: you can change your clothes in the changing room</li>
            <li>
              Videos/Photos: you can take your photos/video on addition charges
              by our river guide
            </li>
          </ul>
        </div>
        {/* itinery */}
        <TitleWithoutDescription
          content={"Eligibility Criteria for River Rafting Rishikesh"}
        />
        <div className="activity-content-section ulli" ref={content_ecfrrrRefm}>
          <ul>
            <li>The minimum age is 14 (12 for the 9KM sections).</li>
            <li>
              Max Age: 60 (65 got 9 KM section)Since 2022 Disabled persons also
              can go for river rafting in Rishiksh with proper support and
              guidance. Before 2022 there was not any policy for disabled
              person.
            </li>
            <li>Min Weight: 40 KM</li>
            <li>Max Weight: 100–110 kg</li>
            <li>Alchol drinking is not allowed.</li>
            <li>
              Asthmatics, pregnant women, recent heart surgery, and people with
              serious medical conditions are not permitted.
            </li>
            <li>
              Since 2022, disabled people can also go river rafting in Rishikesh
              with proper support and guidance. Before 2022, there was no policy
              for disabled people.
            </li>
          </ul>
        </div>
        <div className="activity-content-section ">
          Many people try to go rafting with their babies aged 5–10 years, but
          you need to understand it’s not safe. That’s why Uttrakhand tourism
          has a proper minimum and maximum age for safety.
        </div>
        {/* eligibility */}
        <TitleWithoutDescription
          content={"Rishikesh Rafting Safety Measures"}
        />
        <div className="activity-content-section" ref={content_rrseRefm}>
          Safety is most important when it comes to river rafting, and Raftinggo
          takes this aspect seriously. Here are the detailed guidelines and
          precautions in place to ensure a secure and thrilling River Rafting
          experience in Rishikesh:
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Quality Equipment</li>
            <li>Expert Guides</li>
            <li>Firstaid course</li>
            <li>Rescue course</li>
            <li>Swimming</li>
            <li>CPR Course</li>
            <li>River Reading, river lines, and rapids</li>
            <li>Pre-Departure Briefing</li>
            <li>Flip and rescue</li>
            <li>Life Jackets at all Times</li>
            <li>Weather Monitoring</li>
            <li>First Aid and Medical Support</li>
            <li>Age and Health Restrictions</li>
          </ul>
        </div>
        {/* safety */}
        <TitleWithoutDescription content={"Advantages"} />
        <div
          className="activity-content-section ulli"
          ref={content_advantagesRefm}
        >
          <ul>
            <li>
              Provides a holistic adventure experience combining the tranquility
              of camping with the thrill of rafting.
            </li>
            <li>
              Offers a chance to connect with nature in a serene setting, away
              from the hustle and bustle of city life.
            </li>
            <li>
              Expertly curated packages cater to both beginners, providing a
              gentle introduction, and experienced rafters, seeking the ultimate
              challenge.
            </li>
          </ul>
        </div>
        {/* advantages */}
        <TitleWithoutDescription content={"Inclusions"} />
        <div
          className="activity-content-section ulli"
          ref={content_inclusionsRefm}
        >
          <ul>
            <li>Equipment (Jacket, Helmet, Peddel)</li>
            <li>Professional Guide</li>
            <li>Office to Office Transport</li>
            <li>Safety Kayaker (only for 36KM)</li>
          </ul>
        </div>
        {/* inclusion */}
        <TitleWithoutDescription content={"Exclusions"} />
        <div
          className="activity-content-section ulli"
          ref={content_exclusionsRefm}
        >
          <ul>
            <li>Meals</li>
            <li>
              Video Recording (You can take it by river guide on addition
              charges)
            </li>
          </ul>
        </div>
        {/* exclusion */}
        <TitleWithoutDescription content={"Classification of Rapids"} />
        <div className="activity-content-section" ref={content_corRefm}>
          Planning your journey to Rishikesh? Here are valuable tips on
          transportation options, whether you're traveling by air, train, or
          road:
        </div>
        <div className="activity-content-section ">
          To make the most of your rafting adventure, it's essential to
          understand the different classes of rapids that you'll encounter
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              Class I (Easy): Gentle, slow-moving water with small,
              easy-to-navigate waves. Perfect for beginners
            </li>
            <li>
              Class II (Novice): Slightly more challenging than Class I, with
              moderate waves and maneuvering required. This level is suitable
              for those with some rafting experience or beginners looking for a
              bit more excitement.
            </li>
            <li>
              Class III (Intermediate): Rapids with strong currents, larger
              waves, and potential obstacles. This level requires good teamwork
              and paddling skills. It's an exhilarating experience for those
              seeking a thrilling adventure.
            </li>
            <li>
              Class IV (Advanced): Intense rapids with powerful waves, turbulent
              water, and complex maneuvering. This level demands precise
              paddling and a strong teamwork ethic. It's ideal for experienced
              rafters seeking a true adrenaline rush
            </li>
            <li>
              Class V (Expert): Extremely challenging rapids with very turbulent
              water, large waves, and numerous obstacles. This level demands
              expert paddling skills, strength, and a high level of physical
              fitness. It's reserved for seasoned rafters seeking the ultimate
              white-water challenge.
            </li>
            <li>
              Class VI (Extreme): These rapids are considered extremely
              dangerous and are not recommended for commercial rafting due to
              their unpredictable and potentially life-threatening nature.
            </li>
          </ul>
        </div>
        <div className="activity-content-section">
          Note: After Grade VI all rapids are considers as falls.
        </div>
        <TitleWithoutDescription content={"Rafting in Rishikesh Rapids"} />
        <div className="activity-content-section" ref={content_rirrRefm}>
          Here are the all the rapids which you will see while doing rafting in
          rishikesh.
        </div>
        <div className="activity-content-section ">
          To make the most of your rafting adventure, it's essential to
          understand the different classes of rapids that you'll encounter
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              Deniel's Dip Rapid (Class III+): This rapid is located at
              Kaudiyala, 25–30 km from Rishikesh, and has thrills and
              adventurous waves.
            </li>
            <li>
              The Black money (Class I): This rapid offers a thrilling
              combination of fast-paced water and stunning scenery. It's a
              must-experience for any rafter in Rishikesh.
            </li>
            <li>
              Good Morning/Balancing Rapid (Class I): In this rapid, rafters can
              stand on the raft by taking paddles support and try to balance
              their bodies to avoid falling in the raft or river due to waves.
              That’s why it's called balancing rapid.
            </li>
            <li>
              Three Blind Mice (Class III): This is this three-in-one rapd in
              ganga, which has 3 stages in 1 single rapid and 3 raids in under
              50 miters of area. waves and suddenly hit that raft; that’s why
              it's called "three blind mice". The second mice of this rapid have
              hidrollic eddy, which is only 1 place in Ganga.
            </li>
            <li>
              Cross Fire (class III): This is a most beautiful rapids with
              stunning scenery and also having adventurus.
            </li>
            <li>
              Body surfing (Class II): In this rapid rafting, you can jump on
              the river and experience the waves hitting your body; that’s why
              it's called body surfing. As the class is II, it's totally safe to
              jump on this rapid.
            </li>
            <li>
              Shivpuri Rapid (Class III): Located a few kilometers upstream from
              Rishikesh, this rapid offers an exciting start to your journey.
              Its swirling currents and challenging waves make it a favorite
              among adventure seekers.
            </li>
            <li>
              Return to Sebder (Class II): The waves touch and come back from
              the mountain, and that’s why the name of the rapid is Return to
              Sender. This rapid is located in Shivpuri, 15 km from Rishikesh,
              and Maa Jentapuri temple is located on top of that mountain.
            </li>
            <li>
              Butterfly (Class I): This small rapid is class I and has little
              waves in it.
            </li>
            <li>
              Roller Coaster (Class III+): True to its name, this rapid provides
              an exhilarating ride with its steep drops and wild waves. Hold on
              tight and enjoy the rush of excitement.
            </li>
            <li>
              Tea off (Class I): This is a small wave enjoying rapids that comes
              just before the golf course.
            </li>
            <li>
              Golf Course (Class III+): the most famous and adventurous rapid of
              river rafting in Rishieksh Known for its challenging waves and
              technical maneuvers, this rapid provides an opportunity for
              experienced rafters to test their skills and teamwork. with the
              highest flip chances of 80–95% in heavy volume and 30–50% in low
              volume.
            </li>
            <li>
              {" "}
              Club House (Class III): This rapid comes just after the golf
              course rapid. A thrilling ride awaits at Club House; the heavy
              waves make this rapid uniq. It's a favorite among both beginners
              and experienced rafters.
            </li>
            <li>
              Initiation (Class II): Perfect for beginners, this rapid provides
              a gentle introduction to the world of rafting. It's an opportunity
              to build confidence and get comfortable on the water.
            </li>
            <li>
              Double Trouble (Class II): As the name suggests, this rapid offers
              two wallpools between the main line of the river, and you need to
              go to the exact line; otherwise, you can get stuck or flip, which
              is called double trouble.
            </li>
            <li>
              {" "}
              Ladies Delight (Class II): Named for its relatively calm waters,
              this rapid provides a scenic and enjoyable stretch for rafters of
              all levels.
            </li>
            <li>
              Sweet Sixteen (Class I): A serene section of the river, Sweet
              Sixteen offers a chance to relax and soak in the stunning
              surroundings. It's a great interlude between more intense rapids.
            </li>
            <li>Hilton (Class II)</li>
          </ul>
        </div>
        <TitleWithoutDescription
          content={"Things to Carry for Rafting in Rishikesh"}
        />
        <div className="activity-content-section" ref={content_ttcfrirRefm}>
          To ensure a successful and enjoyable rafting trip, it's essential to
          pack wisely. Here's a comprehensive list of items that should be on
          your checklist:
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              Quick-dry clothing to ensure comfort during and after the rafting
              adventure.
            </li>
            <li>
              Sturdy footwear, such as river sandals or water shoes, Slippers.
            </li>
            <li>
              Sunscreen with a high SPF to protect your skin from the sun's
              intense rays.
            </li>
            <li>
              A waterproof camera or a protective case to capture the moments
              without worrying about water damage or our guide have a Gopro he
              can give you video/Photo on Addition charges.
            </li>
            <li>One Pair of Extra cloth for changing.</li>
          </ul>
        </div>
        <div className="activity-content-section">
          Note: You can keep your valuables—mobiles, wallets, and watches—during
          the rafting duration. We will provide a common dry bag that is
          water-proof for that (10-liter capacity dry bag). If you have more
          Havy bags, you can keep them at our office.
        </div>
        <TitleWithoutDescription content={"Things to Do/Ware"} />
        <div className="activity-content-section ulli" ref={content_ttdwRefm}>
          <ul>
            <li>
              Ware light clothes: ware Light and quick-dry material cloths
            </li>
            <li>
              Ware Jecket: Make sure you ware jecket properly; it should be
              properly tight.
            </li>
            <li>
              Ware helmet: a helmet will protect your head from any obstacle or
              peddle.
            </li>
            <li>
              Footwares: A flip-flop or slipper is the best option to wear while
              going river rafting. Make sure your footwear has a good grip.
            </li>
            <li>
              Proper Peddling: Rafting is teamwork, so you need to do paddling
              and rest according to the guide's instructions.
            </li>
            <li>
              Help your fellows back: If somebody falls into the river, you are
              sure to rescue him or her.
            </li>
            <li>
              Group Activity: Injot group activities like body surfing, maggie
              at maggi point, jumping from boats, etc.
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Things not to do"} />
        <div className="activity-content-section ulli" ref={content_tntdRefm}>
          <ul>
            <li>
              Don’t Stand: In the rapid section, don’t try to stand up or do any
              other activity; just follow the instructions or your river guide.
              Otherwise, you can fall into the water, which can be uncomfortable
              for you.
            </li>
            <li>
              Don’t Drink: Drinking alcohol is not allowed because it can be
              risky. Also, it's a holy river, so please avoid it.
            </li>
            <li>
              {" "}
              Don’t Be Overconfident: Never try to be overconfident and show off
              your skill.
            </li>
            <li>
              Asthmatics, pregnant women, and heart patients are not aloow in
              rafting. Avoid rafting if you have had any recent surgery or
              operation or if you have any serious medical condition.
            </li>
            <li>
              Havy Clothes: In winter, you can use wet or swim suits to maintain
              body temperature instead of havy jackets and sweaters or any kind
              of warm cloth.
            </li>
            <li>
              Waring saree or Burkha: Any kind of cloth that is long or open
              from the bottom is not allowed.
            </li>
          </ul>
        </div>
        {/* things not to do */}

        <TitleWithoutDescription content={"Benefits and Features"} />
        <div className="activity-content-section ulli" ref={content_bafRefm}>
          <ul>
            <li>
              Offers a unique blend of adventure and relaxation, allowing you to
              experience the best of Rishikesh's natural beauty.
            </li>
            <li>
              The scenic beachside setting provides a serene backdrop for your
              camping experience, creating memories that will last a lifetime.
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Spiritual Connection"} />
        <div className="activity-content-section" ref={content_scRefm}>
          Rishikesh allows you to spiritually connect while rafting, surrounded
          by the serene ambiance of ashrams (Osho Ganga Dham Ashram, Ganga Niwas
          Swarg Ashram,Sivananda Ashram, Parmarth Niketan Ashram, Gita Bhawan),
          bridges (Ram Jhula Laxmn Jhula and Janki Setu), ghats, and temples
          dotting the riverbanks. While rafting, you can get a view of these
          from the river, which can be good to see and capture on camera as a
          beautiful memory.
        </div>
        <TitleWithoutDescription content={"Various Rapids for All Levels"} />
        <div className="activity-content-section" ref={content_vroalRefm}>
          Whether you're a beginner and looking for a baby rapid of Grade I or
          you and a true adventure enthusiast are looking for Grand IV Rapid
          Ganges, you have it all. You will get these rapids on different
          routes. Some of the grade I-II rapids are Black Money Rapid,Sweet
          Sixteen Rapid,Good Morning Rapid, and Body Surfing, and Grade III-IV
          rapids are Three Blind Mice Rapid, The Great Wall, and Golf Cours. You
          can choose any route that suits you.
        </div>
        <TitleWithoutDescription
          content={"Rafting with Adventure Activities Combo"}
        />
        <div className="activity-content-section" ref={content_rwaacRefm}>
          Its not just the Rafting, RAftinggo provides you, but in a rafting
          journey we add some activities like boat jumping, body surfing, cliff
          jumping (Banned in Rishikesh since 2021 due to an acident), break at
          Maggie Point, photo/videography (on additional charges), slogans and
          shouting and balancing with paddles.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              Maximizes your adventure quotient with a blend of activities that
              cater to the daring spirit within you.
            </li>
            <li>
              Expert guides ensure safety throughout the experience, allowing
              you to fully enjoy each activity without worry.
            </li>
            <li>
              Perfect for thrill-seekers looking to make the most of their trip,
              ensuring an action-packed adventure from start to finish.
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Camping and Rafting in Rishikesh"} />
        <div className="activity-content-section" ref={content_carirRefm}>
          Camping in Rishikesh is a unique experience. You meet a lot of people
          sitting on the chairs, and music is playing with a bonfire. Beach
          camping is the best and most raw camping experience in Rishikesh,
          which will connect you with nature. Camping is a better option than a
          hotel because of the environment and experience. In this camping and
          rafting combo, we offer 3 time meals, music, a bonfire (Nov-Feb only),
          a 23-hour stay, and rafting. You can choose any rafting that suits you
          better. After camping a whole day, the next day, after breakfast, we
          will take you for river rafting.
        </div>

        <TitleWithoutDescription content={"How to Reach Rishikesh?"} />
        <div className="activity-content-section" ref={content_htrrRefm}>
          Planning your journey to Rishikesh? Here are valuable tips on
          transportation options, whether you're traveling by air, train, or
          road:
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              By Air: The nearest airport to Rishikesh is the Jolly Grant
              Airport in Dehradun, approximately 20-25 kilometers away. From the
              airport, you can hire a taxi or use public transport to reach
              Rishikesh.
            </li>
            <li>
              By Train: Rishikesh has its own railway station; there are two
              railway stations in Rishikesh (Rishikesh and Yog Nagari Rishikesh)
              that are well connected to major cities in India. From the
              station, you can easily find taxis or autorickshaws to take you to
              your destination.
            </li>
            <li>
              By Road: Rishikesh is well-connected by road to major cities in
              North India. You can opt for private or state-run buses, or even
              hire a taxi for a more comfortable journey. Mostly privet buses
              take you to the nepalifar which is 12KM from Rishieksh or you can
              take bus which is going directly to ISBT Rishikesh/Bus adda
              Rishikesh.
            </li>
            <li>
              Local Transportation: Once in Rishikesh, getting around is
              relatively easy. Auto-rickshaws, E-rickshawa,taxis, buses and
              rented motorcycles/car are readily available for local travel.
            </li>
          </ul>
        </div>

        <TitleWithoutDescription
          content={"Beach Camping in Rishikesh with Rafting"}
        />
        <div className="activity-content-section" ref={content_bcirwrRefm}>
          Experience the best of both worlds with a package that combines the
          thrill of rafting with the tranquility of beach camping. Imagine
          falling asleep to the soothing lullaby of the Ganges, the sound of
          water gently lapping against the shore. Wake up refreshed, ready for
          another day of adrenaline-fueled adventure on the river.
        </div>

        <TitleWithoutDescription
          content={"Camping in Rishikesh with Rafting"}
        />
        <div className="activity-content-section " ref={content_cirwrRefm}>
          Delve into the unique experiences offered by camping in Rishikesh
          combined with an adrenaline-pumping rafting adventure. This package
          ensures that you get the best of both worlds, with the serenity of
          nature and the thrill of the rapids. Imagine sitting by the campfire
          under a star-studded sky, recounting the day's adventures with fellow
          travelers.
        </div>
        <TitleWithoutDescription content={"Unique Experiences"} />
        <div className="activity-content-section ulli" ref={content_ueRefm}>
          <ul>
            <li>
              Allows you to reconnect with nature in a tranquil camping setting,
              far from the hustle and bustle of city life.
            </li>
            <li>
              Pairs perfectly with the adrenaline rush of rafting for an
              unforgettable adventure that balances excitement with moments of
              peace and reflection.
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"People Also Ask About Rishikesh"} />
        <div className="activity-content-section ulli" ref={content_paaarRefm}>
          <ul>
            <li>What are the best months for rafting in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          The best months for River rafting in Rishikesh are mid-September -
          November and mid-February to the end of June. you will get a good
          water level in the months of September, October, May, and June which
          will give you the best adventurous experience(Due to the strong
          rapids). and Nov-April you will get the cleanest/most transparent
          water.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Are there age restrictions for rafting in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          The minimum age is 14 for the 12+ section and for the 9KM section, the
          min age is 12 Years. The maximum age is 60 for the 12KM+ section and
          for 9KM 65 is the maximum age.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>What safety measures are in place for rafting in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Rishikesh takes safety seriously. All rafting equipment undergoes
          regular maintenance and quality checks. Expert guides accompany each
          expedition, well-versed in river dynamics and rescue techniques. A
          thorough pre-departure briefing is conducted, and a well-defined
          emergency response plan is in place. a person will only get the river
          guide license after he passes the guide test conducted by the UTDB
          team.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Can non-swimmers participate in rafting in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Absolutely! Wearing a life jacket is mandatory for all participants,
          regardless of their swimming abilities. This ensures that even
          non-swimmers can enjoy the adventure with confidence, knowing that
          they have the necessary safety equipment. this PFD (life jacket) is
          specially designed for non-swimmers.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              What are the nearby attractions in Rishikesh for non-adventurous
              travelers?
            </li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Rishikesh offers a plethora of attractions for those seeking a more
          relaxed experience. Explore the numerous ashrams (Parmarth Niketan,
          Nirmal Ashram, The Beatles Ashram, etc) and yoga centers, visit the
          iconic Triveni Ghat for a spiritual experience, or take a leisurely
          stroll across the Laxman Jhula, Janki Setu, and Ram Jhula suspension
          bridges for breathtaking views of the Ganges. Don’t miss the Ganga
          arti at Triveni ghat, Parmarth Niketan, and Strugan ghat in the
          morning and evening time. Kyarki is the best sunset point. you can
          also visit waterfalls like Neer Waterfall, Patna Waterfall, and some
          other hidden waterfalls.
        </div>

        {/* form starts here */}
        {form ? (
          <GlobalForm
            setForm={setForm}
            mobile_contact={mobile_contact}
            mobile_date={mobile_date}
            mobile_dor={mobile_dor}
            mobile_email={mobile_email}
            mobile_name={mobile_name}
            mobile_no_of_people={mobile_no_of_people}
            setMobile_contact={setMobile_contact}
            setMobile_dor={setMobile_dor}
            setMobile_email={setMobile_email}
            setMobile_name={setMobile_name}
            setMobile_no_of_people={setMobile_no_of_people}
            setMobile_date={setMobile_date}
            setMobile_slot={setMobile_slot}
            mobile_slot={mobile_slot}
            current_type={current_type}
            setCurrent_type={setCurrent_type}
            price={price}
            km={km}
            submitMobileAdvance={submitMobileAdvance}
            submitMobileFull={submitMobileFull}
            setChoosen_slot={setChoosen_slot}
            choosen_slot={choosen_slot}
            weekEnd={weekEnd}
          />
        ) : null}

        {status ? (
          <GlobalPhotoStatus
            setStatus={setStatus}
            desktop_status={desktop_status}
            desktop_status_indi={desktop_status_indi}
          />
        ) : null}
        {photoSection ? (
          <PhotoSection
            setPhotoSection={setPhotoSection}
            photos={[
              require("../Public/rafting/r1.jpg"),
              require("../Public/rafting/r2.jpg"),
              require("../Public/rafting/r3.jpg"),
              require("../Public/rafting/r4.jpg"),
              require("../Public/rafting/r5.jpg"),
              require("../Public/rafting/r6.jpg"),
              require("../Public/rafting/r7.jpg"),
              require("../Public/rafting/r8.jpg"),
              require("../Public/rafting/r9.jpg"),
              require("../Public/rafting/r10.jpg"),
              require("../Public/rafting/r11.jpg"),
              require("../Public/rafting/r12.jpg"),
            ]}
          />
        ) : null}
        <Footer />
        {/* <div className="space"></div> */}
        <BottomTab active={"rafting"} />
      </div>
    </>
  );
}

export default Rafting;
