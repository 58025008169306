import "./Photo_section.css";
import { useEffect, useState } from "react";
import music from "../Public/music.mp3";
import { useNavigate } from "react-router-dom";

function SmallImageSection({ img, active, time, onClick }) {
  return (
    <div className="photo_section_location_div" onClick={() => onClick(img)}>
      <img src={img} className="Photo_section_image" alt="" loading="lazy" />
      {active ? (
        <div className="photo_range_div">
          <div
            className="photo_range_color_div"
            style={{ width: (time * 100) / 10 + "%" }}
          ></div>
        </div>
      ) : null}
    </div>
  );
}

function Photo_section_desktop({ setDesktop_photo, photos, gallery }) {
  const navigate = useNavigate();
  const [current_img, setCurrent_img] = useState(0);
  const [time, setTime] = useState(10);
  const [music_onoff, setMusic_onoff] = useState(false);
  const [cc, setCc] = useState("contain");
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          // Reset time when it reaches 0
          return 10;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (time === 0) {
      setCurrent_img((prevIndex) => (prevIndex + 1) % photos.length);
      setTime(10); // Reset time
    }
  }, [time, photos.length]);

  const handleImageClick = (clickedImgIndex) => {
    setCurrent_img(clickedImgIndex);
    setTime(10); // Restart the animation
  };
  return (
    <div className="Photo_section_div">
      <audio src={music} autoPlay loop muted={music_onoff}></audio>
      <img
        style={{ objectFit: cc }}
        src={photos[current_img]}
        className="Photo_section_image"
        alt=""
        loading="lazy"
      />
      <button
        className="photo_section_cut_div"
        onClick={() => {
          if (gallery == true) {
            navigate("/");
          } else {
            setDesktop_photo(false);
          }
        }}
      >
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="desktop-status-cut-svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
              className="fill-white"
            ></path>{" "}
          </g>
        </svg>
      </button>
      <button
        className="photo_section_cut_div_music"
        onClick={() => {
          // setDesktop_photo(false);
          setMusic_onoff(!music_onoff);
        }}
      >
        {!music_onoff ? (
          <svg
            viewBox="0 0 24 24"
            className="desktop-status-cut-svg"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M19 6C20.5 7.5 21 10 21 12C21 14 20.5 16.5 19 18M16 8.99998C16.5 9.49998 17 10.5 17 12C17 13.5 16.5 14.5 16 15M3 10.5V13.5C3 14.6046 3.5 15.5 5.5 16C7.5 16.5 9 21 12 21C14 21 14 3 12 3C9 3 7.5 7.5 5.5 8C3.5 8.5 3 9.39543 3 10.5Z"
                className="stroke-white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
        ) : (
          <svg
            viewBox="0 0 24 24"
            className="desktop-status-cut-svg"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M22 9L16 15M16 9L22 15M3 10.5V13.5C3 14.6046 3.5 15.5 5.5 16C7.5 16.5 9 21 12 21C14 21 14 3 12 3C9 3 7.5 7.5 5.5 8C3.5 8.5 3 9.39543 3 10.5Z"
                className="stroke-white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
        )}
      </button>
      <div className="photo_section_bottom_images_div">
        {photos.map((item, index) => {
          return index == current_img ? (
            <SmallImageSection img={item} active={true} time={time} />
          ) : (
            <SmallImageSection
              img={item}
              active={false}
              onClick={() => handleImageClick(index)}
            />
          );
        })}
      </div>
      <div className="photo_section_tamplates_div">
        <div
          className="photo_section_tamplates_mobile_div"
          onClick={() => {
            setCc("cover");
          }}
        >
          <button className="photo_section_tamplates_mobile"></button>
        </div>
        <div className="photo_section_tamplates_line"></div>
        <div
          className="photo_section_tamplates_tab_div"
          onClick={() => {
            setCc("contain");
          }}
        >
          <button className="photo_section_tamplates_tab"></button>
        </div>
      </div>
    </div>
  );
}

export default Photo_section_desktop;
