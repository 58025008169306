import axios from "axios";
import "./BigGroup.css";
import { useState } from "react";
function BigGroup({ activity }) {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");

  const sendRequest = () => {
    if (name == "" || contact == "" || email == "") {
      alert("Please fill all the details");
    } else {
      axios
        .post("https://api.thrillthirsty.com/big-group", {
          contact: contact,
          email: email,
          name: name,
          activity: activity,
        })
        .then((res) => {
          console.log(res.data);
          alert(res.data.msg);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="bg-whole-after-form">
      <div className="bg-waf-title">Bigger Group?</div>
      <div className="bg-waf-price">Get upto 50% off</div>
      <form>
        <div className="bg-whole-text-input">
          <div className="bg-wti-title">{"Your Name"}</div>
          <input
            type={"text"}
            className="bg-wti-input"
            placeholder={"Enter your Name"}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="bg-whole-text-input">
          <div className="bg-wti-title">{"Your contact"}</div>
          <input
            type={"number"}
            className="bg-wti-input"
            placeholder={"Enter your contact number"}
            onChange={(e) => {
              setContact(e.target.value);
            }}
          />
        </div>
        <div className="bg-whole-text-input">
          <div className="bg-wti-title">{"Your Email"}</div>
          <input
            type={"email"}
            className="bg-wti-input"
            placeholder={"Enter your email address"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <button type="button" onClick={sendRequest} className="bg-waf-button">
          Request a callback
        </button>
        <div className="bg-morethen-text">
          The group must contain more than 10 people
        </div>
      </form>
    </div>
  );
}

export default BigGroup;
