import "./ActivityTimeline.css";
// import rafting from "../Public/rafting4.jpg";
function ActivityTimeline({ indicator, title, img }) {
  return (
    <div className="activity-timeline-indi">
      <div className="ati-img-div">
        <img src={img} alt="" className="ati-img" loading="lazy" />
      </div>
      <div className="ati-content-div">
        <div className="ati-content-indicator">{indicator}</div>
        <div className="ati-content-title">{title}</div>
      </div>
    </div>
  );
}

export default ActivityTimeline;
