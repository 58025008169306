import "./Page404.jsx";
function Page404() {
  return (
    <div>
      <div>this is the 404 page</div>
    </div>
  );
}

export default Page404;
