import BottomTab from "../ComponentsHome/BottomTab";
import HeaderMobile from "../ComponentsActivity/HeaderMobile";
// import MainTitle from "../ComponentsActivity/MainTitle";
import Options from "../ComponentsActivity/Options";
import "./Rafting.css";
import ActivityTimeline from "../ComponentsActivity/ActivityTimeline";
// import rafting1 from "../Public/rafting7.jpg";
// import rafting8 from "../Public/rafting8.jpg";
// import rafting3 from "../Public/rafting9.jpg";
// import rafting4 from "../Public/rafting5.jpg";
// import rafting2 from "../Public/rafting2.jpg";
import Title from "../ComponentsHome/Title";
import Status from "../GlobalComponents/Status";
import PageMenu from "../GlobalComponents/PageMenu";
import TitleWithoutDescription from "../GlobalComponents/TitleWithoutDescription";
// import ContentSection from "../GlobalComponents/ContentSection";
// import GlobalForm from "../GlobalComponents/Globalform";
import { useEffect, useState } from "react";
import GlobalPhotoStatus from "../GlobalStatus/GlobalPhotoStatus";
import PhotoSection from "../GlobalPhoto/PhotoSection";
import "./RaftingResponsive.css";
import Header from "../DesktopComponents/Header";
import "./RaftingDesktop.css";
import IndiType from "../DesktopComponents/IndiType";
import IndiWhere from "../DesktopComponents/IndiWhere";
import TitleDesktop from "../DesktopComponents/TitleDesktop";
import DesktopStatus from "../DesktopComponents/DesktopStatus";
import Photo_section_desktop from "../DesktopComponents/Photo_section";
import Status_section from "../DesktopComponents/Status_section";
import "../DesktopComponents/DesktopForm.css";
import InputDate from "../DesktopComponents/InputDate";
import TextInput from "../DesktopComponents/InputText";
import { useRef } from "react";
import DesktopContentTitle from "../DesktopComponents/DesktopContentTitle";

// import hd_rafting1 from "../Public/rafting5.jpg";
// import hd_rafting2 from "../Public/rafting4.jpg";
// import hd_rafting3 from "../Public/rafting3.jpg";
import AfterForm from "../DesktopComponents/AfterForm";
import BigGroup from "../DesktopComponents/BigGroup";
// import newrafting1 from "../Public/newrafting1.jpg";
// import newrafting2 from "../Public/newrafting2.jpg";
// import newrafting3 from "../Public/newrafting3.jpg";
// import newrafting4 from "../Public/newrafting4.jpg";
// import newrafting5 from "../Public/newrafting4.jpg";
// import newrafting6 from "../Public/newrafting6.jpg";
// import newrafting7 from "../Public/newrafting7.jpg";
// import newrafting8 from "../Public/newrafting8.jpg";
import Footer from "../GlobalComponents/Footer";

import { useNavigate } from "react-router-dom";

// importing camping images from here

// import camping from "../Public/camping.jpg";
import camping1 from "../Public/newcamping1.jpg";
import camping2 from "../Public/newcamping2.jpg";
import camping3 from "../Public/newcamping3.jpg";
// import camping4 from "../Public/newcamping4.jpg";
// import hd_camping from "../Public/campinghd.png";
import MainTitleCamping from "../ComponentsActivity/MainTitleCamping";
import GlobalFormCamping from "../GlobalComponents/GlobalFormCamping";
import axios from "axios";
import { Helmet } from "react-helmet";
import CallWhattsaap from "../Msgs/CallWhattsaap";
// import MainTitleCamping from "../ComponentsActivity/MainTitleCamping";
// import { withRouter } from "react-router-dom";

// function IndiSection({ section, active, ref }) {
//   return (
//     <div
//       onClick={() => {
//         ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
//       }}
//       className={active ? "damw-indi-section-active" : "damw-indi-section"}
//     >
//       {section}
//     </div>
//   );
// }

function Camping() {
  const navigate = useNavigate();
  const [form, setForm] = useState(false);
  const [status, setStatus] = useState(false);
  const [photoSection, setPhotoSection] = useState(false);
  const [desktop_photo, setDesktop_photo] = useState(false);
  const [desktop_status, setDesktop_status] = useState(false);
  const [time_out_desktop, setTime_out_desktop] = useState(undefined);

  const [current_type, setCurrent_type] = useState(1);
  const [choosen_date, setChoosen_date] = useState(undefined);
  const [price, setPrice] = useState({
    1: 1000,
    2: 1200,
    3: 2000,
  });
  const [rapids, setRapids] = useState({
    1: 6,
    2: 6,
    3: 4,
    4: 8,
    5: 14,
    6: "20+",
  });
  const [km, setKm] = useState({
    1: "09",
    2: "12",
    3: "12",
    4: "16",
    5: "26",
    6: "36",
  });
  const [no_people, setNo_people] = useState(2);
  const kms_blink = useRef(null);
  const rapids_blink = useRef(null);
  const blink_begin = useRef(null);
  const blink_last = useRef(null);

  const aboutRef = useRef(null);
  const content_scRef = useRef(null);
  const content_vroalRef = useRef(null);
  const content_rwaacRef = useRef(null);
  const content_carirRef = useRef(null);
  const content_advantagesRef = useRef(null);
  const content_rrseRef = useRef(null);
  const content_itineraryRef = useRef(null);
  const content_ecfrrrRef = useRef(null);
  const content_inclusionsRef = useRef(null);
  const content_exclusionsRef = useRef(null);
  const content_htrrRef = useRef(null);
  const content_corRef = useRef(null);
  const content_rirrRef = useRef(null);
  const content_ttcfrirRef = useRef(null);
  const content_ttdwRef = useRef(null);
  const content_tntdRef = useRef(null);
  const content_bcirwrRef = useRef(null);
  const content_bafRef = useRef(null);
  const content_cirwrRef = useRef(null);
  const content_ueRef = useRef(null);
  const content_paaarRef = useRef(null);

  const aboutRefm = useRef(null);
  const content_scRefm = useRef(null);
  const content_vroalRefm = useRef(null);
  const content_rwaacRefm = useRef(null);
  const content_carirRefm = useRef(null);
  const content_advantagesRefm = useRef(null);
  const content_rrseRefm = useRef(null);
  const content_itineraryRefm = useRef(null);
  const content_ecfrrrRefm = useRef(null);
  const content_inclusionsRefm = useRef(null);
  const content_exclusionsRefm = useRef(null);
  const content_htrrRefm = useRef(null);
  const content_corRefm = useRef(null);
  const content_rirrRefm = useRef(null);
  const content_ttcfrirRefm = useRef(null);
  const content_ttdwRefm = useRef(null);
  const content_cirwrRefmc = useRef(null);
  const content_tntdRefm = useRef(null);
  const content_bcirwrRefm = useRef(null);
  const content_bafRefm = useRef(null);
  const content_cirwrRefm = useRef(null);
  const content_ueRefm = useRef(null);
  const content_paaarRefm = useRef(null);

  const [slots, setSlots] = useState([
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
  ]);

  const [where, setWhere] = useState({
    1: [
      {
        img: require("../Public/beachCamp/beachcamp3.jpeg"),
        tag: "11:00 AM",
        title: "Check in",
      },
      {
        img: require("../Public/beachCamp/beachcamp4.jpeg"),
        tag: "~ 01:00 PM",
        title: "Lunch",
      },
      {
        img: require("../Public/beachCamp/beachcamp5.jpeg"),
        tag: "~ 05:00 PM",
        title: "Evening snacks",
      },
      {
        img: require("../Public/beachCamp/beachcamp7.jpeg"),
        tag: "~ 08:00 PM",
        title: "Dinner",
      },
      {
        img: require("../Public/beachCamp/beachcamp8.jpeg"),
        tag: "~ 08:00 AM",
        title: "Breakfast",
      },
      {
        img: require("../Public/beachCamp/beachcamp10.jpeg"),
        tag: "~ 11:00 AM",
        title: "Check out",
      },
    ],
    2: [
      {
        img: require("../Public/luxuryCamp/lc6.jpeg"),
        tag: "11:00 AM",
        title: "Check in",
      },
      {
        img: require("../Public/luxuryCamp/lc7.jpeg"),
        tag: "~ 01:00 PM",
        title: "Lunch",
      },
      {
        img: require("../Public/luxuryCamp/lc9.jpeg"),
        tag: "~ 05:00 PM",
        title: "Evening snacks",
      },
      {
        img: require("../Public/luxuryCamp/lc10.jpeg"),
        tag: "~ 08:00 PM",
        title: "Dinner",
      },
      {
        img: require("../Public/luxuryCamp/lc11.jpeg"),
        tag: "~ 08:00 AM",
        title: "Breakfast",
      },
      {
        img: require("../Public/luxuryCamp/lc6.jpeg"),
        tag: "~ check out",
        title: "Beach camp",
      },
    ],
    3: [
      {
        img: require("../Public/ACCottages/ac2.jpeg"),
        tag: "11:00 AM",
        title: "Check in",
      },
      {
        img: require("../Public/ACCottages/ac3.jpeg"),
        tag: "~ 01:00 PM",
        title: "Lunch",
      },
      {
        img: require("../Public/ACCottages/ac4.jpeg"),
        tag: "~ 05:00 PM",
        title: "Evening snacks",
      },
      {
        img: require("../Public/ACCottages/ac5.jpeg"),
        tag: "~ 08:00 PM",
        title: "Dinner",
      },
      {
        img: require("../Public/ACCottages/ac10.jpeg"),
        tag: "~ 08:00 AM",
        title: "Breakfast",
      },
      {
        img: require("../Public/ACCottages/ac9.jpeg"),
        tag: "~ 11:00 AM",
        title: "Check out",
      },
    ],
  });

  const [se, setSe] = useState({
    1: {
      start: "Bhrampuri",
      end: "Nim beach",
    },
    2: {
      start: "Marine Drive",
      end: "Shivpuri",
    },
    3: {
      start: "Club House",
      end: "Nim beach",
    },
    4: {
      start: "Shivpuri",
      end: "Nim beach",
    },
    5: {
      start: "Marine Drive",
      end: "Nim beach",
    },
    6: {
      start: "Kaudiyala",
      end: "Nim beach",
    },
  });

  const [choosen_slot, setChoosen_slot] = useState(undefined);

  // scroll section here
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollStartX, setScrollStartX] = useState(0);
  const [curent_menu_option, setCurrent_menu_option] = useState("");

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStartX(e.clientX);
    setScrollStartX(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dx = e.clientX - dragStartX;
    containerRef.current.scrollLeft = scrollStartX - dx;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // useEffect(() => {
  //   if (time_out_desktop != undefined) {
  //     clearTimeout(time_out_desktop[0]);
  //   }
  //   kms_blink.current.classList.add("blink_animation");
  //   rapids_blink.current.classList.add("blink_animation");
  //   blink_begin.current.classList.add("blink_animation");
  //   blink_last.current.classList.add("blink_animation");

  //   km_blink_mobile.current.classList.add("blink_animation");
  //   rapids_blink_mobile.current.classList.add("blink_animation");
  //   start_blink_mobile.current.classList.add("blink_animation");
  //   end_blink_mobile.current.classList.add("blink_animation");

  //   setTime_out_desktop([
  //     setTimeout(() => {
  //       kms_blink.current.classList.remove("blink_animation");
  //       rapids_blink.current.classList.remove("blink_animation");
  //       blink_begin.current.classList.remove("blink_animation");
  //       blink_last.current.classList.remove("blink_animation");

  //       km_blink_mobile.current.classList.remove("blink_animation");
  //       rapids_blink_mobile.current.classList.remove("blink_animation");
  //       start_blink_mobile.current.classList.remove("blink_animation");
  //       end_blink_mobile.current.classList.remove("blink_animation");
  //     }, 500),
  //   ]);
  // }, [current_type]);

  const [desktop_status_indi, setDesktop_status_indi] = useState([
    {
      smallimg: require("../Public/beachCamp/beachcamp4.jpeg"),
      img: require("../Public/beachCamp/beachcamp4.jpeg"),
      title: "Beach camp",
      des: "Feel the beach",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/luxuryCamp/lc6.jpeg"),
      img: require("../Public/luxuryCamp/lc6.jpeg"),
      title: "Luxury camp",
      des: "feel the luxury",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
    {
      smallimg: require("../Public/ACCottages/ac2.jpeg"),
      img: require("../Public/ACCottages/ac2.jpeg"),
      title: "AC Cottages",
      des: "feel the luxury",
      form_title: "12 km River Rafting",
      form_des: "₹899/person · 5 rapids",
    },
  ]);

  const [menu_desktop_content, setMenu_desktop_content] = useState([
    { content: "About", tag: "about", ref: aboutRef },
    {
      content: "Types of Camps in Rishikesh",
      tag: "content-sc",
      ref: content_scRef,
    },
    {
      content: "Beach Camps",
      tag: "content-vroal",
      ref: content_vroalRef,
    },
    {
      content: "Luxury camps",
      tag: "content-rwaac",
      ref: content_rwaacRef,
    },
    {
      content: "AC Cottages",
      tag: "content-carir",
      ref: content_carirRef,
    },
    {
      content: "Rishikesh Camping Itinerary",
      tag: "content-advantages",
      ref: content_advantagesRef,
    },
    {
      content: "Camping and Rafting in Rishikesh",
      tag: "content-rrse",
      ref: content_rrseRef,
    },
    {
      content: "Advantages",
      tag: "content-itinerary",
      ref: content_itineraryRef,
    },
    {
      content: "Child Policy for Camping in Rishikesh",
      tag: "content-ecfrrr",
      ref: content_ecfrrrRef,
    },
    {
      content: "Inclusions",
      tag: "content-inclusions",
      ref: content_inclusionsRef,
    },
    {
      content: "Rishikesh camping Exclusions",
      tag: "content-exclusions",
      ref: content_exclusionsRef,
    },
    {
      content: "How to Reach Rishikesh?",
      tag: "content-htrr",
      ref: content_htrrRef,
    },
    {
      content: "Things to Do in camping",
      tag: "content-cor",
      ref: content_corRef,
    },
    {
      content: "Things not to do",
      tag: "content-rirr",
      ref: content_rirrRef,
    },
    {
      content: "Camping Rules and Policy",
      tag: "content-ttcfrir",
      ref: content_ttcfrirRef,
    },
    {
      content: "Camping in Rishikesh with Rafting",
      tag: "content-ttdw",
      ref: content_ttdwRef,
    },
    {
      content: "Unique Experiences",
      tag: "content-tntd",
      ref: content_tntdRef,
    },
    {
      content: "People Also Ask About Rishikesh",
      tag: "content-paaar",
      ref: content_paaarRef,
    },
  ]);

  const [menu_mobile_content, setMenu_mobile_content] = useState([
    { content: "About", tag: "about", ref: aboutRefm },
    {
      content: "Types of camps in Rishikesh",
      tag: "content-sc",
      ref: content_scRefm,
    },
    {
      content: "Beach camp in Rishikesh",
      tag: "content-vroal",
      ref: content_vroalRefm,
    },
    {
      content: "Luxury camps in Rishikesh",
      tag: "content-rwaac",
      ref: content_rwaacRefm,
    },
    {
      content: "Ac Cottages in Rishikesh",
      tag: "content-carir",
      ref: content_carirRefm,
    },
    {
      content: "Rishikesh Camping Itinery",
      tag: "content-advantages",
      ref: content_advantagesRefm,
    },
    {
      content: "Camping and Rafting in Rishikesh",
      tag: "content-rrse",
      ref: content_rrseRefm,
    },
    {
      content: "Advantages",
      tag: "content-itinerary",
      ref: content_itineraryRefm,
    },
    {
      content: "Child Policy for Camping Rishikesh",
      tag: "content-ecfrrr",
      ref: content_ecfrrrRefm,
    },
    {
      content: "Inclusion",
      tag: "content-inclusions",
      ref: content_inclusionsRefm,
    },
    {
      content: "Rishikesh camping Exclusions",
      tag: "content-exclusions",
      ref: content_exclusionsRefm,
    },
    {
      content: "How to Reach Rishikesh?",
      tag: "content-htrr",
      ref: content_htrrRefm,
    },
    {
      content: "Things to do in camping",
      tag: "content-cor",
      ref: content_corRefm,
    },
    {
      content: "Things not to do",
      tag: "content-rirr",
      ref: content_rirrRefm,
    },
    {
      content: "Camping rules and policy",
      tag: "content-ttcfrir",
      ref: content_ttcfrirRefm,
    },
    {
      content: "Camping in Rishikesh with Rafting",
      tag: "content-ttcfrir",
      ref: content_cirwrRefmc,
    },
    {
      content: "Unique Experiences",
      tag: "content-ttdw",
      ref: content_ttdwRefm,
    },
    {
      content: "Frequently asked questions",
      tag: "content-tntd",
      ref: content_paaarRefm,
    },
  ]);

  const [current_mobile_menu, setCurrent_mobile_menu] = useState(0);

  // desktop form details
  const [formName, setFormName] = useState("");
  const [formContact, setFormContact] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, SetCheckOut] = useState("");

  // mobile form details
  const [mobile_name, setMobile_name] = useState("");
  const [mobile_contact, setMobile_contact] = useState("");
  const [mobile_email, setMobile_email] = useState("");
  const [mobile_dor, setMobile_dor] = useState("");
  const [mobile_date, setMobile_date] = useState("");
  const [mobile_no_of_people, setMobile_no_of_people] = useState(2);
  const [mobile_slot, setMobile_slot] = useState("");

  // creating blinks for mobile form
  const start_blink_mobile = useRef(null);
  const end_blink_mobile = useRef(null);
  const km_blink_mobile = useRef(null);
  const rapids_blink_mobile = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const rulesDesktop = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      no_people == 0 ||
      no_people == "" ||
      no_people == undefined ||
      no_people == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (checkIn == "" || checkIn == undefined || checkIn == null) {
      alert("please choose a checkin date");
      allgood = false;
    } else if (checkOut == "" || checkOut == undefined || checkOut == null) {
      alert("please choose a checkout date");
      allgood = false;
    } else if (
      current_type == "" ||
      current_type == undefined ||
      current_type == null ||
      current_type > 3
    ) {
      alert("Invalid type of camping");
      allgood = false;
    } else if (
      formContact == "" ||
      formContact == undefined ||
      formContact == null ||
      formContact.length < 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (formEmail == "" || formEmail == undefined || formEmail == null) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(formEmail)) {
      alert("Invalid email");
      allgood = false;
    } else if (formName == "" || formName == undefined || formName == null) {
      alert("Invalid name");
      allgood = false;
    }

    return allgood;
  };

  const rulesMobile = () => {
    let allgood = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      mobile_no_of_people == 0 ||
      mobile_no_of_people == "" ||
      mobile_no_of_people == undefined ||
      mobile_no_of_people == null
    ) {
      alert("Invalid number of people");
      allgood = false;
    } else if (checkIn == "" || checkIn == undefined || checkIn == null) {
      alert("please choose a check in date");
      allgood = false;
    } else if (checkOut == "" || checkOut == undefined || checkOut == null) {
      alert("please choose a checkout date");
      allgood = false;
    } else if (
      current_type == "" ||
      current_type == undefined ||
      current_type == null ||
      current_type > 3
    ) {
      alert("Invalid type of camping");
      allgood = false;
    } else if (
      mobile_contact == "" ||
      mobile_contact == undefined ||
      mobile_contact == null ||
      mobile_contact.length < 10
    ) {
      alert("Invalid contact number");
      allgood = false;
    } else if (
      mobile_email == "" ||
      mobile_email == undefined ||
      mobile_email == null
    ) {
      alert("Invalid email");
      allgood = false;
    } else if (!emailRegex.test(mobile_email)) {
      alert("Invalid email");
      allgood = false;
    } else if (
      mobile_name == "" ||
      mobile_name == undefined ||
      mobile_name == null
    ) {
      alert("Invalid name");
      allgood = false;
    }

    return allgood;
  };

  const submitdesktop = () => {
    if (rulesDesktop()) {
      axios
        .post("https://api.thrillthirsty.com/activities/camping/enquery", {
          name: formName,
          email: formEmail,
          contact: formContact,
          type: (() => {
            if (current_type == 1) {
              return "Beach Camp";
            } else if (current_type == 2) {
              return "Luxury Camp";
            } else if (current_type == 3) {
              return "AC Cottages";
            }
          })(),
          checkIn: checkIn,
          checkOut: checkOut,
          headcount: no_people,
          // payment_type: "advance",
          // totalAmount: parseInt(price[current_type]) * parseInt(no_people),
          // amount:
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100,
          // amount: 1,
        })
        .then(() => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "camping",
            Bookingvalue: 100,
          });
          navigate("/activities/camping/thank-you?bookingvalue=200");
        });
    }
  };
  // const history = useHistory();

  const submitMobile = () => {
    if (rulesMobile()) {
      axios
        .post("https://api.thrillthirsty.com/activities/camping/enquery", {
          name: mobile_name,
          email: mobile_email,
          contact: mobile_contact,
          type: (() => {
            if (current_type == 1) {
              return "Beach Camp";
            } else if (current_type == 2) {
              return "Luxury Camp";
            } else if (current_type == 3) {
              return "AC Cottages";
            }
          })(),
          checkIn: checkIn,
          checkOut: checkOut,
          headcount: mobile_no_of_people,
          // payment_type: "advance",
          // totalAmount: parseInt(price[current_type]) * parseInt(no_people),
          // amount:
          //   (parseInt(price[current_type]) * parseInt(no_people) * 20) / 100,
          // amount: 1,
        })
        .then(() => {
          window.dataLayer.push({
            event: "Booking",
            adventure: "camping",
            Bookingvalue: 100,
          });
          navigate("/activities/camping/thank-you?bookingvalue=200");
        });
    }
  };

  const form_here = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setForm(true);
    }, 15000); // 15 seconds in milliseconds

    // Cleanup function to clear the timeout in case component unmounts before 15 seconds
    return () => clearTimeout(timer);
  }, []);

  // scroll function here
  const containerRef1 = useRef(null);
  const [isDragging1, setIsDragging1] = useState(false);
  const [dragStartX1, setDragStartX1] = useState(0);
  const [scrollStartX1, setScrollStartX1] = useState(0);

  const handleMouseDown1 = (e) => {
    setIsDragging1(true);
    setDragStartX1(e.clientX);
    setScrollStartX1(containerRef1.current.scrollLeft);
  };

  const handleMouseMove1 = (e) => {
    if (!isDragging1) return;
    const dx = e.clientX - dragStartX1;
    containerRef1.current.scrollLeft = scrollStartX1 - dx;
  };

  const handleMouseUp1 = () => {
    setIsDragging1(false);
  };

  //  2nd
  const containerRef2 = useRef(null);
  const [isDragging2, setIsDragging2] = useState(false);
  const [dragStartX2, setDragStartX2] = useState(0);
  const [scrollStartX2, setScrollStartX2] = useState(0);

  const handleMouseDown2 = (e) => {
    setIsDragging2(true);
    setDragStartX2(e.clientX);
    setScrollStartX2(containerRef2.current.scrollLeft);
  };

  const handleMouseMove2 = (e) => {
    if (!isDragging2) return;
    const dx = e.clientX - dragStartX2;
    containerRef2.current.scrollLeft = scrollStartX2 - dx;
  };

  const handleMouseUp2 = () => {
    setIsDragging2(false);
  };

  // 3rd
  const containerRef3 = useRef(null);
  const [isDragging3, setIsDragging3] = useState(false);
  const [dragStartX3, setDragStartX3] = useState(0);
  const [scrollStartX3, setScrollStartX3] = useState(0);

  const handleMouseDown3 = (e) => {
    setIsDragging3(true);
    setDragStartX3(e.clientX);
    setScrollStartX3(containerRef3.current.scrollLeft);
  };

  const handleMouseMove3 = (e) => {
    if (!isDragging3) return;
    const dx = e.clientX - dragStartX3;
    containerRef3.current.scrollLeft = scrollStartX3 - dx;
  };

  const handleMouseUp3 = () => {
    setIsDragging3(false);
  };

  // 4th
  const containerRef4 = useRef(null);
  const [isDragging4, setIsDragging4] = useState(false);
  const [dragStartX4, setDragStartX4] = useState(0);
  const [scrollStartX4, setScrollStartX4] = useState(0);

  const handleMouseDown4 = (e) => {
    setIsDragging4(true);
    setDragStartX4(e.clientX);
    setScrollStartX4(containerRef4.current.scrollLeft);
  };

  const handleMouseMove4 = (e) => {
    if (!isDragging4) return;
    const dx = e.clientX - dragStartX4;
    containerRef4.current.scrollLeft = scrollStartX4 - dx;
  };

  const handleMouseUp4 = () => {
    setIsDragging4(false);
  };

  return (
    <>
      <CallWhattsaap />
      <div className="rafting-desktop-section">
        <Helmet>
          <title>
            Thrill Thirsty: Camping in Rishikesh | starting just @1199
          </title>
          <meta
            name="description"
            content="Stay at camps in Rishikesh | Get a comfortable stay, unlimited food, swimming pool, and bonfire | Book now and make your camping amazing with Thrill Thirsty."
          />
          {/* Other meta tags */}
        </Helmet>
        <Header active={"camping"} />
        <div className="rafting-ds-section1">
          <div className="rafting-ds-section1-bottom-dark">
            <h1 className="rds-sbd-title">Camping In Rishikesh</h1>
            <div className="rds-sbd-description">
              Get a comfortable stay, unlimited food, swimming pool, and bonfire
            </div>
          </div>
          <div className="rafting-ds-section1-right-dark">
            <div className="rdss1-r-div">
              <div className="rdss1-r-d-inner">
                <div
                  className="rdss1-r-d-div1"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={camping1}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div2"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={camping2}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div3"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={camping3}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                </div>
                <div
                  className="rdss1-r-d-div4"
                  onClick={() => {
                    setDesktop_photo(true);
                  }}
                >
                  <img
                    src={require("../Public/beachCamp/beachcamp3.jpeg")}
                    alt=""
                    className="rdss1-r-d-img"
                    loading="lazy"
                  />
                  <div className="rdss1-r-d-div-4-dark">+20</div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={require("../Public/beachCamp/beachcamp4.jpeg")}
            style={{
              objectPosition: "0px 60%",
              transform: "ScaleX(-1)",
            }}
            alt=""
            className="rafting-ds-section1-img"
            loading="lazy"
          />
        </div>

        <div className="rafting-desktop-body">
          <div className="rafting-desktop-body-1">
            <div className="rafting-type-section">
              <IndiType
                type={"Beach camp"}
                active={current_type == 1 ? true : false}
                select={() => {
                  setCurrent_type(1);
                }}
              />
              <IndiType
                type={"Luxury camp"}
                active={current_type == 2 ? true : false}
                select={() => {
                  setCurrent_type(2);
                }}
              />
              <IndiType
                type={"AC Cottages"}
                active={current_type == 3 ? true : false}
                select={() => {
                  setCurrent_type(3);
                }}
              />
            </div>
            {/* <div className="rafting-where-where-section">
              <div
                className="rafting-where-where-section-inner"
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                {where[current_type].map((item, index) => {
                  return (
                    <IndiWhere
                      img={where[current_type][index].img}
                      tag={where[current_type][index].tag}
                      title={where[current_type][index].title}
                    />
                  );
                })}

                <div className="rww-empty-space"></div>
              </div> */}
            {/* <div className="rafting-where-where-dark">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="rww-svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                      className="fill-orange"
                    ></path>{" "}
                  </g>
                </svg>
              </div> */}
            {/* </div> */}
            <TitleDesktop
              title={"Some of our memories"}
              description={"These are some of our memories"}
              more={false}
            />

            <div className="desktop-status-whole">
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/beachCamp/beachcamp4.jpeg")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/luxuryCamp/lc6.jpeg")}
              />
              <DesktopStatus
                setDesktop_status={setDesktop_status}
                desktop_status={desktop_status}
                img={require("../Public/ACCottages/ac2.jpeg")}
              />
            </div>

            <div className="damw-outer">
              <div className="desktop-activity-menu-whole">
                <div className="damw-1">
                  <div className="damw-title">Camping</div>
                  <div className="damw-rating-div">
                    <div className="damw-svg-div">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="damw-svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M9.15316 5.40838C10.4198 3.13613 11.0531 2 12 2C12.9469 2 13.5802 3.13612 14.8468 5.40837L15.1745 5.99623C15.5345 6.64193 15.7144 6.96479 15.9951 7.17781C16.2757 7.39083 16.6251 7.4699 17.3241 7.62805L17.9605 7.77203C20.4201 8.32856 21.65 8.60682 21.9426 9.54773C22.2352 10.4886 21.3968 11.4691 19.7199 13.4299L19.2861 13.9372C18.8096 14.4944 18.5713 14.773 18.4641 15.1177C18.357 15.4624 18.393 15.8341 18.465 16.5776L18.5306 17.2544C18.7841 19.8706 18.9109 21.1787 18.1449 21.7602C17.3788 22.3417 16.2273 21.8115 13.9243 20.7512L13.3285 20.4768C12.6741 20.1755 12.3469 20.0248 12 20.0248C11.6531 20.0248 11.3259 20.1755 10.6715 20.4768L10.0757 20.7512C7.77268 21.8115 6.62118 22.3417 5.85515 21.7602C5.08912 21.1787 5.21588 19.8706 5.4694 17.2544L5.53498 16.5776C5.60703 15.8341 5.64305 15.4624 5.53586 15.1177C5.42868 14.773 5.19043 14.4944 4.71392 13.9372L4.2801 13.4299C2.60325 11.4691 1.76482 10.4886 2.05742 9.54773C2.35002 8.60682 3.57986 8.32856 6.03954 7.77203L6.67589 7.62805C7.37485 7.4699 7.72433 7.39083 8.00494 7.17781C8.28555 6.96479 8.46553 6.64194 8.82547 5.99623L9.15316 5.40838Z"
                            className="fill-orange"
                          ></path>{" "}
                        </g>
                      </svg>
                    </div>
                    <div className="damw-rating-text">4.8</div>
                  </div>
                </div>
                <div
                  className="damw-2"
                  ref={containerRef1}
                  onMouseDown={handleMouseDown1}
                  onMouseMove={handleMouseMove1}
                  onMouseUp={handleMouseUp1}
                  onMouseLeave={handleMouseUp1}
                >
                  {menu_desktop_content.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setCurrent_menu_option(item.tag);
                          let t =
                            item.ref.current.getBoundingClientRect().top +
                            window.pageYOffset;
                          let scrollToPosition = t - 200;
                          window.scrollTo({
                            top: scrollToPosition,
                            behavior: "smooth",
                          });
                          console.log(item.ref);
                          // console.log(content_ttdw);
                        }}
                        className={
                          item.tag == curent_menu_option
                            ? "damw-indi-section-active"
                            : "damw-indi-section"
                        }
                      >
                        {item.content}
                      </div>
                    );
                  })}
                  <div></div>
                </div>
              </div>
            </div>

            <DesktopContentTitle
              title={"Camping in Rishikesh"}
              // description={"ye nhi kra to kya kra"}
              more={false}
              id="about"
            />

            <div className="rafting-desktop-about-content" ref={aboutRef}>
              Rishikesh is one of the best camping spots in India. with many
              activities like trekking/Nature walk, River Rafting, zipline,
              bonfire, night music, and scenic beauty. That’s why it is known as
              the “Adventure capital of India”. Camping is a totally different
              experience in Rishikesh. you meet other people talk to them enjoy
              the evening bonfire together. It’s not just about staying, you get
              to see beautiful mountains, the Ganga River, and much more. with
              hospitality camping also provides food service which is already
              included in the price. every year thousands of people come to
              Rishikesh to experience camping and other adventure activities
              like - river rafting, bungee jumping, rock climbing, paragliding,
              zipline. also, Rishikesh is a well-known spiritual place where
              people come to explore the Temples, ghat, and holy river Ganga.
              there are different types of camping in Rishikesh like Beach
              camping, luxury camping, and AC Cottages you can choose any one of
              these accordingly.
            </div>
            <DesktopContentTitle
              title={"Types of Camps in Rishikesh"}
              more={false}
              id={"content-sc"}
            />
            <div className="rafting-desktop-about-content" ref={content_scRef}>
              <ul>
                <li>Beach camps</li>
                <li>Luxury camps</li>
                <li>AC Cottages</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Beach Camps"}
              more={false}
              id={"content-vrfal"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_vroalRef}
            >
              Beach camps are the type of camps that are located on the beach
              surrounded by forest and a front river view. these beach camps are
              for a totally raw experience and nature lovers. In the evening you
              can spend some time on the riverside to enjoy the fresh and cold
              wind that will give you a different level of experience.
            </div>
            <div className="rafting-desktop-about-content">
              Beach camping in Rishikesh includes - staying in alpine tents, 5-8
              common washrooms (Indian + Western), light and charging points in
              a common area, 3-time meals, morning evening tea, and evening
              snacks. you can play some games like beach volleyball, and
              badminton or go for a walk at the riverside. Alcohol, non-veg
              food, and music are not allowed in beach camps.
            </div>
            <div className="rafting-desktop-about-content">
              Note: Since 2015 Uttarakhand Tourism banned Ganga Beach Camps and
              now only 2-3 beach camping sites are available at Shivpuri.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Camp Capacity - 3 persons Max</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Luxury camps"}
              more={false}
              id={"content-rwaac"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_rwaacRef}
            >
              These camps are specifically designed for your comfortable stay
              with beautiful mountain views. Luxury camps are the types of camps
              that have all the luxury facilities like 24x7 Electricity, a
              Swimming pool, a Cooler for summer, and gaming activities like
              Volleyball, badminton, Burma bridge, commando net, and much more.
              these are the alpine tents with more space than beach camps, 4-5
              people can easily stay in 1 camp.
            </div>
            <div className="rafting-desktop-about-content">
              Luxury camps in Rishikesh include -Stay in Luxury alpine tents, 2
              Double bed, 3-time meals, evening snacks, morning and evening tea,
              a swimming pool, a Cooler, music till 10 PM, a Bonfire (Nov - Feb
              only), gaming activities, nature walk (guidance only), and Attech
              washroom in every camp.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Camp Capacity - 4-5 persons Max</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"AC Cottages"}
              more={false}
              id={"content-carir"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_carirRef}
            >
              AC Cottage are the most comfertabel. these are not the Swiss tents
              but a wall of fiber. you get comfortable bedding, AC, and an
              attached washroom in the Cottages. you get the 2 Double beds in
              each cottage.
            </div>
            <div className="rafting-desktop-about-content">
              AC Cottages in Rishikesh includes - Stay in Cottages, 2 Double
              bed, 3-time meals, evening snacks, morning and evening tea, a
              swimming pool, an AC, music till 10 PM, a Bonfire (Nov - Feb
              only), gaming activities, a nature walk (guidance only), and
              Attech washroom in every cottage.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Camp Capacity - 4-7 persons Max (Ideal 4)</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Rishikesh Camping Itinerary "}
              more={false}
              id={"content-advantages"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_advantagesRef}
            >
              <ul>
                <li>
                  Day 1: Briefing for camping rules and check-in After 11 AM,
                  explore the camp and take a rest, 1-2 PM - Lunch time. then
                  after taking a rest you can do camping activities or enjoy
                  yourself in the pool. 7-8 PM Evening snacks then enjoy your
                  dinner 9-10 PM with music and bonfire.
                </li>
                <li>
                  Day 2: Wake up early to enjoy the sunrise with tea. then you
                  will get your breakfast at 8-9 AM after breakfast you may
                  checkout around 10 AM.
                </li>
              </ul>
              Note: During your whole camping duration you need to follow all
              camping rules.
            </div>
            <DesktopContentTitle
              title={"Camping and Rafting in Rishikesh"}
              more={false}
              id={"content-rrsm"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_rrseRef}
            >
              Camping in Rishikesh is a unique experience. You meet a lot of
              people sitting on the chairs, and music is playing with a bonfire.
              Beach camping is the best and most raw camping experience in
              Rishikesh, which will connect you with nature. Camping is a better
              option than a hotel because of the environment and experience. In
              this camping and rafting combo, we offer 3-time meals, music, a
              bonfire (Nov-Feb only), a 23-hour stay, and rafting. You can
              choose any rafting that suits you better. After camping a whole
              day, the next day, after breakfast, we will take you for river
              rafting.
            </div>

            <DesktopContentTitle
              title={"Advantages"}
              more={false}
              id={"content-itinerary"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_itineraryRef}
            >
              <ul>
                <li>
                  Provides a holistic adventure experience combining the
                  tranquility of camping with the thrill of rafting.
                </li>
                <li>
                  Offers a chance to connect with nature in a serene setting,
                  away from the hustle and bustle of city life.
                </li>
                <li>
                  Expertly curated packages cater to both beginners, providing a
                  gentle introduction, and experienced rafters, seeking the
                  ultimate challenge.
                </li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Child Policy for Camping in  Rishikesh"}
              more={false}
              id={"content-ecfrrr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_ecfrrrRef}
            >
              <ul>
                <li>Below 5 Complimentary </li>
                <li>5-10 half charge</li>
                <li>10+ full charge.</li>
              </ul>
            </div>

            <DesktopContentTitle
              title={"Inclusions "}
              more={false}
              id={"content-inclusions"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_inclusionsRef}
            >
              <ul>
                <li>3 Meals</li>
                <li>Evening snacks</li>
                <li>Attached washroom (Common washroom in Beach camps)</li>
                <li>Music till 10 PM</li>
                <li>Bonfire</li>
                <li>Swimming Pool</li>
                <li>Paid Parking</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Rishikesh camping Exclusions "}
              more={false}
              id={"content-exclusions"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_exclusionsRef}
            >
              <ul>
                <li>Any kind of transport.</li>
                <li>
                  Order Food (You can get on-order food that is not on the menu
                  by paying extra)
                </li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"How to Reach Rishikesh?"}
              more={false}
              id={"content-htrr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_htrrRef}
            >
              Planning your journey to Rishikesh? Here are valuable tips on
              transportation options, whether you're traveling by air, train, or
              road:
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>
                  By Air: The nearest airport to Rishikesh is the Jolly Grant
                  Airport in Dehradun, approximately 20-25 kilometers away. From
                  the airport, you can hire a taxi or use public transport to
                  reach Rishikesh.
                </li>
                <li>
                  By Train: Rishikesh has its own railway station; there are two
                  railway stations in Rishikesh (Rishikesh and Yog Nagari
                  Rishikesh) that are well connected to major cities in India.
                  From the station, you can easily find taxis or autorickshaws
                  to take you to your destination.
                </li>
                <li>
                  By Road: Rishikesh is well-connected by road to major cities
                  in North India. You can opt for private or state-run buses, or
                  even hire a taxi for a more comfortable journey. Mostly privet
                  buses take you to the nepalifar which is 12KM from Rishieksh
                  or you can take bus which is going directly to ISBT
                  Rishikesh/Bus adda Rishikesh.
                </li>
                <li>
                  Local Transportation: Once in Rishikesh, getting around is
                  relatively easy. Auto-rickshaws, E-rickshawa,taxis, buses and
                  rented motorcycles/car are readily available for local travel.
                </li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Things to Do in camping "}
              more={false}
              id={"content-cor"}
            />
            <div className="rafting-desktop-about-content" ref={content_corRef}>
              <ul>
                <li>Enjoying music, and bonfires, you are free to dance.</li>
                <li>Swimming in swimming pool</li>
                <li>Nature walk in the evening</li>
                <li>having all the meals.</li>
                <li>
                  Playing games in the evening like volleyball, badminton, and
                  cricket.
                </li>
                <li>Try activities like Commando Net, Burma Bridge, etc.</li>
              </ul>
            </div>
            <DesktopContentTitle
              title={"Things not to do"}
              more={false}
              id={"content-rirr"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_rirrRef}
            >
              <ul>
                <li>Don’t force anyone to play music after 10 PM</li>
                <li>
                  Drinking in the common area or at the Swimming pool area.
                </li>
                <li>Dusturing other groups in the campsite.</li>
                <li>Any kind of damage or harming camp property.</li>
              </ul>
            </div>

            <DesktopContentTitle
              title={"Camping Rules and Policy "}
              more={false}
              id={"content-ttcfrir"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_ttcfrirRef}
            >
              <ul>
                <li>
                  You need to make all payments while checking or show your
                  payment invoice.
                </li>
                <li>
                  Instant Checkout on any kind of misbehavior with staff
                  members.
                </li>
                <li>Drinking is not allowed in the swimming pool area.</li>
                <li>The last check-in time is 9 PM.</li>
                <li>You need to check out your time.</li>
                <li>Don’t harm camp property.</li>
                <li>You need to follow all the camping rules.</li>
              </ul>
            </div>
            {/* <div className="desktop-content-space"></div> */}
            <DesktopContentTitle
              title={"Camping in Rishikesh with Rafting"}
              more={false}
              id={"content-ttdw"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_ttdwRef}
            >
              Delve into the unique experiences offered by camping in Rishikesh
              combined with an adrenaline-pumping rafting adventure. This
              package ensures that you get the best of both worlds, with the
              serenity of nature and the thrill of the rapids. Imagine sitting
              by the campfire under a star-studded sky, recounting the day's
              adventures with fellow travelers.
            </div>
            <DesktopContentTitle
              title={"Unique Experiences:"}
              more={false}
              id={"content-tntd"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_tntdRef}
            >
              <ul>
                <li>
                  Allows you to reconnect with nature in a tranquil camping
                  setting, far from the hustle and bustle of city life.
                </li>
                <li>
                  Pairs perfectly with the adrenaline rush of rafting for an
                  unforgettable adventure that balances excitement with moments
                  of peace and reflection.
                </li>
              </ul>
            </div>

            <DesktopContentTitle
              title={"People Also Ask About Rishikesh"}
              more={false}
              id={"content-paaar"}
            />
            <div
              className="rafting-desktop-about-content"
              ref={content_paaarRef}
            >
              <ul>
                <li>
                  Are there vegetarian food options available during camping and
                  rafting packages?
                </li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Yes, most camping and rafting packages in Rishikesh offer a
              variety of vegetarian food options.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>How to reach to campsite from Rishikesh?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              There are multiple options available to reach our campsite. you
              can take a bus from Rishikesh Bus Adda, a bus will drop you at the
              highway then you just need to walk 1 km to reach our campsite. you
              can take cabs from Rishieksh market or you can take Scooty on
              rent. if you have any privet vehicles you can directly come.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>What is the best camping spot in Rishikesh?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Shivpuri is known as are camping hub of Rishikesh with most of
              number the camping sites. then Ghatta ghat and Rattakani then
              Kyarki and Neerguddu. if you want other activities like bungee,
              river rafting, zipline, etc at the same location you can go to
              Shivpuri. Ghattughat and RAttapani are best for River side camps
              and Kyarki and NeerGhuddu are best for a Night view of Rishikesh
              city.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>What are the best months for camping in Rishikesh?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              The best months for camping in Rishikesh are generally from
              September to June when the weather is pleasant and suitable for
              outdoor activities. and best for bonfire- music night.
            </div>
            <div className="rafting-desktop-about-content">
              <ul>
                <li>Are camping sites in Rishikesh accessible by road?</li>
              </ul>
            </div>
            <div className="rafting-desktop-about-content">
              Yes, most camping sites in Rishikesh are accessible by road.
              However, some remote sites may require a short trek to reach.
            </div>

            <div className="rafting-content-space"></div>
          </div>
          <div className="rafting-desktop-body-2">
            {/* here will be our form */}
            <div className="backdiv" ref={form_here}>
              <div className="head_background_color">
                <div className="form_heading">
                  <sapn className={"color-orange"}>
                    INR {price[current_type]}
                  </sapn>
                  /person
                </div>
                <div className="form_km_rapid1">
                  <span ref={blink_begin}>2 Day</span>
                  {/* <span className="form-km-space">·</span> */}
                  <span className="fkm_form"></span>
                  <span className="fkm_form"></span>&
                  <span className="fkm_form"></span>
                  <span className="fkm_form"></span>
                  <span ref={blink_last}>1 Night</span>
                  <span className="fkm_form"></span>
                </div>
                <div className="form_km_rapid2">
                  <span ref={kms_blink}> Accomodation, Activites, Meals </span>{" "}
                </div>
              </div>
              <div className="form_fill_heading">Fill your details</div>
              <div className="form_discription">
                Need instant camping? Dial
                <span className="form_no_color">+91-9084040280</span>
              </div>
              <TextInput
                title={"Name"}
                placeholder={"Enter your name"}
                type={"text"}
                set={setFormName}
                get={formName}
              />
              <TextInput
                title={"Contact"}
                placeholder={"Enter your contact"}
                type={"text"}
                get={formContact}
                set={setFormContact}
              />
              <TextInput
                title={"Email"}
                placeholder={"Enter your Email"}
                type={"text"}
                get={formEmail}
                set={setFormEmail}
              />
              <div className="form_name_text">Type of camps</div>
              <div
                className="distances_div"
                ref={containerRef2}
                onMouseDown={handleMouseDown2}
                onMouseMove={handleMouseMove2}
                onMouseUp={handleMouseUp2}
                onMouseLeave={handleMouseUp2}
              >
                <button
                  className={
                    current_type == 1 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(1);
                  }}
                >
                  Beach Camp
                </button>
                <button
                  className={
                    current_type == 2 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(2);
                  }}
                >
                  Luxury Camp
                </button>
                <button
                  className={
                    current_type == 3 ? "options_color_div" : "options_div"
                  }
                  onClick={() => {
                    setCurrent_type(3);
                  }}
                >
                  AC Cottages
                </button>
              </div>
              <InputDate
                title={"Check In date"}
                placeholder={"Choose a date"}
                forhtml={"desktop_date"}
                setChoosen_date={setCheckIn}
                choosen_date={checkIn}
              />
              <InputDate
                title={"Check Out date"}
                placeholder={"Choose a date"}
                forhtml={"desktop_date"}
                setChoosen_date={SetCheckOut}
                choosen_date={checkOut}
              />
              {/* {choosen_date != undefined || choosen_date != "" ? (
                <>
                  <div className="form_name_text">
                    Slots Left: {choosen_date}
                  </div>
                  <div className="distances_div">
                    {slots.map((item, index) => {
                      return (
                        <button
                          className={
                            choosen_slot == index
                              ? "options_time_color_div"
                              : "options_time_div"
                          }
                          onClick={() => {
                            setChoosen_slot(index);
                          }}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                </>
              ) : null} */}
              <div className="number_viv">
                <div className="form_name_text">Total People</div>
                <div
                  className="form_chose_candidate"
                  ref={containerRef3}
                  onMouseDown={handleMouseDown3}
                  onMouseMove={handleMouseMove3}
                  onMouseUp={handleMouseUp3}
                  onMouseLeave={handleMouseUp3}
                >
                  <input
                    type="number"
                    className="formt_number_input"
                    placeholder="Choose another day"
                    onInput={(e) => {
                      setNo_people(e.target.value);
                    }}
                    value={no_people}
                  />
                  <button
                    className={
                      no_people >= 1 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(1);
                    }}
                  >
                    1
                  </button>
                  <button
                    className={
                      no_people >= 2 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(2);
                    }}
                  >
                    2
                  </button>
                  <button
                    className={
                      no_people >= 3 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(3);
                    }}
                  >
                    3
                  </button>
                  <button
                    className={
                      no_people >= 4 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(4);
                    }}
                  >
                    4
                  </button>
                  <button
                    className={
                      no_people >= 5 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(5);
                    }}
                  >
                    5
                  </button>
                  <button
                    className={
                      no_people >= 6 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(6);
                    }}
                  >
                    6
                  </button>
                  <button
                    className={
                      no_people >= 7 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(7);
                    }}
                  >
                    7
                  </button>
                  <button
                    className={
                      no_people >= 8 ? "form_no_div_active" : "form_no_div"
                    }
                    onClick={() => {
                      setNo_people(8);
                    }}
                  >
                    8
                  </button>
                </div>
              </div>
              <div className="form_blue_div">
                Once booked we will call you back to confirm
              </div>
              <div className="form_last_discription">
                Need instant camping? Dial <pre> </pre>
                <span className="form_no_color"> +91-9084040280</span>
              </div>
              <div className="form_last_proceed_div" onClick={submitdesktop}>
                {/* Pay INR{" "}
                {parseInt(price[current_type] * no_people).toLocaleString()} */}
                Book Now
              </div>
            </div>

            <div className="after-form">
              <AfterForm
                title={"Camping in Rishikesh at just"}
                price={"1000"}
                goto={form_here}
              />
              <BigGroup activity={"Camping"} />
            </div>
            {/* form ends here */}
          </div>
          <div></div>
        </div>

        {desktop_photo ? (
          <div className="rafting-desktop-image-section">
            <Photo_section_desktop
              setDesktop_photo={setDesktop_photo}
              photos={[
                require("../Public/beachCamp/beachcamp1.jpeg"),
                require("../Public/beachCamp/beachcamp2.jpeg"),
                require("../Public/beachCamp/beachcamp3.jpeg"),
                require("../Public/beachCamp/beachcamp4.jpeg"),
                require("../Public/beachCamp/beachcamp5.jpeg"),
                require("../Public/beachCamp/beachcamp6.jpeg"),
                require("../Public/beachCamp/beachcamp7.jpeg"),
                require("../Public/beachCamp/beachcamp8.jpeg"),
                require("../Public/beachCamp/beachcamp9.jpeg"),
                require("../Public/beachCamp/beachcamp10.jpeg"),
                require("../Public/beachCamp/beachcamp11.jpeg"),
                require("../Public/beachCamp/beachcamp12.jpeg"),
                require("../Public/beachCamp/beachcamp13.jpeg"),
                require("../Public/luxuryCamp/lc1.jpeg"),
                require("../Public/luxuryCamp/lc2.jpeg"),
                require("../Public/luxuryCamp/lc3.jpeg"),
                require("../Public/luxuryCamp/lc4.jpeg"),
                require("../Public/luxuryCamp/lc5.jpeg"),
                require("../Public/luxuryCamp/lc6.jpeg"),
                require("../Public/luxuryCamp/lc7.jpeg"),
                require("../Public/luxuryCamp/lc8.jpeg"),
                require("../Public/luxuryCamp/lc9.jpeg"),
                require("../Public/ACCottages/ac1.jpeg"),
                require("../Public/ACCottages/ac2.jpeg"),
                require("../Public/ACCottages/ac3.jpeg"),
                require("../Public/ACCottages/ac4.jpeg"),
                require("../Public/ACCottages/ac5.jpeg"),
                require("../Public/ACCottages/ac6.jpeg"),
                require("../Public/ACCottages/ac7.jpeg"),
                require("../Public/ACCottages/ac8.jpeg"),
                require("../Public/ACCottages/ac9.jpeg"),
              ]}
            />
          </div>
        ) : null}
        {desktop_status ? (
          <div className="rafting-desktop-image-section">
            <Status_section
              desktop_status={desktop_status}
              setDesktop_status={setDesktop_status}
              desktop_status_indi={desktop_status_indi}
            />
          </div>
        ) : null}
      </div>

      <div className="rafting-mobile-section">
        <HeaderMobile
          setPhotoSection={setPhotoSection}
          img={require("../Public/beachCamp/beachcamp4.jpeg")}
          title={"Camping in Rishikesh"}
          imgs={[
            require("../Public/beachCamp/beachcamp5.jpeg"),
            require("../Public/beachCamp/beachcamp7.jpeg"),
            require("../Public/luxuryCamp/lc6.jpeg"),
            require("../Public/ACCottages/ac2.jpeg"),
            require("../Public/ACCottages/ac3.jpeg"),
          ]}
        />
        <MainTitleCamping
          setForm={setForm}
          price={price[current_type]}
          // price={300}
          type={km[current_type]}
          rapids={rapids[current_type]}
          se={se}
          current_type={current_type}
          rapids_blink_mobile={rapids_blink_mobile}
          km_blink_mobile={km_blink_mobile}
          start_blink_mobile={start_blink_mobile}
          end_blink_mobile={end_blink_mobile}
        />
        <div className="rafting-options">
          <Options
            content={"Beach camps"}
            active={current_type == 1 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={1}
          />
          <Options
            content={"Luxury camps"}
            active={current_type == 2 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={2}
          />
          <Options
            content={"AC Cottages"}
            active={current_type == 3 ? true : false}
            setCurrent_type={setCurrent_type}
            current_no={3}
          />
          <div></div>
        </div>
        {/* <div className="activity-timeline">
          {where[current_type].map((item, index) => {
            return (
              <ActivityTimeline
                indicator={where[current_type][index].tag}
                title={where[current_type][index].title}
                img={where[current_type][index].img}
              />
            );
          })}

          <div></div>
        </div> */}
        <Title
          title={"Some of our memories"}
          description={"These are some of our best memories"}
          more={false}
        />

        <div className="activity-status-div">
          <Status
            img={require("../Public/beachCamp/beachcamp4.jpeg")}
            content={"Beach camp"}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/luxuryCamp/lc6.jpeg")}
            content={"Luxury camp"}
            setStatus={setStatus}
          />
          <Status
            img={require("../Public/ACCottages/ac2.jpeg")}
            content={"AC cottages"}
            setStatus={setStatus}
          />
        </div>
        <PageMenu
          menu={menu_mobile_content}
          current_mobile_menu={current_mobile_menu}
          setCurrent_mobile_menu={setCurrent_mobile_menu}
          title={"Camping"}
        />
        <TitleWithoutDescription content={"Camping in Rishikesh"} />
        <div className="activity-content-section" ref={aboutRefm}>
          Rishikesh is one of the best camping spots in India. with many
          activities like trekking/Nature walk, River Rafting, zipline, bonfire,
          night music, and scenic beauty. That’s why it is known as the
          “Adventure capital of India”. Camping is a totally different
          experience in Rishikesh. you meet other people talk to them enjoy the
          evening bonfire together. It’s not just about staying, you get to see
          beautiful mountains, the Ganga River, and much more. with hospitality
          camping also provides food service which is already included in the
          price. every year thousands of people come to Rishikesh to experience
          camping and other adventure activities like - river rafting, bungee
          jumping, rock climbing, paragliding, zipline. also, Rishikesh is a
          well-known spiritual place where people come to explore the Temples,
          ghat, and holy river Ganga. there are different types of camping in
          Rishikesh like Beach camping, luxury camping, and AC Cottages you can
          choose any one of these accordingly.
        </div>
        <TitleWithoutDescription content={"Types of Camps in Rishikesh "} />
        <div className="activity-content-section ulli" ref={content_scRefm}>
          <ul>
            <li>Beach camps</li>
            <li>Luxury camps</li>
            <li>AC Cottages</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Beach camp in Rishikesh"} />
        <div className="activity-content-section" ref={content_vroalRefm}>
          Beach camps are the type of camps that are located on the beach
          surrounded by forest and a front river view. these beach camps are for
          a totally raw experience and nature lovers. In the evening you can
          spend some time on the riverside to enjoy the fresh and cold wind that
          will give you a different level of experience.
        </div>
        <div className="activity-content-section">
          Beach camping in Rishikesh includes - staying in alpine tents, 5-8
          common washrooms (Indian + Western), light and charging points in a
          common area, 3-time meals, morning evening tea, and evening snacks.
          you can play some games like beach volleyball, and badminton or go for
          a walk at the riverside. Alcohol, non-veg food, and music are not
          allowed in beach camps.
        </div>
        <div className="activity-content-section">
          Note: Since 2015 Uttarakhand Tourism banned Ganga Beach Camps and now
          only 2-3 beach camping sites are available at Shivpuri.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Camp Capacity - 3 adults Max</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Luxury camps in Rishikesh"} />
        <div className="activity-content-section" ref={content_rwaacRefm}>
          These camps are specifically designed for your comfortable stay with
          beautiful mountain views. Luxury camps are the types of camps that
          have all the luxury facilities like 24x7 Electricity, a Swimming pool,
          a Cooler for summer, and gaming activities like Volleyball, badminton,
          Burma bridge, commando net, and much more. these are the alpine tents
          with more space than beach camps, 4-5 people can easily stay in 1 camp
        </div>
        <div className="activity-content-section">
          Luxury camps in Rishikesh include -Stay in Luxury alpine tents, 2
          Double bed, 3-time meals, evening snacks, morning and evening tea, a
          swimming pool, a Cooler, music till 10 PM, a Bonfire (Nov - Feb only),
          gaming activities, nature walk (guidance only), and Attech washroom in
          every camp.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Camp Capacity - 4-5 adults Max</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"AC Cottages in Rishikesh"} />
        <div className="activity-content-section" ref={content_carirRefm}>
          AC Cottage are the most comfertabel. these are not the Swiss tents but
          a wall of fiber. you get comfortable bedding, AC, and an attached
          washroom in the Cottages. you get the 2 Double beds in each cottage.
        </div>
        <div className="activity-content-section">
          AC Cottages in Rishikesh includes - Stay in Cottages, 2 Double bed,
          3-time meals, evening snacks, morning and evening tea, a swimming
          pool, an AC, music till 10 PM, a Bonfire (Nov - Feb only), gaming
          activities, a nature walk (guidance only), and Attech washroom in
          every cottage.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Camp Capacity - 4-7 adults Max (Ideal 4)</li>
          </ul>
        </div>

        <TitleWithoutDescription content={"Rishikesh Camping Itinerary"} />
        <div
          className="activity-content-section ulli"
          ref={content_advantagesRefm}
        >
          <ul>
            <li>
              Day 1: Briefing for camping rules and check-in After 11 AM,
              explore the camp and take a rest, 1-2 PM - Lunch time. then after
              taking a rest you can do camping activities or enjoy yourself in
              the pool. 7-8 PM Evening snacks then enjoy your dinner 9-10 PM
              with music and bonfire.
            </li>
            <li>
              Day 2: Wake up early to enjoy the sunrise with tea. then you will
              get your breakfast at 8-9 AM after breakfast you may checkout
              around 10 AM.
            </li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Note: During your whole camping duration you need to follow all
          camping rules.
        </div>

        <TitleWithoutDescription content={"Camping and Rafting in Rishikesh"} />
        <div className="activity-content-section" ref={content_rrseRefm}>
          Camping in Rishikesh is a unique experience. You meet a lot of people
          sitting on the chairs, and music is playing with a bonfire. Beach
          camping is the best and most raw camping experience in Rishikesh,
          which will connect you with nature. Camping is a better option than a
          hotel because of the environment and experience. In this camping and
          rafting combo, we offer 3-time meals, music, a bonfire (Nov-Feb only),
          a 23-hour stay, and rafting. You can choose any rafting that suits you
          better. After camping a whole day, the next day, after breakfast, we
          will take you for river rafting.
        </div>
        <TitleWithoutDescription content={"Advantages"} />
        <div
          className="activity-content-section ulli"
          ref={content_itineraryRefm}
        >
          <ul>
            <li>
              Provides a holistic adventure experience combining the tranquility
              of camping with the thrill of rafting.
            </li>
            <li>
              Offers a chance to connect with nature in a serene setting, away
              from the hustle and bustle of city life.
            </li>
            <li>
              Expertly curated packages cater to both beginners, providing a
              gentle introduction, and experienced rafters, seeking the ultimate
              challenge.
            </li>
          </ul>
        </div>
        <TitleWithoutDescription
          content={"Child Policy for Camping in  Rishikesh"}
        />
        <div className="activity-content-section ulli" ref={content_ecfrrrRefm}>
          <ul>
            <li>Below 5 Complimentary</li>
            <li>5-10 half charge</li>
            <li>10+ full charge.</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Inclusions"} />
        <div
          className="activity-content-section ulli"
          ref={content_inclusionsRefm}
        >
          <ul>
            <li>3 Meals</li>
            <li>Evening snacks</li>
            <li>Attached washroom (Common washroom in Beach camps)</li>
            <li>Music till 10 PM</li>
            <li>Bonfire</li>
            <li>Swimming Pool</li>
            <li>Paid Parking</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Exclusions"} />
        <div
          className="activity-content-section ulli"
          ref={content_exclusionsRefm}
        >
          <ul>
            <li>Any kind of transport.</li>
            <li>
              Order Food (You can get on-order food that is not on the menu by
              paying extra)
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"How to Reach Rishikesh?"} />
        <div className="activity-content-section" ref={content_htrrRefm}>
          Planning your journey to Rishikesh? Here are valuable tips on
          transportation options, whether you're traveling by air, train, or
          road:
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>
              By Air: The nearest airport to Rishikesh is the Jolly Grant
              Airport in Dehradun, approximately 20-25 kilometers away. From the
              airport, you can hire a taxi or use public transport to reach
              Rishikesh.
            </li>
            <li>
              By Train: Rishikesh has its own railway station; there are two
              railway stations in Rishikesh (Rishikesh and Yog Nagari Rishikesh)
              that are well connected to major cities in India. From the
              station, you can easily find taxis or autorickshaws to take you to
              your destination.
            </li>
            <li>
              By Road: Rishikesh is well-connected by road to major cities in
              North India. You can opt for private or state-run buses, or even
              hire a taxi for a more comfortable journey. Mostly privet buses
              take you to the nepalifar which is 12KM from Rishieksh or you can
              take bus which is going directly to ISBT Rishikesh/Bus adda
              Rishikesh.
            </li>
            <li>
              Local Transportation: Once in Rishikesh, getting around is
              relatively easy. Auto-rickshaws, E-rickshawa,taxis, buses and
              rented motorcycles/car are readily available for local travel.
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Things to Do in camping"} />

        <div className="activity-content-section ulli" ref={content_corRefm}>
          <ul>
            <li>Enjoying music, and bonfires, you are free to dance.</li>
            <li>Swimming in swimming pool</li>
            <li>Nature walk in the evening</li>
            <li>having all the meals.</li>
            <li>
              Playing games in the evening like volleyball, badminton, and
              cricket.
            </li>
            <li>Try activities like Commando Net, Burma Bridge, etc.</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Things not to do"} />
        <div className="activity-content-section ulli" ref={content_rirrRefm}>
          <ul>
            <li>Don’t force anyone to play music after 10 PM</li>
            <li>Drinking in the common area or at the Swimming pool area.</li>
            <li>Dusturing other groups in the campsite.</li>
            <li>Any kind of damage or harming camp property.</li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Camping Rules and Policy "} />
        <div
          className="activity-content-section ulli"
          ref={content_ttcfrirRefm}
        >
          <ul>
            <li>
              You need to make all payments while checking or show your payment
              invoice.
            </li>
            <li>
              Instant Checkout on any kind of misbehavior with staff members.
            </li>
            <li>Drinking is not allowed in the swimming pool area.</li>
            <li>The last check-in time is 9 PM.</li>
            <li>You need to check out your time.</li>
            <li>Don’t harm camp property.</li>
            <li>You need to follow all the camping rules.</li>
          </ul>
        </div>
        <TitleWithoutDescription
          content={"Camping in Rishikesh with Rafting"}
        />
        <div className="activity-content-section ulli" ref={content_cirwrRefmc}>
          Delve into the unique experiences offered by camping in Rishikesh
          combined with an adrenaline-pumping rafting adventure. This package
          ensures that you get the best of both worlds, with the serenity of
          nature and the thrill of the rapids. Imagine sitting by the campfire
          under a star-studded sky, recounting the day's adventures with fellow
          travelers.
        </div>

        <TitleWithoutDescription content={"Unique Experiences"} />
        <div className="activity-content-section ulli" ref={content_ttdwRefm}>
          <ul>
            <li>
              Allows you to reconnect with nature in a tranquil camping setting,
              far from the hustle and bustle of city life.
            </li>
            <li>
              Pairs perfectly with the adrenaline rush of rafting for an
              unforgettable adventure that balances excitement with moments of
              peace and reflection.
            </li>
          </ul>
        </div>
        <TitleWithoutDescription content={"Frequently asked questions"} />
        <div className="activity-content-section ulli" ref={content_paaarRefm}>
          <ul>
            <li>
              Are there vegetarian food options available during camping and
              rafting packages?
            </li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Yes, most camping and rafting packages in Rishikesh offer a variety of
          vegetarian food options.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>How to reach to campsite from Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          There are multiple options available to reach our campsite. you can
          take a bus from Rishikesh Bus Adda, a bus will drop you at the highway
          then you just need to walk 1 km to reach our campsite. you can take
          cabs from Rishieksh market or you can take Scooty on rent. if you have
          any privet vehicles you can directly come.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>What is the best camping spot in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Rishikesh takes safety seriously. All rafting equipment undergoes
          regular maintenance and quality checks. Expert guides accompany each
          expedition, well-versed in river dynamics and rescue techniques. A
          thorough pre-departure briefing is conducted, and a well-defined
          emergency response plan is in place. a person will only get the river
          guide license after he passes the guide test conducted by the UTDB
          team.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>What is the best camping spot in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Shivpuri is known as are camping hub of Rishikesh with most of number
          the camping sites. then Ghatta ghat and Rattakani then Kyarki and
          Neerguddu. if you want other activities like bungee, river rafting,
          zipline, etc at the same location you can go to Shivpuri. Ghattughat
          and RAttapani are best for River side camps and Kyarki and NeerGhuddu
          are best for a Night view of Rishikesh city.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>What are the best months for camping in Rishikesh?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          The best months for camping in Rishikesh are generally from September
          to June when the weather is pleasant and suitable for outdoor
          activities. and best for bonfire- music night.
        </div>
        <div className="activity-content-section ulli">
          <ul>
            <li>Are camping sites in Rishikesh accessible by road?</li>
          </ul>
        </div>
        <div className="activity-content-section ulli">
          Yes, most camping sites in Rishikesh are accessible by road. However,
          some remote sites may require a short trek to reach.
        </div>

        {/* form starts here */}
        {form ? (
          <GlobalFormCamping
            setForm={setForm}
            mobile_contact={mobile_contact}
            mobile_date={mobile_date}
            mobile_dor={mobile_dor}
            mobile_email={mobile_email}
            mobile_name={mobile_name}
            mobile_no_of_people={mobile_no_of_people}
            setMobile_contact={setMobile_contact}
            setMobile_dor={setMobile_dor}
            setMobile_email={setMobile_email}
            setMobile_name={setMobile_name}
            setMobile_no_of_people={setMobile_no_of_people}
            setMobile_date={setMobile_date}
            setMobile_slot={setMobile_slot}
            mobile_slot={mobile_slot}
            current_type={current_type}
            setCurrent_type={setCurrent_type}
            price={price}
            km={km}
            submitMobile={submitMobile}
            checkIn={checkIn}
            setCheckIn={setCheckIn}
            checkOut={checkOut}
            SetCheckOut={SetCheckOut}
          />
        ) : null}

        {status ? (
          <GlobalPhotoStatus
            setStatus={setStatus}
            desktop_status={desktop_status}
            desktop_status_indi={desktop_status_indi}
          />
        ) : null}
        {photoSection ? (
          <PhotoSection
            setPhotoSection={setPhotoSection}
            photos={[
              require("../Public/beachCamp/beachcamp1.jpeg"),
              require("../Public/beachCamp/beachcamp2.jpeg"),
              require("../Public/beachCamp/beachcamp3.jpeg"),
              require("../Public/beachCamp/beachcamp4.jpeg"),
              require("../Public/beachCamp/beachcamp5.jpeg"),
              require("../Public/beachCamp/beachcamp6.jpeg"),
              require("../Public/beachCamp/beachcamp7.jpeg"),
              require("../Public/beachCamp/beachcamp8.jpeg"),
              require("../Public/beachCamp/beachcamp9.jpeg"),
              require("../Public/beachCamp/beachcamp10.jpeg"),
              require("../Public/beachCamp/beachcamp11.jpeg"),
              require("../Public/beachCamp/beachcamp12.jpeg"),
              require("../Public/beachCamp/beachcamp13.jpeg"),
              require("../Public/luxuryCamp/lc1.jpeg"),
              require("../Public/luxuryCamp/lc2.jpeg"),
              require("../Public/luxuryCamp/lc3.jpeg"),
              require("../Public/luxuryCamp/lc4.jpeg"),
              require("../Public/luxuryCamp/lc5.jpeg"),
              require("../Public/luxuryCamp/lc6.jpeg"),
              require("../Public/luxuryCamp/lc7.jpeg"),
              require("../Public/luxuryCamp/lc8.jpeg"),
              require("../Public/luxuryCamp/lc9.jpeg"),
              require("../Public/ACCottages/ac1.jpeg"),
              require("../Public/ACCottages/ac2.jpeg"),
              require("../Public/ACCottages/ac3.jpeg"),
              require("../Public/ACCottages/ac4.jpeg"),
              require("../Public/ACCottages/ac5.jpeg"),
              require("../Public/ACCottages/ac6.jpeg"),
              require("../Public/ACCottages/ac7.jpeg"),
              require("../Public/ACCottages/ac8.jpeg"),
              require("../Public/ACCottages/ac9.jpeg"),
            ]}
          />
        ) : null}
        <Footer />
        {/* <div className="space"></div> */}
        <BottomTab active={"camping"} />
      </div>
    </>
  );
}

export default Camping;
