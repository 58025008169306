import "./Faq.css";
export default function Faq({ open, setOpen, question, answer }) {
  return (
    <div
      className="lp-faq-whole"
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className="lp-faq-question">{question}</div>
      {open ? <div className="lp-faq-answer">{answer}</div> : null}
    </div>
  );
}
