import "./Options.css";
function Options({ content, active, r, setCurrent_mobile_menu, index }) {
  console.log("this is ref -- " + r);
  return active ? (
    <div className="wide-content-active">{content}</div>
  ) : (
    <div
      className="wide-content-inactive"
      onClick={() => {
        setCurrent_mobile_menu(index);
        let t = r.current.getBoundingClientRect().top + window.pageYOffset;
        let scrollToPosition = t - 170;
        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth",
        });
        // console.log(content_ttdw);
      }}
    >
      {content}
    </div>
  );
}

export default Options;
