import "./Status_section.css";
import React, { useEffect, useState } from "react";
// import back from "../Public/Destination.jpg";
import music from "../Public/music.mp3";

function Status_section({
  setDesktop_status,
  desktop_status,
  desktop_status_indi,
}) {
  const [time, setTime] = useState(15);
  const [music_on_off, setMusic_on_off] = useState(false);
  const [current_index, setCurrent_index] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrent_index((prevIndex) =>
        prevIndex === desktop_status_indi.length - 1 ? 0 : prevIndex + 1
      ); // Increment image index or reset to 0 when reaching the end
    }, 5000); // Change the interval to trigger every 15 seconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="Status_section_div">
      <audio src={music} autoPlay muted={music_on_off} loop></audio>
      <img
        src={desktop_status_indi[current_index].img}
        className="Status_section_background_img"
        alt=""
        loading="lazy"
      />
      <div className="staus_section_range_content">
        <div className="staus_section_range_div">
          <div className="staus_section_range_color"></div>
        </div>
        <div className="staus_section_content_div">
          <div className="thrill_logo">
            <img
              src={desktop_status_indi[current_index].smallimg}
              className="Status_section_background_img"
              alt=""
              loading="lazy"
            />
          </div>
          <div className="content_and_svg_div">
            <div className="staus_section_content">
              <div className="staus_section_activity_name">
                {desktop_status_indi[current_index].title}
              </div>
              <div className="staus_section_activity_subscription">
                {desktop_status_indi[current_index].des}
              </div>
            </div>
            <div className="staus_section_svg_div">
              <div
                onClick={() => {
                  setMusic_on_off(!music_on_off);
                }}
              >
                {!music_on_off ? (
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="speaker_status_desktop"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M19 6C20.5 7.5 21 10 21 12C21 14 20.5 16.5 19 18M16 8.99998C16.5 9.49998 17 10.5 17 12C17 13.5 16.5 14.5 16 15M3 10.5V13.5C3 14.6046 3.5 15.5 5.5 16C7.5 16.5 9 21 12 21C14 21 14 3 12 3C9 3 7.5 7.5 5.5 8C3.5 8.5 3 9.39543 3 10.5Z"
                        className="stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="speaker_status_desktop"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M22 9L16 15M16 9L22 15M3 10.5V13.5C3 14.6046 3.5 15.5 5.5 16C7.5 16.5 9 21 12 21C14 21 14 3 12 3C9 3 7.5 7.5 5.5 8C3.5 8.5 3 9.39543 3 10.5Z"
                        className="stroke-white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                )}
              </div>
              <div>
                <svg
                  height={40}
                  width={40}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setDesktop_status(!desktop_status);
                  }}
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="staus_section_book_div">
        <div className="company_img_contect">
          <div className="staus_section_company_img">
            <img src={back} className="company_img" alt="" />
          </div>
          <div className="staus_section_company_content">
            <div className="staus_section_company_name">
              12 km River Rafting
            </div>
            <div className="staus_section_company_discription">
              ₹899/person · 5 rapids
            </div>
          </div>
        </div>
        <button className="staus_section_booknoe_button">
          <div className="book_text"> Book Now</div>
          <div className="staus_section_next_svg">
            <svg
              fill="#fff"
              height="15px"
              width="15px"
              version="1.1"
              id="Layer_1"
              viewBox="0 0 512.005 512.005"
              stroke="#fff"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <g>
                    <path d="M388.418,240.923L153.751,6.256c-8.341-8.341-21.824-8.341-30.165,0s-8.341,21.824,0,30.165L343.17,256.005 L123.586,475.589c-8.341,8.341-8.341,21.824,0,30.165c4.16,4.16,9.621,6.251,15.083,6.251c5.461,0,10.923-2.091,15.083-6.251 l234.667-234.667C396.759,262.747,396.759,249.264,388.418,240.923z"></path>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </button>
      </div> */}
    </div>
  );
}

export default Status_section;
