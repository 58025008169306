import "./Terms_condition.css";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "../GlobalComponents/Footer";
import DesktopFooter from "./DesktopFooter";
import BottomTab from "../ComponentsHome/BottomTab";
import { useEffect } from "react";
function Terms_condition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about_header">
        <Header />
      </div>
      <div className="p_P_div">
        <Helmet>
          <title>Thrill Thirsty: Terms and Condition</title>
          <meta
            name="description"
            content="This is a must-read before you book any package or adventure activity on our website. Check the cancellation policy with the booking process as well."
          />
        </Helmet>
        <div className="pp_inner_div">
          <div className="Privacy_policy_div">
            <div className="h_text_one">Terms and condition</div>

            <p>
              Welcome to Thrill Thirsty! We're thrilled to have you join us on
              an adventure-packed journey in the heart of Rishikesh. Before you
              embark on your adrenaline-fueled escapades, please take a moment
              to review our Terms and Conditions outlined below:
            </p>
            <p>Booking and Payment:</p>
            <ol>
              <li>
                To reserve your spot for any activity, advance booking is
                required through our website or authorized booking partners.
              </li>
              <li>
                Full payment must be made at the time of booking to secure your
                reservation.
              </li>
              <li>
                We accept payment via various methods, including credit/debit
                cards, online transfers, UPI and other online payment option.
              </li>
            </ol>
            <p>2. Cancellation and Refunds:</p>
            <ol>
              <li>
                The Customer will bear no cancellation charge on cancelling 30
                days prior to the booking date.
              </li>
              <li>
                The Customer will bear 50% of the booking amount as the
                cancellation fee if cancelled 15 days prior to the booking date.
              </li>
              <li>
                No refund will be made in case of cancelling 7 days prior to the
                booking date.
              </li>
              <li>
                If the customer fails to reach the destination on the day of
                booking, no refund will be processed.
              </li>
              <li>
                The refunds will be processed through original payment methods
                within 10 working days after cancellation.
              </li>
            </ol>
            <p>3. Participant Requirements:</p>
            <ol>
              <li>
                Participants must meet the minimum age and health requirements
                specified for each activity.
              </li>
              <li>
                All participants are required to sign a waiver/release form
                before engaging in any adventure activity.
              </li>
              <li>
                It is the responsibility of participants to disclose any medical
                conditions or disabilities that may affect their participation.
              </li>
            </ol>
            <p>4. Safety Regulations:</p>
            <ol>
              <li>
                Safety is our top priority at Thrill Thirsty. All activities are
                conducted under the supervision of experienced guides and
                instructors.
              </li>
              <li>
                Participants are required to follow safety instructions provided
                by our staff at all times.
              </li>
              <li>
                Use of safety equipment, such as helmets, life jackets, and
                harnesses, is mandatory for certain activities.
              </li>
            </ol>
            <p>5. Liability Waiver:</p>
            <ol>
              <li>
                Participation in adventure activities involves inherent risks,
                including but not limited to physical injury and property
                damage.
              </li>
              <li>
                By engaging in our activities, participants acknowledge and
                accept these risks and agree to release Thrill Thirsty and its
                affiliates from any liability for accidents or injuries that may
                occur.
              </li>
            </ol>
            <p>6. Code of Conduct:</p>
            <ol>
              <li>
                We expect all participants to conduct themselves in a respectful
                and responsible manner towards fellow participants, staff, and
                the environment.
              </li>
              <li>
                Any behaviour deemed dangerous, disruptive, or inappropriate
                will result in immediate termination of the activity without
                refund.
              </li>
            </ol>
            <p>7. Personal Belongings:</p>
            <ol>
              <li>
                Thrill Thirsty is not responsible for any loss or damage to
                personal belongings during the course of the activity.
              </li>
              <li>
                Participants are advised to leave valuables such as jewellery,
                electronics, and large sums of cash secured at their
                accommodations.
              </li>
            </ol>
            <p>8. Photography and Media:</p>
            <ol>
              <li>
                Thrill Thirsty may capture photographs or videos during
                activities for promotional purposes.
              </li>
              <li>
                Participants who do not wish to be photographed or filmed must
                inform our staff in advance.
              </li>
            </ol>
            <p>9. Environmental Conservation:</p>
            <ol>
              <li>
                We are committed to preserving the natural beauty of Rishikesh
                and its surroundings.
              </li>
              <li>
                Participants are expected to dispose of waste responsibly and
                refrain from littering or damaging the environment.
              </li>
            </ol>
            <p>10. Governing Law:</p>
            <ol>
              <li>
                These Terms and Conditions are governed by the laws of India.
                Any disputes arising out of or related to these terms shall be
                resolved through arbitration in Rishikesh.
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="about_footer_desktop">
        <DesktopFooter />
      </div>
      <div className="about_footer_mobile">
        <Footer />
        <BottomTab />
      </div>
    </>
  );
}

export default Terms_condition;
