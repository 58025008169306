import axios from "axios";
import { useEffect } from "react";
import Header from "../DesktopComponents/Header";
import HeaderMobile from "../ComponentsActivity/HeaderMobile";
import Footer from "../GlobalComponents/Footer";
import DesktopFooter from "../DesktopComponents/DesktopFooter";
import "./ThankYou.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ThankYouBungee() {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const type = urlParams.get("type");
  const bookingvalue = urlParams.get("bookingvalue");
  let map =
    type == 1
      ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.1626289249634!2d78.38223597511555!3d30.133360474878906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909114edc59aaf9%3A0xb24defa21b719cd5!2sThe%20Shivpuri%20Thrill%20Factory!5e1!3m2!1sen!2sin!4v1712241452392!5m2!1sen!2sin"
      : type == 2
      ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.3451510733753!2d78.39931857511259!3d30.063998674913723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909139eafbe2707%3A0xfb6d8d14f907f8ac!2sJumpin%20Heights%20-%20The%20Bungy%20People*21!5e1!3m2!1sen!2sin!4v1712241414623!5m2!1sen!2sin"
      : type == 3
      ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.1657850819165!2d78.38182357511565!3d30.133260274879003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3909112d60116987%3A0xd458093fc744f14!2sSplash%20Bungy!5e1!3m2!1sen!2sin!4v1712241357647!5m2!1sen!2sin"
      : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24873.428456875048!2d78.335769063935!3d30.132854792230777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390911131a00b57d%3A0x35e4d90f217978fa!2sBungee%20in%20Rishikesh%20%7C%20highest%20bungee%20in%20india%20%7C%20Himalayan%20Bungy!5e1!3m2!1sen!2sin!4v1712241267024!5m2!1sen!2sin";
  const download_invoice = () => {
    if (id != "" || id != null || id != undefined) {
      axios
        .post(
          "https://api.thrillthirsty.com/activities/rafting/thank-you",
          {
            id: id,
          },
          {
            responseType: "blob", // Set responseType to 'blob' to receive binary data
          }
        )
        .then((res) => {
          // Create a URL for the blob data
          const url = window.URL.createObjectURL(new Blob([res.data]));

          // Create an <a> element to trigger the file download
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "invoice.pdf");
          document.body.appendChild(link);
          link.click();

          // Clean up after the download is complete
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    }
  };
  useEffect(() => {
    download_invoice();
  }, []);

  useEffect(() => {
    if (
      bookingvalue == "" ||
      bookingvalue == undefined ||
      bookingvalue == null
    ) {
    } else {
      window.dataLayer.push({
        event: "Booking_Confirm_Bungee",
        adventure: "bungee",
        ecommerce_transaction_id: id,
        ecommerce_value: bookingvalue,
      });
      urlParams.delete("bookingvalue");
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
  }, []);
  return (
    <>
      <div className="desktop-thankyou-section">
        <Helmet>
          <title>Thank you</title>
          <meta
            name="description"
            content="Thank you for booking with Thrillthirsty"
          />
        </Helmet>
        <Header />
        <div className="Thanku_page-div">
          <div className="Thanku_page-center-div">
            <div className="Thanku_page-first-thanku-div">
              <div className="Thanku_page-tick-circle-div">
                <svg
                  height={80}
                  width={80}
                  fill="#fff"
                  viewBox="0 0 1024 1024"
                  stroke="#fff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z"></path>
                  </g>
                </svg>
              </div>
              <div className="Thanku_page-thanku-test-h-div">
                Thank you for <br /> Booking!
              </div>
              <div className="Thanku_page-thanku-discription-h-div">
                Booking confirmed! Expect an email soon—check spam, just in
                case. Your invoice will be downloaded automatically. Need help?
                Call at <span className="orange">+91-9084040280</span>
              </div>
              <div className="t-y-page-call-download-btn-div">
                <button
                  onClick={download_invoice}
                  className="Thanku_page-download-now-button"
                >
                  Download Invoice
                </button>
              </div>
            </div>
            <div className="Thanku_page-second-invoice-div">
              <div className="in-page-div-map-heading">
                Your reporting location -
              </div>
              <div className="in-page-div-map">
                <iframe
                  src={map}
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  frameBorder={0}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <DesktopFooter />
      </div>
      <div className="mobile-thank-you-section">
        <div className="Thanku_page-div">
          <div className="Thanku_page-center-div">
            <div className="Thanku_page-first-thanku-div">
              <div className="Thanku_page-tick-circle-div">
                <svg
                  height={80}
                  width={80}
                  fill="#fff"
                  viewBox="0 0 1024 1024"
                  stroke="#fff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M760 380.4l-61.6-61.6-263.2 263.1-109.6-109.5L264 534l171.2 171.2L760 380.4z"></path>
                  </g>
                </svg>
              </div>
              <div className="Thanku_page-thanku-test-h-div">
                Thank you for <br /> Booking!
              </div>
              <div className="Thanku_page-thanku-discription-h-div">
                Booking confirmed! Expect an email soon—check spam, just in
                case. Your invoice will be downloaded automatically. Need help?
                Call at{" "}
                <Link to={"tel:+919084040280"} className="orange">
                  +91-9084040280
                </Link>
              </div>
              <div className="t-y-page-call-download-btn-div">
                <button
                  onClick={download_invoice}
                  className="Thanku_page-download-now-button"
                >
                  Download Invoice
                </button>
              </div>
            </div>
            <div className="Thanku_page-second-invoice-div">
              <div className="in-page-div-map-heading">
                Your reporting location -
              </div>
              <div className="in-page-div-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55212.670905281775!2d78.25202352167972!3d30.128766799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390916475add96c1%3A0x6d6d8ece8afacddb!2sBhandari%20adventure!5e0!3m2!1sen!2sin!4v1711957975394!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  frameBorder={0}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ThankYouBungee;
