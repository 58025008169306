import Indi_section from "../landing_page/Indi_section";
import Review from "../landing_page/Review";
import "./Ankit.css";
import Faq from "../landing_page/Faq";
import { useEffect, useState } from "react";
export default function RishikeshCamping() {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  const [faq5, setFaq5] = useState(false);
  const [faq6, setFaq6] = useState(false);
  return (
    <>
      <div className="landing-page">
        <div className="landing-thrill-name">
          <span className="landing-thrill-text mogra">Thrill</span>
          <span className="landing-thristy-text mogra">Thirsty</span>
        </div>
        <div className="landing-image-and-content-div">
          <div className="landing-image-header-div">
            <img
              src={require("../Public/luxuryCamp/lc12.jpeg")}
              alt=""
              className="landing-image-header"
            />
          </div>
          <div className="landeing-header-content-div">
            <div className="landeing-header-activity-name">
              Rishikesh Camping
            </div>
            <div className="landeing-header-discription-name">
              Experince the most booked adventure activity of Rishikesh
            </div>
            <div className="landeing-header-title-name">
              Experience camping in rishikesh with family, friends and your
              loved ones. you will get relaxing weekend in rishikesh with clean
              and hygine tents, music, bonfire and delicious food. You will
              create some unforgatable memories with your loved ones. It’s not
              just about staying, you get to see beautiful mountains, the Ganga
              River, and much more
            </div>
            <div className="landing-header-btn-div">
              <a className="landing-call-now-btn" href="tel://9084040280">
                Call now
              </a>
              <a
                className="landing-enquire-now-btn"
                href="http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty"
              >
                Enquire now
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="lp-strip-white">camping in rishikesh</div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Indi_section
          image={require("../Public/beachCamp/beachcamp4.jpeg")}
          title={"Normal Camp"}
          keys={["STARTING PRICE", "OCCUPANCY", "DURATION", "MEALS"]}
          values={["999PP", "2/3", "23 Hours", "3 Meals + Evening snacks"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%20normal%20camps"
          }
        />
        <Indi_section
          image={require("../Public/luxuryCamp/lc4.jpeg")}
          title={"Luxury Camp"}
          keys={["STARTING PRICE", "OCCUPANCY", "DURATION", "CHALLENGE LEVEL"]}
          values={["599PP", "2/3/4", "23 Hours", "3 Meals + Evening snacks"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%20luxury%20camps"
          }
        />
        <Indi_section
          image={require("../Public/ACCottages/ac2.jpeg")}
          title={"AC Cottages"}
          keys={["STARTING PRICE", "OCCUPANCY", "DURATION", "CHALLENGE LEVEL"]}
          values={["599PP", "2/3/4", "23 Hours", "3 Meals + Evening snacks"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%20AC%20Cottages"
          }
        />
      </div>

      <div className="lp-strip">Frequestly Asked Questions</div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Faq
          open={faq1}
          setOpen={setFaq1}
          question={
            "Are there vegetarian food options available during camping and rafting packages?"
          }
          answer={
            "Yes, most camping and rafting packages in Rishikesh offer a variety of vegetarian food options."
          }
        />
        <Faq
          open={faq2}
          setOpen={setFaq2}
          question={"How to reach to campsite from Rishikesh?"}
          answer={
            "There are multiple options available to reach our campsite. you can take a bus from Rishikesh Bus Adda, a bus will drop you at the highway then you just need to walk 1 km to reach our campsite. you can take cabs from Rishieksh market or you can take Scooty on rent. if you have any privet vehicles you can directly come."
          }
        />
        <Faq
          open={faq3}
          setOpen={setFaq3}
          question={"What is the best camping spot in Rishikesh?"}
          answer={
            "Shivpuri is known as are camping hub of Rishikesh with most of number the camping sites. then Ghatta ghat and Rattakani then Kyarki and Neerguddu. if you want other activities like bungee, river rafting, zipline, etc at the same location you can go to Shivpuri. Ghattughat and RAttapani are best for River side camps and Kyarki and NeerGhuddu are best for a Night view of Rishikesh city."
          }
        />
        <Faq
          open={faq4}
          setOpen={setFaq4}
          question={"What are the best months for camping in Rishikesh?"}
          answer={
            "The best months for camping in Rishikesh are generally from September to June when the weather is pleasant and suitable for outdoor activities. and best for bonfire- music night."
          }
        />
        <Faq
          open={faq5}
          setOpen={setFaq5}
          question={"Are camping sites in Rishikesh accessible by road?"}
          answer={
            "Yes, most camping sites in Rishikesh are accessible by road. However, some remote sites may require a short trek to reach."
          }
        />
        {/* <Faq
          open={faq6}
          setOpen={setFaq6}
          question={"WHAT ARE THE VIDEOS CHARGES IN BUNGEE?"}
          answer={
            "Video charges are diffrent for each bungee right now only splash bungee provide free videos and other charges between 800-1500."
          }
        /> */}
      </div>

      <div className="lp-strip">Customers reviews</div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Review
          msg={
            "Very good experience..best place..staff is very cooperative..and serve variety of food veg and non veg.. snacks and fresh food.we hope next time we will stay there again..🏕️..👍"
          }
          name={"Meenakshi Kapil"}
          image={
            "https://lh3.googleusercontent.com/a/ACg8ocLGQKWhXFk3QQCs6CMCm5BPE4zrRsUjdoQcE1wUQZque1E0_g=w60-h60-p-rp-mo-br100"
          }
        />
        <Review
          msg={
            "Its a nice green place but away from Gangaji. If you are looking to stay near Gangaji then it's not for you. They have ample car parking on chargeable basis. Food is okaish."
          }
          name={"vinay gupta"}
          image={
            "https://lh3.googleusercontent.com/a-/ALV-UjXlGDWSSP_4-F_d8uUngHozYwFd9VTSEqH9nzthE8R0Je82Lhe0RA=w60-h60-p-rp-mo-ba5-br100"
          }
        />
        <Review
          msg={
            "Its nice place to spent time there in shivpuri. Food quality and service was impressive. Staff do things without saying and automatically. Owner is also very soft spoken. It is little far from river but has a view point tower. "
          }
          name={"abhishek vishnoi"}
          image={
            "https://raftinggo.in/wp-content/uploads/2024/04/unnamed-17.png"
          }
        />
        <Review
          msg={
            "It's amazing...and very pleasant time in there and we spent lot of fun there .. thankyou so much Sanjeev sir"
          }
          name={"bonish kumar"}
          image={
            "https://lh3.googleusercontent.com/a/ACg8ocLFLBnN3BlVCgwf98d3kUwfLTk70JstNu4mpscNKi2BUDoUYQ=w60-h60-p-rp-mo-br100"
          }
        />
        <Review
          msg={"Food is awesome, hospitality is also very good."}
          name={"Vishant Kumar"}
          image={
            "https://lh3.googleusercontent.com/a-/ALV-UjUDyL6Qm4QapccPixTl095Y7kp9MU_ZhWEGweoD7E0QHUVAlhOl=w60-h60-p-rp-mo-ba3-br100"
          }
        />
        <Review
          msg={
            "This is the best place to stay and the food here is very good, the service is also very good and friendly behavior is desired Manager Omkar does a lot of work👍🏻👍🏻👍🏻👍🏻👍🏻"
          }
          name={"RAMESH RAWAT"}
          image={
            "https://lh3.googleusercontent.com/a-/ALV-UjWpuxED1WwThxegAxG55q7jsBH-r-NZE915ym_Mkce8wH7AnOkmTQ=w60-h60-p-rp-mo-br100"
          }
        />
      </div>

      <div className="landing-bottom-div">
        <div className="landing-fotter-div">
          <div className="landing-fotter-content-div">
            <div className="landing-fotter-book-text">
              Book any Adventure activity in Rishikesh
            </div>
            <div className="landing-fotter-call-text">
              CALL NOW. AND GET A PERSONALIZED ASSISTANCE.
            </div>
          </div>
          <a href="tel://9084040280" className="landing-fotter-call-now-btn">
            Call now
          </a>
        </div>
      </div>
    </>
  );
}
