// import { useState } from "react";
import "./BottomTab.css";
import "../GlobalCss/FontFamily.css";
import { Link } from "react-router-dom";
function BottomTab({ active }) {
  return (
    <div className="bottom-tab">
      {active == "explore" ? (
        <div className="bottom-tab-indi-link">
          <div className="bottom-tab-indi-link-svg-div">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bottom-tab-indi-link-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M12.8324 21.8013C15.9583 21.1747 20 18.926 20 13.1112C20 7.8196 16.1267 4.29593 13.3415 2.67685C12.7235 2.31757 12 2.79006 12 3.50492V5.3334C12 6.77526 11.3938 9.40711 9.70932 10.5018C8.84932 11.0607 7.92052 10.2242 7.816 9.20388L7.73017 8.36604C7.6304 7.39203 6.63841 6.80075 5.85996 7.3946C4.46147 8.46144 3 10.3296 3 13.1112C3 20.2223 8.28889 22.0001 10.9333 22.0001C11.0871 22.0001 11.2488 21.9955 11.4171 21.9858C11.863 21.9296 11.4171 22.085 12.8324 21.8013Z"
                  fill="#ff7c00"
                ></path>
                <path
                  d="M8 18.4442C8 21.064 10.1113 21.8742 11.4171 21.9858C11.863 21.9296 11.4171 22.085 12.8324 21.8013C13.871 21.4343 15 20.4922 15 18.4442C15 17.1465 14.1814 16.3459 13.5401 15.9711C13.3439 15.8564 13.1161 16.0008 13.0985 16.2273C13.0429 16.9454 12.3534 17.5174 11.8836 16.9714C11.4685 16.4889 11.2941 15.784 11.2941 15.3331V14.7439C11.2941 14.3887 10.9365 14.1533 10.631 14.3346C9.49507 15.0085 8 16.3949 8 18.4442Z"
                  fill="#fff"
                ></path>
              </g>
            </svg>
          </div>
          <div className="bottom-tab-indi-name-active">Explore</div>
        </div>
      ) : (
        <Link to={"/"} className="bottom-tab-indi-link">
          <div className="bottom-tab-indi-link-svg-div">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bottom-tab-indi-link-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M12.8324 21.8013C15.9583 21.1747 20 18.926 20 13.1112C20 7.8196 16.1267 4.29593 13.3415 2.67685C12.7235 2.31757 12 2.79006 12 3.50492V5.3334C12 6.77526 11.3938 9.40711 9.70932 10.5018C8.84932 11.0607 7.92052 10.2242 7.816 9.20388L7.73017 8.36604C7.6304 7.39203 6.63841 6.80075 5.85996 7.3946C4.46147 8.46144 3 10.3296 3 13.1112C3 20.2223 8.28889 22.0001 10.9333 22.0001C11.0871 22.0001 11.2488 21.9955 11.4171 21.9858C11.863 21.9296 11.4171 22.085 12.8324 21.8013Z"
                  className="fill-blue"
                ></path>
                <path
                  d="M8 18.4442C8 21.064 10.1113 21.8742 11.4171 21.9858C11.863 21.9296 11.4171 22.085 12.8324 21.8013C13.871 21.4343 15 20.4922 15 18.4442C15 17.1465 14.1814 16.3459 13.5401 15.9711C13.3439 15.8564 13.1161 16.0008 13.0985 16.2273C13.0429 16.9454 12.3534 17.5174 11.8836 16.9714C11.4685 16.4889 11.2941 15.784 11.2941 15.3331V14.7439C11.2941 14.3887 10.9365 14.1533 10.631 14.3346C9.49507 15.0085 8 16.3949 8 18.4442Z"
                  fill="#fff"
                ></path>
              </g>
            </svg>
          </div>
          <div className="bottom-tab-indi-name">Explore</div>
        </Link>
      )}

      {active == "rafting" ? (
        <div className="bottom-tab-indi-link">
          <div className="bottom-tab-indi-link-svg-div">
            <svg
              viewBox="0 0 278.497 278.497"
              className="bottom-tab-indi-link-svg-active"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M12.444,215.388c0.167-0.146,17.098-14.64,51.521-14.64c15.123,0,27.152,6.114,41.081,13.194 c18.265,9.283,38.967,19.806,71.848,19.806c58.612,0,97.427-33.437,99.051-34.86c3.11-2.728,3.421-7.454,0.699-10.569 c-2.724-3.116-7.457-3.435-10.578-0.719c-0.185,0.161-9.912,8.469-26.534,16.464c-1.009-2.469-2.673-4.705-4.979-6.401 l-27.268-20.05c-0.848-0.623-1.719-1.213-2.601-1.787l39.044-50.935c0.101-0.132,0.183-0.271,0.279-0.405 c4.274-1.873,7.617-5.701,8.663-10.614c1.726-8.103-3.444-16.069-11.547-17.795l-108-23c-0.069-0.015-0.138-0.02-0.208-0.033 c-6.184-1.505-12.973-0.181-18.232,4.213c-3.533,2.951-34.518,29.812-34.518,63.686c0,18.781,6.741,38.016,12.191,50.503 c-8.657-3.387-17.795-5.698-28.394-5.698c-40.006,0-60.554,17.61-61.409,18.36c-3.114,2.73-3.426,7.469-0.695,10.584 C4.591,217.807,9.328,218.119,12.444,215.388z M152.782,216.414c-7.015-1.426-13.326-3.416-19.213-5.716 c0.56-0.225,1.115-0.474,1.657-0.772l18.329-10.075c4.532-2.49,9.748-3.803,14.999-4.013L152.782,216.414z M189.514,201.784 l18.269,13.433c-9.443,2.157-19.79,3.531-30.889,3.531c-1.718,0-3.394-0.035-5.037-0.096l14.465-18.87 C187.431,200.387,188.506,201.043,189.514,201.784z M139.104,173.561l-7.418,4.078c-4.295-9.371-9.996-24.541-9.996-36.696 c0.977-20.195,20.477-33.945,22.552-34.826l80.024,17.042l-34.927,45.563C172.951,163.742,154.247,165.236,139.104,173.561z M276.644,221.319c2.722,3.115,2.411,7.842-0.699,10.569c-1.624,1.424-40.438,34.86-99.051,34.86 c-32.881,0-53.583-10.522-71.848-19.806c-13.929-7.08-25.958-13.194-41.081-13.194c-34.423,0-51.354,14.493-51.521,14.64 c-3.116,2.73-7.854,2.42-10.584-0.695s-2.419-7.854,0.695-10.584c0.855-0.75,21.403-18.36,61.409-18.36 c18.717,0,32.882,7.2,47.878,14.822c17.582,8.937,35.763,18.178,65.051,18.178c52.777,0,88.814-30.837,89.172-31.148 C269.187,217.884,273.92,218.203,276.644,221.319z M132,40.415c0-15.832,12.835-28.667,28.667-28.667s28.667,12.834,28.667,28.667 s-12.834,28.667-28.667,28.667S132,56.247,132,40.415z"></path>
              </g>
            </svg>
          </div>
          <div className="bottom-tab-indi-name-active">Rafting</div>
        </div>
      ) : (
        <Link to={"/rafting-in-rishikesh"} className="bottom-tab-indi-link">
          <div className="bottom-tab-indi-link-svg-div">
            <svg
              fill="#000000"
              viewBox="0 0 278.497 278.497"
              className="bottom-tab-indi-link-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M12.444,215.388c0.167-0.146,17.098-14.64,51.521-14.64c15.123,0,27.152,6.114,41.081,13.194 c18.265,9.283,38.967,19.806,71.848,19.806c58.612,0,97.427-33.437,99.051-34.86c3.11-2.728,3.421-7.454,0.699-10.569 c-2.724-3.116-7.457-3.435-10.578-0.719c-0.185,0.161-9.912,8.469-26.534,16.464c-1.009-2.469-2.673-4.705-4.979-6.401 l-27.268-20.05c-0.848-0.623-1.719-1.213-2.601-1.787l39.044-50.935c0.101-0.132,0.183-0.271,0.279-0.405 c4.274-1.873,7.617-5.701,8.663-10.614c1.726-8.103-3.444-16.069-11.547-17.795l-108-23c-0.069-0.015-0.138-0.02-0.208-0.033 c-6.184-1.505-12.973-0.181-18.232,4.213c-3.533,2.951-34.518,29.812-34.518,63.686c0,18.781,6.741,38.016,12.191,50.503 c-8.657-3.387-17.795-5.698-28.394-5.698c-40.006,0-60.554,17.61-61.409,18.36c-3.114,2.73-3.426,7.469-0.695,10.584 C4.591,217.807,9.328,218.119,12.444,215.388z M152.782,216.414c-7.015-1.426-13.326-3.416-19.213-5.716 c0.56-0.225,1.115-0.474,1.657-0.772l18.329-10.075c4.532-2.49,9.748-3.803,14.999-4.013L152.782,216.414z M189.514,201.784 l18.269,13.433c-9.443,2.157-19.79,3.531-30.889,3.531c-1.718,0-3.394-0.035-5.037-0.096l14.465-18.87 C187.431,200.387,188.506,201.043,189.514,201.784z M139.104,173.561l-7.418,4.078c-4.295-9.371-9.996-24.541-9.996-36.696 c0.977-20.195,20.477-33.945,22.552-34.826l80.024,17.042l-34.927,45.563C172.951,163.742,154.247,165.236,139.104,173.561z M276.644,221.319c2.722,3.115,2.411,7.842-0.699,10.569c-1.624,1.424-40.438,34.86-99.051,34.86 c-32.881,0-53.583-10.522-71.848-19.806c-13.929-7.08-25.958-13.194-41.081-13.194c-34.423,0-51.354,14.493-51.521,14.64 c-3.116,2.73-7.854,2.42-10.584-0.695s-2.419-7.854,0.695-10.584c0.855-0.75,21.403-18.36,61.409-18.36 c18.717,0,32.882,7.2,47.878,14.822c17.582,8.937,35.763,18.178,65.051,18.178c52.777,0,88.814-30.837,89.172-31.148 C269.187,217.884,273.92,218.203,276.644,221.319z M132,40.415c0-15.832,12.835-28.667,28.667-28.667s28.667,12.834,28.667,28.667 s-12.834,28.667-28.667,28.667S132,56.247,132,40.415z"></path>
              </g>
            </svg>
          </div>
          <div className="bottom-tab-indi-name">Rafting</div>
        </Link>
      )}

      {active == "camping" ? (
        <div className="bottom-tab-indi-link">
          <div className="bottom-tab-indi-link-svg-div">
            <svg
              viewBox="0 0 14 14"
              fill="none"
              className="bottom-tab-indi-link-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M 14,14 8.000001,2.9999992 9.000001,0 l -1,0 -1,1.9999995 L 6,0 5,0 6,2.9999992 0,14 z m -6.999999,-7.0000008 3,6.0000018 -6.000001,0 z"
                  id="camping"
                  className="fill-orange"
                ></path>{" "}
              </g>
            </svg>
          </div>
          <div className="bottom-tab-indi-name-active">Camping</div>
        </div>
      ) : (
        <Link to={"/camping-in-rishikesh"} className="bottom-tab-indi-link">
          <div className="bottom-tab-indi-link-svg-div">
            <svg
              viewBox="0 0 14 14"
              fill="none"
              className="bottom-tab-indi-link-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M 14,14 8.000001,2.9999992 9.000001,0 l -1,0 -1,1.9999995 L 6,0 5,0 6,2.9999992 0,14 z m -6.999999,-7.0000008 3,6.0000018 -6.000001,0 z"
                  id="camping"
                  fill="#30b5d8"
                ></path>{" "}
              </g>
            </svg>
          </div>
          <div className="bottom-tab-indi-name">Camping</div>
        </Link>
      )}

      {active == "bungee-jumping" ? (
        <div className="bottom-tab-indi-link">
          <div className="bottom-tab-indi-link-svg-div">
            <svg
              fill="#000000"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 517.267 517.267"
              className="bottom-tab-indi-link-svg-active"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <g>
                    <path d="M46.56,367.44c25.857,0,46.818-20.961,46.818-46.789c0-25.685-20.951-46.646-46.818-46.646 C20.875,274.005,0,294.957,0,320.651C0,346.479,20.884,367.44,46.56,367.44z"></path>
                    <path d="M85.565,376.114c-3.71,6.923-1.052,15.472,5.919,19.087l167.248,86.817l14.898,9.792 c5.077,3.338,10.968,5.059,16.916,5.059c2.726,0,5.46-0.363,8.147-1.1l195.897-53.722c16.409-4.495,26.067-21.449,21.572-37.849 c-4.513-16.409-21.458-26.058-37.857-21.563l-177.05,48.549l0.345-1.463l-51.82-30.304l19.966-39.933 c3.51-7.02,0.612-15.492-6.444-18.905l-94.009-45.508c-9.505-4.6-22.883-1.636-26.421,8.31c-1.138,3.193-2.008,6.502-2.735,9.858 l-6.837-101.391c-0.794-11.752-10.997-20.655-22.721-19.842c-11.752,0.793-20.636,10.958-19.842,22.711l9.084,134.716 L85.565,376.114z"></path>
                    <path d="M377.642,369.668c5.288,0,9.562-4.284,9.562-9.562V214.278c0-5.278-4.274-9.562-9.562-9.562s-9.562,4.284-9.562,9.562 v145.828C368.079,365.384,372.363,369.668,377.642,369.668z"></path>
                    <path d="M344.078,309.903c5.288,0,9.562-4.284,9.562-9.562V154.512c0-5.278-4.274-9.562-9.562-9.562s-9.562,4.284-9.562,9.562 V300.34C334.516,305.619,338.799,309.903,344.078,309.903z"></path>
                    <path d="M175.969,245.117c5.288,0,9.562-4.284,9.562-9.562V89.726c0-5.278-4.274-9.562-9.562-9.562s-9.562,4.284-9.562,9.562 v145.828C166.406,240.833,170.69,245.117,175.969,245.117z"></path>
                    <path d="M209.533,185.351c5.288,0,9.562-4.284,9.562-9.562V29.96c0-5.278-4.274-9.562-9.562-9.562s-9.562,4.284-9.562,9.562 v145.828C199.971,181.067,204.245,185.351,209.533,185.351z"></path>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className="bottom-tab-indi-name-active">Bungee</div>
        </div>
      ) : (
        <Link
          to={"/bungee-jumping-in-rishikesh"}
          className="bottom-tab-indi-link"
        >
          <div className="bottom-tab-indi-link-svg-div">
            <svg
              fill="#000000"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 517.267 517.267"
              className="bottom-tab-indi-link-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <g>
                    <path d="M46.56,367.44c25.857,0,46.818-20.961,46.818-46.789c0-25.685-20.951-46.646-46.818-46.646 C20.875,274.005,0,294.957,0,320.651C0,346.479,20.884,367.44,46.56,367.44z"></path>
                    <path d="M85.565,376.114c-3.71,6.923-1.052,15.472,5.919,19.087l167.248,86.817l14.898,9.792 c5.077,3.338,10.968,5.059,16.916,5.059c2.726,0,5.46-0.363,8.147-1.1l195.897-53.722c16.409-4.495,26.067-21.449,21.572-37.849 c-4.513-16.409-21.458-26.058-37.857-21.563l-177.05,48.549l0.345-1.463l-51.82-30.304l19.966-39.933 c3.51-7.02,0.612-15.492-6.444-18.905l-94.009-45.508c-9.505-4.6-22.883-1.636-26.421,8.31c-1.138,3.193-2.008,6.502-2.735,9.858 l-6.837-101.391c-0.794-11.752-10.997-20.655-22.721-19.842c-11.752,0.793-20.636,10.958-19.842,22.711l9.084,134.716 L85.565,376.114z"></path>
                    <path d="M377.642,369.668c5.288,0,9.562-4.284,9.562-9.562V214.278c0-5.278-4.274-9.562-9.562-9.562s-9.562,4.284-9.562,9.562 v145.828C368.079,365.384,372.363,369.668,377.642,369.668z"></path>
                    <path d="M344.078,309.903c5.288,0,9.562-4.284,9.562-9.562V154.512c0-5.278-4.274-9.562-9.562-9.562s-9.562,4.284-9.562,9.562 V300.34C334.516,305.619,338.799,309.903,344.078,309.903z"></path>
                    <path d="M175.969,245.117c5.288,0,9.562-4.284,9.562-9.562V89.726c0-5.278-4.274-9.562-9.562-9.562s-9.562,4.284-9.562,9.562 v145.828C166.406,240.833,170.69,245.117,175.969,245.117z"></path>
                    <path d="M209.533,185.351c5.288,0,9.562-4.284,9.562-9.562V29.96c0-5.278-4.274-9.562-9.562-9.562s-9.562,4.284-9.562,9.562 v145.828C199.971,181.067,204.245,185.351,209.533,185.351z"></path>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className="bottom-tab-indi-name">Bungee</div>
        </Link>
      )}

      {active == "account"
        ? // <div className="bottom-tab-indi-link">
          //   <div className="bottom-tab-indi-link-svg-div">
          //     <svg
          //       viewBox="0 0 24 24"
          //       fill="none"
          //       xmlns="http://www.w3.org/2000/svg"
          //       className="bottom-tab-indi-link-svg-account"
          //     >
          //       <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          //       <g
          //         id="SVGRepo_tracerCarrier"
          //         stroke-linecap="round"
          //         stroke-linejoin="round"
          //       ></g>
          //       <g id="SVGRepo_iconCarrier">
          //         {" "}
          //         <path
          //           fill-rule="evenodd"
          //           clip-rule="evenodd"
          //           d="M14.0137 17L14.0079 19.0029C14.0065 19.4731 14.0058 19.7081 13.8591 19.8541C13.7124 20 13.4767 20 13.0054 20H9.99502C6.21438 20 4.32407 20 3.14958 18.8284C2.34091 18.0218 2.08903 16.8766 2.01058 15.0105C1.99502 14.6405 1.98724 14.4554 2.05634 14.332C2.12545 14.2085 2.40133 14.0545 2.95308 13.7463C3.56586 13.4041 3.98007 12.7503 3.98007 12C3.98007 11.2497 3.56586 10.5959 2.95308 10.2537C2.40133 9.94554 2.12545 9.79147 2.05634 9.66802C1.98724 9.54458 1.99502 9.35954 2.01058 8.98947C2.08903 7.12339 2.34091 5.97823 3.14958 5.17157C4.32407 4 6.21439 4 9.99502 4H13.5052C13.7814 4 14.0056 4.22298 14.0064 4.49855L14.0137 7C14.0137 7.55228 14.4625 8 15.0162 8L15.0162 10C14.4625 10 14.0137 10.4477 14.0137 11V13C14.0137 13.5523 14.4625 14 15.0162 14V16C14.4625 16 14.0137 16.4477 14.0137 17Z"
          //           className="fill-orange"
          //         ></path>
          //         <path
          //           opacity="0.5"
          //           d="M15.0166 15.9998C15.5703 15.9998 16.0191 16.4475 16.0191 16.9998V18.9763C16.0191 19.4578 16.0191 19.6986 16.1735 19.8462C16.3279 19.9939 16.5641 19.9839 17.0366 19.9639C18.8995 19.885 20.0441 19.633 20.8508 18.8282C21.6595 18.0216 21.9114 16.8764 21.9898 15.0104C22.0054 14.6403 22.0132 14.4552 21.9441 14.3318C21.875 14.2083 21.5991 14.0543 21.0473 13.7462C20.4346 13.404 20.0203 12.7501 20.0203 11.9998C20.0203 11.2495 20.4346 10.5957 21.0473 10.2535C21.5991 9.94536 21.875 9.7913 21.9441 9.66785C22.0132 9.5444 22.0054 9.35936 21.9898 8.98929C21.9114 7.12321 21.6595 5.97805 20.8508 5.17139C19.9731 4.29586 18.6956 4.07463 16.5282 4.01872C16.2486 4.01151 16.0191 4.237 16.0191 4.516V6.99982C16.0191 7.55211 15.5703 7.99982 15.0166 7.99982L15.0166 9.99982C15.5703 9.99982 16.0191 10.4475 16.0191 10.9998V12.9998C16.0191 13.5521 15.5703 13.9998 15.0166 13.9998V15.9998Z"
          //           className="fill-orange"
          //         ></path>
          //       </g>
          //     </svg>
          //   </div>
          //   <div className="bottom-tab-indi-name-active">Booking</div>
          // </div>
          null
        : // <Link to={"/booking"} className="bottom-tab-indi-link">
          //   <div className="bottom-tab-indi-link-svg-div">
          //     <svg
          //       viewBox="0 0 24 24"
          //       fill="none"
          //       xmlns="http://www.w3.org/2000/svg"
          //       className="bottom-tab-indi-link-svg-account"
          //     >
          //       <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          //       <g
          //         id="SVGRepo_tracerCarrier"
          //         stroke-linecap="round"
          //         stroke-linejoin="round"
          //       ></g>
          //       <g id="SVGRepo_iconCarrier">
          //         {" "}
          //         <path
          //           fill-rule="evenodd"
          //           clip-rule="evenodd"
          //           d="M14.0137 17L14.0079 19.0029C14.0065 19.4731 14.0058 19.7081 13.8591 19.8541C13.7124 20 13.4767 20 13.0054 20H9.99502C6.21438 20 4.32407 20 3.14958 18.8284C2.34091 18.0218 2.08903 16.8766 2.01058 15.0105C1.99502 14.6405 1.98724 14.4554 2.05634 14.332C2.12545 14.2085 2.40133 14.0545 2.95308 13.7463C3.56586 13.4041 3.98007 12.7503 3.98007 12C3.98007 11.2497 3.56586 10.5959 2.95308 10.2537C2.40133 9.94554 2.12545 9.79147 2.05634 9.66802C1.98724 9.54458 1.99502 9.35954 2.01058 8.98947C2.08903 7.12339 2.34091 5.97823 3.14958 5.17157C4.32407 4 6.21439 4 9.99502 4H13.5052C13.7814 4 14.0056 4.22298 14.0064 4.49855L14.0137 7C14.0137 7.55228 14.4625 8 15.0162 8L15.0162 10C14.4625 10 14.0137 10.4477 14.0137 11V13C14.0137 13.5523 14.4625 14 15.0162 14V16C14.4625 16 14.0137 16.4477 14.0137 17Z"
          //           className="fill-blue"
          //         ></path>{" "}
          //         <path
          //           opacity="0.5"
          //           d="M15.0166 15.9998C15.5703 15.9998 16.0191 16.4475 16.0191 16.9998V18.9763C16.0191 19.4578 16.0191 19.6986 16.1735 19.8462C16.3279 19.9939 16.5641 19.9839 17.0366 19.9639C18.8995 19.885 20.0441 19.633 20.8508 18.8282C21.6595 18.0216 21.9114 16.8764 21.9898 15.0104C22.0054 14.6403 22.0132 14.4552 21.9441 14.3318C21.875 14.2083 21.5991 14.0543 21.0473 13.7462C20.4346 13.404 20.0203 12.7501 20.0203 11.9998C20.0203 11.2495 20.4346 10.5957 21.0473 10.2535C21.5991 9.94536 21.875 9.7913 21.9441 9.66785C22.0132 9.5444 22.0054 9.35936 21.9898 8.98929C21.9114 7.12321 21.6595 5.97805 20.8508 5.17139C19.9731 4.29586 18.6956 4.07463 16.5282 4.01872C16.2486 4.01151 16.0191 4.237 16.0191 4.516V6.99982C16.0191 7.55211 15.5703 7.99982 15.0166 7.99982L15.0166 9.99982C15.5703 9.99982 16.0191 10.4475 16.0191 10.9998V12.9998C16.0191 13.5521 15.5703 13.9998 15.0166 13.9998V15.9998Z"
          //           className="fill-blue"
          //         ></path>{" "}
          //       </g>
          //     </svg>
          //   </div>
          //   <div className="bottom-tab-indi-name">Booking</div>
          // </Link>
          null}
    </div>
  );
}

export default BottomTab;
