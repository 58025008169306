import "./AfterForm.css";
function AfterForm({ title, price, goto }) {
  return (
    <div className="whole-after-form">
      <div className="waf-title">{title}</div>
      <div className="waf-price">
        INR {price} <span className="waf-pp"> per person </span>
      </div>
      <div
        className="waf-button"
        onClick={() => {
          goto.current.scrollIntoView({ behavior: "smooth" });
        }}
      >
        Book Now
      </div>
    </div>
  );
}

export default AfterForm;
