function Payment2() {
  return (
    <>
      <form action="https://secure.payu.in/_payment" method="post">
        <input type="hidden" name="key" value="heRjXi" />
        <input type="hidden" name="txnid" value="t6svtqtjRdl4ws1" />
        <input type="hidden" name="productinfo" value="iPhone" />
        <input type="hidden" name="amount" value="10" />
        <input type="hidden" name="email" value="test@gmail.com" />
        <input type="hidden" name="firstname" value="Ashish" />
        <input type="hidden" name="lastname" value="Kumar" />
        <input
          type="hidden"
          name="surl"
          value="https://apiplayground-response.herokuapp.com/"
        />
        <input
          type="hidden"
          name="furl"
          value="https://apiplayground-response.herokuapp.com/"
        />
        <input type="hidden" name="phone" value="9988776655" />
        <input
          type="hidden"
          name="hash"
          value="d0bb731250000729a98eb35524264bd4a98d693139d8fad4e63f80416f65b55f1242b4cd2669c04aad9c8f85088aa8e30f08577a1278a7097092b3a06b51a736"
        />
        <input type="submit" value="submit" />
      </form>
    </>
  );
}

export default Payment2;
