import "./TextInput.css";
function TextInput({ title, placeholder, type, set, get }) {
  return (
    <div className="whole-text-input">
      <div className="wti-title">{title}</div>
      <input
        type={type}
        value={get}
        onInput={(t) => {
          set(t.target.value);
        }}
        className="wti-input"
        placeholder={placeholder}
      />
    </div>
  );
}

export default TextInput;
