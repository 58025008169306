import "../GlobalCss/FontFamily.css";
import "./Title.css";
function Title({ title, description, more }) {
  return (
    <div className="global-title">
      <div className="gt-1">
        <div className="gt-1-space"></div>
        <div className="gt-1-tp-section">
          <h2 className="gt1-title">{title}</h2>
          <p className="gt1-p">{description}</p>
        </div>
      </div>
      <div className="gt-2">
        {/* <div className="gt-2-title">Explore more</div> */}
        {more ? (
          <div>
            <svg viewBox="0 0 20 20" fill="none" className="gt-2-svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M15.795 11.272L7.795 16.272C6.79593 16.8964 5.5 16.1782 5.5 15L5.5 5.00002C5.5 3.82186 6.79593 3.1036 7.795 3.72802L15.795 8.72802C16.735 9.31552 16.735 10.6845 15.795 11.272Z"
                  className="fill-orange"
                ></path>{" "}
              </g>
            </svg>
          </div>
        ) : null}

        <div className="gt-1-space"></div>
      </div>
    </div>
  );
}

export default Title;
