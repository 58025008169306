import "./Privacy_policy.css";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "../GlobalComponents/Footer";
import DesktopFooter from "./DesktopFooter";
import BottomTab from "../ComponentsHome/BottomTab";
import { useEffect } from "react";
function Privacy_policy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about_header">
        <Header />
      </div>

      <div className="p_P_div_pp">
        <Helmet>
          <title>Thrill Thirsty: Privacy Policy</title>
          <meta
            name="description"
            content="Know everything about our privacy policy here."
          />
        </Helmet>
        <div className="pp_inner_div_pp">
          <div className="Privacy_policy_div_pp">
            <div className="h_text_one_pp">
              Privacy Policy for Thrill Thirsty
            </div>

            <p>
              At Thrill Thirsty, accessible from
              [thrillthirsty.com](http://thrillthirsty.com/), one of our main
              priorities is the privacy of our visitors. This Privacy Policy
              document contains types of information that is collected and
              recorded by Thrill Thirsty and how we use it.
            </p>

            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>

            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in Thrill Thirsty. This policy is
              not applicable to any information collected offline or via
              channels other than this website.
            </p>

            <div className="h_two_text_pp">Consent</div>

            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>

            <div className="h_two_text_pp">Information we collect</div>

            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>
            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>

            <div className="h_two_text_pp">How we use your information</div>

            <p>
              We use the information we collect in various ways, including to:
            </p>

            <ul>
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>
                Develop new products, services, features, and functionality
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>

            <div className="h_two_text_pp">Log Files</div>

            <p>
              Thrill Thirsty follows a standard procedure of using log files.
              These files log visitors when they visit websites. All hosting
              companies do this and a part of hosting services' analytics. The
              information collected by log files include internet protocol (IP)
              addresses, browser type, Internet Service Provider (ISP), date and
              time stamp, referring/exit pages, and possibly the number of
              clicks. These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </p>

            <div className="h_two_text_pp">Cookies and Web Beacons</div>

            <p>
              Like any other website, Thrill Thirsty uses "cookies". These
              cookies are used to store information including visitors'
              preferences, and the pages on the website that the visitor
              accessed or visited. The information is used to optimize the
              users' experience by customizing our web page content based on
              visitors' browser type and/or other information.
            </p>

            <div className="h_two_text_pp">
              Advertising Partners Privacy Policies
            </div>

            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of Thrill Thirsty.
            </p>

            <p>
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on Thrill Thirsty,
              which are sent directly to users' browser. They automatically
              receive your IP address when this occurs. These technologies are
              used to measure the effectiveness of their advertising campaigns
              and/or to personalize the advertising content that you see on
              websites that you visit.
            </p>

            <p>
              Note that Thrill Thirsty has no access to or control over these
              cookies that are used by third-party advertisers.
            </p>

            <div className="h_two_text_pp">Third Party Privacy Policies</div>

            <p>
              Thrill Thirsty's Privacy Policy does not apply to other
              advertisers or websites. Thus, we are advising you to consult the
              respective Privacy Policies of these third-party ad servers for
              more detailed information. It may include their practices and
              instructions about how to opt-out of certain options.
            </p>

            <p>
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers'
              respective websites.
            </p>

            <div className="h_two_text_pp">
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </div>

            <p>
              Under the CCPA, among other rights, California consumers have the
              right to:
            </p>
            <p>
              Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </p>
            <p>
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </p>
            <p>
              Request that a business that sells a consumer's personal data, not
              sell the consumer's personal data.
            </p>
            <p>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>

            <div className="h_two_text_pp">GDPR Data Protection Rights</div>

            <p>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>
            <p>
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </p>
            <p>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </p>
            <p>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </p>
            <p>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </p>
            <p>
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </p>
            <p>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </p>
            <p>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>

            <div className="h_two_text_pp">Children's Information</div>

            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>

            <p>
              Thrill Thirsty does not knowingly collect any Personal
              Identifiable Information from children under the age of 13. If you
              think that your child provided this kind of information on our
              website, we strongly encourage you to contact us immediately and
              we will do our best efforts to promptly remove such information
              from our records.
            </p>

            <div className="h_two_text_pp">Changes to This Privacy Policy</div>

            <p>
              We may update our Privacy Policy from time to time. Thus, we
              advise you to review this page periodically for any changes. We
              will notify you of any changes by posting the new Privacy Policy
              on this page. These changes are effective immediately, after they
              are posted on this page.
            </p>

            {/* <p>
              Our Privacy Policy was created with the help of the{" "}
              <a href="https://www.termsfeed.com/privacy-policy-generator/">
                Privacy Policy Generator
              </a>
              .
            </p> */}

            <div className="h_two_text_pp">Contact Us</div>

            <p>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us.
            </p>
          </div>
        </div>
      </div>

      <div className="about_footer_desktop">
        <DesktopFooter />
      </div>
      <div className="about_footer_mobile">
        <Footer />
        <BottomTab />
      </div>
    </>
  );
}

export default Privacy_policy;
