import "./AccountBooking.css";
import IndiAccountBooking from "./IndiAccountBooking";
function AccountBooking() {
  return (
    <>
      <IndiAccountBooking />
      <IndiAccountBooking />
      <IndiAccountBooking />
      <IndiAccountBooking />
      <IndiAccountBooking />
      <IndiAccountBooking />
      <IndiAccountBooking />
      <div className="account-booking-space"></div>
    </>
  );
}

export default AccountBooking;
