import "./TitleWithoutDescription.css";
function TitleWithoutDescription({ content }) {
  return (
    <div className="title-without-description">
      <div>{content}</div>
      <div className="mobile-rafting-content-line"></div>
    </div>
  );
}

export default TitleWithoutDescription;
