import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import DataContext from "./Context/DataContext";
import "./GlobalCss/Color.css";
import "./GlobalCss/FontFamily.css";
import "./GlobalCss/Global.css";

// importing all routes
import Home from "./Pages/Home";
import Rafting from "./Pages/Rafting";
import Camping from "./Pages/Camping";
import Bungee from "./Pages/Bungee";
import BungeeLp from "./Pages/BungeeLp";
import Account from "./Pages/Account";
import Page404 from "./GlobalPages/Page404";
import axios from "axios";
import About_us from "./DesktopComponents/About_us";
import Contact_page from "./DesktopComponents/Contact_page";
import Privacy_policy from "./DesktopComponents/Privacy_policy";
import Terms_condition from "./DesktopComponents/Terms_condition";
import Payment from "./Pages/Payment";
import Payment2 from "./Pages/Payment2";
import PaymentAndBooking from "./DesktopComponents/PaymentAndBooking";
import ThankYou from "./Pages/ThankYou";
import ThankYouBungee from "./Pages/ThankYouBungee";
import ThankYouCamping from "./Pages/ThankYouCamping";
import Gallery from "./Pages/Gallery";
// import RaftingLp from "./Pages/RaftingLp";
// import RaftingNewLp from "./Pages/RaftingNewLp";
import Ankit from "./Pages/Ankit";
import RishikeshCamping from "./Pages/RishikeshCamping";

function App() {
  console.log("hi how are you");

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    Auth: undefined,
    AuthToken: undefined,
    UserType: "user",
    api: "http://localhost:9000",
  });
  const update = () => {};

  const login = () => {};

  const logout = () => {};

  return !loading ? (
    <DataContext.Provider value={{ data }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rafting-in-rishikesh" element={<Rafting />} />
          <Route path="/camping-in-rishikesh" element={<Camping />} />
          <Route path="/bungee-jumping-in-rishikesh" element={<Bungee />} />
          <Route path="/account" element={<Account />} />
          <Route path="/privacy-policy" element={<Privacy_policy />} />
          <Route path="/terms-and-conditions" element={<Terms_condition />} />
          <Route path="/payment-and-booking" element={<PaymentAndBooking />} />
          <Route path="/about" element={<About_us />} />
          <Route path="/contact" element={<Contact_page />} />
          <Route path="/pay" element={<Payment />} />
          <Route path="/pay2" element={<Payment2 />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* <Route path="/ankit" element={<Ankit />} /> */}
          <Route path="/river-rafting-in-rishikesh" element={<Ankit />} />
          <Route path="/rishikesh-camping" element={<RishikeshCamping />} />
          <Route path="/bungee-jump-in-rishikesh" element={<BungeeLp />} />
          {/* <Route
            path="/river-rafting-in-rishikesh-lp"
            element={<RaftingLp />}
          /> */}

          <Route path="/activities/rafting/thank-you" element={<ThankYou />} />
          <Route
            path="/activities/bungee/thank-you"
            element={<ThankYouBungee />}
          />
          <Route
            path="/activities/camping/thank-you"
            element={<ThankYouCamping />}
          />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </DataContext.Provider>
  ) : null;
}

export default App;
