import "./AccountWishList.css";
import IndiWishList from "./IndiWishList";
function AccountWishList() {
  return (
    <>
      <IndiWishList />
      <IndiWishList />
      <IndiWishList />
      <div className="wishlist-space"></div>
    </>
  );
}

export default AccountWishList;
