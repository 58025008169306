import "./Button.css";
function Button({ content, submit }) {
  return (
    <div className="main-btm-div">
      <button onClick={submit} className="gf-button">
        {content}
      </button>{" "}
    </div>
  );
}

export default Button;
