import "./OptionInput.css";
function OptionInput({ title, options, set, get }) {
  return (
    <div className="whole-option-input">
      <div className="wti-title">{title}</div>
      <div className="woi-options-div">
        {options.map((indiOption, index) => {
          return get == index + 1 ? (
            <div className="woi-indi-option-active">{indiOption}</div>
          ) : (
            <div
              className="woi-indi-option"
              onClick={() => {
                set(index + 1);
              }}
            >
              {indiOption}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OptionInput;
