import "./GlobalForm.css";
import TextInput from "../GlobalFormComponents/TextInput";
import OptionInput from "../GlobalFormComponents/OptionInput";
import { EnterPerson, Persons } from "../GlobalFormComponents/EnterPerason";
import Title from "../GlobalFormComponents/Title";
import Button from "../GlobalFormComponents/Button";
import InputDate from "../GlobalFormComponents/InputDate";
import PriceSlip from "../GlobalFormComponents/PriceSlip";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import OptionInputRafting from "../GlobalFormComponents/OptionInputRafting";
function GlobalForm({
  setForm,
  setMobile_date,
  setMobile_no_of_people,
  setMobile_name,
  setMobile_email,
  setMobile_dor,
  setMobile_contact,
  mobile_no_of_people,
  mobile_name,
  mobile_email,
  mobile_date,
  mobile_dor,
  mobile_contact,
  mobile_slot,
  setMobile_slot,
  setCurrent_type,
  current_type,
  price,
  km,
  submitMobileAdvance,
  submitMobileFull,
  setChoosen_slot,
  choosen_slot,
  weekEnd,
}) {
  // useEffect(() => {
  //   console.log(choosen_slot);
  // }, [choosen_slot]);
  return (
    <div className="whole-form">
      <div
        className="wf-1"
        onClick={() => {
          setForm(false);
        }}
      ></div>
      <div className="wf-2">
        <div className="wf2-header">
          <div className="wf-header-content">
            <div className="wf-h-title">Fill your details</div>
            <p className="wf-h-description">
              Need instant rafting? Dial or click{" "}
              <Link to={"tel:" + "+919084040280"} className="color-orange">
                +91-9084040280
              </Link>
            </p>
          </div>
          <div
            className="wf-header-close"
            onClick={() => {
              setForm(false);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="wf-header-close-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M16 8L8 16M8.00001 8L16 16"
                  className="stroke-gray"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div>
        <TextInput
          title={"Your name"}
          placeholder={"Enter your good name"}
          type={"text"}
          set={setMobile_name}
          get={mobile_name}
        />
        <TextInput
          title={"Your contact"}
          placeholder={"Enter your contact number"}
          type={"number"}
          set={setMobile_contact}
          get={mobile_contact}
        />
        <TextInput
          title={"Your email"}
          placeholder={"Enter your email address"}
          type={"email"}
          set={setMobile_email}
          get={mobile_email}
        />
        <OptionInputRafting
          title={"Distance of rafting"}
          options={["09 km", "12 km"]}
          set={(km) => {
            if (km < 3) {
              setCurrent_type(km);
            } else {
              setCurrent_type(km + 1);
            }
          }}
          get={current_type}
        />
        {/* <OptionInput
          title={"Today’s available slots"}
          options={["06:00 AM", "06:10 AM", "06:20 AM", "06:30 AM", "06:40 AM"]}
        /> */}
        {/* <OptionInput
          title={"Tomorrow’s available slots"}
          options={["06:00 AM", "06:10 AM", "06:20 AM", "06:30 AM", "06:40 AM"]}
        /> */}
        <InputDate
          title={"Select date"}
          placeholder={"Choose a day"}
          forhtml={"bookanotherday"}
          set={setMobile_date}
          get={mobile_date}
          //   type={"date"}
        />

        {/* open it when their is something on the another day box */}
        {mobile_date != "" ? (
          <OptionInput
            title={"Available slots on " + mobile_date}
            options={mobile_slot}
            set={setChoosen_slot}
            get={choosen_slot}
          />
        ) : null}
        {/* <OptionInput title={"Number of person"} options={[]} /> */}
        <Title title={"Number of person"} />
        <div className="form-persons-div">
          <EnterPerson
            placeholder={"Enter the number of person"}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={1}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={2}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={3}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={4}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={5}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={6}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={7}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <Persons
            persons={8}
            set={setMobile_no_of_people}
            get={mobile_no_of_people}
          />
          <div></div>
        </div>
        <PriceSlip
          // content={
          //   km[current_type] +
          //   "km rafting ( ₹" +
          //   parseInt(price[current_type]).toLocaleString() +
          //   " ) x " +
          //   mobile_no_of_people +
          //   " adults = ₹" +
          //   (
          //     parseInt(price[current_type]) * parseInt(mobile_no_of_people)
          //   ).toLocaleString()
          // }
          content={(() => {
            if (current_type == 2 && weekEnd == false) {
              return (
                km[current_type] +
                "km rafting ( ₹" +
                (parseInt(price[current_type]) - 200).toLocaleString() +
                " ) x " +
                mobile_no_of_people +
                " adults = ₹" +
                (
                  (parseInt(price[current_type]) - 200) *
                  parseInt(mobile_no_of_people)
                ).toLocaleString()
              );
            } else {
              return (
                km[current_type] +
                "km rafting ( ₹" +
                parseInt(price[current_type]).toLocaleString() +
                " ) x " +
                mobile_no_of_people +
                " adults = ₹" +
                (
                  parseInt(price[current_type]) * parseInt(mobile_no_of_people)
                ).toLocaleString()
              );
            }
          })()}
        />
        <div className="gf-text-on-btn">
          Missed the slot? Dial or click{" "}
          <Link to={"tel:" + 9084040280} className="color-orange">
            +91-9084040280
          </Link>{" "}
          for instant booking!
        </div>
        <Button
          // content={
          //   "Pay ₹" +
          //   (
          //     (parseInt(price[current_type]) *
          //       parseInt(mobile_no_of_people) *
          //       20) /
          //     100
          //   ).toLocaleString() +
          //   " (As Advance)"
          // }
          content={(() => {
            if (current_type == 2 && weekEnd == false) {
              let ta =
                (parseInt(price[current_type]) - 200) *
                parseInt(mobile_no_of_people);
              // let discount_price = ta - (ta * 20) / 100;
              let advance = (ta * 20) / 100;
              // let advance_wod = (ta * 20) / 100;
              return (
                <>
                  {"Pay "} {"₹" + advance.toLocaleString()} {"(As advance)"}
                </>
              );
            } else {
              return (
                "Pay ₹" +
                (
                  (parseInt(price[current_type]) *
                    parseInt(mobile_no_of_people) *
                    20) /
                  100
                ).toLocaleString() +
                " (As Advance)"
              );
            }
          })()}
          submit={submitMobileAdvance}
        />
        <div className="mf-or-whole">
          <div className="mfow-1"></div>
          <div>or</div>
          <div className="mfow-1"></div>
        </div>
        <Button
          // content={
          //   "Pay ₹" +
          //   (
          //     parseInt(price[current_type]) * parseInt(mobile_no_of_people)
          //   ).toLocaleString()
          // }
          content={(() => {
            if (current_type == 2 && weekEnd == false) {
              let ta =
                (parseInt(price[current_type]) - 200) *
                parseInt(mobile_no_of_people);
              // let discount_price = ta - (ta * 20) / 100;
              let advance = (ta * 20) / 100;
              // let advance_wod = (ta * 20) / 100;
              return (
                <>
                  {"Pay "} {"₹" + ta.toLocaleString()}
                  {" (Full)"}
                </>
              );
            } else {
              let ta =
                parseInt(price[current_type]) * parseInt(mobile_no_of_people);
              return (
                <>
                  {"Pay "} {"₹" + ta.toLocaleString()}
                  {" (Full)"}
                </>
              );
            }
          })()}
          submit={submitMobileFull}
        />
        {current_type == 2 && weekEnd == false ? (
          <div
            className="color-blue "
            style={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "12px",
              fontWeight: "600",
            }}
          >
            Flat ₹200 off on weekdays
          </div>
        ) : null}
        <div className="gf-space"></div>
      </div>
    </div>
  );
}

export default GlobalForm;
