// import logo from "../Public/Logo3.png";
// import destination from "../Public/Destination.jpg";
// import rafting from "../Public/rafting.jpg";
// import rafting1 from "../Public/rafting5.jpg";
// import camping from "../Public/camping.jpg";
// import bungee from "../Public/bungee.jpg";
import "./HeaderMobile.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function HeaderMobile() {
  const [crousal, setCrousal] = useState([
    {
      title: "River Rafting in Rishikesh",
      img: require("../Public/rafting/r14.jpg"),
      description:
        "Thrilling river rafting adventures in Rishikesh with Thrill Thirsty. ",
      link: "/rafting-in-rishikesh",
    },
    {
      title: "Camping in Rishikesh",
      img: require("../Public/beachCamp/beachcamp4.jpeg"),
      description:
        "Get a comfortable stay, unlimited food, swimming pool, and bonfire",
      link: "/camping-in-rishikesh",
    },
    {
      title: "Bungee Jumping in Rishikesh",
      img: require("../Public/bj55/bj55_1.png"),
      description:
        "Experience bungee jumping in Rishikesh with an experienced bungee master",
      link: "/bungee-jumping-in-rishikesh",
    },
  ]);

  const [current_crousal, setCurrent_crousal] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent_crousal((prevCrousal) => (prevCrousal + 1) % 3);
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="section-1">
      <div className="s1-dark-top"></div>
      <header className="section-1-header">
        <div className="s1h-space"></div>
        <div className="s1h-logo-div">
          <h2 className="s1h-logo mogra">
            <span className="orange mogra">Thrill </span>
            <span className="blue mogra">Thirsty</span>
          </h2>
        </div>
        <div className="s1h-options-div">
          {/* <div className="s1h-indi-option">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="s1h-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M16.6725 16.6412L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
          <div className="s1h-indi-option">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="s1h-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  opacity="0.4"
                  d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
                  fill="#fff"
                ></path>
                <path
                  d="M17.0809 14.1499C14.2909 12.2899 9.74094 12.2899 6.93094 14.1499C5.66094 14.9999 4.96094 16.1499 4.96094 17.3799C4.96094 18.6099 5.66094 19.7499 6.92094 20.5899C8.32094 21.5299 10.1609 21.9999 12.0009 21.9999C13.8409 21.9999 15.6809 21.5299 17.0809 20.5899C18.3409 19.7399 19.0409 18.5999 19.0409 17.3599C19.0309 16.1299 18.3409 14.9899 17.0809 14.1499Z"
                  fill="#fff"
                ></path>
              </g>
            </svg>
          </div> */}
        </div>
      </header>
      <img
        src={crousal[current_crousal].img}
        alt=""
        className="s1-whole-img"
        loading="lazy"
      />

      <div className="section-1-arrow-left">
        <div
          className="s1-arrow-div"
          onClick={() => {
            if (current_crousal == 0) {
              setCurrent_crousal(2);
            } else {
              setCurrent_crousal(current_crousal - 1);
            }
          }}
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="s1-arrow"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z"
                fill="#fff"
              ></path>
            </g>
          </svg>
        </div>
      </div>
      <div className="section-1-arrow-right">
        <div
          className="s1-arrow-div"
          onClick={() => {
            if (current_crousal == 2) {
              setCurrent_crousal(0);
            } else {
              setCurrent_crousal(current_crousal + 1);
            }
          }}
        >
          <svg
            className="s1-arrow"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z"
                fill="#fff"
              ></path>
            </g>
          </svg>
        </div>
      </div>

      <div className="section-1-footer">
        <div className="s1f-content-space"></div>
        <div className="s1f-content">
          <h1 className="s1f-content-title">
            {crousal[current_crousal].title}
          </h1>
          <p className="s1f-content-para">
            {crousal[current_crousal].description}
          </p>
        </div>
        <Link className="s1f-arrow-div" to={crousal[current_crousal].link}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="s1f-arrow"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                opacity="0.5"
                d="M4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75V11.25ZM4 12.75H20V11.25H4V12.75Z"
                fill="#fff"
              ></path>{" "}
              <path
                d="M14 6L20 12L14 18"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
          <div className="s1f-arrow-space"></div>
        </Link>
      </div>

      <div className="s1-dark-bottom"></div>
    </div>
  );
}

export default HeaderMobile;
