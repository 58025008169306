import Photo_section_desktop from "../DesktopComponents/Photo_section";
import newrafting1 from "../Public/newrafting1.jpg";
import newrafting2 from "../Public/newrafting2.jpg";
import newrafting3 from "../Public/newrafting3.jpg";
import newrafting4 from "../Public/newrafting4.jpg";
import newrafting5 from "../Public/newrafting5.jpg";
import newrafting6 from "../Public/newrafting6.jpg";

// import raftingnew from "../Public/rafting8.jpg";
// import campingnew from "../Public/newcamping2.jpg";
// import bungeenew from "../Public/bungee4.jpg";

import bungee1 from "../Public/bungee1.jpg";
import bungee2 from "../Public/bungee2.jpg";
import bungee3 from "../Public/bungee3.jpg";
import bungee4 from "../Public/bungee4.jpg";
import bungee5 from "../Public/bungee5.jpg";
import bungee6 from "../Public/bungee6.jpg";
import bungee7 from "../Public/bungee7.jpg";
import bungee8 from "../Public/bungee8.jpg";
// import bungee9 from "../Public/bungee9.jpg";

import newcamping1 from "../Public/newcamping1.jpg";
import newcamping2 from "../Public/newcamping2.jpg";
import newcamping3 from "../Public/newcamping3.jpg";
import newcamping4 from "../Public/newcamping4.jpg";
function Gallery() {
  return (
    <Photo_section_desktop
      gallery={true}
      photos={[
        newrafting1,
        newrafting2,
        newcamping1,
        newcamping2,
        bungee1,
        bungee2,
        newrafting3,
        newcamping3,
        bungee3,
        newrafting4,
        newcamping4,
        bungee4,
        newrafting5,
        bungee5,
        newrafting6,
        bungee6,
        bungee7,
        bungee8,
      ]}
    />
  );
}

export default Gallery;
