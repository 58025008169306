import "./Header.css";
import { Link } from "react-router-dom";
function Header({ active }) {
  return (
    <header className="home-desktop-header">
      <nav className="hd-header-nav">
        <Link
          className={active == "explore" ? "hd-hn-indi-active" : "hd-hn-indi"}
          to={"/"}
        >
          Explore
        </Link>
        <Link
          className={active == "rafting" ? "hd-hn-indi-active" : "hd-hn-indi"}
          to={"/rafting-in-rishikesh"}
        >
          Rafting
        </Link>
        <Link
          className={active == "camping" ? "hd-hn-indi-active" : "hd-hn-indi"}
          to={"/camping-in-rishikesh"}
        >
          Camping
        </Link>
        <Link
          className={active == "bungee" ? "hd-hn-indi-active" : "hd-hn-indi"}
          to={"/bungee-jumping-in-rishikesh"}
        >
          Bungee jump
        </Link>
        {/* <Link className="hd-hn-indi">Services</Link> */}
      </nav>
      <div className="hd-header-search-whole-div">
        {/* <Link className="hd-hn-indi" to={"/account/find-my-booking"}>
          Find Your Booking
        </Link> */}
        {/* <div className="hd-hswd-search">
          <div className="hd-hswd-svg-div">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hd-hswd-svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11ZM11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C13.125 20 15.078 19.2635 16.6177 18.0319L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L18.0319 16.6177C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2Z"
                  className="fill-white"
                ></path>{" "}
              </g>
            </svg>
          </div>
          <input
            type="text"
            className="hd-hswd-search-input"
            placeholder="Search an activity"
          />
        </div>
        <div className="hd-hswd-Login">Login</div> */}
      </div>
    </header>
  );
}

export default Header;
