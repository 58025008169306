import "./InputDate.css";
function InputDate({ title, placeholder, forhtml, set, get }) {
  return (
    <div className="whole-date-input">
      <div className="wti-title">{title}</div>
      <label htmlFor={forhtml} className="wti-input-label">
        {get == "" ? placeholder : get}
      </label>
      <input
        type="date"
        id={forhtml}
        className="wti-input-date"
        placeholder={placeholder}
        onInput={(t) => {
          set(t.target.value);
        }}
      />
    </div>
  );
}

export default InputDate;
