import "./IndiWishList.css";
import img from "../Public/Destination.jpg";
function IndiWishList() {
  return (
    <div className="whole-account-wishlist">
      <img
        src={img}
        alt=""
        className="whole-account-wishlist-img"
        loading="lazy"
      />
      <div className="whole-account-wl-dark">
        <div className="wawld-type">26 km</div>
        <div className="wawld-title">River Rafting</div>
      </div>
    </div>
  );
}

export default IndiWishList;
