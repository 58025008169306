import "./MainTitle.css";
function MainTitle({
  setForm,
  price,
  type,
  rapids,
  se,
  current_type,
  rapids_blink_mobile,
  km_blink_mobile,
  start_blink_mobile,
  end_blink_mobile,
}) {
  return (
    <div className="main-title">
      <div className="mt-price-info-section">
        <h2 className="mt-price">
          INR {price}
          <span className="color-black mt-price-small">/person</span>
        </h2>
        <p className="mt-info">
          <span ref={start_blink_mobile}>{se[current_type].start}</span> to{" "}
          <span ref={end_blink_mobile}> {se[current_type].end}</span>
        </p>
        <p className="mt-info">
          <span ref={km_blink_mobile}> {type}km </span>{" "}
          <div className="mt-dot"></div>
          <span ref={rapids_blink_mobile}> {rapids} rapids </span>
        </p>
      </div>
      <div className="mt-book-now-section">
        <button
          className="mt-book-now-btn"
          onClick={() => {
            setForm(true);
          }}
        >
          Book Now
        </button>
      </div>
    </div>
  );
}

export default MainTitle;
