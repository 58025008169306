import "./Terms_condition.css";
import Header from "./Header";
import Footer from "../GlobalComponents/Footer";
import DesktopFooter from "./DesktopFooter";
import BottomTab from "../ComponentsHome/BottomTab";
import { useEffect } from "react";
function PaymentAndBooking() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about_header">
        <Header />
      </div>
      <div className="p_P_div">
        <div className="pp_inner_div">
          <div className="Privacy_policy_div">
            <div className="h_text_one">Payment and Booking</div>

            <p>
              You can confirm your booking via using our payment and booking
              process, you need to pay 100% advance to confirm your booking.
            </p>

            <p>
              If you are confirming you booking on Whatsapp there is a option
              you confirm you booking by paying 20% advance.
            </p>

            <div className="h_text_one">
              <div className="h_two_text">Consent</div>
            </div>

            <p>
              1. The Customer will bear no cancellation charge on cancelling 30
              days prior to the booking date.
            </p>
            <p>
              2. The Customer will bear 50% of the booking amount as the
              cancellation fee if cancelled 15 days prior to the booking date.
            </p>
            <p>
              3. No refund will be made in case of cancelling 7 days prior to
              the booking date.
            </p>
            <p>
              4. If the customer fails to reach the destination on the day of
              booking, no refund will be processed.
            </p>
            <p>
              5. The refunds will be processed through original payment methods
              within 10 working days after cancellation.
            </p>
            <div className="h_text_one">
              <div className="h_two_text">Payment gateway and fees</div>
            </div>

            <p>
              The payment gateway is the name of Ashish Pundir as a individual.
            </p>
          </div>
        </div>
      </div>
      <div className="about_footer_desktop">
        <DesktopFooter />
      </div>
      <div className="about_footer_mobile">
        <Footer />
        <BottomTab />
      </div>
    </>
  );
}

export default PaymentAndBooking;
