import "./FeatureSection.css";
import { Link } from "react-router-dom";
export function FeatureSectionSmall({ img, title, description, link }) {
  return (
    <Link to={link == undefined ? "#" : link} className="feature-small">
      <div className="f-dark-bottom"></div>
      <div className="f-content">
        <h2 className="f-content-title">{title}</h2>
        <p className="f-content-para">{description}</p>
      </div>
      <img src={img} alt="" className="f-img" loading="lazy" />
    </Link>
  );
}

export function FeatureSectionLarge({ img, title, description, link }) {
  return (
    <Link to={link == undefined ? "#" : link} className="feature-large">
      <div className="f-dark-bottom"></div>
      <div className="f-content">
        <h2 className="f-content-title">{title}</h2>
        <p className="f-content-para">{description}</p>
      </div>
      <img src={img} alt="" className="f-img" loading="lazy" />
    </Link>
  );
}
