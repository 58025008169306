import "./Review.css";
export default function Review({ msg, name, image }) {
  return (
    <div className="whole-lp-review-section">
      <div>{msg}</div>
      <div className="lp-review-img-name-section">
        <img src={image} alt="" className="lp-review-img" />
        <div>{name}</div>
      </div>
    </div>
  );
}
