import "./Account.css";
import BottomTab from "../ComponentsHome/BottomTab";
import img from "../Public/Destination.jpg";
import pp from "../Public/camping.jpg";
import AccountMenu from "../GlobalComponents/AccountMenu";
import { useState } from "react";
// import InputEdit from "../GlobalFormComponents/InputEdit";
// import Button from "../GlobalFormComponents/Button";
import AccountAbout from "../GlobalComponents/AccountAbout";
import AccountBooking from "../GlobalComponents/AccountBooking";
import AccountCart from "../GlobalComponents/AccountCart";
import AccountWishList from "../GlobalComponents/AccountWishList";
import AccountCoupons from "../GlobalComponents/AccountCoupons";
import { Helmet } from "react-helmet";
function Account() {
  const [menu, setMenu] = useState(1);
  return (
    <>
      <Helmet>
        <title>Thrill Thirsty: about us</title>
        <meta
          name="description"
          content="Thrill Thirsty is a Rishishikesh-based adventure service provider. we believe in giving you the best experience in River rafting, camping, and bungee jumping."
        />
        {/* Other meta tags */}
      </Helmet>
      <div className="account-top-section">
        <img src={img} alt="" className="ats-img" loading="lazy" />
        <div className="ats-info">
          <div className="ats-img-div">
            <img src={pp} alt="" className="ats-img-div-img" loading="lazy" />
          </div>
          <div className="ats-name-div">Ankit Pundir</div>
          <div className="ats-title-div">Rafter · Bungee Jumper · Kayaker</div>
        </div>
        <div className="ats-statics-div">
          <div className="ats-sd-info-div">
            <div className="ats-sd-id-title">69</div>
            <div className="ats-sd-id-des">Activities</div>
          </div>
          <div className="ats-line"></div>
          <div className="ats-sd-info-div">
            <div className="ats-sd-id-title">20</div>
            <div className="ats-sd-id-des">Services</div>
          </div>
        </div>
        <div className="ats-dark-bottom"></div>
      </div>
      <AccountMenu menu={menu} setMenu={setMenu} />
      {menu == 1 ? (
        <AccountAbout />
      ) : menu == 2 ? (
        <AccountBooking />
      ) : menu == 3 ? (
        <AccountCart />
      ) : menu == 4 ? (
        <AccountWishList />
      ) : menu == 5 ? (
        <AccountCoupons />
      ) : null}

      <BottomTab active={"account"} />
    </>
  );
}

export default Account;
