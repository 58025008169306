import "./IndiType.css";
function IndiType({ type, active, select }) {
  return (
    <div
      className={active ? "main-indi-type-active" : "main-indi-type"}
      onClick={select}
    >
      {type}
    </div>
  );
}

export default IndiType;
