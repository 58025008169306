import "./InputDate.css";
function InputDate({
  title,
  placeholder,
  forhtml,
  setChoosen_date,
  choosen_date,
}) {
  return (
    <div className="dd-whole-date-input">
      <div className="dd-wti-title">{title}</div>
      {/* <label htmlFor={forhtml} className="dd-wti-input-label">
        {placeholder}
      </label> */}
      <input
        type="date"
        id={forhtml}
        className="dd-wti-input-date"
        placeholder={placeholder}
        value={choosen_date}
        onInput={(e) => {
          setChoosen_date(e.target.value);
        }}
      />
    </div>
  );
}

export default InputDate;
