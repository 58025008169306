import Indi_section from "../landing_page/Indi_section";
import Review from "../landing_page/Review";
import "./Ankit.css";
import Faq from "../landing_page/Faq";
import { useEffect, useState } from "react";
export default function Ankit() {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  const [faq5, setFaq5] = useState(false);
  const [faq6, setFaq6] = useState(false);
  return (
    <>
      <div className="landing-page">
        <div className="landing-thrill-name">
          <span className="landing-thrill-text mogra">Thrill</span>
          <span className="landing-thristy-text mogra">Thirsty</span>
        </div>
        <div className="landing-image-and-content-div">
          <div className="landing-image-header-div">
            <img
              src={require("../Public/rafting/r2.jpg")}
              alt=""
              className="landing-image-header"
            />
          </div>
          <div className="landeing-header-content-div">
            <div className="landeing-header-activity-name">
              River Rafting in Rishikesh
            </div>
            <div className="landeing-header-discription-name">
              Experince the most booked adventure activity of Rishikesh
            </div>
            <div className="landeing-header-title-name">
              Experience River Rafting in Rishikesh with Thrill Thirsty! We
              provide safe and thrilling rafting adventures, complete with
              top-notch equipment and experienced guides. Whether you’re a
              family group or a thrill seeker, we have something for everyone
            </div>
            <div className="landing-header-btn-div">
              <a className="landing-call-now-btn" href="tel://9084040280">
                Call now
              </a>
              <a
                className="landing-enquire-now-btn"
                href="http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty"
              >
                Enquire now
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="lp-strip">Rafting in Rishikesh</div>
      <div className="lp-strip-white">Rafting in Rishikesh</div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Indi_section
          image={require("../Public/rafting/r6.jpg")}
          title={"12 KM Rafting"}
          keys={["STARTING PRICE", "MIN AGE", "DURATION", "CHALLENGE LEVEL"]}
          values={["599PP", "14 Years", "2 Hours", "BEGINNER, INTERMEDIATE"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%2012km%20rafting"
          }
        />
        <Indi_section
          image={require("../Public/rafting/r11.jpg")}
          title={"16 KM Rafting"}
          keys={["STARTING PRICE", "MIN AGE", "DURATION", "CHALLENGE LEVEL"]}
          values={["999PP", "14 Years", "3 Hours", "BEGINNER, INTERMEDIATE"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%2016km%20rafting"
          }
        />
        <Indi_section
          image={require("../Public/rafting/r14.jpg")}
          title={"26 KM Rafting"}
          keys={["STARTING PRICE", "MIN AGE", "DURATION", "CHALLENGE LEVEL"]}
          values={[
            "1499PP",
            "14 Years",
            "3:30 Hours",
            "BEGINNER, INTERMEDIATE",
          ]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%2026km%20rafting"
          }
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Indi_section
          image={require("../Public/rafting/r16.jpg")}
          title={"36 KM Rafting"}
          keys={["STARTING PRICE", "MIN AGE", "DURATION", "CHALLENGE LEVEL"]}
          values={[
            "2499PP",
            "18 Years",
            "Full Day",
            "EXPERIENCED ,MOST CHALLENGING",
          ]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%2036km%20rafting"
          }
        />
        <Indi_section
          image={require("../Public/rafting/r2.jpg")}
          title={"12 KM Rafting"}
          keys={["STARTING PRICE", "MIN AGE", "DURATION", "CHALLENGE LEVEL"]}
          values={["599PP", "14 Years", "2 Hours", "BEGINNER, INTERMEDIATE"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%2012km%20rafting"
          }
        />
        <Indi_section
          image={require("../Public/rafting/r8.jpg")}
          title={"09 KM Rafting"}
          keys={["STARTING PRICE", "MIN AGE", "DURATION", "CHALLENGE LEVEL"]}
          values={["599PP", "12 Years", "1:30 Hours", "KID-FRIENDLY, BEGINNER"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%2009km%20rafting"
          }
        />
      </div>

      <div className="lp-strip">Other Activities</div>
      <div className="lp-strip-white">camping in rishikesh</div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Indi_section
          image={require("../Public/beachCamp/beachcamp4.jpeg")}
          title={"Normal Camp"}
          keys={["STARTING PRICE", "OCCUPANCY", "DURATION", "MEALS"]}
          values={["999PP", "2/3", "23 Hours", "3 Meals + Evening snacks"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%20normal%20camps"
          }
        />
        <Indi_section
          image={require("../Public/luxuryCamp/lc4.jpeg")}
          title={"Luxury Camp"}
          keys={["STARTING PRICE", "OCCUPANCY", "DURATION", "CHALLENGE LEVEL"]}
          values={["599PP", "2/3/4", "23 Hours", "3 Meals + Evening snacks"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%20luxury%20camps"
          }
        />
        <Indi_section
          image={require("../Public/ACCottages/ac2.jpeg")}
          title={"AC Cottages"}
          keys={["STARTING PRICE", "OCCUPANCY", "DURATION", "CHALLENGE LEVEL"]}
          values={["599PP", "2/3/4", "23 Hours", "3 Meals + Evening snacks"]}
          msg={
            "http://api.whatsapp.com/send?phone=9084040280&text=Hi%20Thrill%20Thirsty,%20I%20want%20to%20enquire%20about%20AC%20Cottages"
          }
        />
      </div>

      <div className="lp-strip">Bungee jumping in Rishikesh</div>
      {/* <div className="lp-strip-white">BUngee in rishikesh</div> */}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Indi_section
          image={require("../Public/bj55/bj55_1.png")}
          title={"Thrill Factory"}
          keys={["STARTING PRICE", "BUNGEE HEIGHT", "VIDEO CHARGES", "WEIGHT"]}
          values={["3000PP", "55 Mitr", "800", "40-110KG"]}
        />
        <Indi_section
          image={require("../Public/bj83/bj83_6.jpeg")}
          title={"Jumpin Heights"}
          keys={["STARTING PRICE", "BUNGEE HEIGHT", "VIDEO CHARGES", "WEIGHT"]}
          values={["3700PP", "83 Mitr", "900", "40-110KG"]}
        />
        <Indi_section
          image={require("../Public/bj117/bj117_1.jpeg")}
          title={"Splash Bungee"}
          keys={["STARTING PRICE", "BUNGEE HEIGHT", "VIDEO CHARGES", "WEIGHT"]}
          values={["4000PP", "107 Mitr", "FREE", "50-110KG"]}
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Indi_section
          image={`https://raftinggo.in/wp-content/uploads/2024/04/Bungee-jumping-1.jpg`}
          title={"Couple Bungee"}
          keys={["STARTING PRICE", "BUNGEE HEIGHT", "VIDEO CHARGES", "WEIGHT"]}
          values={["6000PC", "55 Mitr", "800", "80-160KG"]}
        />
        <Indi_section
          image={require("../Public/bj117/bj117_3.jpeg")}
          title={"Himaalayan Bungy"}
          keys={["STARTING PRICE", "BUNGEE HEIGHT", "VIDEO CHARGES", "WEIGHT"]}
          values={["4600PP", "117 Mitr", "1500", "50-110KG"]}
        />
        <Indi_section
          image={`https://raftinggo.in/wp-content/uploads/2024/04/Bungee-jumping-.png`}
          title={"Reverse Bungee"}
          keys={["STARTING PRICE", "BUNGEE HEIGHT", "VIDEO CHARGES", "WEIGHT"]}
          values={["3000Duo", "55 Mitr", "800", "50-110KG"]}
        />
      </div>

      <div className="lp-strip">Frequestly Asked Questions</div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Faq
          open={faq1}
          setOpen={setFaq1}
          question={"WHAT IS ELIGIBILITY CRITERIA FOR RAFTING?"}
          answer={
            "Rafting Min Age – 14Years \n\r Rafting Max age – 60 \nYears Max Weight – 100KG \nMin Weight – 40KG \nDrinking not Allowed Asthma Patients not allowed Heart Patients not allowed"
          }
        />
        <Faq
          open={faq2}
          setOpen={setFaq2}
          question={"WHERE TO KEEP OUR BAGS,MOBILE AND OTHER ACCESSORIES"}
          answer={
            "We provide you with a water proof dry bag for your items like wallets and mobile phones.For heavy bags, we have good space available at our office."
          }
        />
        <Faq
          open={faq3}
          setOpen={setFaq3}
          question={"CHECKIN AND CHECKOUT TIMEING OF CAMPING?"}
          answer={
            "Generally our Check In timing is 10-11AM and last checkin time is 8PM and our checkout timings are 9AM-11AM"
          }
        />
        <Faq
          open={faq4}
          setOpen={setFaq4}
          question={"CAN YOU PROVIDE US PICKUP AND DROP ?"}
          answer={
            "For camping, there is no transportation from us; you have to come by yourself. For rafting, transport from office to office is included in the package."
          }
        />
        <Faq
          open={faq5}
          setOpen={setFaq5}
          question={"WHICH ONE IS THE INDIN'S HEIGHEST BUNGEE?"}
          answer={
            "Himalayan Bungy with 117 Mitrs of hight is the India’s Hightest Bungee"
          }
        />
        <Faq
          open={faq6}
          setOpen={setFaq6}
          question={"WHAT ARE THE VIDEOS CHARGES IN BUNGEE?"}
          answer={
            "Video charges are diffrent for each bungee right now only splash bungee provide free videos and other charges between 800-1500."
          }
        />
      </div>

      <div className="lp-strip">Customers reviews</div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Review
          msg={
            "It was superb experience, they have trained staff who takes you for river rafting with all safety equipments and makes you experience memorable "
          }
          name={"Tarun vikani"}
          image={
            "https://raftinggo.in/wp-content/uploads/2024/04/unnamed-15.png"
          }
        />
        <Review
          msg={
            "Very best experience! It was really amazing & we enjoyed a lot ! Big thanks to bhandari sir which provided the best price"
          }
          name={"	Shubham panday"}
          image={
            "https://raftinggo.in/wp-content/uploads/2024/04/unnamed-16.png"
          }
        />
        <Review
          msg={
            "Ashish ji Thank u so much Also Thanks to our Guide Gajendra Bhai Excellent Rafting instructions/Guidance to our group members. Keyur Panchasara & Krunal Gajjar's Group from Ahmedabad ⭐⭐⭐⭐⭐ 👍🏻 "
          }
          name={"Keyur panchasara"}
          image={
            "https://raftinggo.in/wp-content/uploads/2024/04/unnamed-17.png"
          }
        />
        <Review
          msg={
            "We enjoyed to much, staff and timing is also good. Guide behaviour and habit is also loving. Please go and enjoy with raftingoo service."
          }
          name={"Rajan Chauhan"}
          image={
            "https://raftinggo.in/wp-content/uploads/2024/04/unnamed-18.png"
          }
        />
        <Review
          msg={
            "One of the best experiences that I had. Our instructor Vijender was amazing & made the rafting experience an exceptional one."
          }
          name={"Priya Maurya"}
          image={
            "https://raftinggo.in/wp-content/uploads/2024/04/unnamed-19.png"
          }
        />
        <Review
          msg={
            "Waves were good. Also water was cold. But overall nice experience to have Guide also guided very nicely Thank you for smooth conduct"
          }
          name={"Tejas varade"}
          image={
            "https://raftinggo.in/wp-content/uploads/2024/04/unnamed-20.png"
          }
        />
      </div>

      <div className="landing-bottom-div">
        <div className="landing-fotter-div">
          <div className="landing-fotter-content-div">
            <div className="landing-fotter-book-text">
              Book any Adventure activity in Rishikesh
            </div>
            <div className="landing-fotter-call-text">
              CALL NOW. AND GET A PERSONALIZED ASSISTANCE.
            </div>
          </div>
          <a href="tel://9084040280" className="landing-fotter-call-now-btn">
            Call now
          </a>
        </div>
      </div>
    </>
  );
}
