import "./MainTitle.css";
function MainTitleCamping({
  setForm,
  price,
  type,
  rapids,
  se,
  current_type,
  rapids_blink_mobile,
  km_blink_mobile,
  start_blink_mobile,
  end_blink_mobile,
}) {
  return (
    <div className="main-title">
      <div className="mt-price-info-section">
        <h2 className="mt-price">
          INR {price}
          <span className="color-black mt-price-small">/person</span>
        </h2>
        <p className="mt-info">2 Days & 1 Night</p>
        <p className="mt-info">Accomodation, activities, meals</p>
      </div>
      <div className="mt-book-now-section">
        <button
          className="mt-book-now-btn"
          onClick={() => {
            setForm(true);
          }}
        >
          Book Now
        </button>
      </div>
    </div>
  );
}

export default MainTitleCamping;
